import { Component, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NGXLogger } from 'ngx-logger';


@Component({
    selector: 'dialog-confirm',
    templateUrl: './dialog-confirm.component.html',
    styleUrls: ['./dialog-confirm.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {

    confirmText: string = 'Are you sure you want to perform this action?';

    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        // private _casesService: CasesService,
        public matDialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private logger: NGXLogger
    ) {

        if (data)
            this.confirmText = data;
        // Set the defaults

    }


    ok():void{
        this.matDialogRef.close(true);  
    }

    cancel():void{
        this.matDialogRef.close(false);  
    }
}
