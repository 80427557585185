import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { SelectCase, SetCasesFolder } from '../store/actions/cases.actions';
import { selectCasesList, selectSelectedCase } from '../store/selectors/cases.selector';
import { take, filter, tap, switchMap, map } from 'rxjs/operators';
import { GetCaseMessages } from '../store/actions/messages.actions';
import { selectCaseMessages } from '../store/selectors/messages.selector';
import { CaseFolderType, CaseSortType } from '@appmodels';
//import { CreateHub } from '../store/actions/appsignalr.actions';
import { VoidAction } from '../store/actions/app.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/router";
import * as i3 from "ngx-logger";
export class ChatResolver {
    constructor(_store, _router, logger) {
        this._store = _store;
        this._router = _router;
        this.logger = logger;
    }
    resolve(route, state) {
        const routeParams = route.params;
        this.logger.info('Chat route resolver: ', route.params);
        const obsCollection = [];
        if (routeParams.caseId) {
            obsCollection.push(this._store.select(selectSelectedCase).pipe(take(1), switchMap(selectedCase => {
                if (!selectedCase || selectedCase.id != routeParams.caseId) {
                    this.logger.debug('Case not selected, selecting case: ', routeParams.caseId);
                    this._store.dispatch(new SetCasesFolder(CaseFolderType.all, CaseSortType.LastMessageDate));
                    this._store.dispatch(new SelectCase(routeParams.caseId));
                    return this._store.select(selectSelectedCase).pipe(filter(selectedCase => selectedCase != null), take(1));
                }
                else {
                    this.logger.debug('Case already selected: ', selectedCase.id);
                    return of(null);
                }
            }), map(x => new VoidAction())));
            obsCollection.push(this._store.select(selectCaseMessages).pipe(filter(selectCaseMessages => selectCaseMessages != null), take(1)));
            obsCollection.push(of(this._store.dispatch(new GetCaseMessages(routeParams.caseId))));
        }
        else {
            console.log('no params!');
            //let casesReceived = false;
            this._store.dispatch(new SetCasesFolder(CaseFolderType.all, CaseSortType.LastMessageDate));
            obsCollection.push(this._store.select(selectCasesList).pipe(
            // takeWhile(cases => !casesReceived),              
            //  tap(cases => console.log('cases is ' + (cases ? 'not' : '') + ' null')),
            tap(cases => {
                if (cases != null) {
                    if (cases.length > 0) {
                        this._router.navigate(['chat', cases[0].id]);
                    }
                }
                //return of(null);
            }), filter(cases => cases != null && cases.length > 0), take(1)));
        }
        return forkJoin(obsCollection);
    }
}
ChatResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChatResolver_Factory() { return new ChatResolver(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.NGXLogger)); }, token: ChatResolver, providedIn: "root" });
