import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { Observable, of, forkJoin } from "rxjs";
import { SetAppLogsFilterParams } from "../store/actions/appLogs.actions";
import { selectAppLogsGroups } from "../store/selectors/appLogs.selector";
import { IAppState } from "../store/state/app.state";
import { filter, take, tap } from "rxjs/operators";
import { SetManagementOrganizationByName, SetManagementOrganizationIdSuccess } from "../store/actions/auth.actions";
import { selectManagementOrganizationId } from "../store/selectors/auth.selector";

@Injectable({ providedIn: 'root' })
export class RegisterResolver {
    constructor(private _store: Store<IAppState>, private logger: NGXLogger) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        const routeParams = route.params;       

        this.logger.info('Register route resolver: ', route.params)

        const obsCollection: Observable<any>[] = [];

        const organization = routeParams.organization ? routeParams.organization.trim() : null;
        
        if(!organization)
        {
            obsCollection.push(this._store.select(selectManagementOrganizationId).pipe(
                filter(organizationId => organizationId != null),           
                take(1)
            ));
    
            obsCollection.push(of(this._store.dispatch(new SetManagementOrganizationIdSuccess(1))));  
        }
        else
        {

            obsCollection.push(this._store.select(selectManagementOrganizationId).pipe(
                filter(organizationId => organizationId != null),           
                take(1)
            ));
    
            obsCollection.push(of(this._store.dispatch(new SetManagementOrganizationByName(organization)))); 
        }
         
        return forkJoin(obsCollection);
    }
}