export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',           
            'CASES'        : {
                'TITLE': 'Cases',
                'BADGE': '25'
            },
            'CHAT'        : {
                'TITLE': 'Chat',
                'BADGE': '25'
            },
            'DOWNLOADS' : {
                'TITLE': 'Downloads',
            },
            'ADMINISTRATION': 'Administration', 
            'DASHBOARD'        : {
                'TITLE': 'Dashboard',
                'BADGE': '0'
            },  
            'CASEMANAGEMENT'        : {
                'TITLE': 'Case management',
                'BADGE': '0'
            }, 
            'CASEQUALITYSURVEYS'        : {
                'TITLE': 'Case surveys',
                'BADGE': '0'
            },  
            'BATCHOPERATIONS'        : {
                'TITLE': 'Batch operations',
                'BADGE': '0'
            },         
            'USERS'        : {
                'TITLE': 'Users',
                'BADGE': '25'
            },
            'PAIDMODULES'        : {
                'TITLE': 'Paid modules',
                'BADGE': '0'              
            },
            'APPLOGS'        : {
                'TITLE': 'App logs',
                'BADGE': '25'
            },
            'PAYMENTS'        : {
                'TITLE': 'Payments',
                'BADGE': '25'
            }
        }
    }
};
