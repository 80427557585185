<div class="header accent p-24 pb-0" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="row" fxLayoutAlign="space-between start">

        <span class="mat-display-1 my-0 my-sm-24 welcome-message" [@animate]="{value:'*',params:{x:'50px'}}">Welcome
            back, {{currentUser.userName}}!
        </span>

        <!-- (click)="toggleSidebar('project-dashboard-right-sidebar-1')" -->
        <button mat-icon-button class="sidebar-toggle mr-8" fxHide.gt-md>
            <mat-icon>menu</mat-icon>
        </button>
    </div>

    <div fxLayout="row">

        <div class="selected-project">{{'Bonabyte planner'}}</div>

        <button mat-icon-button class="project-selector" [matMenuTriggerFor]="projectsMenu" aria-label="Select project">
            <mat-icon>more_horiz</mat-icon>
        </button>

        <mat-menu #projectsMenu="matMenu">
            <!-- *ngFor="let project of projects" (click)="selectedProject = project"  -->
            <button mat-menu-item>
                <span>{{'Bonabyte planner'}}</span>
            </button>
        </mat-menu>
    </div>
</div>