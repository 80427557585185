/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import * as i5 from "../../../../../@fuse/services/config.service";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/router";
import * as i8 from "@angular/material/core";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@angular/common";
import * as i11 from "@angular/flex-layout/extended";
import * as i12 from "./main.component";
var styles_AppLogsSidebarComponent = [i0.styles];
var RenderType_AppLogsSidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppLogsSidebarComponent, data: {} });
export { RenderType_AppLogsSidebarComponent as RenderType_AppLogsSidebarComponent };
export function View_AppLogsSidebarComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 30, "div", [["class", "sidebar-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 29, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "header p-24"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(4, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "avatar mr-16"], ["src", "/assets/images/avatars/profile.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "h5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["App logs"])), (_l()(), i1.ɵeld(8, 0, null, null, 22, "div", [["class", "content py-16"], ["fusePerfectScrollbar", ""]], null, [["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._updateOnResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).documentClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 4407296, null, 0, i4.FusePerfectScrollbarDirective, [i1.ElementRef, i5.FuseConfigService, i6.Platform, i7.Router], { enabled: [0, "enabled"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 20, "div", [["class", "nav material2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 9, "div", [["aria-label", "inbox"], ["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 8, "a", [["class", "nav-link mat-ripple"], ["matRipple", ""]], [[2, "mat-ripple-unbounded", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeFilter(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 212992, null, 0, i8.MatRipple, [i1.ElementRef, i1.NgZone, i6.Platform, [2, i8.MAT_RIPPLE_GLOBAL_OPTIONS], [2, i9.ANIMATION_MODULE_TYPE]], null, null), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(15, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(16, { "active accent": 0 }), i1.ɵdid(17, 933888, null, 0, i11.DefaultClassDirective, [i1.ElementRef, i3.StyleUtils, i3.MediaMarshaller, i10.ɵNgClassImpl, [6, i10.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(18, { "active accent": 0 }), (_l()(), i1.ɵeld(19, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Users only logs"])), (_l()(), i1.ɵeld(21, 0, null, null, 9, "div", [["aria-label", "inbox"], ["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 8, "a", [["class", "nav-link mat-ripple"], ["matRipple", ""]], [[2, "mat-ripple-unbounded", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeFilter(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 212992, null, 0, i8.MatRipple, [i1.ElementRef, i1.NgZone, i6.Platform, [2, i8.MAT_RIPPLE_GLOBAL_OPTIONS], [2, i9.ANIMATION_MODULE_TYPE]], null, null), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(25, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(26, { "active accent": 0 }), i1.ɵdid(27, 933888, null, 0, i11.DefaultClassDirective, [i1.ElementRef, i3.StyleUtils, i3.MediaMarshaller, i10.ɵNgClassImpl, [6, i10.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(28, { "active accent": 0 }), (_l()(), i1.ɵeld(29, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Users and admins logs"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "start center"; _ck(_v, 4, 0, currVal_1); var currVal_2 = ""; _ck(_v, 9, 0, currVal_2); _ck(_v, 13, 0); var currVal_4 = "nav-link"; var currVal_5 = _ck(_v, 16, 0, (_co.appLogsFilterParams.isWithAdmins == false)); _ck(_v, 15, 0, currVal_4, currVal_5); var currVal_6 = _ck(_v, 18, 0, (_co.appLogsFilterParams.isWithAdmins == false)); var currVal_7 = "nav-link"; _ck(_v, 17, 0, currVal_6, currVal_7); _ck(_v, 23, 0); var currVal_9 = "nav-link"; var currVal_10 = _ck(_v, 26, 0, (_co.appLogsFilterParams.isWithAdmins == true)); _ck(_v, 25, 0, currVal_9, currVal_10); var currVal_11 = _ck(_v, 28, 0, (_co.appLogsFilterParams.isWithAdmins == true)); var currVal_12 = "nav-link"; _ck(_v, 27, 0, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 13).unbounded; _ck(_v, 12, 0, currVal_3); var currVal_8 = i1.ɵnov(_v, 23).unbounded; _ck(_v, 22, 0, currVal_8); }); }
export function View_AppLogsSidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "contacts-main-sidebar", [], null, null, null, View_AppLogsSidebarComponent_0, RenderType_AppLogsSidebarComponent)), i1.ɵdid(1, 245760, null, 0, i12.AppLogsSidebarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppLogsSidebarComponentNgFactory = i1.ɵccf("contacts-main-sidebar", i12.AppLogsSidebarComponent, View_AppLogsSidebarComponent_Host_0, { appLogsFilterParams: "appLogsFilterParams" }, { onIsWithAdminsChanged: "onIsWithAdminsChanged" }, []);
export { AppLogsSidebarComponentNgFactory as AppLogsSidebarComponentNgFactory };
