import { BufferOffset } from "../models/planner/buffer/buffer-offset";
import { Vector3 } from "../models/planner/geometry/vector3";

export class BitUtils {

    private static _newLineSymbol: number = new TextEncoder().encode('\n')[0];

    //TODO: bit operations
    public static ReadByte(dataView: DataView, offsetObj: BufferOffset): number {
        const byte = dataView.getUint8(offsetObj.offset);
        offsetObj.offset += 1;
        return byte;
    }

    public static ReadBytes(dataView: DataView, count: number, offsetObj: BufferOffset): Uint8Array {

        const readedBytes = new Uint8Array(count);
        for (let i = 0; i < count; i++) {
            readedBytes[i] = this.ReadByte(dataView, offsetObj);            
        }
        return readedBytes;
    }

    public static ReadSingle(dataView: DataView, offsetObj: BufferOffset): number {
        const float = dataView.getFloat32(offsetObj.offset, true);
        offsetObj.offset += 4;
        return float;
    }

    public static ReadInt32(dataView: DataView, offsetObj: BufferOffset): number {
        const float = dataView.getInt32(offsetObj.offset, true);
        offsetObj.offset += 4;
        return float;
    }

    public static ReadUint32(dataView: DataView, offsetObj: BufferOffset): number {
        const float = dataView.getUint32(offsetObj.offset, true);
        offsetObj.offset += 4;
        return float;
    }

    public static ReadVector3(dataView: DataView, offsetO: BufferOffset): Vector3 {

        const x = this.ReadSingle(dataView, offsetO);
        const y = this.ReadSingle(dataView, offsetO);
        const z = this.ReadSingle(dataView, offsetO);

        const v = new Vector3(x, y, z)

        return v;
    }

    public static ReadLine(dataView: DataView, offsetObj: BufferOffset): string {

        let newLineSymbolFound = false;
        let line = '';

        while (!newLineSymbolFound) {
            const symUint8 = this.ReadByte(dataView, offsetObj); //dataView.getUint8(offsetObj.offset);
            //offsetObj.offset++;
            const sym = String.fromCharCode.apply(null, [symUint8]);

            if (symUint8 == this._newLineSymbol)
                newLineSymbolFound = true;
            else
                line += sym;
        }

        return line;
    }



    public static WriteByte(dataView: DataView, byteToWrite: number, offsetObj: BufferOffset) {
        dataView.setUint8(offsetObj.offset, byteToWrite);
        offsetObj.offset++;
    }

    public static WriteBytes(dataView: DataView, bytesToWrite: Uint8Array, offsetObj: BufferOffset) {
        for (let i = 0; i < bytesToWrite.byteLength; i++) {
            this.WriteByte(dataView, bytesToWrite[i], offsetObj);
        }
    }

    public static WriteUint32(dataView: DataView, val: number, offsetObj: BufferOffset) {
        dataView.setUint32(offsetObj.offset, val, true);
        offsetObj.offset += 4;
    }

    public static WriteInt32(dataView: DataView, val: number, offsetObj: BufferOffset) {
        dataView.setInt32(offsetObj.offset, val, true);
        offsetObj.offset += 4;
    }

    public static WriteSingle(dataView: DataView, val: number, offsetObj: BufferOffset) {
        dataView.setFloat32(offsetObj.offset, val, true);
        offsetObj.offset += 4;
    }

    public static WriteVector3(dataView: DataView, vector: Vector3, offsetObj: BufferOffset) {
        this.WriteSingle(dataView, vector.x, offsetObj);
        this.WriteSingle(dataView, vector.y, offsetObj);
        this.WriteSingle(dataView, vector.z, offsetObj);
    }
}