export class Vector3 {
    public x: number = 0;
    public y: number = 0;
    public z: number = 0;

    constructor();
    constructor(x: number, y: number, z: number);
    constructor(x?: number, y?: number, z?: number) {
        if (x)
            this.x = x;

        if (y)
            this.y = y;

        if (z)
            this.z = z;
    }

    public Normalize()
    {
      const num = Vector3.Magnitude(this);
      if (num > 9.999999747378752E-06)
      {
        const v = this.Div(num);
        this.x = v.x;
        this.y = v.y;
        this.z = v.z;
      }
        
      else
      {
        const zero = Vector3.zero();
        this.x = zero.x;
        this.y = zero.y;
        this.z = zero.z;
      }
        
    }

    public Sub(vectorToSub: Vector3): Vector3 {
        return new Vector3(this.x - vectorToSub.x, this.y - vectorToSub.y, this.z - vectorToSub.z);
    }

    public Div(d: number): Vector3{
        return new Vector3(this.x / d, this.y / d, this.z / d)
    }

    public static zero(): Vector3{
        return new Vector3(0,0,0);
    }

    public static Cross(lhs: Vector3, rhs: Vector3): Vector3 {
        return new Vector3((lhs.y * rhs.z - lhs.z * rhs.y), (lhs.z * rhs.x - lhs.x * rhs.z), (lhs.x * rhs.y - lhs.y * rhs.x));
    }

    public static Dot(lhs: Vector3,  rhs: Vector3): number
    {
      return (lhs.x * rhs.x + lhs.y * rhs.y + lhs.z * rhs.z);
    }

    public static Magnitude(vector: Vector3): number
    {
      return Math.sqrt(vector.x * vector.x + vector.y * vector.y + vector.z * vector.z);
    }

    public static SqrMagnitude(vector: Vector3): number {
        return (vector.x * vector.x + vector.y * vector.y + vector.z * vector.z);
    }
}