import { HttpClient } from "@angular/common/http";
import { AppConfig } from "app/app-config.module";
import { NGXLogger } from "ngx-logger";
import { map, tap } from "rxjs/operators";
import * as FileSaver from 'file-saver';
import * as i0 from "@angular/core";
import * as i1 from "../../../app-config.module";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-logger";
export class DocumentsService {
    constructor(config, http, logger) {
        this.config = config;
        this.http = http;
        this.logger = logger;
    }
    getBundlePdf(bundle) {
        const url = `${this.config.documentsEndpoint}/pdf/bundle?json_model=${escape(JSON.stringify(bundle))}`;
        this.logger.debug("HTTP GET document get bundle pdf", `Url: ${url}`);
        return this.http
            .get(url, {
            responseType: 'arraybuffer',
        })
            .pipe(tap(response => {
            this.logger.debug("Document get bundle:", response);
        }), map(response => {
            let blob = new Blob([response], { type: 'application/pdf' });
            FileSaver.saveAs(blob, 'Комплектация.pdf');
            return true;
        }));
    }
}
DocumentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DocumentsService_Factory() { return new DocumentsService(i0.ɵɵinject(i1.APP_CONFIG), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.NGXLogger)); }, token: DocumentsService, providedIn: "root" });
