import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { AppLogsService } from '@appservices';
import { MatSelectModule, MatChipsModule, MatAutocompleteModule } from '@angular/material';
import { AppLogsComponent } from './appLogs.component';
import { AppLogsListComponent } from './appLogs-list/appLogs-list.component';
import { AppLogsSelectedBarComponent } from './selected-bar/selected-bar.component';
import { AppLogsSidebarComponent } from './sidebars/main/main.component';
import { AppLogsGroupDetailsComponent } from './dialogs/appLogs-details.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';



// const routes: Routes = [
//     {
//         path     : '**',
//         component: ContactsComponent,
//         resolve  : {
//             contacts: ContactsService
//         }
//     }
// ];

@NgModule({
    declarations   : [
        AppLogsComponent,
        AppLogsListComponent,
        AppLogsSelectedBarComponent,
        AppLogsSidebarComponent,
        AppLogsGroupDetailsComponent
    ],
    imports        : [
        RouterModule,

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatSelectModule,
        MatChipsModule,
        MatAutocompleteModule,
        FuseSharedModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,
        InfiniteScrollModule
    ],
    providers      : [
        AppLogsService
    ],
    entryComponents: [
       AppLogsGroupDetailsComponent
    ]
})
export class AppLogsModule
{
}
