import { STLReader } from "../geometry/stl-reader";
import { PLYReader } from "../geometry/ply-reader";
import { Subject } from "rxjs";
import { MeshWeld } from "../geometry/mesh-weld";
import * as i0 from "@angular/core";
export class GeometryService {
    convertToPLY(file) {
        const obs = new Subject();
        var fileReader = new FileReader();
        fileReader.onload = function (event) {
            const arrayBuffer = event.target['result'];
            const stlReader = new STLReader();
            const plyReader = new PLYReader();
            const meshWeld = new MeshWeld();
            const mesh = stlReader.Read(arrayBuffer);
            const weldedMesh = meshWeld.FullWeld(mesh);
            // console.log(mesh);
            // console.log(weldedMesh);
            const fileBytes = plyReader.Write(weldedMesh);
            const convertedFile = new File([new Blob([fileBytes])], file.name + '.ply');
            obs.next(convertedFile);
            obs.complete();
        };
        fileReader.readAsArrayBuffer(file);
        return obs;
    }
    convertToSTL(file) {
        var fileReader = new FileReader();
        const obs = new Subject();
        fileReader.onload = function (event) {
            const arrayBuffer = event.target['result'];
            const stlReader = new STLReader();
            const plyReader = new PLYReader();
            const mesh = plyReader.Read(arrayBuffer);
            const fileBytes = stlReader.Write(mesh);
            let newFileName = file.name + '.stl';
            const stlExtLastIdx = file.name.lastIndexOf('.stl');
            if (stlExtLastIdx == file.name.length - (4 + 4)) {
                newFileName = file.name.slice(0, stlExtLastIdx) + '.stl';
            }
            const convertedFile = new File([new Blob([fileBytes])], newFileName);
            obs.next(convertedFile);
            obs.complete();
        };
        fileReader.readAsArrayBuffer(file);
        return obs;
    }
}
GeometryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GeometryService_Factory() { return new GeometryService(); }, token: GeometryService, providedIn: "root" });
