import { Gender, OperationType, SceneType, MedicalReferals } from '@appmodels';

export class PlannerCaseCommand {
    public title: string = null;
    public patientName: string = null;
    public patientCode: string = null;
    public surgicalDate: Date = null;
    public operationType: OperationType  = null;
    public sceneType: SceneType  = null;
    public serializedData: string  = null;
    public isAllowAll: boolean = null;
    public isArchived: boolean = null;
    public isExcludeFromAnalytics: boolean = null;
    public medicalReferalsType: MedicalReferals = null;
    public gender: Gender = null;
    public service: string = null;
    public procedures: string = null;
    public birthday: Date = null;
    public surgicalSide: string = null;
    public scanCenter: string = null;
    public shipment: string = null;
    public description: string = null;
}

export class CreatePlannerCaseCommand extends PlannerCaseCommand {
    public ownerId: string = null;
}

export class UpdatePlannerCaseCommand extends PlannerCaseCommand {
    public id: string = null;
}
