import { Store } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { of, forkJoin } from "rxjs";
import { filter, take } from "rxjs/operators";
import { SetManagementOrganizationByName, SetManagementOrganizationIdSuccess } from "../store/actions/auth.actions";
import { selectManagementOrganizationId } from "../store/selectors/auth.selector";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "ngx-logger";
export class RegisterResolver {
    constructor(_store, logger) {
        this._store = _store;
        this.logger = logger;
    }
    resolve(route, state) {
        const routeParams = route.params;
        this.logger.info('Register route resolver: ', route.params);
        const obsCollection = [];
        const organization = routeParams.organization ? routeParams.organization.trim() : null;
        if (!organization) {
            obsCollection.push(this._store.select(selectManagementOrganizationId).pipe(filter(organizationId => organizationId != null), take(1)));
            obsCollection.push(of(this._store.dispatch(new SetManagementOrganizationIdSuccess(1))));
        }
        else {
            obsCollection.push(this._store.select(selectManagementOrganizationId).pipe(filter(organizationId => organizationId != null), take(1)));
            obsCollection.push(of(this._store.dispatch(new SetManagementOrganizationByName(organization))));
        }
        return forkJoin(obsCollection);
    }
}
RegisterResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegisterResolver_Factory() { return new RegisterResolver(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.NGXLogger)); }, token: RegisterResolver, providedIn: "root" });
