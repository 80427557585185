import { initialAppLogsState, IAppLogsState } from '../state/appLogs.interface';
import { EAppLogsActions, AppLogsActions } from '../actions/appLogs.actions';

export function appLogsReducers(
    state = initialAppLogsState,
    action: AppLogsActions
): IAppLogsState {
    switch (action.type) {
        case EAppLogsActions.SetAppLogsFilterParamsSuccess:
            {
                return {
                    ...state,
                    appLogsGroups: [],
                    isWithAdmins: action.isWithAdmins
                }
            }
            case EAppLogsActions.SetAppLogsSearchPatternSuccess:
                {
                    return {
                        ...state,
                        appLogsGroups: [],
                        searchPattern: action.payload
                    }
                }
        case EAppLogsActions.GetAppLogsGroupsSuccess:
            {
                const existedAppLogs = state.appLogsGroups ? state.appLogsGroups : [];
                const newAppLogs = existedAppLogs.concat(action.payload);
                return {
                    ...state,
                    appLogsGroups: newAppLogs
                }
            }
            case EAppLogsActions.GetAppLogsByConnectionSuccess:
                {                
                    return {
                        ...state,
                        appLogsByConnection: action.payload
                    }
                }
        default:
            {
                return state;
            }
    }
}