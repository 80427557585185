import { Injectable } from "@angular/core";
import { STLReader } from "../geometry/stl-reader";
import { PLYReader } from "../geometry/ply-reader";
import { Observable, Subject } from "rxjs";
import { MeshWeld } from "../geometry/mesh-weld";
import { lastIndexOf } from "lodash";
import { MeshVolume } from "../geometry/mesh-volume";

@Injectable({ providedIn: 'root' })
export class GeometryService {

    public convertToPLY(file: File): Observable<File> {

        const obs = new Subject<File>();

        var fileReader = new FileReader();
        fileReader.onload = function (event) {
            const arrayBuffer = event.target['result'] as Uint8Array;
            const stlReader = new STLReader();
            const plyReader = new PLYReader();
            const meshWeld = new MeshWeld();
            const mesh = stlReader.Read(arrayBuffer);
            const weldedMesh = meshWeld.FullWeld(mesh);
            // console.log(mesh);
            // console.log(weldedMesh);
            const fileBytes = plyReader.Write(weldedMesh);

            const convertedFile = new File([new Blob([fileBytes])], file.name + '.ply');

            obs.next(convertedFile)
            obs.complete()
        };
        fileReader.readAsArrayBuffer(file);

        return obs;
    }

    public convertToSTL(file: File): Observable<File> {

        var fileReader = new FileReader();

        const obs = new Subject<File>();


        fileReader.onload = function (event) {

            const arrayBuffer = event.target['result'] as Uint8Array;
            const stlReader = new STLReader();
            const plyReader = new PLYReader();
            
            const mesh = plyReader.Read(arrayBuffer);

            const fileBytes = stlReader.Write(mesh);

            let newFileName = file.name + '.stl';

            const stlExtLastIdx = file.name.lastIndexOf('.stl');

            if(stlExtLastIdx == file.name.length - (4+4))
            {
                newFileName = file.name.slice(0, stlExtLastIdx) + '.stl';
            }

            const convertedFile = new File([new Blob([fileBytes])], newFileName);

            obs.next(convertedFile)
            obs.complete()
        };

        fileReader.readAsArrayBuffer(file);      

        return obs;
    }
}