<!-- SIDEBAR -->
<!-- <fuse-sidebar class="sidebar" name="contacts-main-sidebar" position="left" lockedOpen="gt-sm">

                 
                    <div class="content" fusePerfectScrollbar>
                        <contacts-main-sidebar (onGenerateLicense)="generateLicenseHandler($event)"
                            (onUsersFilterChanged)="usersFilterChanged($event)" (onExportUsersClicked)="exportUsers()"
                            [@animate]="{value:'*'}"></contacts-main-sidebar>
                    </div>
                

                </fuse-sidebar> -->
<!-- / SIDEBAR -->

<!-- CENTER -->
<div class="center p-24 pb-56 pr-sm-92" fusePerfectScrollbar>

    <paidModules-list [paidModules]="paidModules$ | async" [users]="users$ | async"
        (onUsersPaidModuleEdit)="editUsersPaidModule($event)" (onUsersPaidModuleDelete)="deleteUsersPaidModule($event)"
        (onUsersSearch)="usersSearch($event)">

    </paidModules-list>

    <!-- / CONTENT -->

</div>
<!-- / CENTER -->
<!-- ADD CONTACT BUTTON -->
<button mat-fab class="accent" id="add-contact-button" (click)="newUsersPaidModule()" aria-label="add contact"
    [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
    <mat-icon>add_circle_outline
    </mat-icon>
</button>
