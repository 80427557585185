import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { PlannerUsersAvailableModules } from "@appmodels";
import { fuseAnimations } from "@fuse/animations";
import { select, Store } from "@ngrx/store";
import { AddUsersPaidModules, EditUsersPaidModules, RemoveUsersPaidModules } from "app/core/store/actions/paidModules.actions";
import { selectUsersPaidModules } from "app/core/store/selectors/paidModules.selector";
import { IAppState } from "app/core/store/state/app.state";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PaidModulesFormDialogComponent } from "./paidModules-form/paidModules-form.component";
import { MatDialog } from "@angular/material";
import { selectUsers } from "app/core/store/selectors/users.selectors";
import { PlannerUser } from "app/core/models/planner/plannerUser";
import { SetUsersFilter } from "app/core/store/actions/users.actions";

@Component({
    selector: 'online-licenses',
    templateUrl: './online-licenses.component.html',
    styleUrls: ['./online-licenses.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class OnlineLicensesComponent implements OnInit, OnDestroy {
    dialogRef: any;
    paidModules$: Observable<PlannerUsersAvailableModules[]>;   
    users$: Observable<PlannerUser[]>;
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _matDialog: MatDialog,
        private _store: Store<IAppState>
    )
    {
        this._unsubscribeAll = new Subject();
    }

    addUsersPaidModule(addUsersPaidModule: PlannerUsersAvailableModules): void {


        if (!addUsersPaidModule)
            return;

        this._store.dispatch(new AddUsersPaidModules(addUsersPaidModule));

    }

    editUsersPaidModule(editUsersPaidModule: PlannerUsersAvailableModules): void {

        if (!editUsersPaidModule)
            return;
            
        this._store.dispatch(new EditUsersPaidModules(editUsersPaidModule));
    }

    deleteUsersPaidModule(deletedUsersPaidModule: PlannerUsersAvailableModules): void {

        if (!deletedUsersPaidModule)
            return;

        this._store.dispatch(new RemoveUsersPaidModules(deletedUsersPaidModule));
    }

    /**
     * New contact
     */
    newUsersPaidModule(): void {

        this.dialogRef = this._matDialog.open(PaidModulesFormDialogComponent, {
            panelClass: 'paidModules-form-dialog',
            data: {
                action: 'new'
            }
        });

        const instance = this.dialogRef.componentInstance;

        this.users$.pipe(takeUntil(this._unsubscribeAll)).subscribe((users) => {
            instance.users = users;
        });


        instance.onSearchQueryChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((query) => {
            this.usersSearch(query);
        });


        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }

                const actionType: string = response[0];

                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':

                        const newPaidModule: PlannerUsersAvailableModules = response[1];

                        this.addUsersPaidModule(newPaidModule);

                        break;
                    /**
                     * Delete
                     */
                    case 'cancel':

                        //this.deleteContact(user);

                        break;
                }
            });
    }

    usersSearch(searchQuery: string): void {
        this._store.dispatch(new SetUsersFilter({ searchQuery: searchQuery }))
    }

    usersFilterChanged(filter: any): void {


        this._store.dispatch(new SetUsersFilter(filter));

    }



    ngOnInit(): void {

        this.users$ = this._store.pipe(select(selectUsers));
        this.paidModules$ = this._store.pipe(select(selectUsersPaidModules));
       
    }
    ngOnDestroy(): void {
         // Unsubscribe from all subscriptions
         this._unsubscribeAll.next();
         this._unsubscribeAll.complete();
    }
}