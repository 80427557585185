import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';


import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { PaidModulesFormDialogComponent } from '../paidModules-form/paidModules-form.component';
import { PlannerModuleType, PlannerUsersAvailableModules, SceneType } from '@appmodels';
import { ConfirmDialogComponent } from 'app/core/shared';


@Component({
    selector: 'paidModules-list',
    templateUrl: './paidModules-list.component.html',
    styleUrls: ['./paidModules-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaidModulesListComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', { static: false })
    dialogContent: TemplateRef<any>;

    contacts: any;
    //user: any;
    displayedColumns = ['email', 'name', 'hospital', 'moduleType', 'startDate', 'daysAllocated', 'daysRemaining', 'buttons'];
    selectedContacts: any[];
    checkboxes: {};
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    moduleTypeEnum = PlannerModuleType;

    // Private
    private _unsubscribeAll: Subject<any>;

    @Input()
    paidModules: PlannerUsersAvailableModules[] = [];

    @Input()
    set users(value: PlannerUser[]) {
        this.users$.next(value);
    }x

    users$: Subject<PlannerUser[]>;

    @Output()
    onUsersPaidModuleEdit: EventEmitter<any>;

    @Output()
    onUsersPaidModuleDelete: EventEmitter<PlannerUsersAvailableModules>;

    @Output()
    onUsersSearch: EventEmitter<string>;

    /**
     * Constructor
     *
     * @param {UsersService} _contactsService
     * @param {MatDialog} _matDialog
     */
    constructor(

        public _matDialog: MatDialog
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.users$ = new Subject();

        this.onUsersPaidModuleEdit = new EventEmitter();
        this.onUsersPaidModuleDelete = new EventEmitter();
        this.onUsersSearch = new EventEmitter();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Edit user
     *
     * @param user
     */
    editUser(user): void {
        this.dialogRef = this._matDialog.open(PaidModulesFormDialogComponent, {
            panelClass: 'paidModules-form-dialog',
            data: {
                paidModule: user,
                action: 'edit'
            }
        });

        const instance = this.dialogRef.componentInstance;

        this.users$.pipe(takeUntil(this._unsubscribeAll)).subscribe((users) => {
            instance.users = users;
        });


        instance.onSearchQueryChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((query) => {
            this.onUsersSearch.emit(query);
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }

                const actionType: string = response[0];
                   
                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':

                        const editedUsersPaidModule: PlannerUsersAvailableModules = response[1];
     
                        this.onUsersPaidModuleEdit.emit(editedUsersPaidModule);

                        break;
                    /**
                     * Delete
                     */
                    case 'cancel':

                        //this.deleteContact(user);

                        break;
                }
            });
    }

    deleteUsersPaidModule(paidModule: PlannerUsersAvailableModules): void {

        const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
            panelClass: 'dialog-confirm',
            data: `Are you sure you want to delete user paid module?`
        });

        confirmDialogRef.afterClosed()
            .subscribe(confirmResult => {

                if (!confirmResult) {
                    return;
                }

                this.onUsersPaidModuleDelete.emit(paidModule);
            });       
    }

    /**
     * Delete Contact
     */
    // deleteContact(contact): void {
    //     this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
    //         disableClose: false
    //     });

    //     this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

    //     this.confirmDialogRef.afterClosed().subscribe(result => {
    //         if (result) {
    //             // this._contactsService.deleteContact(contact);
    //         }
    //         this.confirmDialogRef = null;
    //     });

    // }

    /**
     * On selected change
     *
     * @param contactId
     */
    onSelectedChange(contactId): void {
        //  this._contactsService.toggleSelectedContact(contactId);
    }

    /**
     * Toggle star
     *
     * @param contactId
     */
    // toggleStar(contactId): void {
    //     if (this.user.starred.includes(contactId)) {
    //         this.user.starred.splice(this.user.starred.indexOf(contactId), 1);
    //     }
    //     else {
    //         this.user.starred.push(contactId);
    //     }

    //     //  this._contactsService.updateUserData(this.user);
    // }
}
