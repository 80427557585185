import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { distinctUntilChanged, map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class S3StorageService {
    constructor(http) {
        this.http = http;
    }
    uploadToBlobStorage(file, preSignedUrl, fileBlobName) {
        const headers = new HttpHeaders().set('Content-type', '');
        return this.http.put(preSignedUrl, file, { reportProgress: true, observe: "events", headers: headers })
            .pipe(map(event => {
            if (event.type === HttpEventType.UploadProgress) {
                return event.loaded;
            }
            if (event.type === HttpEventType.Response) {
                return file.size;
            }
        }), distinctUntilChanged());
    }
}
S3StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function S3StorageService_Factory() { return new S3StorageService(i0.ɵɵinject(i1.HttpClient)); }, token: S3StorageService, providedIn: "root" });
