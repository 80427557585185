import { Component, ViewEncapsulation, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ClonePlannerCaseBatchCommand, LongRunningTaskInfo } from "@appmodels";
import { fuseAnimations } from "@fuse/animations";

@Component({
    selector: 'batch-clone',
    templateUrl: './batch-clone.component.html',
    styleUrls: ['./batch-clone.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    // changeDetection: ChangeDetectionStrategy.Default
})
export class BatchCloneComponent implements OnInit {

    private batchCloneForm: FormGroup;
    private requestInProgress: boolean;

    private _caseCloneBatchInfo: LongRunningTaskInfo;

    public lastCaseCloneBatchInfo: LongRunningTaskInfo;

    @Input()
    public set caseCloneBatchInfo(value: LongRunningTaskInfo) {

        this._caseCloneBatchInfo = value;

        if (!value)
            return;

        this.lastCaseCloneBatchInfo = value;

        if (value.isCompleted) {
            this.requestInProgress = false;
            this._caseCloneBatchInfo = null;
        }
    }

    public get caseCloneBatchInfo(): LongRunningTaskInfo {
        return this._caseCloneBatchInfo;
    }

    @Output()
    public onBatchClone: EventEmitter<ClonePlannerCaseBatchCommand>;
    /**
     *
     */
    constructor() {

        this.batchCloneForm = new FormGroup({

            caseId: new FormControl('', Validators.required),
            newTitle: new FormControl(''),
            isExcludeFromAnalytics: new FormControl(true, Validators.required),
            usersEmails: new FormControl('', Validators.required),

        });

        this.onBatchClone = new EventEmitter();
    }

    ngOnInit(): void {

    }

    public batchClone(): void {

        this.requestInProgress = true;
        this.lastCaseCloneBatchInfo = null;

        const caseId = this.batchCloneForm.controls['caseId'].value + '';
        //this.batchCloneForm.controls['caseId'].setValue('');
        const newTitle = this.batchCloneForm.controls['newTitle'].value + '';
        const isExcludeFromAnalytics = this.batchCloneForm.controls['isExcludeFromAnalytics'].value;
        const userEmailsString = this.batchCloneForm.controls['usersEmails'].value + '';

        console.log(userEmailsString);

        const userEmails = userEmailsString.split(/[\s,]+/);

        const clonePlannerCaseBatchCommand = new ClonePlannerCaseBatchCommand();
        clonePlannerCaseBatchCommand.caseId = caseId;
        clonePlannerCaseBatchCommand.newTitle = newTitle;
        clonePlannerCaseBatchCommand.isExcludeFromAnalytics = isExcludeFromAnalytics;
        clonePlannerCaseBatchCommand.newOwnersEmails = userEmails;

        this.onBatchClone.emit(clonePlannerCaseBatchCommand);
    }
}