export const locale = {
    lang: 'en',
    data: {
        MAIL: {
            CASECREATE                 : 'CASE CREATE',
            FOLDERS                 : 'FOLDERS',
            FILTERS                 : 'FILTERS',
            LABELS                  : 'LABELS',
            NO_MESSAGES             : 'There are no cases!',
            SELECT_A_MESSAGE_TO_READ: 'Select a case to view info',
            SEARCH_PLACEHOLDER      : 'Search for an e-mail or contact'
        }
    }
};
