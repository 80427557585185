import { selectUsers } from '../store/selectors/users.selectors';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of, forkJoin } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ResetUsersFilter } from '../store/actions/users.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "ngx-logger";
export class UsersResolver {
    constructor(_store, logger) {
        this._store = _store;
        this.logger = logger;
    }
    resolve(route, state) {
        const routeParams = route.params;
        this.logger.info('Users route resolver: ', route.params);
        const obsCollection = [];
        obsCollection.push(this._store.select(selectUsers).pipe(filter(allUsers => allUsers != null), take(1)));
        obsCollection.push(of(this._store.dispatch(new ResetUsersFilter({ isWithAdmins: false }))));
        return forkJoin(obsCollection);
    }
}
UsersResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersResolver_Factory() { return new UsersResolver(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.NGXLogger)); }, token: UsersResolver, providedIn: "root" });
