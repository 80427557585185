import { NgModule } from "@angular/core";
import { MatSnackBarModule } from "@angular/material";
import { BrowserModule } from "@angular/platform-browser";
import { AppNotificationsComponent } from "./app-notifications.component";
import { NotificationSnackbarComponent } from "./snackbar/notification-snackbar.component";

@NgModule({
    declarations   : [
        AppNotificationsComponent,
        NotificationSnackbarComponent
    ],
    imports        : [
        //BrowserModule,
MatSnackBarModule
    ],  
    exports     : [
        AppNotificationsComponent
    ],
    entryComponents: [
        NotificationSnackbarComponent
    ]
})
export class AppNotificationsModule
{
}