<div class="dialog-content-wrapper">
    <div mat-dialog-content class="p-8 m-0" fxLayout="column">
        <div fxFlex class="p-8 confirm-text" fxLayoutAlign="center"> <span class="mat-title">{{confirmText}}</span>
        </div>
    </div>
    <div mat-dialog-actions class="m-0 p-8" fxLayout="row" fxLayoutAlign="center center">

        <button mat-raised-button class="confirm-button" color="accent" (click)="ok()">
            Ok
        </button>

        <button mat-raised-button class="confirm-button" (click)="cancel()">
            Cancel
        </button>

    </div>
</div>