import { Injectable, Inject } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';
//import { SIGNALR_HUB_UNSTARTED, mergeMapHubToAction, startSignalRHub, SIGNALR_CONNECTED, createSignalRHub, stopSignalRHub, selectHubsStatuses, SignalRHub, signalrHubUnstarted, SignalRHubState, SIGNALR_DISCONNECTED } from 'ngrx-signalr-core';
import { tap, map, switchMap, catchError, switchMapTo, take, filter, takeUntil, takeWhile, mergeMap } from 'rxjs/operators';
import { of, merge, forkJoin, Subject, pipe } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { NGXLogger } from 'ngx-logger';
import { VoidAction } from '../actions/app.actions';
import { selectSelectedCase } from '../selectors/cases.selector';
import { selectCurrentUser } from '../selectors/users.selectors';
import { EAppSignalRActions, CreateHub, DisconnectHub } from '../actions/appsignalr.actions';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { AuthenticationService } from '@appservices';
import { GetCaseMessages, NewMessageFromServer } from '../actions/messages.actions';
import { basename } from 'path';
import * as signalr from '@microsoft/signalr';
import { CaseSceneSavedNotification, ChatNewMessageNotification } from '@appmodels';
import { NewNotification } from '../actions/notifications.actions';
import { ObjectDateFieldsFormatter } from 'app/core/helpers';
import { CaseSceneSavedFromServer } from '../actions/cases.actions';
import { Observable } from 'rxjs';

import { from } from 'rxjs';


@Injectable()
export class AppSignalREffects {

   // private _setCaseMethodName: string = "SetConnectionCase";
   // private _setConnectionAppConnectionUid: string = "SetConnectionAppConnectionUid";

  //  private _customState: Subject<boolean>;

    private _hubConnection: signalr.HubConnection;

    @Effect()
    signalRCreateHub$ = this._actions$.pipe(
        ofType<CreateHub>(EAppSignalRActions.CreateHub),
        tap(x => this.logger.log('SIGNALR_HUB_CREATING')),
        switchMap(_ => {
            return this._store.select(selectCurrentUser)
        }),
        switchMap(user => {


            //TODO: check valid token


            if (user != null) {

                const options = {
                    accessTokenFactory: () => {
                        return user.token;
                    }
                };

                this._hubConnection = new signalr.HubConnectionBuilder()
                    .withUrl(this.config.signalREndpoint, options)
                    .withAutomaticReconnect()
                    .configureLogging(this.logger)
                    .build();

                    
                    this._hubConnection.on("OnServerNewMessage", (data: ChatNewMessageNotification) => {

                        ObjectDateFieldsFormatter.convertFieldsDate(data);
                        this.logger.log('SignalR', 'OnServerNewMessage', data);

                        this._store.dispatch(new NewMessageFromServer(data));
                      });

                      this._hubConnection.on("OnServerCaseSceneSaved", (data: CaseSceneSavedNotification) => {

                        ObjectDateFieldsFormatter.convertFieldsDate(data);
                        this.logger.log('SignalR', 'OnServerCaseSceneSaved', data);

                        this._store.dispatch(new CaseSceneSavedFromServer(data));
                      });

             return from(this._hubConnection.start()).pipe(
                    tap(_=> this.logger.log("SIGNALR_CONNECTED")),
                    map(_=> new VoidAction())
                );                
            }

            return [new VoidAction()];
        })
    );

    @Effect()
    signalRDisconnect$ = this._actions$.pipe(
        ofType<DisconnectHub>(EAppSignalRActions.DisconnectHub),
        tap(x => this.logger.log('SIGNALR_HUB_DISCONNECTING')),
        switchMap(() => {

            return from(this._hubConnection.stop()).pipe(
                tap(_=> this.logger.log("SIGNALR_DISCONNECTED")),
                map(_=> new VoidAction())
            );
        })

    );

    constructor(
        @Inject(APP_CONFIG) private config: AppConfig,
        private _authenticationService: AuthenticationService,
        private logger: NGXLogger,
        private _actions$: Actions,
        private _store: Store<IAppState>
    ) {
    //    this._customState = new Subject<boolean>();
    }
}