import { createSelector } from "@ngrx/store";
import { IAppState } from "../state/app.state";
import { IPaidModulesState } from "../state/paidModules.state";

const selectPaidModules = (state: IAppState) => state.paidModules;

export const selectUsersPaidModules = createSelector(
    selectPaidModules,
    (state: IPaidModulesState) => state.usersPaidModules
)

export const selectPaidModulesSearchPattern = createSelector(
    selectPaidModules,
    (state: IPaidModulesState) => state.searchPattern
)


export const selectUsersPaidModulesOffline = createSelector(
    selectPaidModules,
    (state: IPaidModulesState) => state.usersPaidModulesOffline
)

// export const selectPaidModulesOfflineSearchPattern = createSelector(
//     selectPaidModules,
//     (state: IPaidModulesState) => state.searchPatternOffline
// )