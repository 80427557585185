import * as i0 from "@angular/core";
export class SettingsService {
    constructor() {
        this.ModulesFiltersSetting = 'modulesFilters';
    }
    getModulesFilters() {
        const loadedModulesFiletrs = JSON.parse(localStorage.getItem(this.ModulesFiltersSetting));
        if (loadedModulesFiletrs)
            return loadedModulesFiletrs;
        return [];
    }
    setModulesFilters(modulesFilters) {
        localStorage.setItem(this.ModulesFiltersSetting, JSON.stringify(modulesFilters));
    }
}
SettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(); }, token: SettingsService, providedIn: "root" });
