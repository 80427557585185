<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{isEditingCase ? 'Edit case' : 'New case'}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>



        <form name="composeForm" [formGroup]="caseEditForm" class="case-edit-form" fxLayout="column" fxFlex>

            <!-- <mat-form-field appearance="outline">
                <mat-label>Owner</mat-label>
                <input matInput name="owner" formControlName="owner" type="email">
            </mat-form-field> -->


            <mat-form-field appearance="outline">
                <mat-label>Owner</mat-label>
                <input type="text" placeholder="Search user..." aria-label="User email" matInput formControlName="owner"
                    #owner (input)="onSearchQuery(owner.value)" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let user of foundUsers" [value]="user.email">
                        {{user.email}}
                    </mat-option>
                </mat-autocomplete>

                <mat-error *ngIf="caseEditForm.get('owner').hasError('required')">
                    User is required
                </mat-error>
                <mat-error
                    *ngIf="!caseEditForm.get('owner').hasError('required') && caseEditForm.get('owner').hasError('wrongUserEmail')">
                    Wrong user email
                </mat-error>
            </mat-form-field>



            <div class="show-hide-extra-fields" (click)="toggleExtraToFields()">
                <span [fxShow]="!showExtraToFields">Show not required fields</span>
                <span [fxShow]="showExtraToFields">Hide not required fields</span>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Title</mat-label>
                <input matInput name="title" formControlName="title" type="text">
            </mat-form-field>


            <div class="slideToggleGroup" [fxShow]="showExtraToFields">

                <mat-slide-toggle name="isAllowAll" formControlName="isAllowAll">
                    Is allow all
                </mat-slide-toggle>

                <mat-slide-toggle name="isArchived" formControlName="isArchived">
                    Is archived
                </mat-slide-toggle>

                <mat-slide-toggle name="isExcludeFromAnalytics" formControlName="isExcludeFromAnalytics">
                    Is exclude from analytics
                </mat-slide-toggle>

            </div>

            <mat-form-field appearance="outline">
                <mat-label>Medical referal</mat-label>
                <mat-select name="type" formControlName="medicalReferalsType">
                    <mat-option [value]="medicalReferalsEnum.Human">
                        Medicine
                    </mat-option>
                    <mat-option [value]="medicalReferalsEnum.Veterinary">
                        Veterinary
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Operation type</mat-label>
                <mat-select name="type" formControlName="operationType">
                    <mat-option [value]="operationTypeEnum.General">
                        General
                    </mat-option>
                    <mat-option [value]="operationTypeEnum.Knee">
                        Knee
                    </mat-option>
                    <mat-option [value]="operationTypeEnum.Spine">
                        Spine
                    </mat-option>
                    <mat-option [value]="operationTypeEnum.DeformationCorrection">
                        Deformation correction
                    </mat-option>
                    <mat-option [value]="operationTypeEnum.Arthrodesis">
                        Arthrodesis
                    </mat-option>
                    <mat-option [value]="operationTypeEnum.GuidesForTransped">
                        Guides for transped
                    </mat-option>
                    <mat-option [value]="operationTypeEnum.THR">
                        THR
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Scene type</mat-label>
                <mat-select name="type" formControlName="sceneType">
                    <mat-option [value]="sceneTypeEnum.General">
                        General
                    </mat-option>
                    <mat-option [value]="sceneTypeEnum.Knee2D">
                        Knee2D
                    </mat-option>
                    <mat-option [value]="sceneTypeEnum.Spine">
                        Spine
                    </mat-option>
                    <mat-option [value]="sceneTypeEnum.OrthoVET">
                        OrthoVET
                    </mat-option>
                    <mat-option [value]="sceneTypeEnum.Hip2D">
                        Hip2D
                    </mat-option>
                    <mat-option [value]="sceneTypeEnum.Maxillofacial">
                    Maxillofacial
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="slideToggleGroup">

                <mat-slide-toggle name="planning" formControlName="planning">
                    Planning
                </mat-slide-toggle>

                <mat-slide-toggle name="anatomicalModel" formControlName="anatomicalModel">
                    Anatomical model
                </mat-slide-toggle>

                <mat-slide-toggle name="guide" formControlName="guide">
                    Guide
                </mat-slide-toggle>

                <mat-slide-toggle name="titan" formControlName="titan">
                    Titan
                </mat-slide-toggle>

                <mat-slide-toggle name="titan" formControlName="peek">
                    Peek
                </mat-slide-toggle>
            </div>

            <!-- <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
                <mat-label>Medical referals type</mat-label>
                <mat-select name="medicalReferalsType" formControlName="medicalReferalsType">
                    <mat-option [value]="medicalReferalsTypeEnum.Human">
                        Human
                    </mat-option>
                    <mat-option [value]="medicalReferalsTypeEnum.Veterinary">
                        Veterinary
                    </mat-option>
                </mat-select>
            </mat-form-field> -->

            <mat-form-field appearance="outline">
                <mat-label>Surgical date</mat-label>
                <input matInput name="surgicalDate" formControlName="surgicalDate" [matDatepicker]="surgicalDatePicker"
                    placeholder="choose a surgical date">
                <mat-datepicker-toggle matSuffix [for]="surgicalDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #surgicalDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput name="description" formControlName="description" rows="4">
                    </textarea>
            </mat-form-field>

            <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
                <mat-label>Patient name</mat-label>
                <input matInput name="patientName" formControlName="patientName" type="text">
            </mat-form-field>
            <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
                <mat-label>Patient code</mat-label>
                <input matInput name="patientCode" formControlName="patientCode" type="text">
            </mat-form-field>

            <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
                <mat-label>Patient birthday</mat-label>
                <input matInput name="birthday" formControlName="birthday" [matDatepicker]="birthdayPicker"
                    placeholder="choose a patient birthday">
                <mat-datepicker-toggle matSuffix [for]="birthdayPicker"></mat-datepicker-toggle>
                <mat-datepicker #birthdayPicker></mat-datepicker>
            </mat-form-field>


            <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
                <mat-label>Patient gender</mat-label>
                <mat-select name="gender" formControlName="gender">
                    <mat-option [value]="genderEnum.Male">
                        Male
                    </mat-option>
                    <mat-option [value]="genderEnum.Female">
                        Female
                    </mat-option>
                </mat-select>
            </mat-form-field>



            <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
                <mat-label>Service</mat-label>
                <input matInput name="service" formControlName="service">
            </mat-form-field>
            <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
                <mat-label>Procedures</mat-label>
                <input matInput name="procedures" formControlName="procedures">
            </mat-form-field>

            <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
                <mat-label>Surgical side</mat-label>
                <input matInput name="surgicalSide" formControlName="surgicalSide">
            </mat-form-field>
            <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
                <mat-label>Scan center</mat-label>
                <input matInput name="scanCenter" formControlName="scanCenter">
            </mat-form-field>
            <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
                <mat-label>Shipment</mat-label>
                <input matInput name="shipment" formControlName="shipment">
            </mat-form-field>


        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div>

            <button [fxShow]="!isEditingCase" mat-raised-button color="accent" (click)="createCase()"
                class="save-button" [disabled]="caseEditForm.invalid || requestInProgress" aria-label="Create">
                Create
            </button>

            <button [fxShow]="isEditingCase" mat-raised-button color="accent" (click)="editCase()" class="save-button"
                [disabled]="caseEditForm.invalid || requestInProgress" aria-label="Create">
                Edit
            </button>

            <span class="p-4 red-500-fg">
                {{errorText}}
            </span>

            <!-- <button mat-icon-button matTooltip="Attach a file">
                <mat-icon>attach_file</mat-icon>
            </button> -->
        </div>

        <button mat-icon-button (click)="matDialogRef.close(['delete',caseEditForm])" aria-label="Delete"
            matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>