/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-403.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./error-403.component";
var styles_Error403Component = [i0.styles];
var RenderType_Error403Component = i1.ɵcrt({ encapsulation: 2, styles: styles_Error403Component, data: {} });
export { RenderType_Error403Component as RenderType_Error403Component };
export function View_Error403Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["fxLayout", "column"], ["fxLayoutAlign", "center center"], ["id", "error-403"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "content"], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(5, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "error-code"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["403"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Forbidden"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "a", [["class", "back-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Login to another account"]))], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "column"; _ck(_v, 4, 0, currVal_2); var currVal_3 = "center center"; _ck(_v, 5, 0, currVal_3); var currVal_6 = "/login"; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var currVal_4 = i1.ɵnov(_v, 11).target; var currVal_5 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_4, currVal_5); }); }
export function View_Error403Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "error-403", [], null, null, null, View_Error403Component_0, RenderType_Error403Component)), i1.ɵdid(1, 49152, null, 0, i6.Error403Component, [], null, null)], null, null); }
var Error403ComponentNgFactory = i1.ɵccf("error-403", i6.Error403Component, View_Error403Component_Host_0, {}, {}, []);
export { Error403ComponentNgFactory as Error403ComponentNgFactory };
