import { PlannerUsersAvailableModules, PlannerUsersAvailableModulesOffline } from "@appmodels";

export interface IPaidModulesState
{
    usersPaidModules: PlannerUsersAvailableModules[];   
    searchPattern: string;

    usersPaidModulesOffline: PlannerUsersAvailableModulesOffline[];   
    //searchPatternOffline: string;
}

export const initialPaidModulesState: IPaidModulesState =
{
    usersPaidModules: null,
    searchPattern:'',

    usersPaidModulesOffline: null, 
    //searchPatternOffline: ''
}