import {Component, Inject, OnDestroy, ChangeDetectionStrategy} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FileResponse } from 'app/core/models';
import { takeUntil } from 'rxjs/operators';

// export interface DialogData {
//   animal: string;
//   name: string;
// }


@Component({
  selector: 'download-dialog',
  styleUrls: ['download-dialog.component.css'],
  templateUrl: 'download-dialog.component.html',
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadDialogComponent implements OnDestroy{

  private _unsubscribeAll: Subject<any>;

public fileResponses:FileResponse[];// = new FileResponse();
  constructor(
    public dialogRef: MatDialogRef<DownloadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Observable<FileResponse[]>) 
    {
      this._unsubscribeAll = new Subject();
    //  this.fileResponses = FileResponse[];
      //this.fileResponses.Progress = 0;

          data
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(
            {
              next: fileResponses=> {
             
              this.fileResponses = fileResponses;

              },
              //error: err => console.error('Observer got an error: ' + err),
              complete: () => this.dialogRef.close()
            }
            
            );
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

  onNoClick(): void {
    this.dialogRef.close(); 
  }

}
