import { Action } from '@ngrx/store';
import { ResetPasswordByCodeCommand } from 'app/core/models/planner/commands/resetPasswordByCodeCommand';

export enum EAuthActions {
    SetManagementOrganizationByName = '[Auth] Set Management Organization By Name',
    SetManagementOrganizationIdSuccess = '[Auth] Set Management Organization Id Success',
    SendRecoverPasswordRequest = '[Auth] Send Recover Password Request',
    SendRecoverPasswordRequestSuccess = '[Auth] Send Recover Password Request Success',
    RecoverPassword = '[Auth] Recover Password',
    RecoverPasswordSuccess = '[Auth] Recover Password Success'    
}

export class SetManagementOrganizationByName implements Action {
    public readonly type = EAuthActions.SetManagementOrganizationByName;
    constructor(public payload: string) { };
}

export class SetManagementOrganizationIdSuccess implements Action {
    public readonly type = EAuthActions.SetManagementOrganizationIdSuccess;
    constructor(public payload: number) { };
}

export class SendRecoverPasswordRequest implements Action {
    public readonly type = EAuthActions.SendRecoverPasswordRequest;
    constructor(public payload: string) { };
}

export class SendRecoverPasswordRequestSuccess implements Action {
    public readonly type = EAuthActions.SendRecoverPasswordRequestSuccess;
    constructor(public payload: any) { };
}

export class RecoverPassword implements Action {
    public readonly type = EAuthActions.RecoverPassword;
    constructor(public payload: ResetPasswordByCodeCommand) { };
}

export class RecoverPasswordSuccess implements Action {
    public readonly type = EAuthActions.RecoverPasswordSuccess;
    constructor(public payload: any) { };
}

export type AuthActions = SetManagementOrganizationByName | SetManagementOrganizationIdSuccess | SendRecoverPasswordRequest |
 SendRecoverPasswordRequestSuccess| RecoverPassword | RecoverPasswordSuccess;