import { AppConfig } from 'app/app-config.module';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { map, switchMap, mergeMap, catchError, finalize, tap } from 'rxjs/operators';
import { GetPlannerFilesSASListQuery, GetPlannerFileSASQuery, RequestPlannerFileUploadCommand, ApiResponse } from 'app/core/models';
import * as FileSaver from 'file-saver';
import { Observable, merge, concat, Subscriber, from, of, } from 'rxjs';
import { FileResponse } from 'app/core/models/';
import { Md5 } from 'ts-md5/dist/md5';
import { ObjectMapper } from 'app/core/helpers';
import * as JSZip from 'jszip';
import { NGXLogger } from 'ngx-logger';
import { StorageService } from '@appservices';
import { ConvertType } from 'app/core/models/planner/enums/fileEnums';
import { GeometryService } from './geometry.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../app-config.module";
import * as i2 from "@angular/common/http";
import * as i3 from "../storage/storage.service";
import * as i4 from "ngx-logger";
//import { BlobServiceClient } from '@azure/storage-blob';
export class FilesService {
    constructor(config, http, _storageService, logger) {
        this.config = config;
        this.http = http;
        this._storageService = _storageService;
        this.logger = logger;
    }
    getFileDownloadUrl(caseId, fileId, fileName) {
        this.logger.info('Get file download url.', `CaseId: ${caseId},`, `fileId: ${fileId},`, `fileName: ${fileName}`);
        let filesSASQuery = new GetPlannerFilesSASListQuery();
        filesSASQuery.CaseId = caseId;
        let fileSASQuery = new GetPlannerFileSASQuery();
        fileSASQuery.FileId = fileId;
        fileSASQuery.FileName = fileName;
        filesSASQuery.SASRequestFiles.push(fileSASQuery);
        return this.getFilesSaS(filesSASQuery).pipe(map(response => {
            return response.sasResponseFiles[0].url;
        }));
    }
    downloadFile(caseId, fileId, fileName, convertType = ConvertType.None) {
        this.logger.info('Download file.', `CaseId: ${caseId},`, `fileId: ${fileId},`, `fileName: ${fileName}`);
        let filesSASQuery = new GetPlannerFilesSASListQuery();
        filesSASQuery.CaseId = caseId;
        let fileSASQuery = new GetPlannerFileSASQuery();
        fileSASQuery.FileId = fileId;
        fileSASQuery.FileName = fileName;
        filesSASQuery.SASRequestFiles.push(fileSASQuery);
        return this.download(filesSASQuery, convertType);
    }
    downloadFiles(caseId, filesQuery) {
        this.logger.info('Download files.', `CaseId: ${caseId},`, filesQuery);
        let filesSASQuery = new GetPlannerFilesSASListQuery();
        filesSASQuery.CaseId = caseId;
        filesSASQuery.SASRequestFiles = filesQuery;
        return this.download(filesSASQuery);
    }
    uploadFile(file, caseId, title, fileType, additionalInfo, isNeedToZip) {
        this.logger.info('Upload file.', `CaseId: ${caseId},`, `title: ${title},`, `fileType: ${fileType}`, `additionalInfo: ${additionalInfo}`, `isNeedToZip: ${isNeedToZip}`);
        let sasRequest = new RequestPlannerFileUploadCommand();
        sasRequest.caseId = caseId;
        sasRequest.fileType = fileType;
        sasRequest.additionalInfo = encodeURIComponent(additionalInfo);
        sasRequest.fileName = file.name + (isNeedToZip ? '.zip' : '');
        sasRequest.title = title;
        sasRequest.isFileZipped = isNeedToZip;
        sasRequest.fileSize = file.size;
        var fileResponse = new FileResponse();
        let observable = new Observable((subscriber) => {
            fileResponse.status = 'md5 calc';
            subscriber.next(fileResponse);
            this.getMd5(file).pipe(switchMap(md5string => {
                fileResponse.status = 'zipping';
                subscriber.next(fileResponse);
                let fileProcessing = isNeedToZip ? this.zipFile(file) : of(file);
                return fileProcessing.pipe(map(processedFile => {
                    if (isNeedToZip)
                        sasRequest.fileSizeZipped = processedFile.size;
                    return processedFile;
                }), switchMap(blob => {
                    this.logger.info(md5string);
                    sasRequest.md5hash = md5string;
                    var urlParams = ObjectMapper.mapObjectToURLParams(sasRequest);
                    fileResponse.status = 'getting sas';
                    subscriber.next(fileResponse);
                    const url = `${this.config.apiEndpoint}/api/public/Files/RequestFileUpload?` + urlParams;
                    this.logger.debug('HTTP GET: request file upload', sasRequest, `url: ${url}`);
                    return this.http.get(url).pipe(tap(x => this.logger.debug('Request file upload response:', x)), switchMap(res => {
                        this.logger.debug('Sas response:', res);
                        // const fileBlobNameUrlEncoded = encodeURIComponent(res.fileBlobName);
                        fileResponse.fileName = title;
                        fileResponse.fileId = res.fileId;
                        fileResponse.status = 'uploading';
                        fileResponse.progress = 0;
                        subscriber.next(fileResponse);
                        this.logger.debug('BLOB PUT: upload to blob storage', blob);
                        //  return res;
                        var uploadObs = this._storageService.uploadToBlobStorage(blob, res.url, res.fileBlobName).pipe(finalize(() => this.logger.debug('Upload to blob storage done')), map(x => {
                            fileResponse.status = 'uploading';
                            fileResponse.progress = Math.ceil((x / blob.size) * 100);
                            subscriber.next(fileResponse);
                            return x;
                        }));
                        var commitObs = this.http.get(`${this.config.apiEndpoint}/api/public/Files/SetFileUploaded?caseId=` + caseId + '&fileId=' + res.fileId).pipe(tap(commitResponse => this.logger.debug('Set file uploaded done.', `CaseId: ${caseId},`, `fileId: ${res.fileId}`, commitResponse)), map(x => {
                            fileResponse.status = 'done';
                            subscriber.next(fileResponse);
                        }), finalize(() => {
                            subscriber.complete();
                        }));
                        return concat(uploadObs, commitObs);
                    }));
                }));
            })).pipe(catchError(err => {
                subscriber.error(err);
                throw err;
            }))
                .subscribe();
        });
        return observable;
    }
    editFile(deletePlannerFileCommand) {
        const url = `${this.config.apiEndpoint}/api/private/FilesManagement/EditPlannerFile`;
        this.logger.debug("HTTP POST edit planner file.", `File to edit: ${deletePlannerFileCommand},`, `url: ${url}`);
        return this.http.post(url, deletePlannerFileCommand)
            .pipe(tap(response => {
            this.logger.debug("Response edit file:", response);
        }), map(response => new ApiResponse(response)), catchError((error) => {
            // console.error(error)
            this.logger.error(error);
            return of(new ApiResponse(null, false, error.error));
        }));
    }
    deleteFile(deletePlannerFileCommand) {
        const url = `${this.config.apiEndpoint}/api/private/FilesManagement/DeletePlannerFile`;
        this.logger.debug("HTTP POST delete planner file.", `File to delete: ${deletePlannerFileCommand},`, `url: ${url}`);
        return this.http.post(url, deletePlannerFileCommand)
            .pipe(tap(response => {
            this.logger.debug("Response delete file:", response);
        }), map(response => new ApiResponse(response)), catchError((error) => {
            // console.error(error)
            this.logger.error(error);
            return of(new ApiResponse(null, false, error.error));
        }));
    }
    editPlannerFilePermissions(editPlannerFilePermissionsCommand) {
        const url = `${this.config.apiEndpoint}/api/private/FilesManagement/EditPlannerFilePermissions`;
        this.logger.debug("HTTP POST edit planner files permissions.", `Files to edit permissions: ${editPlannerFilePermissionsCommand},`, `url: ${url}`);
        return this.http.post(url, editPlannerFilePermissionsCommand)
            .pipe(tap(response => {
            this.logger.debug("Response edit planner files permissions:", response);
        }), map(response => new ApiResponse(response)), catchError((error) => {
            // console.error(error)
            this.logger.error(error);
            return of(new ApiResponse(null, false, error.error));
        }));
    }
    download(filesSASQuery, convertType = ConvertType.None) {
        return this.getFilesSaS(filesSASQuery)
            .pipe(mergeMap(response => {
            this.logger.debug('Fetch files SAS response:', response);
            let obsCollection = new Array();
            let fileResponses = new Array();
            response.sasResponseFiles.forEach(sasResponseFile => {
                const request = new HttpRequest('GET', sasResponseFile.url, {
                    reportProgress: true,
                    responseType: 'blob'
                });
                let fileResponse = new FileResponse();
                fileResponse.fileId = sasResponseFile.fileId;
                fileResponse.fileName = sasResponseFile.fileName;
                fileResponses.push(fileResponse);
                let obs = this.http.request(request).pipe(map(event => {
                    // progress
                    if (event.type === HttpEventType.DownloadProgress) {
                        const percentage = 100 / event.total * event.loaded;
                        fileResponse.progress = percentage;
                        return fileResponses;
                    }
                    else {
                        //  this.logger.info(event);
                    }
                    // finished
                    if (event.type === HttpEventType.Response) {
                        //this.logger.info(event.body);
                        this.saveFile(event.body, sasResponseFile.fileName, convertType);
                        return null;
                    }
                    return null;
                }));
                obsCollection.push(obs);
            });
            let mergedObs = obsCollection[0];
            for (let i = 1; i < obsCollection.length; i++) {
                mergedObs = merge(mergedObs, obsCollection[i]);
            }
            return mergedObs;
        }));
    }
    getFilesSaS(filesSASQuery) {
        const url = `${this.config.apiEndpoint}/api/public/Files/FetchFilesSAS`;
        this.logger.debug('HTTP POST: fetch files SAS', filesSASQuery, `url: ${url}`);
        return this.http.post(url, filesSASQuery);
    }
    saveFile(blob, fileName, convertType = ConvertType.None) {
        const convertArgs = {};
        convertArgs.blob = blob;
        convertArgs.fileName = fileName;
        let convert = of(convertArgs);
        //convertType = ConvertType.PlyToStl;
        switch (convertType) {
            case ConvertType.PlyToStl:
                const file = new File([new Blob([blob])], fileName);
                convert = this.unzipFile(file).pipe(switchMap(unzipResult => {
                    const blob = unzipResult.blob;
                    const fileName = unzipResult.filename;
                    return new GeometryService().convertToSTL(new File([new Blob([blob])], fileName)).pipe(map(convertedFile => {
                        const convertResult = {};
                        convertResult.blob = convertedFile;
                        convertResult.fileName = convertedFile.name;
                        return convertResult;
                    }));
                }));
        }
        convert.subscribe(converResult => {
            FileSaver.saveAs(converResult.blob, converResult.fileName);
            this.logger.info('File saved');
            this.logger.debug('File info:', converResult.blob, `fileName: ${converResult.fileName}`);
        });
    }
    getMd5(file) {
        this.logger.debug('Getting file md5', file);
        let md5 = new Md5();
        var reader = new FileReader();
        let observable = Observable.create((observer) => {
            // if success
            reader.onloadend = ((ev) => {
                var arrayBuffer = reader.result;
                var bytes = new Uint8Array(arrayBuffer);
                // resolve(bytes);
                md5.appendByteArray(bytes);
                let md5String = md5.end();
                this.logger.info('Getting file md5 done!', `md5String: ${md5String}`);
                observer.next(md5String);
                observer.complete();
            });
            // if failed
            reader.onerror = (error) => {
                this.logger.error(error);
                observer.error(error);
            };
        });
        reader.readAsArrayBuffer(file);
        return observable;
    }
    zipFile(file) {
        this.logger.debug('Zipping file', file);
        let zip = new JSZip();
        zip.file(file.name, file);
        let obs = from(zip.generateAsync({ type: "blob", compression: 'DEFLATE', compressionOptions: { level: 6 } })).pipe(tap(result => {
            this.logger.info('Zipping file done!');
            this.logger.debug('Zipped file:', result);
        }));
        return obs;
    }
    unzipFile(file) {
        this.logger.debug('Unzipping file', file);
        var zip = new JSZip();
        let obs = from(zip.loadAsync(file)).pipe(switchMap(contents => {
            const filename = Object.keys(contents["files"])[0];
            return from(zip.file(filename).async('uint8array')).pipe(map(content => [filename, content]));
        }), map((([filename, content]) => {
            const result = {};
            result.blob = new Blob([content]);
            result.filename = filename;
            return result;
        })));
        // let obs = from(zip.loadAsync(file).then(function (contents) {
        //     Object.keys(contents.files).forEach(function (filename) {
        //         zip.file(filename).async('nodebuffer').then(function (content) {
        //             console.log(filename);
        //             console.log(content);
        //         });
        //     });
        // }));
        return obs;
        // let zip = new JSZip();
        // zip.file(file.name, file)
        // let obs = from(zip.generateAsync({ type: "blob", compression: 'DEFLATE', compressionOptions: { level: 6 } })).pipe(
        //     tap(result => {
        //         this.logger.info('Zipping file done!');
        //         this.logger.debug('Zipped file:', result);
        //     })
        // );
        // return obs as Observable<Blob>;
    }
}
FilesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FilesService_Factory() { return new FilesService(i0.ɵɵinject(i1.APP_CONFIG), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.StorageService), i0.ɵɵinject(i4.NGXLogger)); }, token: FilesService, providedIn: "root" });
