export const locale = {
    lang: 'ru',
    data: {
        DOWNLOADS: {
            CLICKTODOWNLOAD: 'НАЖМИТЕ ЧТОБЫ СКАЧАТЬ',
            USERGUIDE: 'Руководство пользователя',
            DONTHAVEACCOUNT: 'Нет аккаунта?',
            CREATEANACCOUNT: 'Создать аккаунт'
        }
    }
};
