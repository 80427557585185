<div [ngSwitch]="view" class="views">
    <chat-chats-sidenav class="view"
                        *ngSwitchCase="'chats'"
                        [@slideInRight] [cases] = "cases" [selectedCase] = "selectedCase" (onCaseSelected)="selectCase($event)" >
    </chat-chats-sidenav>

    <chat-user-sidenav class="view"
                       *ngSwitchCase="'user'"
                       [@slideInLeft]
                       fusePerfectScrollbar>
    </chat-user-sidenav>
</div>
