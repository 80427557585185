import { FileType } from '@appmodels';

export class RequestPlannerFileUploadCommand {
    public caseId: string = null;
    public fileName: string = null;
    public title: string = null;
    public fileSize: number = 0;
    public isFileZipped: boolean = false;
    public fileSizeZipped: number;
    public fileType: FileType = null;
    public additionalInfo: string = null;
    public md5hash: string = null;
}