import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { NotificationArgs } from '@appmodels';
import { NotificationSnackbarComponent } from './snackbar/notification-snackbar.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './app-notifications.component.html',
  styleUrls: ['./app-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppNotificationsComponent implements OnInit {

  constructor(private _snackBar: MatSnackBar, private router: Router) { }

  @Input()
  set notification(notification: NotificationArgs) {
    if (!notification)
      return;

    let duration = 2500;
    let panelClass = ['blue-snackbar'];

    if (notification.type) {
      if (notification.type == 'NewMessage') {
        duration = 0;
        this.notifyBrowser("New message!", notification.message)
      }
      else if (notification.type == 'FileDelete') {
        duration = 0;
      }
      else if (notification.type == 'CaseSceneSaved') {
        duration = 0;
      }
    }

    let onAction = () => {};

    switch(notification.action)
    {
      case "Error":{
        panelClass = ['red-snackbar'];
        break;
      }
      case "Link":{

        const caseId: string = notification.additionalParams.caseId;
        const notificationLink = '/chat/' + caseId;

        onAction = ()=>{
          
          this.followLink(notificationLink);

        };

        break;
      }
    }


    const snackBarRef = this._snackBar.openFromComponent(NotificationSnackbarComponent
      , {
          duration: duration,
          panelClass: panelClass,
          horizontalPosition: 'right',
          data:{
            message: notification.message,
            action: notification.action,
            onAction: onAction,
            onBarClicked: ()=>{
              snackBarRef.dismiss();
            }
          }
        });

    // const snackBarRef = this._snackBar.open(notification.message, notification.action, {
    //   duration: duration,
    //   panelClass: panelClass,
    //   horizontalPosition: 'right'
    // });

  //   snackBarRef.onAction().subscribe(() => {      
  //     onAction();
  //   });
   }

  // onAction()
  // {

  // }

  ngOnInit() {
  }

  followLink(link: string) : void{
    this.router.navigateByUrl(link);
  }
  

  notifyBrowser(subject: string, text: string) {
    // Проверка поддержки браузером уведомлений
    if (!("Notification" in window)) {
      return;
    }

    // Проверка разрешения на отправку уведомлений
    else if (Notification.permission === "granted") {
      // Если разрешено, то создаём уведомление
      this.showBrowserNotification(subject, text);
    }

    // В противном случае, запрашиваем разрешение
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function (permission) {
        // Если пользователь разрешил, то создаём уведомление
        if (permission === "granted") {
          this.showBrowserNotification(subject, text);
        }
      });
    }
  }

  showBrowserNotification(subject: string, text: string) {
    var options = {
      silent: true,
      body: text,
    }

    var notification = new Notification(subject, options);
    notification.onclick = function (x) { window.focus(); this.close(); };

    try {
      const audio = new Audio("/assets/sounds/message-sound.mp3");
      audio.volume = 0.2;
      audio.play();
    }
    catch {

    }
  }

}
