<div id="chat" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-sidenav-container>

                <!-- LEFT SIDENAV -->
                <mat-sidenav class="sidenav" position="start" opened="true" mode="side"
                             fuseMatSidenavHelper="chat-left-sidenav" matIsLockedOpen="gt-md">
                    <chat-left-sidenav [cases] = "cases$ | async"  [selectedCase] = "selectedCase$ | async"  (onCaseSelected)="selectCase($event)"></chat-left-sidenav>
                </mat-sidenav>
                <!-- / LEFT SIDENAV -->

                <!-- CONTENT -->
             

                <chat-panel  (onNewMessage)="newMessage($event)" (onMessagesReaded)="messagesReaded()" (onGetMoreMessages)="getMoreMessages()" [currentUser] = "currentUser$ | async" [selectedCase] = "selectedCase$ | async" [messages] = "messages$ | async" ></chat-panel>
                <!-- / CONTENT -->

                <!-- RIGHT SIDENAV -->
                <mat-sidenav class="sidenav" position="end" opened="false" mode="over"
                             fuseMatSidenavHelper="chat-right-sidenav">
                    <chat-right-sidenav></chat-right-sidenav>
                </mat-sidenav>
                <!-- / RIGHT SIDENAV -->

            </mat-sidenav-container>

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
