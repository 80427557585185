export class BacketSpace
{
  private _buckets = {};

  public GetBucket(x: number, y: number, z: number): number[]
  {
    var key = x * 1000000 + y * 1000 + z;
    if (!this._buckets.hasOwnProperty(key))
      this._buckets[key] = [];

    return this._buckets[key];
  }

  //public int Count => this._buckets.Count;

//   public long GetTotalSize()
//   {
//     return (long) this._buckets.Values.Sum<List<int>>((Func<List<int>, int>) (x => x.Count));
//   }
}