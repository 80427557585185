import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { FuseSharedModule } from '@fuse/shared.module';

import { RegisterComponent } from './register.component';
import { MatStepperModule, MatSelectModule, MatProgressSpinnerModule, MatMenuModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'app/core/shared/shared.module';





@NgModule({
    declarations: [
        RegisterComponent
    ],
    imports: [
        //  RouterModule.forChild(routes),
        RouterModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        FuseSharedModule,
        SharedModule,
        TranslateModule,
        MatMenuModule
    ]
})
export class RegisterModule {
}
