import { Injectable } from '@angular/core';
import { DateInterval, DateMonth } from '@appmodels';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SortOrder } from 'app/core/models/planner/enums/commonEnums';
import { AnalyticsService } from 'app/core/services/planner/analytics.service';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { EAnalyticsActions, GetCasesAnalytics, GetCasesAnalyticsSuccess, GetGeneralAnalytics, GetGeneralAnalyticsSuccess, GetUsersAnalytics, GetUsersAnalyticsSuccess } from '../actions/analytics.actions';
import { IAppState } from '../state/app.state';

@Injectable()
export class AnalyticsEffects {

    @Effect()
    getGeneralAnalytics$ = this._actions$.pipe(
        ofType<GetGeneralAnalytics>(EAnalyticsActions.GetGeneralAnalytics),
        map(action => action),
 
        switchMap((params) => {
            const dateInterval: DateInterval = params.dateInterval;
            const selectedMonth: DateMonth = params.selectedMonth;
            const selectedQuarter: number = params.selectedQuarter;
            const selectedYear: number = params.selectedYear;

            return this._analyticsService.getGeneralAnalytics(dateInterval, selectedMonth, selectedQuarter, selectedYear);
        }),
        switchMap((result) => of(new GetGeneralAnalyticsSuccess(result)))
    );

    @Effect()
    getCasesAnalytics$ = this._actions$.pipe(
        ofType<GetCasesAnalytics>(EAnalyticsActions.GetCasesAnalytics),
        map(action => action),
 
        switchMap((params) => {
            const dateInterval: DateInterval = params.dateInterval;
            const selectedMonth: DateMonth = params.selectedMonth;
            const selectedQuarter: number = params.selectedQuarter;
            const selectedYear: number = params.selectedYear;

            return this._analyticsService.getCasesAnalytics(dateInterval, selectedMonth, selectedQuarter, selectedYear);
        }),
        switchMap((result) => of(new GetCasesAnalyticsSuccess(result)))
    );

    @Effect()
    getUsersAnalytics$ = this._actions$.pipe(
        ofType<GetUsersAnalytics>(EAnalyticsActions.GetUsersAnalytics),
        map(action => action),
 
        switchMap((params) => {
            const dateInterval: DateInterval = params.dateInterval;
            const selectedMonth: DateMonth = params.selectedMonth;
            const selectedQuarter: number = params.selectedQuarter;
            const selectedYear: number = params.selectedYear;

            return this._analyticsService.getUsersAnalytics(dateInterval, selectedMonth, selectedQuarter, selectedYear);
        }),
        switchMap((result) => of(new GetUsersAnalyticsSuccess(result)))
    );

    constructor(
        private _analyticsService: AnalyticsService,
        private _actions$: Actions,
        private _store: Store<IAppState>,
        private _logger: NGXLogger
    ) { }
}