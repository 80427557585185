import { ConnectionInfo, ICurrentUser } from '@appmodels';
import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { IUsersFilter } from 'app/core/models/usersFilter';

export interface IUsersState
{
    currentUser: ICurrentUser;   
    usersFilter: IUsersFilter;
    users: PlannerUser[];
    onlineUsers: ConnectionInfo[];
    createdWebLinkToken: string;
}

export const initialUsersFilterState : IUsersFilter = {
    isWithAdmins: true,
    searchQuery: null
}

export const initialUsersState: IUsersState =
{
    currentUser: null,
    usersFilter: { ...initialUsersFilterState },
    users: null,
    onlineUsers: null,
    createdWebLinkToken: null
}

