import { PlannerQualitySurveyCase } from "@appmodels";
import { Action } from "@ngrx/store";

export enum EQualitySurveyCasesActions {
    SetQualitySurveyCasesIsWidthAdmins = '[QualitySurveyCases] Set Quality Survey Cases Is Width Admins',
    SetQualitySurveyCasesIsWidthAdminsSuccess = '[QualitySurveyCases] Set Quality Survey Cases Is Width Admins Success',
    SetQualitySurveyCasesSearchPattern = '[QualitySurveyCases] Set Quality Survey Cases Search Pattern',
    SetQualitySurveyCasesSearchPatternSuccess = '[QualitySurveyCases] Set Quality Survey Cases Search Pattern Success',
    SetQualitySurveysCasesFilterParams = '[QualitySurveyCases] Set Quality Surveys Cases Filter Params',
    SetQualitySurveysCasesFilterParamsSuccess = '[QualitySurveyCases] Set Quality Surveys Cases Filter Params Success',
    GetQualitySurveysCases = '[QualitySurveyCases] Get Quality Surveys Cases',
    GetQualitySurveysCasesSuccess = '[QualitySurveyCases] Get Quality Surveys Cases Success'
}

export class SetQualitySurveyCasesIsWidthAdmins implements Action {
    public readonly type = EQualitySurveyCasesActions.SetQualitySurveyCasesIsWidthAdmins;
    constructor(public isWidthAdmins: boolean) { };
}

export class SetQualitySurveyCasesIsWidthAdminsSuccess implements Action {
    public readonly type = EQualitySurveyCasesActions.SetQualitySurveyCasesIsWidthAdminsSuccess;
    constructor(public isWidthAdmins: boolean) { };
}

export class SetQualitySurveyCasesSearchPattern implements Action {
    public readonly type = EQualitySurveyCasesActions.SetQualitySurveyCasesSearchPattern;
    constructor(public payload: string) { };
}

export class SetQualitySurveyCasesSearchPatternSuccess implements Action {
    public readonly type = EQualitySurveyCasesActions.SetQualitySurveyCasesSearchPatternSuccess;
    constructor(public payload: string) { };
}

export class SetQualitySurveysCasesFilterParams implements Action {
    public readonly type = EQualitySurveyCasesActions.SetQualitySurveysCasesFilterParams;
    constructor(public notAdmins: boolean,public searchPattern: string) { };
}

export class SetQualitySurveysCasesFilterParamsSuccess implements Action {
    public readonly type = EQualitySurveyCasesActions.SetQualitySurveysCasesFilterParamsSuccess;
    constructor(public notAdmins: boolean,public searchPattern: string) { };
}


export class GetQualitySurveysCases implements Action {
    public readonly type = EQualitySurveyCasesActions.GetQualitySurveysCases;
    isReload: boolean;
    constructor()
    constructor(isReload: boolean)
    constructor(isReload?: boolean) {
        if (isReload)
            this.isReload = isReload;
        else
            this.isReload = false;
    };
}

export class GetQualitySurveysCasesSuccess implements Action {
    public readonly type = EQualitySurveyCasesActions.GetQualitySurveysCasesSuccess;
    constructor(public payload: PlannerQualitySurveyCase[], public isReload: boolean) { 
       
    };
}

export type QualitySurveyCasesActions = SetQualitySurveyCasesIsWidthAdmins |  SetQualitySurveyCasesIsWidthAdminsSuccess | SetQualitySurveyCasesSearchPattern | SetQualitySurveyCasesSearchPatternSuccess |
 SetQualitySurveysCasesFilterParams | SetQualitySurveysCasesFilterParamsSuccess | GetQualitySurveysCases | GetQualitySurveysCasesSuccess;