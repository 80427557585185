import { IAppState } from '../state/app.state';
import { createSelector } from '@ngrx/store';
import { IAppLogsState } from '../state/appLogs.interface';

const selectAppLogs = (state: IAppState) => state.appLogs;

export const selectAppLogsFilterParams = createSelector(
    selectAppLogs,
    (state: IAppLogsState) => {
        return { isWithAdmins : state.isWithAdmins };
    }
)

export const selectAppLogsSearchPattern = createSelector(
    selectAppLogs,
    (state: IAppLogsState) => {
        return state.searchPattern;
    }
)

export const selectAppLogsGroups = createSelector(
    selectAppLogs,
    (state: IAppLogsState) => state.appLogsGroups
)

export const selectAppLogsByConnection = createSelector(
    selectAppLogs,
    (state: IAppLogsState) => state.appLogsByConnection
)