import { Component, OnInit, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { PlannerCase, PlannerModuleType } from '@appmodels';

@Component({
  selector: 'tool-bar',
  templateUrl: './cases-tool-bar.component.html',
  styleUrls: ['./cases-tool-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CasesToolBarComponent implements OnInit {

  plannerModuleType = PlannerModuleType;

  @Input()
  selectedCase: PlannerCase;

  @Input()
  selectCasesRequestArgs: any;

  @Output()
  onModulesFilterSelected: EventEmitter<any>;

  @Output()
  onQCFilterSelected: EventEmitter<any>;


  @Output()
  onCaseDeselected: EventEmitter<any>;

  constructor() {
    this.onModulesFilterSelected = new EventEmitter();
    this.onQCFilterSelected = new EventEmitter();
    this.onCaseDeselected = new EventEmitter();
  }

  ngOnInit() {
  }

  deselectCase(): void {
    this.onCaseDeselected.emit();
  }

  public selectModules(moduleType: PlannerModuleType) {
    this.onModulesFilterSelected.emit(moduleType);
  }

  public selectQCFilter(){
    this.onQCFilterSelected.emit();
  }

  public isModuleSelected(moduleType: PlannerModuleType): boolean {

    if (!this.selectCasesRequestArgs.modulesFilter)
      return false;

    const modulesFilter = this.selectCasesRequestArgs.modulesFilter as PlannerModuleType[];

    return modulesFilter.indexOf(moduleType) >= 0;
  }
}
