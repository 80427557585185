import { OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { APP_DATE_FORMATS } from 'app/core/helpers';
import { Subject } from 'rxjs';
import { FormGroup, FormArray, FormControl, AbstractControl } from '@angular/forms';
import * as _ from 'lodash';
const ɵ0 = APP_DATE_FORMATS;
export class EditFilePermissionsComponent {
    constructor(matDialogRef, _data) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        //this.visibilityGroups = this._data.visibilityGroups;
        // console.log();
        this.caseFiles = _.orderBy(this._data.caseFiles, ['foundationDate', obj => obj.title.toLowerCase()], ['desc', 'asc']);
        this.editFilePermissionsForm = this.createEditFilePermissionsForm(); //this.visibilityGroups);
        this._unsubscribeAll = new Subject();
    }
    getArrayGroup(control, name) {
        return control.get(name).controls;
    }
    ngOnInit() {
    }
    createEditFilePermissionsForm() {
        const editFilePermissionsFormGroup = new FormGroup({});
        const editFilePermissionsFormArray = new FormArray([]);
        this.caseFiles.forEach(plannerFile => {
            const editFilePermissionsGroup = new FormGroup({
                id: new FormControl(plannerFile.id),
                sceneObjectTitle: new FormControl(plannerFile.title),
                isAllowUserDownload: new FormControl(plannerFile.isAllowUserDownload)
            });
            editFilePermissionsFormArray.push(editFilePermissionsGroup);
        });
        editFilePermissionsFormGroup.addControl('editFilePermissions', editFilePermissionsFormArray);
        return editFilePermissionsFormGroup;
    }
    saveFilesPermissions() {
        const formArray = this.editFilePermissionsForm.controls['editFilePermissions'];
        const filesAllowUserDownload = [];
        formArray.controls.forEach(editFilePermissionsGroup => {
            const fileId = editFilePermissionsGroup.get('id').value;
            const isActionAllowed = editFilePermissionsGroup.get('isAllowUserDownload').value;
            const fileAllowUserDownload = { fileId, isActionAllowed };
            filesAllowUserDownload.push(fileAllowUserDownload);
        });
        this.matDialogRef.close(['save', filesAllowUserDownload]);
    }
    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
export { ɵ0 };
