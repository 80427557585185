<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

        <mat-toolbar-row class="toolbar-bottom py-16" fxLayout="column" fxLayoutAlign="center center">
            <img src="/assets/images/avatars/profile.jpg" class="avatar contact-avatar huge m-0" />
            <div class="contact-name mt-8">{{user.email}}</div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

        <form [formGroup]="userForm" novalidate>

            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <!-- (blur)="isEmailAvailable($event.target.value)" -->
                <input matInput formControlName="email">
                <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                <mat-error *ngIf="userForm.get('email').hasError('required')">
                    Email is required
                </mat-error>
                <mat-error *ngIf="userForm.get('email').hasError('email')">
                    Please enter a valid email address
                </mat-error>
                <!-- <mat-error
                    *ngIf="!firstStepFormGroup.get('email').hasError('email') && firstStepFormGroup.get('email').hasError('pattern')">
                    Email is unavailable
                </mat-error> -->
            </mat-form-field>

            
            <mat-form-field appearance="outline">
                <mat-label>Registration date</mat-label>
                <input matInput name="registrationDate" formControlName="registrationDate" [matDatepicker]="registrationDatePicker"
                placeholder="">
                <mat-datepicker-toggle matSuffix [for]="registrationDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #registrationDatePicker></mat-datepicker>
            </mat-form-field>



            <mat-form-field appearance="outline">
                <mat-label>First name</mat-label>
                <input matInput formControlName="firstName">
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                <mat-error>
                    First name is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Last name</mat-label>
                <input matInput formControlName="lastName">
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                <mat-error>
                    Last name is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Middle name</mat-label>
                <input matInput formControlName="middleName">
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Medical referal type</mat-label>
                <mat-select name="type" formControlName="medicalReferalsType">
                    <mat-option [value]="medicalReferalsEnum.Human">
                        Medicine
                    </mat-option>
                    <mat-option [value]="medicalReferalsEnum.Veterinary">
                        Veterinary
                    </mat-option>
                </mat-select>
                <mat-error>
                    Medical referal type is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline"
                [fxShow]="userForm.get('medicalReferalsType').value == medicalReferalsEnum.Human">
                <mat-label>Speciality</mat-label>
                <mat-select name="type" formControlName="speciality">
                    <!-- <mat-option [value]="specialityEnum.NotSet">
                        Not set
                    </mat-option> -->
                    <mat-option [value]="specialityEnum.TraumatologistOrthopedist">
                        Traumatologist orthopedist
                    </mat-option>
                    <mat-option [value]="specialityEnum.MaxillofacialSurgeon">
                        Maxillofacial surgeon
                    </mat-option>
                    <mat-option [value]="specialityEnum.Neurosurgeon">
                        Neurosurgeon
                    </mat-option>
                    <mat-option [value]="specialityEnum.Other">
                        Other
                    </mat-option>
                </mat-select>
                <mat-error>
                    Wrong speciality, speciality must be set for medicalReferals: Human
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Country</mat-label>
                <input matInput formControlName="country">
                <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                <mat-error>
                    Country is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>City</mat-label>
                <input matInput formControlName="city">
                <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                <mat-error>
                    City is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Phone</mat-label>
                <input matInput formControlName="phoneNumber">
                <mat-icon matSuffix class="secondary-text">contact_phone</mat-icon>
                <mat-error>
                    Phone is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Hospital/Company</mat-label>
                <input matInput formControlName="company">
                <mat-icon matSuffix class="secondary-text">account_balance</mat-icon>
                <mat-error>
                    Hospital/Company is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Language</mat-label>
                <mat-select name="type" formControlName="language">
                    <mat-option [value]="languageEnum.enUS">
                        English
                    </mat-option>
                    <mat-option [value]="languageEnum.ruRU">
                        Russian
                    </mat-option>
                </mat-select>
                <mat-error>
                    Language is required
                </mat-error>
            </mat-form-field>


            <div fxLayout="column" fxLayoutAlign="center center">

                <mat-form-field appearance="outline">
                    <mat-label>Roles</mat-label>
                    <mat-chip-list *ngIf="user.roles.length > 0" #chipList aria-label="Roles selection">
                        <mat-chip *ngFor="let role of user.roles" (removed)="onRemoveRole(role)">
                            {{role}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>

                        <input placeholder="Add role..." [matAutocomplete]="auto">
                    </mat-chip-list>
                    <input matInput *ngIf="user.roles.length == 0" placeholder="Add role..." [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onAddRole($event)">
                        <mat-option *ngFor="let role of rolesList" [value]="role">
                            {{role}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

            </div>


            <div [fxShow]="showPasswordFields">
                <div class="multiline-error">
                    <mat-form-field appearance="outline">
                        <mat-label> {{'USERS.PASSWORD' | translate }} </mat-label>
                        <input id="password" matInput autocomplete="off" type="password" formControlName="password">
                        <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                        <mat-error *ngIf="userForm.get('password').hasError('required')">
                            {{'USERS.PASSWORDREQUIRED' | translate }}
                        </mat-error>
                        <mat-error style="position: relative;" *ngIf="userForm.get('password').hasError('pattern')">
                            <span [innerHtml]="'USERS.PASSWORDREQUIREMENT' | translate | safe: 'html'"></span>

                        </mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field appearance="outline">
                    <mat-label>{{'USERS.PASSWORDCONFIRM' | translate }}</mat-label>
                    <input id="passwordConfirm" matInput autocomplete="off" type="password" formControlName="passwordConfirm">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="userForm.get('passwordConfirm').hasError('required')">
                        {{'USERS.PASSWORDCONFIRMREQUIRED' | translate }}
                    </mat-error>
                    <mat-error *ngIf="!userForm.get('passwordConfirm').hasError('required') &&
                    userForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        {{'USERS.PASSWORDMUSTMATCH' | translate }}
                    </mat-error>
                </mat-form-field>


            </div>

            <div class="show-hide-extra-fields" (click)="toggleExtraToFields()">
                <span [fxShow]="!showPasswordFields">Change password</span>
                <span [fxShow]="showPasswordFields">Don't change password</span>
            </div>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button *ngIf="action === 'edit'" mat-button class="mr-8" (click)="matDialogRef.close(['cancel',userForm])"
            aria-label="Delete" matTooltip="Delete">
            CANCEL
        </button>

        <button *ngIf="action !== 'edit'" mat-button class="save-button" (click)="matDialogRef.close(userForm)"
            [disabled]="userForm.invalid" aria-label="SAVE">
            ADD
        </button>

        <button *ngIf="action === 'edit'" mat-button class="save-button" (click)="onSave()"
            [disabled]="userForm.invalid" aria-label="SAVE">
            SAVE
        </button>

    </div>
</div>