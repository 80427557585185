import { Store } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { of, forkJoin } from "rxjs";
import { filter, take } from "rxjs/operators";
import { GetUsersPaidModules, GetUsersPaidModulesOffline } from "../store/actions/paidModules.actions";
import { selectUsersPaidModules } from "../store/selectors/paidModules.selector";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "ngx-logger";
export class PaidModulesResolver {
    constructor(_store, logger) {
        this._store = _store;
        this.logger = logger;
    }
    resolve(route, state) {
        const routeParams = route.params;
        this.logger.info('Paid modules route resolver: ', route.params);
        const obsCollection = [];
        obsCollection.push(this._store.select(selectUsersPaidModules).pipe(filter(allPaidModules => allPaidModules != null), take(1)));
        obsCollection.push(of(this._store.dispatch(new GetUsersPaidModules())));
        obsCollection.push(of(this._store.dispatch(new GetUsersPaidModulesOffline())));
        return forkJoin(obsCollection);
    }
}
PaidModulesResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaidModulesResolver_Factory() { return new PaidModulesResolver(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.NGXLogger)); }, token: PaidModulesResolver, providedIn: "root" });
