<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Clone case</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fxLayout="column">

        <div fxFlex class="p-12" fxLayoutAlign="center"> <span class="mat-headline">Select user to clone case </span>
        </div>


        <form name="caseCloneForm" [formGroup]="caseCloneForm" novalidate class="case-clone-form" fxLayout="column"
            fxFlex>

            <div fxLayout="column" fxLayoutAlign="center center">

                <mat-form-field appearance="outline">
                    <mat-label>New title</mat-label>
                    <input matInput name="newTitle" formControlName="newTitle" type="text">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Select user</mat-label>
                    <input type="text" placeholder="Search user..." aria-label="User email" matInput
                        formControlName="userToClone" #userToClone (input)="onSearchQuery(userToClone.value)"
                        [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let user of foundUsers" [value]="user.email">
                            {{user.email}}
                        </mat-option>
                    </mat-autocomplete>

                    <mat-error *ngIf="caseCloneForm.get('userToClone').hasError('required')">
                        User is required
                    </mat-error>
                    <mat-error
                        *ngIf="!caseCloneForm.get('userToClone').hasError('required') && caseCloneForm.get('userToClone').hasError('wrongUserEmail')">
                        Wrong user email
                    </mat-error>
                </mat-form-field>


            </div>
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div>

            <button type="submit" mat-raised-button color="accent" class="save-button"
                [disabled]="caseCloneForm.invalid || requestInProgress" aria-label="Create" (click)="onSubmit()">
                Clone
            </button>

            <span class="p-4 red-500-fg">
                {{errorText}}
            </span>

            <!-- <button mat-icon-button matTooltip="Attach a file">
                <mat-icon>attach_file</mat-icon>
            </button> -->
        </div>

        <button mat-icon-button (click)="matDialogRef.close(['delete',caseCloneForm])" aria-label="Delete"
            matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>