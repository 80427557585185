

<div id="batch-operations" class="page-layout simple right-sidebar" fxLayout="row">

    <!-- CENTER -->
    <div class="center" fusePerfectScrollbar>

        <!-- HEADER -->
        <header ></header>
        <!-- / HEADER -->

        <!-- CONTENT -->
        <div class="content">

            <mat-tab-group dynamicHeight>

                <mat-tab label="Batch clone">
             
                    <batch-clone [caseCloneBatchInfo]="caseCloneBatchInfo$ | async"  (onBatchClone)="batchClone($event)"></batch-clone>

                </mat-tab>

                <mat-tab label="...">
             

                </mat-tab>

                <mat-tab label="...">                   



                </mat-tab>
            </mat-tab-group>

        </div>
        <!-- / CONTENT -->

    </div>
    <!-- / CENTER -->



</div>