import { IAppState } from '../state/app.state';
import { IAuthState } from '../state/auth.interface';
import { createSelector } from '@ngrx/store';

const selectAuth = (state: IAppState) => state.auth;

export const selectManagementOrganizationId = createSelector(
    selectAuth,
    (state: IAuthState) => {        
        return state.currentManagementOrganizationId;
    }
)

export const selectResetPasswordStatus = createSelector(
    selectAuth,
    (state: IAuthState) => {        
        return state.resetPasswordStatus;
    }
)