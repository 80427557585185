import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { AuthenticationService, LanguageService } from '@appservices';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IAppState } from 'app/core/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { ClonePlannerCaseBatchCommand, LongRunningTaskInfo } from '@appmodels';
import { CloneCaseBatch } from 'app/core/store/actions/cases.actions';
import { selectCaseCloneBatchInfo } from 'app/core/store/selectors/cases.selector';
import { Observable } from 'rxjs';


@Component({
    selector: 'batch-operations',
    templateUrl: './batch-operations.component.html',
    styleUrls: ['./batch-operations.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class BatchOperationsComponent implements OnInit {
   
    isLoading: boolean;
    error: any;
    private returnUrl: string;
    public caseCloneBatchInfo$: Observable<LongRunningTaskInfo>;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _store: Store<IAppState>,
        private logger: NGXLogger
    ) {

        this.caseCloneBatchInfo$ = this._store.pipe(select(selectCaseCloneBatchInfo));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
  
    }

   public batchClone(clonePlannerCaseBatchCommand: ClonePlannerCaseBatchCommand):void{

        this._store.dispatch(new CloneCaseBatch(clonePlannerCaseBatchCommand));      
        
   }
}
