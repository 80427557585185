export class GetPlannerFilesSASListQuery {
    public CaseId: string;
    public SASRequestFiles: Array<GetPlannerFileSASQuery>;

    constructor()
    {
        this.SASRequestFiles = new Array<GetPlannerFileSASQuery>();
    }
}

export class GetPlannerFileSASQuery {
    public FileId: string;
    public FileName: string;
}