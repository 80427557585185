import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { LongRunningTaskInfo } from "@appmodels";
import { APP_CONFIG, AppConfig } from "app/app-config.module";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class LongRunningTasksService {  

    constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient, private logger: NGXLogger) {       
    }

    public getLongRunningTaskInfo(taskId: string): Observable<LongRunningTaskInfo> {      

        const url = `${this.config.apiEndpoint}/api/private/LongRunningTasks/GetLongRunningTaskInfo?TaskId=${taskId}`;
        this.logger.debug("HTTP GET get long running task info", `Url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => {
                    this.logger.debug("Response get long running task info:", response);                    
                }),
                map(response => response));
    }
}