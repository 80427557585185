import { Component, Inject, ViewEncapsulation, OnDestroy, ChangeDetectionStrategy, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS, } from 'app/core/helpers';
import { PlannerCase, FileType, ModelMaterialType, FileResponse } from '@appmodels';
import { Subject, Observable, forkJoin, observable, pipe, of } from 'rxjs';
import { takeUntil, finalize, map, catchError, switchMap } from 'rxjs/operators';
import { FilesService } from 'app/core/services/planner/files.sevice';
import { } from 'tunnel';
import { NGXLogger } from 'ngx-logger';
import { GeometryService } from 'app/core/services/planner/geometry.service';
import { flatMap } from 'lodash';

@Component({
    selector: 'add-file',
    templateUrl: './add-file.component.html',
    styleUrls: ['./add-file.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class AddFileDialogComponent implements OnDestroy {

    fileTypeEnum = FileType;
    modelMaterialTypeEnum = ModelMaterialType;

    files: any;

    //dialogRef: any;

    validComboDrag: any;
    invalidComboDrag: any;

    filesIndices: any[];
    filesEditForm: FormGroup;
    errorText: string;
    requestInProgress: boolean;
    private _selectedCase: PlannerCase;

    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        private _filesService: FilesService,
        public matDialogRef: MatDialogRef<AddFileDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private logger: NGXLogger
    ) {
        // Set the defaults
        this._selectedCase = _data;

        this.filesEditForm = this.createFilesEditForm(null);

        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */


    createFilesEditForm(files: File[]): FormGroup {

        this.filesIndices = [];

        let parentFormGroup = new FormGroup({});

        if (!files)
            return parentFormGroup;

        let fileIndex = 1;

        files.forEach(fileToUpload => {

            let fileExtensionIndex = fileToUpload.name.lastIndexOf('.');
            let fileExtension = fileToUpload.name.substring(fileExtensionIndex + 1, fileToUpload.name.length);
            let title = fileToUpload.name.substring(0, fileExtensionIndex);

            let fileFormGroup = new FormGroup({
                title: new FormControl(title, Validators.required)
            });

            this.logger.info('fileExtension: ' + fileExtension);

            let fileType: FileType = fileExtension == 'stl' || fileExtension == 'ply' ? FileType.STLMODEL : null;

            let defaultMaterialType = null;

            if (fileType == FileType.STLMODEL) {

                const titleLower = title.toLowerCase();

                if (/\sP\d\d\s/.test(title) || titleLower.includes('peek') || titleLower.includes('пик') || titleLower.includes('cage')) {

                    defaultMaterialType = ModelMaterialType.PEEK;

                }
                else if (/\sT\d\d\s/.test(title) || titleLower.includes('cup') || titleLower.includes('prosthesis') || titleLower.includes('implant') ||
                 titleLower.includes('plate') || titleLower.includes('insert') || titleLower.includes('screw') ||
                  titleLower.includes('stem') || titleLower.includes('wedge') || titleLower.includes('pgr')) {

                    defaultMaterialType = ModelMaterialType.Titanium;

                }
                else if (/\sG\d\d\s/.test(title) || titleLower.includes('guide')) {

                    defaultMaterialType = ModelMaterialType.Plastic;

                }
                else if (/\sF\d\d\s/.test(title)) {

                    defaultMaterialType = ModelMaterialType.Gypsum;

                }
                else {

                    defaultMaterialType = ModelMaterialType.Bone;

                }
            }

            fileFormGroup.addControl('fileType', new FormControl(null, Validators.required));

            const fileAdditionalInfoGroupName = 'fileAdditionalInfoGroup';

            let fileAdditionalInfoGroup = new FormGroup({
                isUseIn3d: new FormControl(false),
                materialType: new FormControl(defaultMaterialType),
                saw: new FormControl(null),
                pin: new FormControl(null),
                screw: new FormControl(null)
            });

            fileFormGroup.addControl(fileAdditionalInfoGroupName, fileAdditionalInfoGroup);

            const changes$ = fileFormGroup.controls.fileType.valueChanges;

            changes$.subscribe(newFileType => {

                this.logger.info('File type changed!');

                fileAdditionalInfoGroup.controls['materialType'].clearValidators();

                switch (newFileType) {

                    case FileType.STLMODEL:

                        fileAdditionalInfoGroup.controls['materialType'].setValidators(Validators.required);

                        break;
                    default:
                        break;
                }

                fileAdditionalInfoGroup.controls['materialType'].updateValueAndValidity();

            });

            fileFormGroup.controls.fileType.setValue(fileType);

            let groupName = 'file' + fileIndex

            parentFormGroup.addControl(groupName, fileFormGroup);

            this.filesIndices.push({ groupName: groupName, file: fileToUpload });

            fileIndex++;
        });

        return parentFormGroup;

    }

    filesSelected(files: File[]) {
        this.logger.info('files selected, count: ' + files.length);

        this.filesEditForm = this.createFilesEditForm(files);
    }

    removeFiles() {
        this.createFilesEditForm(null);
    }

    addFiles(isAddToScene: boolean) {

        if (this.filesEditForm.invalid)
            return;

        this.requestInProgress = true;

        const fileUploads: Observable<FileResponse>[] = [];

        this.filesIndices.forEach(x => {
            fileUploads.push(this.uploadFile(x));
        });

        forkJoin(fileUploads).pipe(
            takeUntil(this._unsubscribeAll),
            finalize(() => {
                this.requestInProgress = false;
            })
        )
            .subscribe({
                next: result => {

                    result.forEach(r => console.log(r));

                    if (isAddToScene) {
                        this.matDialogRef.close(['upload&add', true, result]);
                    }
                    else {
                        this.matDialogRef.close(['upload', true]);
                    }
                },
                error: err => {
                    this.logger.info(err);
                    this.errorText = err;
                }
            })
    }

    uploadFile(filesIndex: any): Observable<FileResponse> {


        const fileGroup = this.filesEditForm.controls[filesIndex.groupName] as FormGroup;
        let file = filesIndex.file;

        const title = fileGroup.controls['title'].value;
        const fileType = fileGroup.controls['fileType'].value;

        const additionalInfoObj: any = {};
        const fileAdditionalInfoGroup = fileGroup.controls['fileAdditionalInfoGroup'] as FormGroup;

        let preprocess = of<File>(file);

        let needToZip = false;

        switch (fileType) {
            case FileType.DICOM:
                additionalInfoObj.isUseIn3d = fileAdditionalInfoGroup.controls['isUseIn3d'].value;
                break;
            case FileType.STLMODEL:
                
                if(file.name.toLowerCase().lastIndexOf('.stl') == file.name.length - 4)
                    preprocess = new GeometryService().convertToPLY(file);

                needToZip = true;

                additionalInfoObj.materialType = fileAdditionalInfoGroup.controls['materialType'].value;
                additionalInfoObj.saw = fileAdditionalInfoGroup.controls['saw'].value;
                additionalInfoObj.pin = fileAdditionalInfoGroup.controls['pin'].value;
                additionalInfoObj.screw = fileAdditionalInfoGroup.controls['screw'].value;
                break;
            default:
                break;
        }

        const additionalInfo = JSON.stringify(additionalInfoObj);


        const piped = 
          preprocess.pipe(
           
           switchMap(res=> this._filesService.uploadFile(res, this._selectedCase.id, title, fileType, additionalInfo, needToZip).pipe(
            map(fileResponse => {

                filesIndex.fileResponse = fileResponse;

                return fileResponse;
            }),
            catchError(err => {
                filesIndex.fileResponse = null;
                throw err;
            })
           )
           
           )          
        )

        return piped;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
