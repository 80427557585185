import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'app/core/store/state/app.state';
import { selectUsers } from 'app/core/store/selectors/users.selectors';
import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { EditUser, DeleteUser } from 'app/core/store/actions/users.actions';
import { selectAppLogsGroups, selectAppLogsByConnection, selectAppLogsFilterParams } from 'app/core/store/selectors/appLogs.selector';
import { PlannerAppLogsGroup } from 'app/core/models/planner/plannerAppLogsGroup';
import { GetAppLogsGroups, GetAppLogsByConnection, SetAppLogsFilterParams, SetAppLogsSearchPattern } from 'app/core/store/actions/appLogs.actions';
import { selectCasesList } from 'app/core/store/selectors/cases.selector';
import { PlannerCase, PlannerQualitySurveyCase } from '@appmodels';
import { GetCases, SetCaseStatus, SetCasesSearchPattern } from 'app/core/store/actions/cases.actions';
import { FusePerfectScrollbarPosition } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.interfaces';
import PerfectScrollbar from 'perfect-scrollbar';
import { selectQualitySurveyCasesList, selectQualitySurveyCasesRequestArgs } from 'app/core/store/selectors/qualitySurveyCases.selector';
import { GetQualitySurveysCases, SetQualitySurveyCasesIsWidthAdmins, SetQualitySurveyCasesSearchPattern, SetQualitySurveysCasesFilterParams } from 'app/core/store/actions/qualitySurveyCases.actions';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'case-quality-surveys',
    templateUrl: './caseQualitySurveys.component.html',
    styleUrls: ['./caseQualitySurveys.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class CaseQualitySurveysComponent implements OnInit, OnDestroy {
    dialogRef: any;
    hasSelectedContacts: boolean;
    searchInput: FormControl;

    qualitySurveyCasesFilterParams$: Observable<any>;
    qualitySurveyCases$: Observable<PlannerQualitySurveyCase[]>;

    private _changeSearchPatternTimer: NodeJS.Timer;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UsersService} _contactsService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _matDialog: MatDialog,
        private _store: Store<IAppState>,
        private router: Router, private route: ActivatedRoute
    ) {
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    linkClick(caseId: string): void {
        this.router.navigate([`../cases/all/${caseId}`], { relativeTo: this.route });
    }

    /**
     * On init
     */
    ngOnInit(): void {

        this.qualitySurveyCasesFilterParams$ = this._store.pipe(select(selectQualitySurveyCasesRequestArgs));
        this.qualitySurveyCases$ = this._store.pipe(select(selectQualitySurveyCasesList));
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    filterParamsChanged(isWithAdmins: boolean): void {

        this._store.dispatch(new SetQualitySurveyCasesIsWidthAdmins(isWithAdmins));
    }

    getMoreCases(): void {
        this._store.dispatch(new GetQualitySurveysCases());
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // setCaseStatus(setCaseStatusArgs: any): void {


    //     if (!setCaseStatusArgs)
    //         return;

    //     // this.dialogRef = this._matDialog.open(CaseManagementDetailsComponent, {
    //     //     panelClass: 'logs-group-details-dialog',
    //     //     data: {
    //     //         action: 'logsGroupDetails',
    //     //         $appLogs: this._store.pipe(select(selectAppLogsByConnection))
    //     //     }
    //     // });

    //     this._store.dispatch(new SetCaseStatus(setCaseStatusArgs.caseId, setCaseStatusArgs.caseStatus, true));

    // }

    onSearchQuery(event: any): void {
        const searchQuery: string = event.target.value;

        const dispatchFunc = () => this._store.dispatch(new SetQualitySurveyCasesSearchPattern(searchQuery));

        clearTimeout(this._changeSearchPatternTimer);
        this._changeSearchPatternTimer = setTimeout(dispatchFunc, 2000);
    }


    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
}
