import { FileType } from '..';

export class PlannerFile {
    id: string = null;
    title: string = null;
    fileName: string;
    fileExtension: string;
    fileSize: number;
    fileType: FileType = FileType.UNKNOWN;
    creationDate: Date;
    md5hash: string;
    additionalInfo: string = null;
    version: number;
    isZipped: boolean;
    fileSizeZipped: number;
    fileAeskey: string;
    isAllowUserDownload: boolean;
}