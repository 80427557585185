import { Component, Inject, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ElementRef, OnInit, ViewChild, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { MedicalReferals, Language, PlannerRoles, Speciality, PlannerUsersAvailableModules, SceneType, PlannerModuleType } from '@appmodels';
import { confirmPasswordValidator, ObjectMapper, passwordPattern } from 'app/core/helpers';
import { MatOption, MatAutocomplete, MatAutocompleteSelectedEvent, MatInput } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../../../i18n/en';
import { locale as russian } from '../../../i18n/ru';
import { AfterViewInit } from '@angular/core';
import { ViewChildren } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'paidModules-form-dialog',
    templateUrl: './paidModules-form.component.html',
    styleUrls: ['./paidModules-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PaidModulesFormDialogComponent implements OnInit, OnDestroy {
    action: string;

    paidModuleForm: FormGroup;
    dialogTitle: string;

    paidModule: PlannerUsersAvailableModules;
    rolesList: string[];

    moduleTypeEnum = PlannerModuleType;
    specialityEnum = Speciality;
    languageEnum = Language;

    showPasswordFields: boolean = false;

    @Input()
    set users(value: PlannerUser[]) {
        this.foundUsers = value;
        if (!this._cdr['destroyed']) {
            this._cdr.detectChanges();
        }
    }

    foundUsers: PlannerUser[];

    @Output()
    onSearchQueryChanged: EventEmitter<string>;

    // @ViewChildren('#password')
    // public passwordElement : MatInput;
    // @ViewChildren('#passwordConfirm')
    // public passwordConfirmElement : MatInput;

    //private _isFirstPasswordChange: boolean = true;

    // Private
    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {MatDialogRef<PaidModulesFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<PaidModulesFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _cdr: ChangeDetectorRef,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, russian);
        this._unsubscribeAll = new Subject();
        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit user paid module';
            this.paidModule = { ..._data.paidModule };
        }
        else {
            this.dialogTitle = 'Add user paid module';
            this.paidModule = new PlannerUsersAvailableModules();           
        }

        this.paidModuleForm = this.createPaidModuleForm(this.paidModule);

        this.onSearchQueryChanged = new EventEmitter();
    }

    ngOnInit(): void {
        // this.userForm.get('password').setValue('');
        // this.userForm.get('passwordConfirm').setValue('');
    }



    onSave() {

        ObjectMapper.mapFormGroupToObject(this.paidModuleForm, this.paidModule);        

        if (this.action === 'new') {
            const selectedUser = this.foundUsers.find(user => user.email == this.paidModule.userEmail);
            this.paidModule.userId = selectedUser.id;
        }

        const dateObj = moment(this.paidModule.startDate);

        const date = moment();
        date.set({year:dateObj.year(),month:dateObj.month(),date:dateObj.date()});

        this.paidModule.startDate = date as any;

        this.matDialogRef.close(['save', this.paidModule])
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createPaidModuleForm(paidModule: PlannerUsersAvailableModules = null): FormGroup {
        const form = this._formBuilder.group({
            userEmail: [{ value: '', disabled: this.action === 'new' ? false : true }, [Validators.required, Validators.email]],
            moduleType: ['', Validators.required],
            startDate: ['', Validators.required],
            daysAllocated: [''],
            hospital: [''],
        });

        if (paidModule) {
            ObjectMapper.mapObjectToFormGroup(paidModule, form);
        }

        return form;
    }

    onSearchQuery(searchQuery) {

        if (!searchQuery || searchQuery.length < 3)
            return;

        this.onSearchQueryChanged.emit(searchQuery);
    }



    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
