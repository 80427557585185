import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/authentication.service";
export class AuthGuard {
    constructor(router, authenticationService) {
        this.router = router;
        this.authenticationService = authenticationService;
    }
    canActivate(route, state) {
        const currentUser = this.authenticationService.currentUser.value;
        if (currentUser) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthenticationService)); }, token: AuthGuard, providedIn: "root" });
