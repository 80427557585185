export class EnumHelpers {

    static getValAsString<T>(o: T, key: string | number): string {

        if (typeof key === 'string') {
            return (o as T)[(o as T)[key]];
        } else if (typeof key === 'number') {
            return (o as T)[key];
        } else {
            throw new Error(`Unable to parse enum from ${typeof (key)}`);
        }
    }
}