import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from 'app/core/helpers';
import { Subject } from 'rxjs';
import { EditSceneObjectDialogComponent } from '../edit-scene-object/edit-scene-object.component';
import { FileAllowAction, PlannerFile, PlannerSceneVisibilityGroup } from '@appmodels';
import { PlannerSceneObject } from 'app/core/models/planner/plannerSceneObject';
import { FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'edit-file-permissions',
  templateUrl: './edit-file-permissions.component.html',
  styleUrls: ['./edit-file-permissions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class EditFilePermissionsComponent implements OnDestroy {

  editFilePermissionsForm: FormGroup;


  // get visabilityGroupsFormArray(): AbstractControl[] {
  //   return (this.editFilePermissionsForm.get('visibilityGroups') as FormArray).controls;
  // }



  errorText: string;
  visibilityGroups: PlannerSceneVisibilityGroup[];
  caseFiles: PlannerFile[];

  private _unsubscribeAll: Subject<any>;

  constructor(
    public matDialogRef: MatDialogRef<EditSceneObjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    //this.visibilityGroups = this._data.visibilityGroups;
    // console.log();
    this.caseFiles = _.orderBy(this._data.caseFiles as PlannerFile[], ['foundationDate', obj => obj.title.toLowerCase()], ['desc', 'asc']);

    this.editFilePermissionsForm = this.createEditFilePermissionsForm();//this.visibilityGroups);

    this._unsubscribeAll = new Subject();
  }

  public getArrayGroup(control: AbstractControl, name: string): AbstractControl[] {
    return (control.get(name) as FormArray).controls;
  }

  ngOnInit() {
  }


  private createEditFilePermissionsForm(): FormGroup {

    const editFilePermissionsFormGroup = new FormGroup({
    });

    const editFilePermissionsFormArray = new FormArray([]);

    this.caseFiles.forEach(plannerFile => {

      const editFilePermissionsGroup = new FormGroup({
        id: new FormControl(plannerFile.id),
        sceneObjectTitle: new FormControl(plannerFile.title),
        isAllowUserDownload: new FormControl(plannerFile.isAllowUserDownload)
      });

      editFilePermissionsFormArray.push(editFilePermissionsGroup);
    });

    editFilePermissionsFormGroup.addControl('editFilePermissions', editFilePermissionsFormArray);

    return editFilePermissionsFormGroup;
  }



  public saveFilesPermissions(): void {

    const formArray = this.editFilePermissionsForm.controls['editFilePermissions'] as FormArray;
    const filesAllowUserDownload: FileAllowAction[] = [];

    formArray.controls.forEach(editFilePermissionsGroup => {

      const fileId = editFilePermissionsGroup.get('id').value;
      const isActionAllowed = editFilePermissionsGroup.get('isAllowUserDownload').value;

      const fileAllowUserDownload: FileAllowAction = { fileId, isActionAllowed}

      filesAllowUserDownload.push(fileAllowUserDownload);

    });


    this.matDialogRef.close(['save', filesAllowUserDownload]);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
