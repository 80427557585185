import { Component, Inject, ViewEncapsulation, OnDestroy, ChangeDetectionStrategy, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MatAutocompleteSelectedEvent } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS, DateValidator, ObjectMapper } from 'app/core/helpers';
import { OperationType, SceneType, Gender, MedicalReferals, CreatePlannerCaseCommand } from '@appmodels';
import { CasesService } from '@appservices';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { PlannerUser } from 'app/core/models/planner/plannerUser';

@Component({
    selector: 'case-clone',
    templateUrl: './case-clone.component.html',
    styleUrls: ['./case-clone.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class CaseCloneDialogComponent implements OnDestroy {

    medicalReferalsEnum = MedicalReferals;
    operationTypeEnum = OperationType;
    sceneTypeEnum = SceneType;
    genderEnum = Gender;
    medicalReferalsTypeEnum = MedicalReferals;


    caseCloneForm: FormGroup;
    errorText: string;
    requestInProgress: boolean;

    @Input()
    set users(value: PlannerUser[]) {
        this.foundUsers = value;
        if (!this._cdr['destroyed']) {
            this._cdr.detectChanges();
        }
    }

    foundUsers: PlannerUser[];

    @Output()
    onSearchQueryChanged: EventEmitter<string>;

    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        // private _casesService: CasesService,
        public matDialogRef: MatDialogRef<CaseCloneDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _cdr: ChangeDetectorRef,
        private logger: NGXLogger
    ) {
        // Set the defaults
        _data.title;

        this.caseCloneForm = this.createCaseCloneForm(_data.title);

        this._unsubscribeAll = new Subject();

        this.onSearchQueryChanged = new EventEmitter();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    createCaseCloneForm(title: string): FormGroup {
        return new FormGroup({
            newTitle: new FormControl(title, Validators.required),
            userToClone: new FormControl('', [Validators.required, this.validateUser])
        });
    }

    onSearchQuery(searchQuery) {

        if (!searchQuery || searchQuery.length < 3)
            return;

        this.onSearchQueryChanged.emit(searchQuery);
    }

    onSubmit() {

        if (this.caseCloneForm.invalid)
            return;

        const newTitle = this.caseCloneForm.get('newTitle').value;
        const userEmail = this.caseCloneForm.get('userToClone').value;

        const selectedUser = this.foundUsers.find(user => user.email == userEmail);

        this.matDialogRef.close(['clone', newTitle, selectedUser]);
    }

    validateUser: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

        if (!control || !control.parent) {
            return null;
        }

        const userEmail = control.parent.get('userToClone').value;

        let isValid = false;

        if (!this.foundUsers || this.foundUsers.length == 0) {
            isValid = false;
        }
        else {

            const foundUsersIndex = this.foundUsers.findIndex(user => user.email == userEmail);

            if (foundUsersIndex >= 0)
                isValid = true;
        }

        return isValid ? null : { wrongUserEmail: true };
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
