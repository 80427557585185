import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { AppConfig } from '../../app-config.module';
import { NGXLogger } from 'ngx-logger';
import { Guid } from '../helpers/classes';
import * as i0 from "@angular/core";
import * as i1 from "../../app-config.module";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-logger";
export class AuthenticationService {
    // public currentUser: Observable<AuthResponse>;
    constructor(config, http, logger) {
        this.config = config;
        this.http = http;
        this.logger = logger;
        this.authCredentials = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.setNewAppConnectionUid();
        //     this.currentUser = this.authCredentials.asObservable();
    }
    get currentUser() {
        return this.authCredentials;
    }
    get appConnectionUid() {
        return this.appConnectionUidString;
    }
    login(username, password) {
        const url = `${this.config.apiEndpoint}/api/login`;
        this.logger.debug("HTTP POST login", `url: ${url}`);
        return this.http.post(url, { UserName: username, Password: password, GrantType: 'password', MacAddress: '30-5A-3A-46-03-4C' })
            .pipe(tap(response => this.logger.debug("Response login:", response)), map(authCredentials => {
            // login successful if there's a jwt token in the response
            if (authCredentials && authCredentials.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(authCredentials));
                this.setNewAppConnectionUid();
                this.authCredentials.next(authCredentials);
            }
            return;
        }), catchError(err => {
            this.logger.info(err.status);
            return throwError(err);
        }));
    }
    tokenLogin() {
        this.logger.info('tokenLogin');
        return this.http.post(`${this.config.apiEndpoint}/api/login`, { UserName: this.authCredentials.value.userName, Password: this.authCredentials.value.refreshToken, GrantType: 'token', MacAddress: '30-5A-3A-46-03-4C' })
            .pipe(map(authCredentials => {
            this.logger.info(authCredentials);
            // login successful if there's a jwt token in the response
            if (authCredentials && authCredentials.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(authCredentials));
                this.setNewAppConnectionUid();
                this.authCredentials.next(authCredentials);
            }
            return;
        }), catchError(err => {
            this.logger.info('token login err');
            this.logger.info(err.status);
            return throwError(err);
        }));
    }
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.authCredentials.next(null);
    }
    sendRecoverPasswordRequest(userEmail, returnUrl) {
        const url = `${this.config.apiEndpoint}/api/public/Users/RequestRecoverPassword`;
        this.logger.debug("HTTP POST RequestRecoverPassword", `url: ${url}`);
        return this.http.post(url, { Email: userEmail, ReturnUrl: returnUrl })
            .pipe(tap(response => this.logger.debug("Response request recover password:", response)), catchError(err => {
            this.logger.info(err.status);
            return throwError(err);
        }));
    }
    recoverPassword(recoverPasswordCommand) {
        const url = `${this.config.apiEndpoint}/api/public/Users/RecoverPassword`;
        this.logger.debug("HTTP POST RecoverPassword", `url: ${url}`);
        return this.http.post(url, recoverPasswordCommand)
            .pipe(tap(response => this.logger.debug("Response request recover password:", response)), catchError(err => {
            this.logger.info(err.status);
            return throwError(err);
        }));
    }
    setNewAppConnectionUid() {
        this.appConnectionUidString = Guid.newGuid();
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.APP_CONFIG), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.NGXLogger)); }, token: AuthenticationService, providedIn: "root" });
