/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i3 from "@angular/material/toolbar";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/flex-layout/flex";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "mat-toolbar", [["class", "mat-toolbar"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(1, 4243456, null, 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], null, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 5, "div", [["fxFlex", ""], ["fxLayout", "row"], ["fxLayoutAlign", "center center"], ["fxLayoutAlign.gt-xs", "space-between center"]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i6.DefaultLayoutDirective, [i1.ElementRef, i7.StyleUtils, [2, i6.LayoutStyleBuilder], i7.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(5, 671744, null, 0, i6.DefaultLayoutAlignDirective, [i1.ElementRef, i7.StyleUtils, [2, i6.LayoutAlignStyleBuilder], i7.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"], "fxLayoutAlign.gt-xs": [1, "fxLayoutAlign.gt-xs"] }, null), i1.ɵdid(6, 671744, null, 0, i6.DefaultFlexDirective, [i1.ElementRef, i7.StyleUtils, i7.LAYOUT_CONFIG, i6.FlexStyleBuilder, i7.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "mat-subheading-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Polygon Medical Engineering 2016 - 2019"]))], function (_ck, _v) { var currVal_2 = "row"; _ck(_v, 4, 0, currVal_2); var currVal_3 = "center center"; var currVal_4 = "space-between center"; _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = ""; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 49152, null, 0, i8.FooterComponent, [], null, null)], null, null); }
var FooterComponentNgFactory = i1.ɵccf("footer", i8.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
