import { Injectable } from '@angular/core';
import { IAppState } from '../store/state/app.state';
import { Store } from '@ngrx/store';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { GetCaseBundle, GetCases, GetSelectedCaseScenes, GetSelectedCaseShares, SelectCase, SelectScene, SetCasesFolder, SetCasesSearchPattern } from '../store/actions/cases.actions';
import { selectCasesList, selectSelectedCase } from '../store/selectors/cases.selector';
import { take, filter, tap } from 'rxjs/operators';
import { CaseFolderType } from '../models/planner/enums/plannerCaseEnums';
import { ResetUsersFilter } from '../store/actions/users.actions';

@Injectable({ providedIn: 'root' })
export class CasesResolver {
    constructor(private _store: Store<IAppState>, private logger: NGXLogger) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        const routeParams = route.params;

        this.logger.info('Cases route resolver: ', route.params)

        const obsCollection: Observable<any>[] = [];

        obsCollection.push(this._store.select(selectCasesList).pipe(
            filter(cases => cases != null),
            take(1)
        ));

        let folder: CaseFolderType = CaseFolderType[routeParams.folderHandle as keyof typeof CaseFolderType];

        obsCollection.push(of(this._store.dispatch(new SetCasesFolder(folder))));

        //this.logger.warn(routeParams);

        if (routeParams.caseId) {

            obsCollection.push(of(this._store.dispatch(new SetCasesSearchPattern(routeParams.caseId))));

            obsCollection.push(this._store.select(selectSelectedCase).pipe(
                filter(selectedCase => selectedCase != null),
                take(1),
                tap(x => {
                    this._store.dispatch(new GetSelectedCaseScenes(routeParams.caseId));
                    this._store.dispatch(new GetSelectedCaseShares(routeParams.caseId));
                    this._store.dispatch(new GetCaseBundle(routeParams.caseId));                    
                    this._store.dispatch(new SelectScene({ caseId: routeParams.caseId, sceneId: null }));
                })
            ));

            obsCollection.push(of(this._store.dispatch(new SelectCase(routeParams.caseId))));
        }
        else {

        }

        this._store.dispatch(new ResetUsersFilter());

        return forkJoin(obsCollection);
    }
}