import { Input } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ICurrentUser } from '@appmodels';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  changeDetection: ChangeDetectionStrategy.Default
})
export class HeaderComponent implements OnInit {

  @Input()
  public currentUser: ICurrentUser;

  constructor() { }

  ngOnInit() {
  }

}
