import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { UsersComponent } from './users.component';

import { UserFormDialogComponent } from './user-form/user-form.component';
import { UsersListComponent } from './users-list/users-list.component';
import { UsersSelectedBarComponent } from './selected-bar/selected-bar.component';
import { ContactsMainSidebarComponent } from './sidebars/main/main.component';
import { UsersService } from '@appservices';
import { MatSelectModule, MatChipsModule, MatAutocompleteModule } from '@angular/material';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedModule } from 'app/core/shared/shared.module';



// const routes: Routes = [
//     {
//         path     : '**',
//         component: ContactsComponent,
//         resolve  : {
//             contacts: ContactsService
//         }
//     }
// ];

@NgModule({
    declarations   : [
        UsersComponent,
        UsersListComponent,
        UsersSelectedBarComponent,
        ContactsMainSidebarComponent,
        UserFormDialogComponent
    ],
    imports        : [
        RouterModule,

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatSelectModule,
        MatChipsModule,
        MatAutocompleteModule,
        SharedModule,
        TranslateModule,
        FuseSharedModule,
        FuseConfirmDialogModule,
        FuseSidebarModule
    ],
    providers      : [
        UsersService,
      
    ],
    entryComponents: [
        UserFormDialogComponent
    ]
})
export class UsersModule
{
}
