import { Action } from '@ngrx/store';

export enum EAppActions {
    VoidAction = '[App] Void Action'
}

export class VoidAction implements Action {
    public readonly type = EAppActions.VoidAction;
    constructor() { };
}

export type AppActions = VoidAction;