import { HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { APP_CONFIG, AppConfig } from "app/app-config.module";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import * as FileSaver from 'file-saver';


@Injectable({ providedIn: 'root' })
export class DocumentsService {

    constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient, private logger: NGXLogger) {
    }

    public getBundlePdf(bundle: any): Observable<boolean> {
        const url = `${this.config.documentsEndpoint}/pdf/bundle?json_model=${escape(JSON.stringify(bundle))}`;
        this.logger.debug("HTTP GET document get bundle pdf", `Url: ${url}`);

        return this.http
            .get(url, {
                responseType: 'arraybuffer',
            })
            .pipe(
                tap(response => {
                    this.logger.debug("Document get bundle:", response);
                }),
                map(response => {
                    let blob = new Blob([response], { type: 'application/pdf' });          
                    
                    FileSaver.saveAs(blob, 'Комплектация.pdf');
                    return true;
                })
            );        
    }
}