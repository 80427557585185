<div fxLayout="row" fxLayoutAlign="start center">

    <!-- <mat-checkbox [(ngModel)]="selected" (ngModelChange)="onSelectedChange()" (click)="$event.stopPropagation();">
    </mat-checkbox> -->

    <div class="info" fxFlex FlexLayout="column">

        <div class="row-1"  fxLayout="row" fxLayoutAlign="start center">

            <div class="name" fxLayout="row" fxLayoutAlign="start center" fxFlex>
                <!-- *ngIf="case.from?.avatar" alt="{{case.from?.name}}" src="{{case.from?.avatar}}" -->
                <!-- <img class="avatar" /> -->
                <!-- *ngIf="!case.from?.avatar" -->
                <div class="avatar accent">
                    <!-- {{case.from?.name[0]}} 
                    {{case.ownerName}} -->
                </div>
                <div class="title text-truncate">{{case.title}}</div>
                <!-- *ngIf="case.hasAttachments" -->
                <!-- <mat-icon class="s-16 ml-8">attachment</mat-icon> -->
            </div>



            <div fxLayout="row" fxLayoutAlign="start center">

                <div class="time">{{case.creationDate | date : 'LLL dd'}}</div>

            </div>

        </div>

        <div class="row-2" fxLayout="row" fxLayoutAlign="start center">

            <div fxLayout="column" fxLayoutAlign="start start">

                <!-- <div class="subject text-truncate">
                    {{case.id}}
                </div> -->
                <div class="subject text-truncate">
                    {{case.ownerName}}
                </div>

                <div class="subject text-truncate">
                    <span> status:</span>
                    <case-status-translate [caseStatus]="case.status">
                    </case-status-translate>
                    |
                    <span> op date: {{case.surgicalDate | date}}</span>
                </div>

                <!-- <div class="message text-truncate" *ngIf="mail?.message">
                    {{case.message | htmlToPlaintext | slice:0:180}}{{case.message.length > 180 ? '...' : ''}}
                </div> -->

                <div class="labels" fxLayout="row wrap" fxHide fxShow.gt-sm>
                    <!-- <div class="label" *ngFor="let labelId of case.labels"
                         fxLayout="row" fxLayoutAlign="start center">
                        <div class="label-color"
                             [ngStyle]="{'background-color': labels | getById:labelId:'color'}"></div>
                        <div class="label-title">{{labels | getById:labelId:'title'}}</div>
                    </div> -->

                    <div class="label" fxLayout="row" fxLayoutAlign="start center" *ngIf="case.sceneType == sceneTypeEnum.Spine">
                        <div class="label-color" [ngStyle]="{'background-color': 'green'}"></div>
                        <div class="label-title">Spine</div>
                    </div>

                    <div class="label" fxLayout="row" fxLayoutAlign="start center" *ngIf="case.sceneType == sceneTypeEnum.Knee2D">
                        <div class="label-color" [ngStyle]="{'background-color': 'blue'}"></div>
                        <div class="label-title">Knee 2d</div>
                    </div>

                    <div class="label" fxLayout="row" fxLayoutAlign="start center" *ngIf="case.sceneType == sceneTypeEnum.Hip2D">
                        <div class="label-color" [ngStyle]="{'background-color': 'red'}"></div>
                        <div class="label-title">Hip 2d</div>
                    </div>

                    <div class="label" fxLayout="row" fxLayoutAlign="start center" *ngIf="case.sceneType == sceneTypeEnum.OrthoVET">
                        <div class="label-color" [ngStyle]="{'background-color': 'red'}"></div>
                        <div class="label-title">Ortho vet</div>
                    </div>

                    <div class="label" fxLayout="row" fxLayoutAlign="start center" *ngIf="case.sceneType == sceneTypeEnum.Maxillofacial">
                        <div class="label-color" [ngStyle]="{'background-color': 'red'}"></div>
                        <div class="label-title">Maxillofacial</div>
                    </div>

            
                    <!-- Knee2D = 2,
                    Spine = 3,
                    OrthoVET = 4,
                    Hip2D = 5,
                    Maxillofacial = 6 -->
                </div>

            </div>

        </div>

    </div>

</div>