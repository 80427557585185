import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import * as shape from 'd3-shape';

import { fuseAnimations } from '@fuse/animations';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/core/store/state/app.state';
import { GetOnlineUsers } from 'app/core/store/actions/users.actions';
import { CasesAnalytics, ConnectionInfo, DateInterval, GeneralAnalytics, ICurrentUser, UsersAnalytics } from '@appmodels';
import { selectCurrentUser, selectOnlineUsers } from 'app/core/store/selectors/users.selectors';
import { selectCasesAnalytics, selectGeneralAnalytics, selectUsersAnalytics } from 'app/core/store/selectors/analytics.selector';
import { GetCasesAnalytics, GetGeneralAnalytics, GetUsersAnalytics } from 'app/core/store/actions/analytics.actions';


//import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
//import { ProjectDashboardService } from './project.service';

@Component({
    selector     : 'project-dashboard',
    templateUrl  : './project.component.html',
    styleUrls    : ['./project.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ProjectDashboardComponent implements OnInit
{
    projects: any[];
    selectedProject: any;

    // widgets: any;

    // widget5 = {
    //     currentRange  : 'TW',
    //     xAxis         : true,
    //     yAxis         : true,
    //     gradient      : false,
    //     legend        : false,
    //     showXAxisLabel: false,
    //     xAxisLabel    : 'Days',
    //     showYAxisLabel: false,
    //     yAxisLabel    : 'Isues',
    //     scheme        : {
    //         domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA']
    //     },
    //     onSelect      : (ev) => {
    //         console.log(ev);
    //     },
    //     supporting    : {
    //         currentRange  : '',
    //         xAxis         : false,
    //         yAxis         : false,
    //         gradient      : false,
    //         legend        : false,
    //         showXAxisLabel: false,
    //         xAxisLabel    : 'Days',
    //         showYAxisLabel: false,
    //         yAxisLabel    : 'Isues',
    //         scheme        : {
    //             domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA']
    //         },
    //         curve         : shape.curveBasis
    //     },
    //     mainChart :  {
    //                 '2W': [
    //                     {
    //                         'name'  : 'Mon',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 37
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 9
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Tue',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 32
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 12
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Wed',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 39
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 9
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Thu',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 27
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 12
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Fri',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 18
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 7
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Sat',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 24
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 8
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Sun',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 20
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 16
    //                             }
    //                         ]
    //                     }
    //                 ],
    //                 'LW': [
    //                     {
    //                         'name'  : 'Mon',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 37
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 12
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Tue',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 24
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 8
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Wed',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 51
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 7
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Thu',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 31
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 13
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Fri',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 29
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 7
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Sat',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 17
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 6
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Sun',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 31
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 10
    //                             }
    //                         ]
    //                     }
    //                 ],
    //                 'TW': [
    //                     {
    //                         'name'  : 'Mon',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 42
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 11
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Tue',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 28
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 10
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Wed',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 43
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 8
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Thu',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 34
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 11
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Fri',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 20
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 8
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Sat',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 25
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 10
    //                             }
    //                         ]
    //                     },
    //                     {
    //                         'name'  : 'Sun',
    //                         'series': [
    //                             {
    //                                 'name' : 'issues',
    //                                 'value': 22
    //                             },
    //                             {
    //                                 'name' : 'closed issues',
    //                                 'value': 17
    //                             }
    //                         ]
    //                     }
    //                 ]
    //             }
    // };



    // widget6: any = {};

    // widget7: any = {};
    // widget8: any = {};
    // widget9: any = {};
    // widget11: any = {};

    dateNow = Date.now();

    currentUser$: Observable<ICurrentUser>;
    generalAnalytics$: Observable<GeneralAnalytics>;
    casesAnalytics$: Observable<CasesAnalytics>;
    usersAnalytics$: Observable<UsersAnalytics>;
    onlineUsers$: Observable<ConnectionInfo[]>;

    /**
     * Constructor
     *
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {ProjectDashboardService} _projectDashboardService
     */
    constructor(
        private _store: Store<IAppState>
     //   private _fuseSidebarService: FuseSidebarService,
    //    private _projectDashboardService: ProjectDashboardService
    )
    {

        this._store.dispatch(new GetCasesAnalytics(DateInterval.CalendarMonth));
    
        /**
         * Widget 5
         */
       // this.

        /**
         * Widget 6
         */
        // this.widget6 = {
        //     currentRange : 'TW',
        //     legend       : false,
        //     explodeSlices: false,
        //     labels       : true,
        //     doughnut     : true,
        //     gradient     : false,
        //     scheme       : {
        //         domain: ['#f44336', '#9c27b0', '#03a9f4', '#e91e63']
        //     },
        //     onSelect     : (ev) => {
        //         console.log(ev);
        //     }
        // };

        // /**
        //  * Widget 7
        //  */
        // this.widget7 = {
        //     currentRange: 'T'
        // };

        // /**
        //  * Widget 8
        //  */
        // this.widget8 = {
        //     legend       : false,
        //     explodeSlices: false,
        //     labels       : true,
        //     doughnut     : false,
        //     gradient     : false,
        //     scheme       : {
        //         domain: ['#f44336', '#9c27b0', '#03a9f4', '#e91e63', '#ffc107']
        //     },
        //     onSelect     : (ev) => {
        //         console.log(ev);
        //     }
        // };

        // /**
        //  * Widget 9
        //  */
        // this.widget9 = {
        //     currentRange  : 'TW',
        //     xAxis         : false,
        //     yAxis         : false,
        //     gradient      : false,
        //     legend        : false,
        //     showXAxisLabel: false,
        //     xAxisLabel    : 'Days',
        //     showYAxisLabel: false,
        //     yAxisLabel    : 'Isues',
        //     scheme        : {
        //         domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA']
        //     },
        //     curve         : shape.curveBasis
        // };

        setInterval(() => {
            this.dateNow = Date.now();
        }, 1000);

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._store.dispatch(new GetOnlineUsers());

        this.currentUser$ =  this._store.pipe(select(selectCurrentUser));
        this.generalAnalytics$ = this._store.pipe(select(selectGeneralAnalytics));
        this.casesAnalytics$ = this._store.pipe(select(selectCasesAnalytics));
        this.usersAnalytics$ = this._store.pipe(select(selectUsersAnalytics));
        this.onlineUsers$ =  this._store.pipe(select(selectOnlineUsers));
       // this.projects = this._projectDashboardService.projects;
   //     this.selectedProject = this.projects[0];
      //  this.widgets = this._projectDashboardService.widgets;

        /**
         * Widget 11
         */
        // this.widget11.onContactsChanged = new BehaviorSubject({});
        // this.widget11.onContactsChanged.next(this.widgets.widget11.table.rows);
        // this.widget11.dataSource = new FilesDataSource(this.widget11);


    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void
    {
       // this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

    public casesDateIntervalChanged(selectedIntervals: any): void{

        this._store.dispatch(new GetGeneralAnalytics(selectedIntervals.selectedDateInterval, selectedIntervals.selectedMonth, selectedIntervals.selectedQuarter, selectedIntervals.selectedYear));

        this._store.dispatch(new GetCasesAnalytics(selectedIntervals.selectedDateInterval, selectedIntervals.selectedMonth, selectedIntervals.selectedQuarter, selectedIntervals.selectedYear));
   
        this._store.dispatch(new GetUsersAnalytics(selectedIntervals.selectedDateInterval, selectedIntervals.selectedMonth, selectedIntervals.selectedQuarter, selectedIntervals.selectedYear));
   
    }
}
