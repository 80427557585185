import { Component, Inject, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { MedicalReferals, Language, PlannerRoles } from '@appmodels';
import { ObjectMapper } from 'app/core/helpers';
import { MatOption, MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { Observable, Subject } from 'rxjs';
import { PlannerAppLog } from 'app/core/models/planner/plannerAppLog';
import { LogActionType } from 'app/core/models/planner/enums/logActionTypeEnums';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'appLogs-details',
    templateUrl: './appLogs-details.component.html',
    styleUrls: ['./appLogs-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppLogsGroupDetailsComponent implements OnDestroy{

    public logActionTypeEnum = LogActionType;

    action: string;

    userForm: FormGroup;
    dialogTitle: string;

    user: PlannerUser;
    rolesList: string[];

    medicalReferalsEnum = MedicalReferals;
    languageEnum = Language;

    $appLogs: Observable<PlannerAppLog[]>;

    displayedColumns = ['actionType', 'info', 'additionalInfo', 'creationDate'];

    isHaveData: boolean = false;
    selectedIndex: number = -1;

    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {MatDialogRef<AppLogsGroupDetailsComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<AppLogsGroupDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any

    ) {
        this._unsubscribeAll = new Subject();
        // Set the defaults
        this.action = _data.action;

        if (this.action === 'logsGroupDetails') {
            this.dialogTitle = 'Logs';
            this.user = { ..._data.user };
        }

        this.$appLogs = _data.$appLogs;

        this.$appLogs.pipe(takeUntil(this._unsubscribeAll)).subscribe(x => {
            this.isHaveData = true;
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    public selectRow(index: number): void {
        if (this.selectedIndex == index) {
            this.selectedIndex = -1;
        }
        else {
            this.selectedIndex = index;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */


}
