<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Share case</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fxLayout="column">

        <div fxFlex class="p-12" fxLayoutAlign="center" [fxShow]="caseSharesEntries.length>0"> <span class="mat-headline">Case shares </span>
        </div>

        <form name="caseShareForm" [formGroup]="caseShareForm" novalidate class="case-share-form" fxLayout="column"
            fxFlex>

            <!-- <div fxLayout="column" fxLayoutAlign="center center"> -->

                <!--  -->
                <div class="p-12" formArrayName="caseSharesEntries"
                    *ngFor="let control of caseSharesEntries; index as i">
                    <div [formGroupName]="i">
                        <!-- <div [formGroupName]="i"> -->
                        <div fxLayout="row" fxLayoutGap="10px">


                            <mat-form-field fxLayout="column" appearance="outline" style="width:100%">
                                <mat-label>Share to</mat-label>
                                <input matInput name="userToShare" formControlName="userToShare" type="text">
                            </mat-form-field>


                            <div mat-dialog-actions class="group-buttons-wrapper">
                                <!-- <div class="group-buttons">  -->
                                <button mat-raised-button color="basic" (click)="deleteShareEntry(i)">
                                    Delete
                                </button>
                                <!-- </div> -->
                            </div>




                            <!-- </div> -->
                        </div>
                    </div>
                </div>

            <!-- </div> -->
        </form>


        <div fxFlex class="p-12" fxLayoutAlign="center"> <span class="mat-headline">Select user to share case </span>
        </div>


        <form name="addCaseShareForm" [formGroup]="addCaseShareForm" novalidate class="case-share-form"
            fxLayout="column" fxFlex>

            <!-- <div fxLayout="column" fxLayoutAlign="center center"> -->

                <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field appearance="outline">
                        <mat-label>Select user</mat-label>
                        <input type="text" placeholder="Search user..." aria-label="User email" matInput
                            formControlName="userToShare" #userToShare (input)="onSearchQuery(userToShare.value)"
                            [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let user of foundUsers" [value]="user.email">
                                {{user.email}}
                            </mat-option>
                        </mat-autocomplete>

                        <mat-error *ngIf="addCaseShareForm.get('userToShare').hasError('required')">
                            User is required
                        </mat-error>
                        <mat-error
                            *ngIf="!addCaseShareForm.get('userToShare').hasError('required') && addCaseShareForm.get('userToShare').hasError('wrongUserEmail')">
                            Wrong user email
                        </mat-error>
                    </mat-form-field>

                    <div mat-dialog-actions class="group-buttons-wrapper">
                        <button [disabled]="addCaseShareForm.invalid" mat-raised-button color="accent"
                            (click)="addShareEntry()">
                            Add
                        </button>
                    </div>
                </div>
            <!-- </div> -->
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div>

            <button type="submit" mat-raised-button color="accent" class="save-button"
                [disabled]="caseShareForm.invalid || requestInProgress" aria-label="Create" (click)="onSubmit()">
                Update shares
            </button>



            <span class="p-4 red-500-fg">
                {{errorText}}
            </span>

            <!-- <button mat-icon-button matTooltip="Attach a file">
                <mat-icon>attach_file</mat-icon>
            </button> -->
        </div>

        <!-- (click)="matDialogRef.close(['delete',caseEditForm])"  -->
        <button mat-icon-button aria-label="Delete"
            matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>

        <!-- <div mat-dialog-actions class="group-buttons-wrapper">
         
            <button mat-raised-button color="accent" style="width: 74px" (click)="addShareEntry()">
                Add
            </button>
          
        </div> -->
    </div>
</div>