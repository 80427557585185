<div class="sidebar-content">

    <div class="card">

        <!-- SIDEBAR HEADER -->
        <div class="header p-24" fxLayout="row" fxLayoutAlign="start center">

            <!-- USER -->
            <img src="/assets/images/avatars/profile.jpg" class="avatar mr-16" [alt]="" />
            <span class="h5">Users</span>
            <!-- / USER -->

        </div>
        <!-- / SIDEBAR HEADER -->

        <!-- SIDEBAR CONTENT -->
        <div class="content py-16" fusePerfectScrollbar>

            <div>
                <span class="mat-subheading-2">- Users filter -</span>
                <div class="nav material2">

                    <div class="nav-item" aria-label="inbox">
                        <a class="nav-link" matRipple (click)="changeIsWithAdmins(false)"
                            [ngClass]="{'active accent': !usersFilter.isWithAdmins}">
                            <span class="title">Users only</span>
                        </a>
                    </div>

                    <div class="nav-item" aria-label="starred" (click)="changeIsWithAdmins(true)">
                        <a class="nav-link" matRipple [ngClass]="{'active accent':usersFilter.isWithAdmins}">
                            <div class="title">Users and admins</div>
                        </a>
                    </div>
                </div>
            </div>

            <div>
                <span class="mat-subheading-2">- Medical referals -</span>

                <div class="nav material2">
                    <div class="nav-item" aria-label="inbox">
                        <a class="nav-link" matRipple (click)="changeMedicalReferals(medicalReferalsEnum.Human)"
                            [ngClass]="{'active accent': usersFilter.medicalReferals == medicalReferalsEnum.Human}">
                            <span class="title">Medicine</span>
                        </a>
                    </div>
                    <div class="nav-item" aria-label="starred"
                        (click)="changeMedicalReferals(medicalReferalsEnum.Veterinary)">
                        <a class="nav-link" matRipple
                            [ngClass]="{'active accent':usersFilter.medicalReferals == medicalReferalsEnum.Veterinary}">
                            <div class="title">Veterinary</div>
                        </a>
                    </div>
                </div>
            </div>

            <div [fxShow]="usersFilter.medicalReferals == medicalReferalsEnum.Human">
                <span class="mat-subheading-2">- Speciality -</span>
                <div class="nav material2">
                    <div class="nav-item" aria-label="inbox">
                        <a class="nav-link" matRipple
                            (click)="changeSpeciality(specialityEnum.TraumatologistOrthopedist)"
                            [ngClass]="{'active accent': usersFilter.speciality == specialityEnum.TraumatologistOrthopedist}">
                            <span class="title">Traumatologist orthopedist</span>
                        </a>
                    </div>

                    <div class="nav-item" aria-label="inbox">
                        <a class="nav-link" matRipple (click)="changeSpeciality(specialityEnum.MaxillofacialSurgeon)"
                            [ngClass]="{'active accent': usersFilter.speciality == specialityEnum.MaxillofacialSurgeon}">
                            <span class="title">Maxillofacial surgeon</span>
                        </a>
                    </div>

                    <div class="nav-item" aria-label="inbox">
                        <a class="nav-link" matRipple (click)="changeSpeciality(specialityEnum.Neurosurgeon)"
                            [ngClass]="{'active accent': usersFilter.speciality == specialityEnum.Neurosurgeon}">
                            <span class="title">Neurosurgeon</span>
                        </a>
                    </div>

                    <div class="nav-item" aria-label="inbox">
                        <a class="nav-link" matRipple (click)="changeSpeciality(specialityEnum.Other)"
                            [ngClass]="{'active accent': usersFilter.speciality == specialityEnum.Other}">
                            <span class="title">Other</span>
                        </a>
                    </div>
                </div>
            </div>

            <div>
                <span class="mat-subheading-2">- Language -</span>
                <div class="nav material2">
                    <div class="nav-item" aria-label="inbox">
                        <a class="nav-link" matRipple (click)="changeLanguage(languageEnum.ruRU)"
                            [ngClass]="{'active accent': usersFilter.language == languageEnum.ruRU}">
                            <span class="title">Rus</span>
                        </a>
                    </div>
                    <div class="nav-item" aria-label="inbox">
                        <a class="nav-link" matRipple (click)="changeLanguage(languageEnum.enUS)"
                            [ngClass]="{'active accent': usersFilter.language == languageEnum.enUS}">
                            <span class="title">Eng</span>
                        </a>
                    </div>
                </div>
            </div>
            <div>
                <div class="export-button">
                    <button mat-raised-button (click)="exportUsersList()" aria-label="Export users list">
                        EXPORT
                    </button>
                </div>
            </div>

        </div>

    </div>
    <!-- / SIDEBAR CONTENT -->