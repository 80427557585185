import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { GetCaseBundle, GetSelectedCaseScenes, GetSelectedCaseShares, SelectCase, SelectScene, SetCasesFolder, SetCasesSearchPattern } from '../store/actions/cases.actions';
import { selectCasesList, selectSelectedCase } from '../store/selectors/cases.selector';
import { take, filter, tap } from 'rxjs/operators';
import { CaseFolderType } from '../models/planner/enums/plannerCaseEnums';
import { ResetUsersFilter } from '../store/actions/users.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "ngx-logger";
export class CasesResolver {
    constructor(_store, logger) {
        this._store = _store;
        this.logger = logger;
    }
    resolve(route, state) {
        const routeParams = route.params;
        this.logger.info('Cases route resolver: ', route.params);
        const obsCollection = [];
        obsCollection.push(this._store.select(selectCasesList).pipe(filter(cases => cases != null), take(1)));
        let folder = CaseFolderType[routeParams.folderHandle];
        obsCollection.push(of(this._store.dispatch(new SetCasesFolder(folder))));
        //this.logger.warn(routeParams);
        if (routeParams.caseId) {
            obsCollection.push(of(this._store.dispatch(new SetCasesSearchPattern(routeParams.caseId))));
            obsCollection.push(this._store.select(selectSelectedCase).pipe(filter(selectedCase => selectedCase != null), take(1), tap(x => {
                this._store.dispatch(new GetSelectedCaseScenes(routeParams.caseId));
                this._store.dispatch(new GetSelectedCaseShares(routeParams.caseId));
                this._store.dispatch(new GetCaseBundle(routeParams.caseId));
                this._store.dispatch(new SelectScene({ caseId: routeParams.caseId, sceneId: null }));
            })));
            obsCollection.push(of(this._store.dispatch(new SelectCase(routeParams.caseId))));
        }
        else {
        }
        this._store.dispatch(new ResetUsersFilter());
        return forkJoin(obsCollection);
    }
}
CasesResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CasesResolver_Factory() { return new CasesResolver(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.NGXLogger)); }, token: CasesResolver, providedIn: "root" });
