import { Injectable } from "@angular/core";
import { Effect, Actions, ofType, OnInitEffects } from "@ngrx/effects"
import { AuthenticationService, CasesService } from '@appservices';
import { IAppState } from '../state/app.state';
import { Action, Store, select } from '@ngrx/store';
import * as FileSaver from 'file-saver';
import {
    GetCases, ECasesActions, GetCasesSuccess, SelectCase, SelectCaseSuccess, GetSelectedCaseScenes, GetSelectedCaseScenesSuccess, SelectSceneSuccess, SelectScene, CreateCase, CreateCaseSuccess,
    SaveScene, SaveSceneSuccess, CloneCase, CloneCaseSuccess, DeleteCaseSuccess, DeselectCase, DeselectCaseSuccess, SetCasesFolder, SetCasesFolderSuccess, RollbackScene, RollbackSceneSuccess,
    SetCasesSearchPattern, SetCasesSearchPatternSuccess, EditCase, EditCaseSuccess, AddSceneObjects, SaveSceneVisibilityGroups, SaveSceneVisibilityGroupsSuccess, CaseMessagesReaded, CaseMessagesReadedSuccess,
    DeleteCase, GetSelectedCaseSharesSuccess, GetSelectedCaseShares, UpdateCaseShares, UpdateCaseSharesSuccess, CaseSceneSavedFromServer, GetCase, GetCaseSuccess, SetModulesType, SetModulesTypeSuccess, CloneCaseBatch, CloneCaseBatchSuccess, SetCasesFilterParams, SetCasesFilterParamsSuccess, SetCaseStatus, SetCaseStatusSuccess, SetQCFilterSuccess,
    GetCaseBundlePdf as GetCaseBundlePdf,
    SaveCaseBundle,
    GetCaseBundleSuccess,
    GetCaseBundle,
    SaveCaseBundleSuccess,

} from '../actions/cases.actions';
import { switchMap, map, withLatestFrom, tap, take, switchMapTo, skipUntil, filter, mergeMap, catchError, repeat, delay } from 'rxjs/operators';
import { of, Observable, timer, combineLatest, forkJoin, throwError } from 'rxjs';
import { PlannerCase, PlannerScene, NotificationArgs, SavePlannerSceneCommand, CaseSortType, CaseSceneSavedNotification, SetPlannerCaseStatusCommand } from '@appmodels';
import { selectCasesList, selectCasesRequestArgs, selectSelectedCase, selectSelectedScene } from '../selectors/cases.selector';
import { NewNotification } from '../actions/notifications.actions';
import { NGXLogger } from 'ngx-logger';
import { SavePlannerCaseSceneCommandModel } from 'app/core/models/planner/responses/savePlannerCaseSceneCommandModel';
import { VoidAction } from "../actions/app.actions";
import { SettingsService } from "app/core/services/settings.service";
import { LongRunningTasksService } from "app/core/services/planner/longRunningTasks.service";
import { selectQualitySurveyCasesList, selectQualitySurveyCasesRequestArgs } from "../selectors/qualitySurveyCases.selector";
import { SetQualitySurveysCasesFilterParams, SetQualitySurveysCasesFilterParamsSuccess, GetQualitySurveysCases, GetQualitySurveysCasesSuccess } from "../actions/qualitySurveyCases.actions";
import { DocumentsService } from "app/core/services/planner/documents.service";

@Injectable()
export class CasesEffects implements OnInitEffects {

    ngrxOnInitEffects(): Action {
        const modulesFilters = this._settingsService.getModulesFilters();
        return new SetModulesTypeSuccess(modulesFilters);
    }


    @Effect()
    setModulesType$ = this._actions$.pipe(
        ofType<SetModulesType>(ECasesActions.SetModulesType),
        map(action => action.moduleType),
        withLatestFrom(this._store.select(selectCasesRequestArgs)),
        map(([moduleType, casesRequestArgs]) => {

            const modulesFilter = [...casesRequestArgs.modulesFilter];
            const moduleTypeIndex = modulesFilter.indexOf(moduleType)
            if (moduleTypeIndex < 0) {
                modulesFilter.push(moduleType)
            }
            else {
                modulesFilter.splice(moduleTypeIndex, 1);
            }

            this._settingsService.setModulesFilters(modulesFilter);

            return modulesFilter;
        }),
        switchMap((modulesFilter) => {
            return [
                new SetModulesTypeSuccess(modulesFilter),
                new GetCases(),
            ];
        })
    );

    @Effect()
    setQCFilter$ = this._actions$.pipe(
        ofType<SetModulesType>(ECasesActions.SetQCFilter),
        withLatestFrom(this._store.select(selectCasesRequestArgs)),
        map(([_, casesRequestArgs]) => {

            return !casesRequestArgs.forQualityControl;
        }),
        switchMap((isForQualityControl) => {
            return [
                new SetQCFilterSuccess(isForQualityControl),
                new GetCases(),
            ];
        })
    );

    @Effect()
    setFolder$ = this._actions$.pipe(
        ofType<SetCasesFolder>(ECasesActions.SetCasesFolder),
        map((action) => {

            return { folderType: action.folderType, sortType: (action.sortType ? action.sortType : CaseSortType.CreationDate) };

        }),

        switchMap((sortSettings) => {
            return [
                new SetCasesFolderSuccess(sortSettings.folderType, sortSettings.sortType),
                new GetCases(),
            ];
        })
    );

    @Effect()
    setSearchPattern$ = this._actions$.pipe(
        ofType<SetCasesSearchPattern>(ECasesActions.SetCasesSearchPattern),
        map(action => action.payload),

        switchMap((searchPattern) => {
            return [
                new SetCasesSearchPatternSuccess(searchPattern),
                new GetCases(),
            ];
        })
    );

    @Effect()
    setCasesFilterParams$ = this._actions$.pipe(
        ofType<SetCasesFilterParams>(ECasesActions.SetCasesFilterParams),
        switchMap((searchParams) => {
            return [
                new SetCasesFilterParamsSuccess(searchParams.folder, searchParams.modulesFilter, searchParams.notAdmins, searchParams.onlyUncompleted, searchParams.onlyWithGuideOrImplant, searchParams.searchPattern),
                new GetCases(),
            ];
        })
    );

    @Effect()
    getCases$ = this._actions$.pipe(
        ofType<GetCases>(ECasesActions.GetCases),
        map(action => action.isReload),
        //map
        switchMap((isReload) => {

            this._logger.info(`GetCases, is reload: ${isReload}`);

            if (isReload) {
                return of([]).pipe(
                    map(() => {
                        return { existedCasesCount: 0, isReload: isReload }
                    })
                );
            }
            else {
                return this._store.pipe(
                    select(selectCasesList),
                    take(1),
                    map(cases => {
                        return { existedCasesCount: cases ? cases.length : 0, isReload: isReload }
                    })
                )
            }
        }),
        withLatestFrom(this._store.select(selectCasesRequestArgs)),
        switchMap(([existedCases, casesRequestArgs]) => {

            const skip = existedCases.isReload ? 0 : existedCases.existedCasesCount; //? existedCases.length : 0;
            const take = 20;
            return this._casesService.getCases(casesRequestArgs, skip, take).pipe(
                map(cases => { return { cases: cases, isReload: existedCases.isReload } })
            );
        }),
        switchMap((casesResult) => of(new GetCasesSuccess(casesResult.cases, casesResult.isReload)))
    );

    @Effect()
    getCase$ = this._actions$.pipe(
        ofType<GetCase>(ECasesActions.GetCase),
        map(action => action.payload),
        //map
        switchMap((caseId) => {
            return this._casesService.getCase(caseId);
        }),
        mergeMap(plannerCase => {
            return [new GetCaseSuccess(plannerCase)]
        })
    );

    @Effect()
    selectCase$ = this._actions$.pipe(
        ofType<SelectCase>(ECasesActions.SelectCase),
        map(action => action.payload),
        switchMap(caseId =>
            forkJoin(
                this._casesService.getCase(caseId),
                this._casesService.getCaseRelatedData(caseId),
                this._store.select(selectCasesList).pipe(
                    filter(cases => {
                        if (!cases)
                            return false;
                        if (!cases.find(x => x.id == caseId))
                            return false;
                        return true;
                    }),
                    take(1)
                )
            ).pipe(
                map(([response1, response2, cases]) => {

                    //var existedCaseInList = cases.filter(x => x.id == caseId)[0]

                    const selectedCase = response1;
                    const caseRelatedDataContainer = response2;
                    selectedCase.details = caseRelatedDataContainer.caseRelatedData;
                    return selectedCase;
                })
            )
        ),
        switchMap((selectedCase: PlannerCase) => of(new SelectCaseSuccess(selectedCase)))
    );

    @Effect()
    deselectCase$ = this._actions$.pipe(
        ofType<DeselectCase>(ECasesActions.DeselectCase),
        mergeMap((result) => {
            return [
                new DeselectCaseSuccess()
            ];
        })
    );


    @Effect()
    createCase$ = this._actions$.pipe(
        ofType<CreateCase>(ECasesActions.CreateCase),
        map(action => action.payload),
        switchMap((caseCreateCommand) => this._casesService.createCase(caseCreateCommand)),
        mergeMap((caseId) => {
            return [
                new CreateCaseSuccess(caseId),
                new GetCases(),
                new SelectCase(caseId),
                new GetSelectedCaseScenes(caseId),
                new SelectScene({ caseId: caseId, sceneId: null }),
                new NewNotification({ message: 'Case created!', type: 'CaseCreated', action: 'Ok' })
            ];
        })
    );

    @Effect()
    editCase$ = this._actions$.pipe(
        ofType<EditCase>(ECasesActions.EditCase),
        map(action => action.payload),
        switchMap((caseUpdateCommand) => this._casesService.editCase(caseUpdateCommand)),
        mergeMap((caseId) => {
            return [
                new EditCaseSuccess(caseId),
                new GetCases(),
                new SelectCase(caseId),
                new GetSelectedCaseScenes(caseId),
                new SelectScene({ caseId: caseId, sceneId: null }),
                new NewNotification({ type: 'CaseEdited', message: 'Case edited!', action: 'Ok' })
            ];
        })
    );

    @Effect()
    cloneCase$ = this._actions$.pipe(
        ofType<CloneCase>(ECasesActions.CloneCase),
        map(action => action.payload),
        switchMap((cloneCaseCommand) => this._casesService.cloneCase(cloneCaseCommand)),
        mergeMap((result) => {
            return [
                new CloneCaseSuccess(),
                new GetCases(true),
                new NewNotification({ type: 'CaseClonned', message: 'Case clonned!', action: 'Ok' })
            ];
        }),
        catchError(err => {
            new NewNotification({ type: 'CaseClonned', message: 'Case clone error!', action: 'Error' })
            return throwError(err);
        })
    );



    @Effect()
    cloneCaseBatch$ = this._actions$.pipe(
        ofType<CloneCaseBatch>(ECasesActions.CloneCaseBatch),
        map(action => action.payload),
        switchMap((cloneCaseBatchCommand) => this._casesService.cloneCaseBatch(cloneCaseBatchCommand)),
        mergeMap((longRunnigTaskId) => {

            return this._longRunningTasksService.getLongRunningTaskInfo(longRunnigTaskId).pipe(
                delay(200),
                repeat(),
                tap(info => this._store.dispatch(new CloneCaseBatchSuccess(info))),
                filter(response => response && response.isCompleted),
                take(1)
            )
        }),
        mergeMap((result) => {
            return [
                new CloneCaseBatchSuccess(result),
                new NewNotification({ type: 'CaseClonned', message: 'Case clonned!', action: 'Ok' })
            ];
        }),
        catchError(err => {
            new NewNotification({ type: 'CaseClonned', message: 'Case clone batch error!', action: 'Error' })
            return throwError(err);
        })
    );

    @Effect()
    getSelectedCaseShares$ = this._actions$.pipe(
        ofType<GetSelectedCaseShares>(ECasesActions.GetSelectedCaseShares),
        map(action => action.payload),
        switchMap((caseId) => this._casesService.getCaseShares(caseId)),
        mergeMap((result) => {
            return [
                new GetSelectedCaseSharesSuccess(result)
            ];
        })
    );

    @Effect()
    updateCaseShares$ = this._actions$.pipe(
        ofType<UpdateCaseShares>(ECasesActions.UpdateCaseShares),
        map(action => action.payload),
        switchMap((updateCaseSharesCommand) => this._casesService.updateCaseShares(updateCaseSharesCommand).pipe(switchMap(res => of(updateCaseSharesCommand.caseId)))),
        mergeMap((updatesSharesCaseId) => {



            return [
                new UpdateCaseSharesSuccess(),
                new GetSelectedCaseShares(updatesSharesCaseId),
                //  new GetCases(true),
                new NewNotification({ type: 'CaseShared', message: 'Case shared!', action: 'Ok' })
            ];
        }),
        catchError(err => {
            new NewNotification({ type: 'CaseShared', message: 'Case share error!', action: 'Error' })
            return throwError(err);
        })
    );

    @Effect()
    deleteCase$ = this._actions$.pipe(
        ofType<DeleteCase>(ECasesActions.DeleteCase),
        map(action => action.payload),
        switchMap((deleteCaseCommand) => this._casesService.deleteCase(deleteCaseCommand)),
        mergeMap((result) => {
            return [
                new DeleteCaseSuccess(),
                new GetCases(true),
                new DeselectCase(),
                new NewNotification({ type: 'CaseDeleted', message: 'Case deleted!', action: 'Ok' })
            ];
        }),
        catchError(err => {
            const errNotification = new NewNotification({ type: 'CaseDeleted', message: 'Case delete error!', action: 'Error' })
            this._store.dispatch(errNotification);
            return throwError(err);
        })
    );

    @Effect()
    getSelectedCaseScenes$ = this._actions$.pipe(
        ofType<GetSelectedCaseScenes>(ECasesActions.GetSelectedCaseScenes),
        map(action => action.payload),
        switchMap(caseId =>
            forkJoin(
                this._casesService.getCaseScenes(caseId),
                this._store.select(selectCasesList).pipe(
                    filter(cases => {
                        if (!cases)
                            return false;
                        if (!cases.find(x => x.id == caseId))
                            return false;
                        return true;
                    }),
                    take(1)
                )).pipe(
                    map(([response, cases]) => {
                        return response;
                    }))),
        switchMap((plannerCaseScenes: PlannerScene[]) => of(new GetSelectedCaseScenesSuccess(plannerCaseScenes)))
    );

    @Effect()
    selectSceneSelectedCase$ = this._actions$.pipe(
        ofType<SelectScene>(ECasesActions.SelectScene),
        map(action => action.payload),
        switchMap(args =>
            forkJoin(
                this._casesService.getCaseScene(args.caseId, args.sceneId),
                this._store.select(selectCasesList).pipe(
                    filter(cases => {
                        if (!cases)
                            return false;
                        if (!cases.find(x => x.id == args.caseId))
                            return false;
                        return true;
                    }),
                    take(1)
                )).pipe(
                    map(([response, cases]) => {
                        return response;
                    }))),
        switchMap((plannerCaseScene: PlannerScene) => of(new SelectSceneSuccess(plannerCaseScene)))
    );

    @Effect()
    addSceneObjects$ = this._actions$.pipe(
        ofType<AddSceneObjects>(ECasesActions.AddSceneObjects),
        map(action => action.payload),
        withLatestFrom(this._store.select(selectSelectedScene), this._store.select(selectSelectedCase)),

        switchMap(([sceneObjects, selectedScene, selectedCase]) => {

            sceneObjects.forEach(sceneObject => {
                selectedScene.sceneObjects.push(sceneObject);
            })

            const saveSceneCommand = new SavePlannerSceneCommand()
            saveSceneCommand.caseId = selectedCase.id;
            saveSceneCommand.scene = selectedScene;

            return [
                //new AddSceneObjectsSuccess(),
                new SaveScene(saveSceneCommand)
            ];
        })
    );

    @Effect()
    saveScene$ = this._actions$.pipe(
        ofType<SaveScene>(ECasesActions.SaveScene),
        map(action => action.payload),
        switchMap((saveSceneCommand) => this._casesService.saveScene(saveSceneCommand).pipe(
            map(saveResult => [saveSceneCommand.caseId, saveResult])
        )),
        mergeMap(([caseId, saveResult]) => {
            const saveResultModel = saveResult as SavePlannerCaseSceneCommandModel;
            return [
                new SaveSceneSuccess(saveResultModel),
                new SelectCase(caseId as string),
                new GetSelectedCaseScenes(caseId as string),
                new SelectScene({ caseId: caseId as string, sceneId: saveResultModel.sceneNewId }),
                new NewNotification({ type: 'SceneSaved', message: 'Scene saved!', action: 'Ok' })
            ];
        })
    );

    @Effect()
    rollbackScene$ = this._actions$.pipe(
        ofType<RollbackScene>(ECasesActions.RollbackScene),
        map(action => action.payload),
        switchMap((rollbackSceneCommand) => this._casesService.rollbackScene(rollbackSceneCommand).pipe(
            map(sceneId => [rollbackSceneCommand.caseId, sceneId])
        )),
        mergeMap(([caseId, sceneId]) => {
            return [
                new RollbackSceneSuccess(sceneId),
                new GetSelectedCaseScenes(caseId),
                new SelectScene({ caseId: caseId, sceneId: sceneId }),
                new NewNotification({ type: 'SceneRollbacked', message: 'Scene rollbacked!', action: 'Ok' })
            ];
        })
    );

    @Effect()
    saveSceneVisibilityGroups$ = this._actions$.pipe(
        ofType<SaveSceneVisibilityGroups>(ECasesActions.SaveSceneVisibilityGroups),
        map(action => action.payload),
        switchMap((saveSceneVisibilityGroupsCommand) => this._casesService.saveSceneVisibilityGroups(saveSceneVisibilityGroupsCommand).pipe(
            map(result => [saveSceneVisibilityGroupsCommand.caseId, saveSceneVisibilityGroupsCommand.sceneId])
        )),
        mergeMap(([caseId, sceneId]) => {
            return [
                new SaveSceneVisibilityGroupsSuccess(true),
                new GetSelectedCaseScenes(caseId),
                new SelectScene({ caseId: caseId, sceneId: sceneId }),
                new NewNotification({ type: 'SceneVisibilityGroupsSaved', message: 'Scene visibility groups saved!', action: 'Ok' })
            ];
        }),
        catchError(err => {
            const errNotification = new NewNotification({ type: 'SceneVisibilityGroupsSaved', message: 'Scene visibility groups save error!', action: 'Error' })
            this._store.dispatch(errNotification);
            return throwError(err);
        })
    );


    @Effect()
    setCaseStatus$ = this._actions$.pipe(
        ofType<SetCaseStatus>(ECasesActions.SetCaseStatus),
        switchMap((saveSceneArgs) => {

            const setPlannerCaseStatusCommand = new SetPlannerCaseStatusCommand();
            setPlannerCaseStatusCommand.caseId = saveSceneArgs.caseId;
            setPlannerCaseStatusCommand.status = saveSceneArgs.status;

            return this._casesService.setPlannerCaseStatus(setPlannerCaseStatusCommand).pipe(
                map(saveResult => saveSceneArgs));
        }),
        mergeMap((saveSceneArgs) => {

            return [
                new SetCaseStatusSuccess(saveSceneArgs.caseId, saveSceneArgs.status, saveSceneArgs.isRemoveCaseFromList),
                new NewNotification({ type: 'CaseStatusSet', message: 'Case status set!', action: 'Ok' })
            ];
        })
    );

    @Effect()
    getCaseBundle$ = this._actions$.pipe(
        ofType<GetCaseBundle>(ECasesActions.GetCaseBundle),
        map(action => action.payload),
        switchMap((caseId) => {
            return this._casesService.getPlannerCaseBundle(caseId);
        }),
        mergeMap((result) => {
            return [
                new GetCaseBundleSuccess(result)
            ];
        })
    );


    @Effect()
    saveCaseBundle$ = this._actions$.pipe(
        ofType<SaveCaseBundle>(ECasesActions.SaveCaseBundle),
        map(action => action.payload),
        switchMap(saveCaseBundleCommand => {

            return this._casesService.saveCaseBundle(saveCaseBundleCommand).pipe(
                map(result => {
                    return saveCaseBundleCommand.bundle
                }));
        }),
        mergeMap((bundle) => {

            return [
                new GetCaseBundlePdf(bundle),
                new SaveCaseBundleSuccess(bundle),
                // new SetCaseStatusSuccess(saveSceneArgs.caseId, saveSceneArgs.status, saveSceneArgs.isRemoveCaseFromList),
                new NewNotification({ type: 'BundleSaved', message: 'Bundle saved!', action: 'Ok' })
            ];
        })
    );

    @Effect()
    getCaseBundlePdf$ = this._actions$.pipe(
        ofType<GetCaseBundlePdf>(ECasesActions.GetCaseBundlePdf),
        map(action => action.payload),
        switchMap(bundle => {

            const bundleObj: any = {}
            bundleObj.bundle = JSON.parse(bundle);

            console.log(bundleObj)

            return this._documentsService.getBundlePdf(bundleObj).pipe(
                map(result => {

                }));
        }),
        mergeMap((_) => {

            return [
                new NewNotification({ type: 'BundlePdf', message: 'Bundle pdf ready!', action: 'Ok' })
            ];
        })
    );


    @Effect()
    caseMessagesReaded$ = this._actions$.pipe(
        ofType<CaseMessagesReaded>(ECasesActions.CaseMessagesReaded),
        map(action => action),
        withLatestFrom(this._store.select(selectSelectedCase)),
        map(([x, selectedCase]) => {
            return selectedCase.id;
        }),
        mergeMap(selectedCaseId => {
            return [
                new CaseMessagesReadedSuccess(selectedCaseId),
            ];
        })
    );

    @Effect()
    caseSceneSavedFromServer$ = this._actions$.pipe(
        ofType<CaseSceneSavedFromServer>(ECasesActions.CaseSceneSavedFromServer),
        map(action => action.payload),
        withLatestFrom(this._store.select(selectSelectedCase)),
        mergeMap(([caseSceneSavedNotification, selectedCase]) => {


            let isFromAnotherConnectionApp = this._authenticationService.appConnectionUid != caseSceneSavedNotification.senderAppConnectionUid;

            const res = [];

            res.push(new VoidAction());

            if (isFromAnotherConnectionApp) {
                res.push(new NewNotification({ type: 'CaseSceneSaved', message: `Scene saved! ${caseSceneSavedNotification.info.userInitiator}: ${caseSceneSavedNotification.info.caseTitle}`, action: 'Ok' }));

                if (selectedCase && selectedCase.id == caseSceneSavedNotification.caseId) {

                    const caseId = caseSceneSavedNotification.caseId;

                    res.push(new SelectCase(caseId));
                    res.push(new GetSelectedCaseScenes(caseId));
                    res.push(new SelectScene({ caseId: caseId, sceneId: null }));
                }
            }


            return res;
        }),
        // mergeMap(selectedCaseId => {
        //     return [
        //         new CaseSceneSavedFromServerSuccess(selectedCaseId),
        //     ];
        // })
    );


    constructor(
        private _settingsService: SettingsService,
        private _casesService: CasesService,
        private _documentsService: DocumentsService,
        private _longRunningTasksService: LongRunningTasksService,
        private _authenticationService: AuthenticationService,
        private _actions$: Actions,
        private _store: Store<IAppState>,
        private _logger: NGXLogger
    ) { }

}
