import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';

import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { PlannerSceneElement } from './plannerScene-element';
import { CaseStatus, PlannerScene } from 'app/core/models';


@Component({
  selector: 'scenes-timeline',
  templateUrl: './scenes-timeline.component.html',
  styleUrls: ['./scenes-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('contentState', [
      state('active', style({
        position: 'relative', 'z-index': 2, opacity: 1,
      })),
      transition('right => active', [
        style({
          transform: 'translateX(100%)'
        }),
        animate('400ms ease-in-out', keyframes([
          style({ opacity: 0, transform: 'translateX(100%)', offset: 0 }),
          style({ opacity: 1, transform: 'translateX(0%)', offset: 1.0 })
        ]))
      ]),
      transition('active => right', [
        style({
          transform: 'translateX(-100%)'
        }),
        animate('400ms ease-in-out', keyframes([
          style({ opacity: 1, transform: 'translateX(0%)', offset: 0 }),
          style({ opacity: 0, transform: 'translateX(100%)', offset: 1.0 })
        ]))
      ]),
      transition('active => left', [
        style({
          transform: 'translateX(-100%)'
        }),
        animate('400ms ease-in-out', keyframes([
          style({ opacity: 1, transform: 'translateX(0%)', offset: 0 }),
          style({ opacity: 0, transform: 'translateX(-100%)', offset: 1.0 })
        ]))
      ]),
      transition('left => active', [
        style({
          transform: 'translateX(100%)'
        }),
        animate('400ms ease-in-out', keyframes([
          style({ opacity: 0, transform: 'translateX(-100%)', offset: 0 }),
          style({ opacity: 1, transform: 'translateX(0%)', offset: 1.0 })
        ]))
      ]),
    ])
  ]
})


export class ScenesTimeLineComponent implements AfterViewInit {

  prevLinkInactive: boolean = false;
  nextLinkInactive: boolean = true;
  loaded: boolean = false;
  selectedIndex: number = 0;
  @ViewChild('timeline', { static: true }) timeline: ElementRef;
  @ViewChild('eventsWrapper', { static: true }) eventsWrapper: ElementRef;
  @ViewChild('fillingLine', { static: true }) fillingLine: ElementRef;
  @ViewChildren('timelineEvents') timelineEvents: QueryList<ElementRef>;
  eventsWrapperWidth: number = 0;
  private _viewInitialized = false;

  constructor(private _cdr: ChangeDetectorRef) {

  }

  private _timelineWrapperWidth: number;
  private _eventsMinDistance: number = 80;
  private _plannerSceneElements: PlannerSceneElement[];
  private _disabled: boolean = false;
  private _showContent: boolean = false;

  get plannerSceneElements(): PlannerSceneElement[] {
    return this._plannerSceneElements;
  }

  caseStatusEnum: CaseStatus;

  @Input()
  set plannerSceneElements(value: PlannerSceneElement[]) {
    if (!value)
      return;

    this._plannerSceneElements = value.sort((a: PlannerSceneElement, b: PlannerSceneElement) => { return a.creationDate.getTime() - b.creationDate.getTime() });

    this.initView();
  }

  @Output()
  sceneSelected: EventEmitter<string> = new EventEmitter<string>();

  get showContent(): boolean {
    return this._showContent;
  }

  ngAfterViewInit(): void {
    this._cdr.detach();
    this._viewInitialized = true;
    this.initView();
  }

  onScrollClick(event: Event, forward: boolean) {
    event.preventDefault();
    this.updateSlide(this.eventsWrapperWidth, forward);
    this._cdr.detectChanges();
  }

  onSceneClick(event: Event, selectedItem: PlannerSceneElement) {
    event.preventDefault();
    if (this._disabled) {
      return;
    }
    let element = event.target;
    // detect click on the a single event - show new event content
    let visibleItem = this._plannerSceneElements[0];
    this._plannerSceneElements.forEach(function (item: PlannerSceneElement) {
      if (item.selected && item != selectedItem) {
        visibleItem = item;
        item.selected = false;
      }
    });
    this.selectedIndex = this._plannerSceneElements.indexOf(selectedItem);
    selectedItem.selected = true;
    this.updateFilling(element);
    this._cdr.detectChanges();

    this.sceneSelected.emit(selectedItem.id);
  }

  getSceneStatusText(caseStatus: CaseStatus): string {
    return CaseStatus[caseStatus];
  }

  private initView(): void {

    if (!this._viewInitialized) {
      return;
    }

    this.translateTimeline(0, null);

    if (this._plannerSceneElements && this._plannerSceneElements.length) {
      this.selectedIndex = this._plannerSceneElements.length - 1;
      this._plannerSceneElements[this.selectedIndex].selected = true;
      this._cdr.detectChanges();
      this._timelineWrapperWidth = this.timeline.nativeElement.offsetWidth * 0.85;
      this.initTimeline(this._plannerSceneElements);
    }

    this._cdr.detectChanges();
  }

  private initTimeline(timeLines: PlannerSceneElement[]) {
    let eventsMinLapse = ScenesTimeLineComponent.minLapse(timeLines);
    // assign a left position to the single events along the timeline
    let timelineTotalDistance = this.setDatePosition(timeLines, this._eventsMinDistance, eventsMinLapse);
    // assign a width to the timeline
    this.setTimelineWidth(timeLines, this._eventsMinDistance, eventsMinLapse, timelineTotalDistance);
    // the timeline has been initialize - show it
    this.loaded = true;
  }

  private updateSlide(timelineTotWidth: number, forward: boolean) {
    let translateValue = ScenesTimeLineComponent.getTranslateValue(this.eventsWrapper.nativeElement);

    if (forward) {
      this.translateTimeline(translateValue - this._timelineWrapperWidth + this._eventsMinDistance, this._timelineWrapperWidth - timelineTotWidth);
    } else {
      this.translateTimeline(translateValue + this._timelineWrapperWidth - this._eventsMinDistance, null);
    }
  }

  private updateTimelinePosition(element: Element) {

    let eventStyle = window.getComputedStyle(element);

    let eventLeft = ScenesTimeLineComponent.pxToNumber(eventStyle.getPropertyValue('left'));
    let translateValue = ScenesTimeLineComponent.getTranslateValue(this.eventsWrapper.nativeElement);
    if (eventLeft > this._timelineWrapperWidth - translateValue) {
      this.translateTimeline(-eventLeft + this._timelineWrapperWidth, this._timelineWrapperWidth - this.eventsWrapperWidth);
    }

  }

  private translateTimeline(value: number, totWidth: number | null) {
    // only negative translate value
    value = (value > 0) ? 0 : value;
    // do not translate more than timeline width
    value = (!(totWidth === null) && value < totWidth) ? totWidth : value;
    ScenesTimeLineComponent.setTransformValue(this.eventsWrapper.nativeElement, 'translateX', value + 'px');
    // update navigation arrows visibility
    this.prevLinkInactive = value === 0;
    this.nextLinkInactive = value === totWidth;
  }

  private setTimelineWidth(elements: PlannerSceneElement[], width: number, eventsMinLapse: number, timelineTotalDistance: number) {
    this.eventsWrapperWidth = Math.max(timelineTotalDistance * width, 1);
    let aHref = this.eventsWrapper.nativeElement.querySelectorAll('a.selected')[0];
    this.updateFilling(aHref);
    this.updateTimelinePosition(aHref);
    return this.eventsWrapperWidth;
  }

  private updateFilling(element: any) {
    let eventStyle = window.getComputedStyle(element);
    let eventLeft = eventStyle.getPropertyValue('left');
    let eventWidth = eventStyle.getPropertyValue('width');
    let eventLeftNum = ScenesTimeLineComponent.pxToNumber(eventLeft) + ScenesTimeLineComponent.pxToNumber(eventWidth) / 2;

    let scaleValue = Math.max(eventLeftNum / this.eventsWrapperWidth, 70);

    ScenesTimeLineComponent.setTransformValue(this.fillingLine.nativeElement, 'scaleX', scaleValue);
  }

  private setDatePosition(elements: PlannerSceneElement[], min: number, eventsMinLapse: number): number {

    let timelineEventsArray = this.timelineEvents.toArray();

    let offsetDistance: number = 0;

    for (let i = 0; i < elements.length; i++) {

      let firstElementIndex = Math.max(i - 1, 0);

      let distanceNorm = ScenesTimeLineComponent.GetShrinkedNormalizedDistance(elements, firstElementIndex, i, eventsMinLapse);

      let distanceNormWithOffset = distanceNorm + offsetDistance;

      offsetDistance += distanceNorm;

      timelineEventsArray[i].nativeElement.style.left = distanceNormWithOffset * min + 'px';
      // span
      let span: HTMLSpanElement = <HTMLSpanElement>timelineEventsArray[i].nativeElement.parentElement.children[1];
      let spanWidth = ScenesTimeLineComponent.getElementWidth(span);

      span.style.left = distanceNormWithOffset * min + spanWidth / 2 + 'px';

    }

    return offsetDistance;
  }

  private static GetShrinkedNormalizedDistance(elements: PlannerSceneElement[], firstElementIndex: number, i: number, eventsMinLapse: number) {
    let distance = ScenesTimeLineComponent.dayDiff(elements[firstElementIndex].creationDate, elements[i].creationDate);



    let shrinkedDistance = Math.round(Math.sqrt(distance) / 5) + eventsMinLapse;
    let distanceNorm = eventsMinLapse > 0 ? Math.round((shrinkedDistance) / eventsMinLapse) : 0;


    return distanceNorm;
  }

  private static pxToNumber(val: string): number {
    return Number(val.replace('px', ''));
  }

  private static getElementWidth(element: Element): number {

    const computedStyle = window.getComputedStyle(element);
    if (!computedStyle.width) {
      return 0;
    }
    return ScenesTimeLineComponent.pxToNumber(computedStyle.width);
  }

  private static getTranslateValue(timeline: Element) {
    let timelineStyle = window.getComputedStyle(timeline);
    let timelineTranslate = timelineStyle.getPropertyValue('-webkit-transform') ||
      timelineStyle.getPropertyValue('-moz-transform') ||
      timelineStyle.getPropertyValue('-ms-transform') ||
      timelineStyle.getPropertyValue('-o-transform') ||
      timelineStyle.getPropertyValue('transform');

    let translateValue = 0;
    if (timelineTranslate.indexOf('(') >= 0) {
      let timelineTranslateStr = timelineTranslate
        .split('(')[1]
        .split(')')[0]
        .split(',')[4];
      translateValue = Number(timelineTranslateStr);
    }

    return translateValue;
  }

  private static setTransformValue(element: any, property: any, value: any) {
    element.style['-webkit-transform'] = property + '(' + value + ')';
    element.style['-moz-transform'] = property + '(' + value + ')';
    element.style['-ms-transform'] = property + '(' + value + ')';
    element.style['-o-transform'] = property + '(' + value + ')';
    element.style['transform'] = property + '(' + value + ')';
  }

  private static dayDiff(first: Date, second: Date): number {
    return Math.abs(Math.round(second.getTime() - first.getTime()));
  }

  private static minLapse(elements: PlannerSceneElement[]): number {

    if (elements && elements.length && elements.length === 1) {
      return 0;
    }

    let result: number = 0;
    for (let i = 1; i < elements.length; i++) {
      let distance = ScenesTimeLineComponent.dayDiff(elements[i - 1].creationDate, elements[i].creationDate);
      result = result ? Math.min(result, distance) : distance;
    }

    return result;
  }
}
