import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { PlannerSceneObject } from 'app/core/models/planner/plannerSceneObject';
import { ChangeDetectionStrategy } from '@angular/core';
import { PlannerFile, FileType } from '@appmodels';

@Component({
    selector: 'case-file-item',
    templateUrl: './case-file-item.component.html',
    styleUrls: ['./case-file-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseFileItemComponent implements OnInit {

    fileTypeEnum = FileType;
    mouseOvered;

    @Input()
    file: PlannerFile;

    //   @Output()
    //   onEditClicked: EventEmitter<PlannerSceneObject>

    @Output()
    onDownloadClicked: EventEmitter<PlannerFile>

    @Output()
    onEditClicked: EventEmitter<PlannerFile>

    @Output()
    onSelectClicked: EventEmitter<PlannerFile>

    @Output()
    onDeleteClicked: EventEmitter<PlannerFile>

    @Output()
    onDownloadAsPlyClicked: EventEmitter<PlannerFile>

    constructor() {
        // this.onEditClicked = new EventEmitter();
        this.onDownloadClicked = new EventEmitter();
        this.onEditClicked = new EventEmitter();
        this.onSelectClicked = new EventEmitter();
        this.onDeleteClicked = new EventEmitter();
        this.onDownloadAsPlyClicked = new EventEmitter();
    }


    ngOnInit() {

    }

    //   editClicked(sceneObject): void {
    //     this.onEditClicked.emit(sceneObject);
    //   }

    downloadClicked(plannerFile: PlannerFile): void {
        this.onDownloadClicked.emit(plannerFile);
    }

    editClicked(plannerFile: PlannerFile): void {
        this.onEditClicked.emit(plannerFile);
    }

    selectClicked(plannerFile: PlannerFile): void {
        this.onSelectClicked.emit(plannerFile);
    }

    deleteClicked(plannerFile: PlannerFile): void {
        this.onDeleteClicked.emit(plannerFile);
    }

    downloadAsPlyClicked(plannerFile: PlannerFile): void {
        this.onDownloadAsPlyClicked.emit(plannerFile);
    }
}
