import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';
import { sortBy } from "lodash";

@Pipe({
    name: 'orderByExt'
})
export class OrderByExtPipe implements PipeTransform {

   

   public transform(collection: _.List<any> | null | undefined, iteratees?: any[], orders?: _.Many<boolean|"asc"|"desc">, setups?: any[]): any[] {
         
if(setups!=null)
{
    for(let i =0; i<setups.length; i++)
    {
        if(setups[i] == 'toLower')
        {
            iteratees[i] = obj => obj.title.toLowerCase();
        }
    }
}

         return _.orderBy(collection, iteratees, orders);
         }
}