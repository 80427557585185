import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { CaseStatus } from 'app/core/models';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'case-status-translate',
  templateUrl: './case-status-translate.component.html',
  styleUrls: ['./case-status-translate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseStatusTranslateComponent implements OnInit {

  @Input() caseStatus: CaseStatus;

  public caseStatusEnum = CaseStatus;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {

    this._fuseTranslationLoaderService.loadTranslations(english, turkish);

  }

  ngOnInit() {
  }

}
