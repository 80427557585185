import { createSelector } from "@ngrx/store";
import { IAppState } from "../state/app.state";
import { IQualitySurveyCasesState } from "../state/qualitySurveyCases.state";

const selectQualitySurveyCases = (state: IAppState) => state.qualitySurveyCases;

export const selectQualitySurveyCasesRequestArgs = createSelector(
    selectQualitySurveyCases,
    (state: IQualitySurveyCasesState) => {
        const notAdmins = state.notAdmins;
        const searchPattern = state.searchPattern;
        return { searchPattern, notAdmins };
    }
)

export const selectQualitySurveyCasesList = createSelector(
    selectQualitySurveyCases,
    (state: IQualitySurveyCasesState) => state.qualitySurveyCases
)