<mat-table #table [dataSource]="appLogsGroups"
           [@animateStagger]="{value:'50'}" >
    <!-- Checkbox Column -->
    <ng-container matColumnDef="checkbox">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let user">
            <mat-checkbox  (ngModelChange)="onSelectedChange(user.id)"
                          (click)="$event.stopPropagation()">
            </mat-checkbox>
        </mat-cell>
    </ng-container>

    <!-- Avatar Column -->
    <ng-container matColumnDef="avatar">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let appLogGroup">
            <img class="avatar" [alt]="appLogGroup.userName"
                 src="/assets/images/avatars/profile.jpg"/>
        </mat-cell>
    </ng-container>

     
    <!-- UserName Column -->
    <ng-container matColumnDef="userName">
        <mat-header-cell *matHeaderCellDef >User name</mat-header-cell>
        <mat-cell *matCellDef="let appLogGroup" >
            <p class="userName text-truncate">
                {{appLogGroup.userName}}
            </p>
        </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="fullName">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm>Full name</mat-header-cell>
        <mat-cell *matCellDef="let appLogGroup" fxHide fxShow.gt-sm>
            <p class="fullName text-truncate">
                {{appLogGroup.fullName}}
            </p>
        </mat-cell>
    </ng-container>

    <!-- Phone Column -->
    <ng-container matColumnDef="company">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>Company</mat-header-cell>
        <mat-cell *matCellDef="let appLogGroup" fxHide fxShow.gt-md>
            <p class="company text-truncate">
                {{appLogGroup.company}}
            </p>
        </mat-cell>
    </ng-container>


    <!-- Residence Column -->
    <ng-container matColumnDef="residence">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-lg>Residence</mat-header-cell>
        <mat-cell *matCellDef="let appLogGroup" fxHide fxShow.gt-lg>
            <p class="residence text-truncate">
                {{appLogGroup.residence}}
            </p>
        </mat-cell>
    </ng-container>

     <!-- Last activity Column -->
     <ng-container matColumnDef="lastActivity">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-lg>Last activity</mat-header-cell>
        <mat-cell *matCellDef="let appLogGroup" fxHide fxShow.gt-lg>
            <p class="lastActivity text-truncate">
                {{appLogGroup.lastActivity | date : 'HH:mm dd.MM.yyyy'}}
            </p>
        </mat-cell>
    </ng-container>

    <!-- Buttons Column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef ></mat-header-cell>
        <mat-cell *matCellDef="let user">
            <div fxFlex="row" fxLayoutAlign="end center">
            

                <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More"
                        (click)="$event.stopPropagation();">
                    <mat-icon class="secondary-text">more_vert</mat-icon>
                </button>

                <mat-menu #moreMenu="matMenu">
                    <button mat-menu-item aria-label="remove" (click)="deleteUser(user)">
                        <mat-icon>delete</mat-icon>
                        <span>Remove</span>
                    </button>
                </mat-menu>
            </div>

        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let appLogGroup; columns: displayedColumns;"
            
             [ngClass]="{'red-500': appLogGroup.hasUnhandledExceptions, 'red-200': appLogGroup.hasHandledExceptions}"
             (click)="viewLogsGroup(appLogGroup)"
             
             matRipple
             [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>

