import { NotificationArgs } from '@appmodels';

export interface INotificationsState
{
    notification: NotificationArgs 
}

export const initialNotificationState: INotificationsState =
{
    notification: null
}