import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { map, switchMap, mergeMap, mergeAll, combineAll, catchError, finalize, concatMap, tap } from 'rxjs/operators';
import { GetPlannerFilesSASListQuery, GetPlannerFileSASQuery, FileType, RequestPlannerFileUploadCommand, AzureSASModel, ApiResponse, DeletePlannerFileCommand, EditPlannerFileCommand, EditPlannerFilePermissionsCommand } from 'app/core/models';
import * as FileSaver from 'file-saver';
import { Observable, forkJoin, merge, concat, fromEvent, Subscriber, throwError, from, of, } from 'rxjs';
import { doesNotThrow } from 'assert';
import { FileResponse } from 'app/core/models/';
import { Md5 } from 'ts-md5/dist/md5';
import { ObjectMapper } from 'app/core/helpers';

import * as JSZip from 'jszip';
import { NGXLogger } from 'ngx-logger';
import { StorageService } from '@appservices';
import { ConvertType } from 'app/core/models/planner/enums/fileEnums';
import { GeometryService } from './geometry.service';


//import { BlobServiceClient } from '@azure/storage-blob';

@Injectable({ providedIn: 'root' })
export class FilesService {

    constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient, private _storageService: StorageService, private logger: NGXLogger) {
    }

    public getFileDownloadUrl(caseId: string, fileId: string, fileName: string): Observable<string> {

        this.logger.info('Get file download url.', `CaseId: ${caseId},`, `fileId: ${fileId},`, `fileName: ${fileName}`);

        let filesSASQuery = new GetPlannerFilesSASListQuery();
        filesSASQuery.CaseId = caseId;

        let fileSASQuery = new GetPlannerFileSASQuery();
        fileSASQuery.FileId = fileId;
        fileSASQuery.FileName = fileName;
        filesSASQuery.SASRequestFiles.push(fileSASQuery);

        return this.getFilesSaS(filesSASQuery).pipe(
            map(response => {
                return response.sasResponseFiles[0].url;
            }));
    }

    public downloadFile(caseId: string, fileId: string, fileName: string, convertType: ConvertType = ConvertType.None): Observable<FileResponse[]> {

        this.logger.info('Download file.', `CaseId: ${caseId},`, `fileId: ${fileId},`, `fileName: ${fileName}`);

        let filesSASQuery = new GetPlannerFilesSASListQuery();
        filesSASQuery.CaseId = caseId;

        let fileSASQuery = new GetPlannerFileSASQuery();
        fileSASQuery.FileId = fileId;
        fileSASQuery.FileName = fileName;
        filesSASQuery.SASRequestFiles.push(fileSASQuery);

        return this.download(filesSASQuery, convertType);
    }

    public downloadFiles(caseId: string, filesQuery: GetPlannerFileSASQuery[]): Observable<FileResponse[]> {

        this.logger.info('Download files.', `CaseId: ${caseId},`, filesQuery);

        let filesSASQuery = new GetPlannerFilesSASListQuery();
        filesSASQuery.CaseId = caseId;

        filesSASQuery.SASRequestFiles = filesQuery;

        return this.download(filesSASQuery);
    }


    public uploadFile(file: File, caseId: string, title: string, fileType: FileType, additionalInfo: string, isNeedToZip: boolean): Observable<FileResponse> {

        this.logger.info('Upload file.', `CaseId: ${caseId},`, `title: ${title},`, `fileType: ${fileType}`, `additionalInfo: ${additionalInfo}`, `isNeedToZip: ${isNeedToZip}`);

        let sasRequest = new RequestPlannerFileUploadCommand();

        sasRequest.caseId = caseId;
        sasRequest.fileType = fileType;
        sasRequest.additionalInfo = encodeURIComponent(additionalInfo);
        sasRequest.fileName = file.name + (isNeedToZip ? '.zip' : '');
        sasRequest.title = title;

        sasRequest.isFileZipped = isNeedToZip;
        sasRequest.fileSize = file.size;

        var fileResponse = new FileResponse();

        let observable = new Observable<FileResponse>((subscriber) => {

            fileResponse.status = 'md5 calc';

            subscriber.next(fileResponse);

            this.getMd5(file).pipe(

                switchMap(
                    md5string => {

                        fileResponse.status = 'zipping';
                        subscriber.next(fileResponse);

                        let fileProcessing = isNeedToZip ? this.zipFile(file) : of(file);

                        return fileProcessing.pipe(
                            map(processedFile => {
                                if (isNeedToZip)
                                    sasRequest.fileSizeZipped = processedFile.size;
                                return processedFile;
                            }),
                            switchMap(
                                blob => {

                                    this.logger.info(md5string);

                                    sasRequest.md5hash = md5string;

                                    var urlParams = ObjectMapper.mapObjectToURLParams(sasRequest);

                                    fileResponse.status = 'getting sas';
                                    subscriber.next(fileResponse);

                                    const url = `${this.config.apiEndpoint}/api/public/Files/RequestFileUpload?` + urlParams;
                                    this.logger.debug('HTTP GET: request file upload', sasRequest, `url: ${url}`);
                                    return this.http.get<AzureSASModel>(url).pipe(
                                        tap(x => this.logger.debug('Request file upload response:', x)),
                                        switchMap(res => {

                                            this.logger.debug('Sas response:', res);

                                            // const fileBlobNameUrlEncoded = encodeURIComponent(res.fileBlobName);

                                            fileResponse.fileName = title;
                                            fileResponse.fileId = res.fileId;
                                            fileResponse.status = 'uploading';
                                            fileResponse.progress = 0;
                                            subscriber.next(fileResponse);

                                            this.logger.debug('BLOB PUT: upload to blob storage', blob);
                                            //  return res;
                                            var uploadObs = this._storageService.uploadToBlobStorage(blob, res.url, res.fileBlobName).pipe(
                                                finalize(() => this.logger.debug('Upload to blob storage done')),
                                                map(x => {
                                                    fileResponse.status = 'uploading';
                                                    fileResponse.progress = Math.ceil((x / blob.size) * 100);
                                                    subscriber.next(fileResponse);
                                                    return x;
                                                })
                                            );

                                            var commitObs = this.http.get<any>(`${this.config.apiEndpoint}/api/public/Files/SetFileUploaded?caseId=` + caseId + '&fileId=' + res.fileId).pipe(
                                                tap(commitResponse => this.logger.debug('Set file uploaded done.', `CaseId: ${caseId},`, `fileId: ${res.fileId}`, commitResponse)),
                                                map(x => {
                                                    fileResponse.status = 'done';
                                                    subscriber.next(fileResponse);
                                                }),
                                                finalize(() => {
                                                    subscriber.complete();
                                                })
                                            );

                                            return concat(uploadObs, commitObs);
                                        })
                                    );
                                }))
                    }

                )).pipe(

                    catchError(err => {
                        subscriber.error(err);
                        throw err;
                    })
                )
                .subscribe(

            );
        });

        return observable;


    }

    public editFile(deletePlannerFileCommand: EditPlannerFileCommand): Observable<ApiResponse<boolean>> {
        const url = `${this.config.apiEndpoint}/api/private/FilesManagement/EditPlannerFile`;
        this.logger.debug("HTTP POST edit planner file.", `File to edit: ${deletePlannerFileCommand},`, `url: ${url}`);

        return this.http.post<any>(url, deletePlannerFileCommand)
            .pipe(tap(response => {
                this.logger.debug("Response edit file:", response);
            }),
                map(response => new ApiResponse(response)),
                catchError((error) => {
                    // console.error(error)

                    this.logger.error(error);

                    return of(
                        new ApiResponse(null, false, error.error)
                    );
                }));
    }

    public deleteFile(deletePlannerFileCommand: DeletePlannerFileCommand): Observable<ApiResponse<boolean>> {
        const url = `${this.config.apiEndpoint}/api/private/FilesManagement/DeletePlannerFile`;
        this.logger.debug("HTTP POST delete planner file.", `File to delete: ${deletePlannerFileCommand},`, `url: ${url}`);

        return this.http.post<any>(url, deletePlannerFileCommand)
            .pipe(tap(response => {
                this.logger.debug("Response delete file:", response);
            }),
                map(response => new ApiResponse(response)),
                catchError((error) => {
                    // console.error(error)

                    this.logger.error(error);

                    return of(
                        new ApiResponse(null, false, error.error)
                    );
                }));
    }

    public editPlannerFilePermissions(editPlannerFilePermissionsCommand: EditPlannerFilePermissionsCommand): Observable<ApiResponse<boolean>> {
        const url = `${this.config.apiEndpoint}/api/private/FilesManagement/EditPlannerFilePermissions`;
        this.logger.debug("HTTP POST edit planner files permissions.", `Files to edit permissions: ${editPlannerFilePermissionsCommand},`, `url: ${url}`);

        return this.http.post<any>(url, editPlannerFilePermissionsCommand)
            .pipe(tap(response => {
                this.logger.debug("Response edit planner files permissions:", response);
            }),
                map(response => new ApiResponse(response)),
                catchError((error) => {
                    // console.error(error)

                    this.logger.error(error);

                    return of(
                        new ApiResponse(null, false, error.error)
                    );
                }));
    }

    private download(filesSASQuery: GetPlannerFilesSASListQuery, convertType: ConvertType = ConvertType.None): Observable<FileResponse[]> {

        return this.getFilesSaS(filesSASQuery)
            .pipe(mergeMap(response => {

                this.logger.debug('Fetch files SAS response:', response);

                let obsCollection = new Array<Observable<FileResponse[]>>();
                let fileResponses = new Array<FileResponse>();

                response.sasResponseFiles.forEach(sasResponseFile => {

                    const request = new HttpRequest('GET', sasResponseFile.url, {
                        reportProgress: true,
                        responseType: 'blob'
                    });

                    let fileResponse = new FileResponse();
                    fileResponse.fileId = sasResponseFile.fileId;
                    fileResponse.fileName = sasResponseFile.fileName;

                    fileResponses.push(fileResponse);

                    let obs = this.http.request(request).pipe(map(event => {

                        // progress
                        if (event.type === HttpEventType.DownloadProgress) {

                            const percentage = 100 / event.total * event.loaded;
                            fileResponse.progress = percentage;
                            return fileResponses;

                        }
                        else {
                            //  this.logger.info(event);
                        }
                        // finished
                        if (event.type === HttpEventType.Response) {
                            //this.logger.info(event.body);
                            this.saveFile(event.body as Blob, sasResponseFile.fileName, convertType)

                            return null;
                        }

                        return null;
                    }));


                    obsCollection.push(obs);

                });

                let mergedObs = obsCollection[0];
                for (let i = 1; i < obsCollection.length; i++) {

                    mergedObs = merge(mergedObs, obsCollection[i]);
                }

                return mergedObs;
            }));
    }

    private getFilesSaS(filesSASQuery: GetPlannerFilesSASListQuery): Observable<any> {
        const url = `${this.config.apiEndpoint}/api/public/Files/FetchFilesSAS`;
        this.logger.debug('HTTP POST: fetch files SAS', filesSASQuery, `url: ${url}`);
        return this.http.post<any>(url, filesSASQuery);
    }

    private saveFile(blob: Blob, fileName: string, convertType: ConvertType = ConvertType.None) {

        const convertArgs: any = {}
        convertArgs.blob = blob;
        convertArgs.fileName = fileName;

        let convert = of<any>(convertArgs);

        //convertType = ConvertType.PlyToStl;

        switch (convertType) {
            case ConvertType.PlyToStl:

                const file = new File([new Blob([blob])], fileName);

                convert = this.unzipFile(file).pipe(
                    switchMap(unzipResult => {

                        const blob = unzipResult.blob;
                        const fileName = unzipResult.filename;

                        return new GeometryService().convertToSTL(new File([new Blob([blob])], fileName)).pipe(map(convertedFile => {

                            const convertResult: any = {}

                            convertResult.blob = convertedFile;
                            convertResult.fileName = convertedFile.name;

                            return convertResult;
                        }));
                    })
                );
        }

        convert.subscribe(converResult => {

            FileSaver.saveAs(converResult.blob, converResult.fileName);

            this.logger.info('File saved');
            this.logger.debug('File info:', converResult.blob, `fileName: ${converResult.fileName}`);

        });
    }

    private getMd5(file: File): Observable<string> {

        this.logger.debug('Getting file md5', file);

        let md5 = new Md5();
        var reader = new FileReader();

        let observable = Observable.create((observer: Subscriber<string>): void => {
            // if success
            reader.onloadend = ((ev: ProgressEvent): void => {
                var arrayBuffer = reader.result;
                var bytes = new Uint8Array(arrayBuffer as ArrayBuffer);
                // resolve(bytes);
                md5.appendByteArray(bytes);
                let md5String = md5.end() as string;

                this.logger.info('Getting file md5 done!', `md5String: ${md5String}`);

                observer.next(md5String);
                observer.complete();
            });

            // if failed
            reader.onerror = (error: any): void => {

                this.logger.error(error);
                observer.error(error);
            }
        });

        reader.readAsArrayBuffer(file);

        return observable;
    }

    private zipFile(file: File): Observable<Blob> {

        this.logger.debug('Zipping file', file);

        let zip = new JSZip();
        zip.file(file.name, file)

        let obs = from(zip.generateAsync({ type: "blob", compression: 'DEFLATE', compressionOptions: { level: 6 } })).pipe(
            tap(result => {
                this.logger.info('Zipping file done!');
                this.logger.debug('Zipped file:', result);
            })
        );

        return obs as Observable<Blob>;
    }

    private unzipFile(file: File): Observable<any> {

        this.logger.debug('Unzipping file', file);

        var zip = new JSZip();


        let obs = from(zip.loadAsync(file)).pipe(
            switchMap(contents => {
                const filename = Object.keys(contents["files"])[0];

                return from(zip.file(filename).async('uint8array')).pipe(
                    map(content => [filename, content])
                )
            }),
            map((([filename, content]) => {

                const result: any = {};
                result.blob = new Blob([content as Uint8Array])
                result.filename = filename;

                return result;
            }))
        )

        // let obs = from(zip.loadAsync(file).then(function (contents) {

        //     Object.keys(contents.files).forEach(function (filename) {
        //         zip.file(filename).async('nodebuffer').then(function (content) {
        //             console.log(filename);
        //             console.log(content);
        //         });
        //     });

        // }));

        return obs as Observable<Blob>;

        // let zip = new JSZip();
        // zip.file(file.name, file)

        // let obs = from(zip.generateAsync({ type: "blob", compression: 'DEFLATE', compressionOptions: { level: 6 } })).pipe(
        //     tap(result => {
        //         this.logger.info('Zipping file done!');
        //         this.logger.debug('Zipped file:', result);
        //     })
        // );

        // return obs as Observable<Blob>;
    }
}

