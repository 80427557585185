import { OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_DATE_FORMATS } from 'app/core/helpers';
import { PlannerCaseShare } from '@appmodels';
import { Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
const ɵ0 = APP_DATE_FORMATS;
export class CaseShareDialogComponent {
    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
    // private _casesService: CasesService,
    matDialogRef, _data, _cdr, logger) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._cdr = _cdr;
        this.logger = logger;
        this.validateUser = (control) => {
            if (!control || !control.parent) {
                return null;
            }
            const userEmail = control.parent.get('userToShare').value;
            let isValid = false;
            if (!this.foundUsers || this.foundUsers.length == 0) {
                isValid = false;
            }
            else {
                const foundUsersIndex = this.foundUsers.findIndex(user => user.email == userEmail);
                if (foundUsersIndex >= 0)
                    isValid = true;
            }
            return isValid ? null : { wrongUserEmail: true };
        };
        // Set the defaults
        _data.title;
        this.caseShares = _data.caseShares;
        this.caseShareForm = this.createCaseShareForm(this.caseShares);
        this.addCaseShareForm = this.createAddCaseShareForm();
        this._unsubscribeAll = new Subject();
        this.onSearchQueryChanged = new EventEmitter();
    }
    set users(value) {
        this.foundUsers = value;
        if (!this._cdr['destroyed']) {
            this._cdr.detectChanges();
        }
    }
    get caseSharesEntries() {
        return this.caseShareForm.get('caseSharesEntries').controls;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    createCaseShareForm(caseShares) {
        const caseSharesFormGroups = new FormGroup({});
        const caseSharesFormGroupsArray = new FormArray([]);
        caseShares.forEach(caseShare => {
            var shareFormGroup = new FormGroup({
                // newTitle: new FormControl(title, Validators.required),
                userToShare: new FormControl({ value: caseShare.shareToName, disabled: true }, [Validators.required]),
                userToShareId: new FormControl({ value: caseShare.shareToId, disabled: true }, [Validators.required])
            });
            caseSharesFormGroupsArray.push(shareFormGroup);
        });
        caseSharesFormGroups.addControl('caseSharesEntries', caseSharesFormGroupsArray);
        return caseSharesFormGroups;
    }
    createAddCaseShareForm() {
        var shareFormGroup = new FormGroup({
            // newTitle: new FormControl(title, Validators.required),
            userToShare: new FormControl('', [Validators.required])
        });
        return shareFormGroup;
    }
    onSearchQuery(searchQuery) {
        if (!searchQuery || searchQuery.length < 3)
            return;
        this.onSearchQueryChanged.emit(searchQuery);
    }
    addShareEntry() {
        if (this.addCaseShareForm.invalid)
            return;
        const formArray = this.caseSharesEntries;
        //  const newTitle = this.caseShareForm.get('newTitle').value;
        const userEmail = this.addCaseShareForm.get('userToShare').value;
        const selectedUser = this.foundUsers.find(user => user.email == userEmail);
        var shareFormGroup = new FormGroup({
            // newTitle: new FormControl(title, Validators.required),
            userToShare: new FormControl({ value: userEmail, disabled: true }, [Validators.required]),
            userToShareId: new FormControl({ value: selectedUser.id, disabled: true }, [Validators.required])
        });
        formArray.push(shareFormGroup);
    }
    deleteShareEntry(index) {
        const formArray = this.caseSharesEntries;
        formArray.splice(index, 1);
    }
    onSubmit() {
        //console.log(this.caseShareForm);
        if (this.caseShareForm.invalid)
            return;
        //  const newTitle = this.caseShareForm.get('newTitle').value;
        //   const userEmail = this.addCaseShareForm.get('userToShare').value;
        //  const selectedUser = this.foundUsers.find(user => user.email == userEmail);
        const formArray = this.caseSharesEntries;
        const usersToShare = [];
        formArray.forEach(control => {
            const userToShareId = control.get('userToShareId').value;
            //const userToShare = this.foundUsers.find(user => user.email == userToShareName);
            usersToShare.push(userToShareId);
        });
        this.matDialogRef.close(['share', usersToShare]);
    }
    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
export { ɵ0 };
