import { CasesActions, ECasesActions } from "../actions/cases.actions";
import { EQualitySurveyCasesActions, QualitySurveyCasesActions } from "../actions/qualitySurveyCases.actions";
import { IQualitySurveyCasesState, initialQualitySurveyCasesState } from "../state/qualitySurveyCases.state";

export function qualitySurveyCasesReducers(
    state = initialQualitySurveyCasesState,
    action: QualitySurveyCasesActions
): IQualitySurveyCasesState {
    switch (action.type) {
        case EQualitySurveyCasesActions.SetQualitySurveyCasesSearchPatternSuccess:
            {
                return {
                    ...state,
                    qualitySurveyCases: [],
                    searchPattern: action.payload
                }
            }
        case EQualitySurveyCasesActions.SetQualitySurveyCasesIsWidthAdminsSuccess:
            {
                return {
                    ...state,
                    qualitySurveyCases: [],
                    notAdmins: action.isWidthAdmins
                }
            }
        case EQualitySurveyCasesActions.SetQualitySurveysCasesFilterParamsSuccess:
            {
                return {
                    ...state,
                    qualitySurveyCases: [],
                    notAdmins: action.notAdmins
                }
            }

        case EQualitySurveyCasesActions.GetQualitySurveysCasesSuccess:
            {
                const existedQualitySurveysCases = state.qualitySurveyCases ? state.qualitySurveyCases : [];
                const newQualitySurveysCases = existedQualitySurveysCases.concat(action.payload);
                return {
                    ...state,
                    qualitySurveyCases: newQualitySurveysCases
                }
            }
        default:
            {
                return state;
            }
    }
}