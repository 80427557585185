import { OperationType, SceneType, CaseStatus, MedicalReferals } from './enums/plannerCaseEnums';
import { PlannerCaseRelatedData } from './plannerCaseRelatedData';
import { PlannerScene } from '@appmodels';


export class PlannerCase {
  id: string = null;
  title: string = null;
  ownerId: string = null;
  ownerName: string = null;
  creationDate: Date = null;
  surgicalDate: Date = null;
  status: CaseStatus = null;
  operationType: OperationType = null;
  sceneType: SceneType = null;

  medicalReferalsType: MedicalReferals = null;
  accessType: number  = null;

  lastMessageDate: Date = null;
  lastMessageText: string = null;

  details: PlannerCaseRelatedData = null;
  scenes: PlannerScene[] = null;



  hasNewMessages: boolean = false;
  newMessagesCount: number = 0;
}
