import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ConnectionInfo, Language, MedicalReferals } from '@appmodels';
import { fuseAnimations } from '@fuse/animations';
import { OriginApp } from 'app/core/models/planner/enums/originAppEnums';

@Component({
  selector: 'online-users',
  templateUrl: './online-users.component.html',
  styleUrls: ['./online-users.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  changeDetection: ChangeDetectionStrategy.Default
})
export class OnlineUsersComponent implements OnInit {

  @Input()
  public onlineUsers: ConnectionInfo[];

  originAppEnum = OriginApp;
  medicalReferalsEnum = MedicalReferals;
  languageEnum = Language;

  public displayedColumns = ['avatar', 'email', 'language', 'med-ref', 'app-origin', 'case', 'buttons'];

  constructor() { }

  ngOnInit() {
  }

}
