import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';
import { UserType } from '../models/planner/enums/plannerCaseEnums';
import { AuthGuard } from './auth.guard';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
    constructor(
        private authGuard: AuthGuard,
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const currentUser = this.authenticationService.currentUser.value;

        console.log(currentUser);

        if (currentUser && (currentUser.userType == UserType.Admin || currentUser.userType == UserType.Bioengineer)) {
            // logged in so return true
            return true;
        }
        else if (currentUser) {
            // forbidden
            this.router.navigate(['/errors/error-403'], { queryParams: { returnUrl: state.url } });
            return false;
        }

        return this.authGuard.canActivate(route, state);
    }
}