import { FileType } from "../enums/plannerCaseEnums";

export class EditPlannerFileCommand {
    public caseId: string = null;
    public id: string = null;
 
    public title: string = null;   
    public fileType: FileType = null;
    public additionalInfo: string = null;

}