import { FuseNavigation } from '@fuse/types';

export const adminNavigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'cases',
                title    : 'Cases',
                translate: 'NAV.CASES.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/cases',
                badge    : {
                    
                }
            },
            {
                id       : 'chat',
                title    : 'Chat',
                translate: 'NAV.CHAT.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/chat',
                badge    : {
                    
                }
            },
            {
                id       : 'downloads',
                title    : 'Downloads',
                translate: 'NAV.DOWNLOADS.TITLE',
                type     : 'item',
                icon     : 'cloud_download',
                url      : '/downloads',
             
            }
        ]
    },{
        id       : 'administration',
        title    : 'Administartion',
        translate: 'NAV.ADMINISTRATION',
        type     : 'group',
        children : [
            {
                id       : 'dashboard',
                title    : 'Dashboard',
                translate: 'NAV.DASHBOARD.TITLE',
                type     : 'item',
                icon     : 'dashboard',
                url      : '/dashboard',
                badge    : {
                    
                }
            },
            {
                id       : 'casemanagement',
                title    : 'Case management',
                translate: 'NAV.CASEMANAGEMENT.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/casemanagement',
                badge    : {
                    
                }
            },
            {
                id       : 'casequalitysurveys',
                title    : 'Case surveys',
                translate: 'NAV.CASEQUALITYSURVEYS.TITLE',
                type     : 'item',
                icon     : 'thumbs_up_down',
                url      : '/casequalitysurveys',
                badge    : {
                    
                }
            },
            {
                id       : 'batch-operations',
                title    : 'Batch operations',
                translate: 'NAV.BATCHOPERATIONS.TITLE',
                type     : 'item',
                icon     : 'file_copy',
                url      : '/batch-operations',
                badge    : {
                    
                }
            },
            {
                id       : 'users',
                title    : 'Users',
                translate: 'NAV.USERS.TITLE',
                type     : 'item',
                icon     : 'account_circle',
                url      : '/users',
                badge    : {
                    
                }
            },
            {
                id       : 'paidModules',
                title    : 'PaidModules',
                translate: 'NAV.PAIDMODULES.TITLE',
                type     : 'item',
                icon     : 'attach_money',
                url      : '/paidModules',
                badge    : {
                }          
            },
            {
                id       : 'applogs',
                title    : 'App logs',
                translate: 'NAV.APPLOGS.TITLE',
                type     : 'item',
                icon     : 'dvr',
                url      : '/applogs',
                badge    : {
                    title    : '0',
                    translate: 'NAV.USERS.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            },
            // {
            //     id       : 'payments',
            //     title    : 'Payments',
            //     translate: 'NAV.PAYMENTS.TITLE',
            //     type     : 'item',
            //     icon     : 'attach_money',
            //     url      : '/payments',
            //     badge    : {
            //         title    : '25',
            //         translate: 'NAV.PAYMENTS.BADGE',
            //         bg       : '#F44336',
            //         fg       : '#FFFFFF'
            //     }
            // }
        ]
    }
];
