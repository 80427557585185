import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DateInterval } from '@appmodels';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, forkJoin } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { GetGeneralAnalytics } from '../store/actions/analytics.actions';
import { GetAppLogsGroups } from '../store/actions/appLogs.actions';
import { selectGeneralAnalytics } from '../store/selectors/analytics.selector';
import { selectAppLogsGroups } from '../store/selectors/appLogs.selector';
import { IAppState } from '../store/state/app.state';

@Injectable({ providedIn: 'root' })
export class AnalyticsResolver {
    constructor(private _store: Store<IAppState>, private logger: NGXLogger) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        const routeParams = route.params;

        this.logger.info('Analytics route resolver: ', route.params)

        const obsCollection: Observable<any>[] = [];    

        obsCollection.push(this._store.select(selectGeneralAnalytics).pipe(
            filter(val => val != null),           
            take(1)
        ));

        obsCollection.push(of(this._store.dispatch(new GetGeneralAnalytics(DateInterval.CalendarMonth))));       

        return forkJoin(obsCollection);
    }
}