<section class="cd-horizontal-timeline" [ngClass]="{'loaded': loaded}">

    <div class="timeline" #timeline>

        <div class="events-wrapper">
            <div class="events" #eventsWrapper [style.width.px]="eventsWrapperWidth">
                <ol>
                    <li *ngFor="let item of plannerSceneElements; let index = index">

                        <a [attr.title]="getSceneStatusText(item.caseSceneStatus)" #timelineEvents href="#" [ngClass]="{'selected': item.selected}" [caseStatusStyle]="item.caseSceneStatus" caseStatusStylePrefix="tl-" (click)="onSceneClick($event, item)">
                            {{item.creationDate | date: 'dd.MM'}}
                        </a>
                        
                        <span>&nbsp;</span>
                    </li>
                </ol>
                <span class="filling-line" aria-hidden="true" #fillingLine></span>
            </div>
        </div>

        <ul class="cd-timeline-navigation">
            <li>
                <a href="#" (click)="onScrollClick($event, false)" class="prev" [ngClass]="{'inactive':prevLinkInactive}">Prev</a>
            </li>
            <li>
                <a href="#" (click)="onScrollClick($event, true)" class="next" [ngClass]="{'inactive':nextLinkInactive}">Next</a>
            </li>
        </ul>

    </div>
</section>