import { PlannerFile } from './plannerFile';

export class PlannerMessage
{
      id: string;
      title: string;
      text: string ;
      creationDate: Date = null; 
      userNameFrom: string;
      userFromIsAdmin: boolean;
      userNameTo: string ;   
      messageFile: PlannerFile;
      isNew: boolean;
}