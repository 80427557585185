import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { PlannerSceneObject } from 'app/core/models/planner/plannerSceneObject';
import { ChangeDetectionStrategy } from '@angular/core';
import { ConfirmDialogComponent } from 'app/core/shared';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'scene-object-item',
  templateUrl: './scene-object-item.component.html',
  styleUrls: ['./scene-object-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SceneObjectItemComponent implements OnInit {

  mouseOvered;

  constructor(private _matDialog: MatDialog) {
    this.onShowHideClicked = new EventEmitter();
    this.onEditClicked = new EventEmitter();
    this.onDeleteClicked = new EventEmitter();    
   }

  @Input()
  sceneObject: PlannerSceneObject;

  @Output()
  onShowHideClicked: EventEmitter<PlannerSceneObject>

  @Output()
  onEditClicked: EventEmitter<PlannerSceneObject>

  @Output()
  onDeleteClicked: EventEmitter<PlannerSceneObject>

  ngOnInit() {
  }

  showHideClicked(sceneObject): void {
    this.onShowHideClicked.emit(sceneObject);
  }

  editClicked(sceneObject): void {
    this.onEditClicked.emit(sceneObject);
  }

  deleteClicked(sceneObject): void {

    const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
      panelClass: 'dialog-confirm',
      data: `Are you sure you want to delete scene object "${sceneObject.title}"?`
  });

  confirmDialogRef.afterClosed()
      .subscribe(confirmResult => {

          if (!confirmResult) {
              return;
          }

          this.onDeleteClicked.emit(sceneObject);
      });   
  }
}
