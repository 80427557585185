import { Injectable } from "@angular/core";
import { PlannerModuleType } from "../models/planner/enums/plannerCaseEnums";

@Injectable({ providedIn: 'root' })
export class SettingsService {

    private ModulesFiltersSetting: string = 'modulesFilters';

    public getModulesFilters(): PlannerModuleType[] {
        const loadedModulesFiletrs = JSON.parse(localStorage.getItem(this.ModulesFiltersSetting)) as PlannerModuleType[];

        if (loadedModulesFiletrs)
            return loadedModulesFiletrs;

        return [];
    }

    public setModulesFilters(modulesFilters: PlannerModuleType[]): void {

        localStorage.setItem(this.ModulesFiltersSetting, JSON.stringify(modulesFilters));

    }
}