import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'cases',
                title    : 'Cases',
                translate: 'NAV.CASES.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/cases',
                badge    : {
                    title    : '25',
                    translate: 'NAV.CASES.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            },
            {
                id       : 'chat',
                title    : 'Chat',
                translate: 'NAV.CHAT.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/chat',
                badge    : {
                    title    : '25',
                    translate: 'NAV.CHAT.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            },
            {
                id       : 'downloads',
                title    : 'Downloads',
                translate: 'NAV.DOWNLOADS.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/downloads',
             
            }
        ]
    }
];
