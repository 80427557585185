import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { SetModulesTypeSuccess } from "../actions/cases.actions";
import { CasesService, AuthenticationService } from "@appservices";
import { LongRunningTasksService } from "app/core/services/planner/longRunningTasks.service";
import { SettingsService } from "app/core/services/settings.service";
import { NGXLogger } from "ngx-logger";
import { of } from "rxjs";
import { map, switchMap, take, withLatestFrom } from "rxjs/operators";
import { SetQualitySurveysCasesFilterParamsSuccess, GetQualitySurveysCases, GetQualitySurveysCasesSuccess, EQualitySurveyCasesActions, SetQualitySurveyCasesSearchPatternSuccess, SetQualitySurveyCasesIsWidthAdminsSuccess } from "../actions/qualitySurveyCases.actions";
import { selectQualitySurveyCasesList, selectQualitySurveyCasesRequestArgs } from "../selectors/qualitySurveyCases.selector";
export class QualitySurveyCasesEffects {
    constructor(_settingsService, _casesService, _longRunningTasksService, _authenticationService, _actions$, _store, _logger) {
        this._settingsService = _settingsService;
        this._casesService = _casesService;
        this._longRunningTasksService = _longRunningTasksService;
        this._authenticationService = _authenticationService;
        this._actions$ = _actions$;
        this._store = _store;
        this._logger = _logger;
        this.setQualitySurveyCasesSearchPattern$ = this._actions$.pipe(ofType(EQualitySurveyCasesActions.SetQualitySurveyCasesSearchPattern), map(action => action.payload), switchMap((searchPattern) => {
            return [
                new SetQualitySurveyCasesSearchPatternSuccess(searchPattern),
                new GetQualitySurveysCases(),
            ];
        }));
        this.setQualitySurveyCasesIsWidthAdmins$ = this._actions$.pipe(ofType(EQualitySurveyCasesActions.SetQualitySurveyCasesIsWidthAdmins), map(action => action.isWidthAdmins), switchMap((isWidthAdmins) => {
            return [
                new SetQualitySurveyCasesIsWidthAdminsSuccess(isWidthAdmins),
                new GetQualitySurveysCases(),
            ];
        }));
        this.setQualitySurveysCasesFilterParams$ = this._actions$.pipe(ofType(EQualitySurveyCasesActions.SetQualitySurveysCasesFilterParams), switchMap((searchParams) => {
            return [
                new SetQualitySurveysCasesFilterParamsSuccess(searchParams.notAdmins, searchParams.searchPattern),
                new GetQualitySurveysCases(),
            ];
        }));
        this.getQulitySurveysCases$ = this._actions$.pipe(ofType(EQualitySurveyCasesActions.GetQualitySurveysCases), map(action => action.isReload), 
        //map
        switchMap((isReload) => {
            this._logger.info(`Get Quality Surveys Cases, is reload: ${isReload}`);
            if (isReload) {
                return of([]).pipe(map(() => {
                    return { existedQualitySurveyCasesCount: 0, isReload: isReload };
                }));
            }
            else {
                return this._store.pipe(select(selectQualitySurveyCasesList), take(1), map(qualitySurveyCases => {
                    return { existedQualitySurveyCasesCount: qualitySurveyCases ? qualitySurveyCases.length : 0, isReload: isReload };
                }));
            }
        }), withLatestFrom(this._store.select(selectQualitySurveyCasesRequestArgs)), switchMap(([existedQualitySurveyCases, casesRequestArgs]) => {
            const skip = existedQualitySurveyCases.isReload ? 0 : existedQualitySurveyCases.existedQualitySurveyCasesCount; //? existedCases.length : 0;
            const take = 20;
            return this._casesService.getQualitySurveyCases(casesRequestArgs, skip, take).pipe(map(cases => { return { cases: cases, isReload: existedQualitySurveyCases.isReload }; }));
        }), switchMap((casesResult) => of(new GetQualitySurveysCasesSuccess(casesResult.cases, casesResult.isReload))));
    }
    ngrxOnInitEffects() {
        const modulesFilters = this._settingsService.getModulesFilters();
        return new SetModulesTypeSuccess(modulesFilters);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], QualitySurveyCasesEffects.prototype, "setQualitySurveyCasesSearchPattern$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], QualitySurveyCasesEffects.prototype, "setQualitySurveyCasesIsWidthAdmins$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], QualitySurveyCasesEffects.prototype, "setQualitySurveysCasesFilterParams$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], QualitySurveyCasesEffects.prototype, "getQulitySurveysCases$", void 0);
