import { GetAppLogsGroups, EAppLogsActions, GetAppLogsGroupsSuccess, GetAppLogsByConnection, GetAppLogsByConnectionSuccess, SetAppLogsFilterParams, SetAppLogsFilterParamsSuccess, SetAppLogsSearchPattern, SetAppLogsSearchPatternSuccess } from '../actions/appLogs.actions';
import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppLogsService } from 'app/core/services/planner/appLogs.service';
import { IAppState } from '../state/app.state';
import { NGXLogger } from 'ngx-logger';
import { SortOrder } from 'app/core/models/planner/enums/commonEnums';
import { selectAppLogsFilterParams, selectAppLogsGroups, selectAppLogsSearchPattern } from '../selectors/appLogs.selector';

@Injectable()
export class AppLogsEffects {

    @Effect()
    setFilterParams$ = this._actions$.pipe(
        ofType<SetAppLogsFilterParams>(EAppLogsActions.SetAppLogsFilterParams),
        map(action => action.isWithAdmins),
        switchMap((filterParams) => {
            return [
                new SetAppLogsFilterParamsSuccess(filterParams),
                new GetAppLogsGroups(true),
            ];
        })
    );

    @Effect()
    setSearchPattern$ = this._actions$.pipe(
        ofType<SetAppLogsSearchPattern>(EAppLogsActions.SetAppLogsSearchPattern),
        map(action => action.payload),
        switchMap((filterParams) => {
            return [
                new SetAppLogsSearchPatternSuccess(filterParams),
                new GetAppLogsGroups(true),
            ];
        })
    );

    @Effect()
    getAppLogsGroups$ = this._actions$.pipe(
        ofType<GetAppLogsGroups>(EAppLogsActions.GetAppLogsGroups),
        map(action => action.isReload),
        //map
        switchMap((isReload) => {

            this._logger.info(`GetAppLogsGroups, is reload: ${isReload}`);

            if (isReload) {
                return of([]);
            }
            else {
                return this._store.pipe(
                    select(selectAppLogsGroups),
                    take(1)
                )
            }
        }),
        withLatestFrom(this._store.select(selectAppLogsFilterParams), this._store.select(selectAppLogsSearchPattern)),
        switchMap(([existedAppLogsGroups, appLogsFilterParams, appLogsSearchPattern]) => {
            const skip = existedAppLogsGroups ? existedAppLogsGroups.length : 0;
            const take = 20;
            return this._appLogsService.getAppLogsGroups(appLogsFilterParams.isWithAdmins, appLogsSearchPattern, 'LastActivity', SortOrder.Descending, skip, take);
        }),
        switchMap((appLogsGroups) => of(new GetAppLogsGroupsSuccess(appLogsGroups)))
    );

    @Effect()
    getAppLogsByConnection$ = this._actions$.pipe(
        ofType<GetAppLogsByConnection>(EAppLogsActions.GetAppLogsByConnection),
        map(action => action.payload),       //map
    
        switchMap((connectionId) => {
        
            return this._appLogsService.getAppLogsByConnection(connectionId);
        }),
        switchMap((appLogs) => of(new GetAppLogsByConnectionSuccess(appLogs)))
    );

    constructor(
        private _appLogsService: AppLogsService,
        private _actions$: Actions,
        private _store: Store<IAppState>,
        private _logger: NGXLogger
    ) { }
}