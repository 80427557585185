import { AnalyticsActions, EAnalyticsActions } from '../actions/analytics.actions';
import { IAnalyticsState, initialAnalyticsState } from '../state/analytics.state';

export function analyticsReducers(
    state = initialAnalyticsState,
    action: AnalyticsActions
): IAnalyticsState {
    switch (action.type) {
        case EAnalyticsActions.GetGeneralAnalyticsSuccess:
            {
                return {
                    ...state,
                    generalAnalytics: action.payload
                }
            }
        case EAnalyticsActions.GetCasesAnalyticsSuccess:
            {
                return {
                    ...state,
                    casesAnalytics: action.payload
                }
            }
        case EAnalyticsActions.GetUsersAnalyticsSuccess:
            {
                return {
                    ...state,
                    usersAnalytics: action.payload
                }
            }
        default:
            {
                return state;
            }
    }
}