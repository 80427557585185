import { Component, OnDestroy, OnInit, ViewEncapsulation, Input, ChangeDetectionStrategy, Output, EventEmitter, Inject } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';

//import { Mail } from '../mail.model';
//import { MailService } from '../mail.service';
import { PlannerCase, PlannerCaseRelatedData, FileType, SceneObjectType, PlannerFile, GetPlannerFileSASQuery, PlannerSceneObjectOperationArgs as PlannerSceneObjectOperationArgs, ObjectOperationType, CaseStatus, FileResponse, PlannerSceneVisibilityGroup, MaterialQuality, OperationType, PlannerCaseShare, PlannerCaseBundle } from 'app/core/models';
import { CasesService } from '@appservices';
import { PlannerScene } from 'app/core/models/planner/plannerScene';
import { FilesService } from 'app/core/services/planner/files.sevice';
import { DownloadDialogComponent } from 'app/core/shared/dialog-download/download-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AddFileDialogComponent } from '../dialogs/add-file/add-file.component';
import { EditSceneObjectDialogComponent } from '../dialogs/edit-scene-object/edit-scene-object.component';
import { PlannerSceneObject } from 'app/core/models/planner/plannerSceneObject';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { CaseCloneDialogComponent } from '../dialogs/case-clone/case-clone.component';
import { FormGroup } from '@angular/forms';
import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { ConfirmDialogComponent } from 'app/core/shared';
import { CaseEditDialogComponent } from '../dialogs/case-edit/case-edit.component';
import { EditVisabilityGroupsComponent } from '../dialogs/edit-visability-groups/edit-visability-groups.component';
import { CaseShareDialogComponent } from '../dialogs/case-share/case-share.component';
import { EditFileDialogComponent } from '../dialogs/edit-file/edit-file.component';
import { EditFilePermissionsComponent } from '../dialogs/edit-file-permissions/edit-file-permissions.component';
import { CaseWebLinkDialogComponent } from '../dialogs/case-weblink/case-weblink.component';
import * as moment from 'moment';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { ConvertType } from 'app/core/models/planner/enums/fileEnums';
import { EditBundleComponent } from '../dialogs/edit-bundle/edit-bundle.component';


@Component({
    selector: 'cases-details',
    templateUrl: './cases-details.component.html',
    styleUrls: ['./cases-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CasesDetailsComponent implements OnInit, OnDestroy {

    //statusSelectShown: boolean;
    caseStatusEnum = CaseStatus;
    panelOpenStateObject3d = true;

    objectTypeEnum = SceneObjectType;
    operationTypeEnum = OperationType;

    fileTypeEnum = FileType;

    //selectedCaseScene: PlannerScene;
    //selectedCaseScenes: PlannerScene[];

    editSceneObjectDialogRef: any;
    addFileDialogRef: any;
    addBundleDialogRef: any;

    selectedFiles: PlannerFile[];

    labels: any[];
    showDetails: boolean;

    _selectedCase: PlannerCase;

    @Input()  
    get selectedCase(): PlannerCase {
        return this._selectedCase;
    }
    set selectedCase(value: PlannerCase) {
        this._selectedCase = value;
        this.selectedFiles = [];
    }

    @Input()
    selectedCaseShares: PlannerCaseShare[];

    @Input()
    selectedCaseBundle: PlannerCaseBundle;

    @Input()
    selectedScene: PlannerScene;

    @Input()
    set users(value: PlannerUser[]) {
        this.users$.next(value);
    }

    users$: Subject<PlannerUser[]>;

    @Input()
    selectCreatedWebLinkToken$: Observable<any>;

    @Output()
    onSceneSelected: EventEmitter<string>;

    @Output()
    onFilesUploaded: EventEmitter<any>;

    @Output()
    onFilesUploadedAndAdded: EventEmitter<PlannerSceneObject[]>;

    @Output()
    onSceneObjectEvent: EventEmitter<PlannerSceneObjectOperationArgs>;

    @Output()
    onSceneVisibilityGroupsEvent: EventEmitter<PlannerSceneVisibilityGroup[]>;

    @Output()
    onFilesPermissionsEvent: EventEmitter<any[]>;

    @Output()
    onChatClick: EventEmitter<string>;

    // @Output()
    // onCloneClick: EventEmitter<string>;

    @Output()
    onSaveScene: EventEmitter<PlannerScene>;

    @Output()
    onRollbackToScene: EventEmitter<PlannerScene>;

    @Output()
    onUsersSearch: EventEmitter<string>;

    @Output()
    onCaseEdit: EventEmitter<PlannerCase>;

    @Output()
    onCaseClone: EventEmitter<any>;

    @Output()
    onCaseSharesUpdate: EventEmitter<any>;

    @Output()
    onCreateCaseWebLink: EventEmitter<any>;

    @Output()
    onRevokeCaseWebLink: EventEmitter<any>;

    @Output()
    onCaseDelete: EventEmitter<string>;

    @Output()
    onPlannerFileEdit: EventEmitter<PlannerFile>;

    @Output()
    onPlannerFileDelete: EventEmitter<PlannerFile>;

    @Output()
    onBundleSaved: EventEmitter<any>;

    @Output()
    onNotification: EventEmitter<string>;

    // Private

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {MailService} _mailService
     */
    constructor(
        @Inject(APP_CONFIG) private config: AppConfig,
        private _downloadFileDialog: MatDialog,
        private _matDialog: MatDialog,
        //   private _casesService: CasesService,
        private _filesService: FilesService,
        private logger: NGXLogger
    ) {
        // Set the defaults
        this.showDetails = false;
        this.selectedFiles = [];

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.onSceneSelected = new EventEmitter();
        this.onFilesUploaded = new EventEmitter();
        this.onFilesUploadedAndAdded = new EventEmitter();
        this.onSceneObjectEvent = new EventEmitter();
        this.onSceneVisibilityGroupsEvent = new EventEmitter();
        this.onFilesPermissionsEvent = new EventEmitter();
        //this.onCloneClick = new EventEmitter();
        this.onChatClick = new EventEmitter();
        this.onSaveScene = new EventEmitter();
        this.onRollbackToScene = new EventEmitter();
        this.onUsersSearch = new EventEmitter();
        this.onCaseEdit = new EventEmitter();
        this.onCaseClone = new EventEmitter();
        this.onCaseSharesUpdate = new EventEmitter();
        this.onCreateCaseWebLink = new EventEmitter();
        this.onRevokeCaseWebLink = new EventEmitter();
        this.onCaseDelete = new EventEmitter();
        this.onPlannerFileEdit = new EventEmitter();
        this.onPlannerFileDelete = new EventEmitter();
        this.onBundleSaved = new EventEmitter();
        this.onNotification = new EventEmitter();

        this.users$ = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    sceneSelected(sceneId: string) {
        //this.logger.info('scene selected: ' + sceneId);

        this.onSceneSelected.emit(sceneId);
        //   this._casesService.selectScene(sceneId);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    sceneObjectShowHideClicked(sceneObject: PlannerSceneObject): void {
        this.logger.info('Scene object edit');
        sceneObject.isVisible = !sceneObject.isVisible;
        const plannerSceneObjectEditArgs = new PlannerSceneObjectOperationArgs(sceneObject, ObjectOperationType.Edit);
        this.onSceneObjectEvent.emit(plannerSceneObjectEditArgs);
    }

    sceneObjectEditClicked(sceneObject: PlannerSceneObject): void {
        this.editSceneObjectDialog(sceneObject);
    }

    sceneObjectDeleteClicked(sceneObject: PlannerSceneObject): void {
        this.logger.info('Scene object delete');
        const plannerSceneObjectAddArgs = new PlannerSceneObjectOperationArgs(sceneObject, ObjectOperationType.Delete);
        this.onSceneObjectEvent.emit(plannerSceneObjectAddArgs);
    }


    editSceneObjectDialog(sceneObject: PlannerSceneObject): void {

        this.logger.info('add scene object dialog');

        this.editSceneObjectDialogRef = this._matDialog.open(EditSceneObjectDialogComponent, {
            panelClass: 'sceneObject-edit-dialog',
            data: { selectedCase: this.selectedCase, sceneObject: sceneObject }
        });

        this.editSceneObjectDialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const sceneObject: PlannerSceneObject = response[1];

                switch (actionType) {
                    /**
                     * Create
                     */
                    case 'add':
                        this.logger.info('Scene object add');
                        const plannerSceneObjectAddArgs = new PlannerSceneObjectOperationArgs(sceneObject, ObjectOperationType.Add);
                        this.onSceneObjectEvent.emit(plannerSceneObjectAddArgs);
                        break;
                    case 'edit':
                        this.logger.info('Scene object edit');
                        const plannerSceneObjectEditArgs = new PlannerSceneObjectOperationArgs(sceneObject, ObjectOperationType.Edit);
                        this.onSceneObjectEvent.emit(plannerSceneObjectEditArgs);
                        break;
                }
            });

    }

    editVisabilityGroupsDialog(): void {

        const editVisabilityGroupsDialogRef = this._matDialog.open(EditVisabilityGroupsComponent, {
            panelClass: 'visbilityGroups-edit-dialog',
            data: { visibilityGroups: this.selectedScene.visibilityGroups, sceneObjects: this.selectedScene.sceneObjects }
        });

        editVisabilityGroupsDialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const visabilityGroups: PlannerSceneVisibilityGroup[] = response[1];

                switch (actionType) {
                    /**
                     * Create
                     */
                    case 'save':
                        this.logger.info('Scene object add');
                        //  const plannerSceneObjectAddArgs = new PlannerSceneObjectOperationArgs(sceneObject, ObjectOperationType.Add);
                        this.onSceneVisibilityGroupsEvent.emit(visabilityGroups);
                        break;
                }
            });
    }

    editFilePermissionsDialog(): void {

        const editFilePermissionsDialogRef = this._matDialog.open(EditFilePermissionsComponent, {
            panelClass: 'filePermissions-edit-dialog',
            data: { visibilityGroups: this.selectedScene.visibilityGroups, caseFiles: this.selectedCase.details.files }
        });

        editFilePermissionsDialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const filesAllowUserDownload: any[] = response[1];

                switch (actionType) {
                    /**
                     * Create
                     */
                    case 'save':
                        this.logger.info('Scene object add');
                        //  const plannerSceneObjectAddArgs = new PlannerSceneObjectOperationArgs(sceneObject, ObjectOperationType.Add);
                        this.onFilesPermissionsEvent.emit(filesAllowUserDownload);
                        break;
                }
            });
    }

    onCaseStatusChange(caseStatus: CaseStatus) {
        this.selectedScene.caseSceneStatus = caseStatus;
    }

    editCase(): void {


        const caseEditDialog = this._matDialog.open(CaseEditDialogComponent, {
            panelClass: 'case-edit-dialog',
            data: this.selectedCase
        });

        caseEditDialog.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const plannerCase: PlannerCase = response[1];


                switch (actionType) {
                    /**
                     * Create
                     */
                    case 'edit':
                        this.logger.info('Case edited, case :', plannerCase);

                        this.onCaseEdit.emit(plannerCase);

                        //this.onCaseCreateFormGroup.emit(plannerCaseFormGroup);

                        break;
                }
            });

        //this.onCaseEdit.emit(this.selectedCase);
    }

    cloneCase(): void {
        //this.onCloneClick.emit(this.selectedCase.id);

        const dialogRef = this._matDialog.open(CaseCloneDialogComponent, {
            panelClass: 'case-clone-dialog',
            data: {
                title: this.selectedCase.title
            }
        });

        //users
        const instance = dialogRef.componentInstance;

        this.users$.pipe(takeUntil(this._unsubscribeAll)).subscribe((users) => {
            instance.users = users;
        });


        instance.onSearchQueryChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((query) => {
            this.onUsersSearch.emit(query);
        });

        dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const newTitle: string = response[1];
                const selectedUser: PlannerUser = response[2];

                switch (actionType) {

                    case 'clone':
                        this.onCaseClone.emit({ selectedCase: this.selectedCase, newTitle: newTitle, selectedUser: selectedUser });
                        break;

                }
            });
    }

    shareCase(): void {
        //this.onCloneClick.emit(this.selectedCase.id);

        const dialogRef = this._matDialog.open(CaseShareDialogComponent, {
            panelClass: 'case-share-dialog',
            data: {
                title: this.selectedCase.title,
                caseShares: this.selectedCaseShares
            }
        });

        //users
        const instance = dialogRef.componentInstance;

        this.users$.pipe(takeUntil(this._unsubscribeAll)).subscribe((users) => {
            instance.users = users;
        });


        instance.onSearchQueryChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((query) => {
            this.onUsersSearch.emit(query);
        });

        dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];

                const usersToShare: PlannerUser = response[1];

                switch (actionType) {

                    case 'share':
                        this.onCaseSharesUpdate.emit({ selectedCase: this.selectedCase, usersToShare: usersToShare });
                        break;

                }
            });
    }

    createCaseWebLink(): void {
        //this.onCloneClick.emit(this.selectedCase.id);

        const dialogRef = this._matDialog.open(CaseWebLinkDialogComponent, {
            panelClass: 'case-weblink-dialog',
            data: {
                title: this.selectedCase.title,
                selectCreatedWebLinkToken$: this.selectCreatedWebLinkToken$
                //  caseShares: this.selectedCaseShares
            }
        });

        const issuedUtc = moment(moment.now());
        const expiresUtc = issuedUtc.add(1, 'years');

        this.onCreateCaseWebLink.emit({
            caseId: this.selectedCase.id, userId: this.selectedCase.ownerId,
            issuedUtc: issuedUtc,
            expiresUtc: expiresUtc
        });

        dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const webLinkToken: string = response[1];

                const url = `${this.config.visualizationEndpoint}/${webLinkToken}`;


                switch (actionType) {

                    case 'copy':
                        this.copyToClipboard(url);
                        break;
                    case 'follow':
                        window.open(url, '_blank');
                        break;
                    case 'revoke':
                        this.onRevokeCaseWebLink.emit({
                            caseId: this.selectedCase.id
                        });
                        break;
                }
            });
    }

    deleteCase(selectedCaseId: string): void {

        const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
            panelClass: 'dialog-confirm',
            data: `Are you sure you want to delete case "${this.selectedCase.title}"?`
        });

        confirmDialogRef.afterClosed()
            .subscribe(confirmResult => {

                if (!confirmResult) {
                    return;
                }

                this.onCaseDelete.emit(selectedCaseId);
            });
    }

    openChat(): void {
        this.onChatClick.emit(this.selectedCase.id);
    }

    openBundle(): void {
        
        this.logger.info('Opening add file dialog');

        this.addBundleDialogRef = this._matDialog.open(EditBundleComponent, {
            panelClass: 'edit-bundle-dialog',
            data: { sceneObjects: this.selectedScene.sceneObjects, bundle: this.selectedCaseBundle }
        });

        this.addBundleDialogRef.afterClosed()
            .subscribe(response => {

                this.logger.info('Upload file dialog was closed');

                if (!response) {
                    return;
                }
                
                const actionType: string = response[0];
                const bundleFormArray: any = response[1];

                switch (actionType) {
                    case 'save':
                        this.onBundleSaved.emit(bundleFormArray);
                        break;                   
                }
            });

    }

    saveScene(): void {
        this.onSaveScene.emit(this.selectedScene);
    }

    rollbackToScene(): void {
        this.onRollbackToScene.emit(this.selectedScene);
    }


    downloadFile(plannerFile: PlannerFile, convertType?: ConvertType): void {

        const fileId = plannerFile.id;
        const fileName = plannerFile.fileName;

        if (!convertType && plannerFile.fileType == FileType.STLMODEL)
            convertType = ConvertType.PlyToStl;

        let sub = this._filesService.downloadFile(this.selectedCase.id, fileId, fileName, convertType)
            .pipe(takeUntil(this._unsubscribeAll));

        const dialogRef = this._downloadFileDialog.open(DownloadDialogComponent, {
            width: '250px',
            data: sub
        });

        dialogRef.afterClosed().subscribe(result => {
            this.logger.info('The dialog was closed');
        });
    }

    selectFile(plannerFile: PlannerFile): void {

        const selectedFile = this.selectedFiles.find(x=>x.id == plannerFile.id);

        if(!selectedFile)
        {
            this.selectedFiles.push(plannerFile);
        }
        else{
            const selectedFileIndex = this.selectedFiles.indexOf(selectedFile);
            this.selectedFiles.splice(selectedFileIndex, 1);
        }
    }


    deleteFile(plannerFile: PlannerFile): void {

        const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
            panelClass: 'dialog-confirm',
            data: `Are you sure you want to delete file "${plannerFile.title}"?`
        });

        confirmDialogRef.afterClosed()
            .subscribe(confirmResult => {

                if (!confirmResult) {
                    return;
                }

                this.onPlannerFileDelete.emit(plannerFile);
            });

    }

    downloadFiles(files: PlannerFile[]) {
        var getFilesQuery = new Array<GetPlannerFileSASQuery>();

        files.forEach(file => {
            getFilesQuery.push({ FileId: file.id, FileName: file.fileName });
        });

        let sub = this._filesService.downloadFiles(this.selectedCase.id, getFilesQuery)
            .pipe(takeUntil(this._unsubscribeAll));

        const dialogRef = this._downloadFileDialog.open(DownloadDialogComponent, {
            width: '250px',
            data: sub
        });

        dialogRef.afterClosed().subscribe(result => {
            this.logger.info('Download files dialog was closed');
            //this.animal = result;
        });

        this.selectedFiles = [];
    }

    addFileDialog(): void {
        this.logger.info('Opening add file dialog');

        this.addFileDialogRef = this._matDialog.open(AddFileDialogComponent, {
            panelClass: 'file-add-dialog',
            data: this.selectedCase
        });

        this.addFileDialogRef.afterClosed()
            .subscribe(response => {

                this.logger.info('Upload file dialog was closed');

                if (!response) {
                    return;
                }
                const actionType: string = response[0];

                switch (actionType) {
                    case 'upload':
                        this.onFilesUploaded.emit();
                        break;
                    case 'upload&add':

                        const fileResponses: FileResponse[] = response[2];

                        const plannerSceneObjects: PlannerSceneObject[] = [];

                        fileResponses.forEach(fileResponse => {

                            const sceneObjectFromFile = new PlannerSceneObject();
                            sceneObjectFromFile.objectType = SceneObjectType.Object3d;
                            sceneObjectFromFile.isVisible = true;
                            sceneObjectFromFile.materialQuality = MaterialQuality.Good;
                            sceneObjectFromFile.objectFile = new PlannerFile();
                            sceneObjectFromFile.objectFile.id = fileResponse.fileId;
                            sceneObjectFromFile.title = fileResponse.fileName;

                            plannerSceneObjects.push(sceneObjectFromFile);
                        });

                        this.onFilesUploadedAndAdded.emit(plannerSceneObjects);
                        break;
                }
            });
    }

    editFileDialog(plannerFile: PlannerFile): void {
        this.logger.info('Opening add file dialog');

        this.addFileDialogRef = this._matDialog.open(EditFileDialogComponent, {
            panelClass: 'file-edit-dialog',
            data: { selectedCase: this.selectedCase, plannerFile }
        });

        this.addFileDialogRef.afterClosed()
            .subscribe(response => {

                this.logger.info('Edit file dialog was closed');

                if (!response) {
                    return;
                }
                const actionType: string = response[0];

                switch (actionType) {
                    case 'edit':

                        this.onPlannerFileEdit.emit(response[1]);
                        break;

                }
            });
    }


    isLastScene(): boolean {

        if (!this.selectedCase || !this.selectedCase.scenes || !this.selectedScene)
            return false;

        const scenesCount = this.selectedCase.scenes.length;

        if (scenesCount == 0)
            return false;

        return this.selectedCase.scenes[scenesCount - 1].id == this.selectedScene.id ? true : false;
    }

    copyToClipboard(val: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.onNotification.emit("Copied to clipboard!");
    }
}
