import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from 'app/core/helpers';
import { Subject } from 'rxjs';
import { EditSceneObjectDialogComponent } from '../edit-scene-object/edit-scene-object.component';
import { PlannerCaseBundle, PlannerSceneVisibilityGroup } from '@appmodels';
import { PlannerSceneObject } from 'app/core/models/planner/plannerSceneObject';
import { FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'edit-bundle',
  templateUrl: './edit-bundle.component.html',
  styleUrls: ['./edit-bundle.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class EditBundleComponent implements OnDestroy {

  bundleEditForm: FormGroup;


  get visabilityGroupsFormArray(): AbstractControl[] {
    return (this.bundleEditForm.get('bundleEdit') as FormArray).controls;
  }

  public getArrayGroup(control: AbstractControl, name: string): AbstractControl[] {
    return (control.get(name) as FormArray).controls;
  }

  errorText: string;
  //visibilityGroups: PlannerSceneVisibilityGroup[];
  sceneObjects: PlannerSceneObject[];
  bundle: PlannerCaseBundle;

  private _unsubscribeAll: Subject<any>;

  constructor(
    public matDialogRef: MatDialogRef<EditBundleComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {

    //this.visibilityGroups = this._data.visibilityGroups;
    this.sceneObjects = _.orderBy(this._data.sceneObjects as PlannerSceneObject[], ['foundationDate', obj => obj.title.toLowerCase()], ['desc', 'asc']);
    this.bundle = this._data.bundle;
    this.bundleEditForm = this.createBundleEditForm(this.bundle);//this.visibilityGroups);

    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
  }

  private createBundleEditForm(bundleObj: PlannerCaseBundle): FormGroup { //visibilityGroups: PlannerSceneVisibilityGroup[]

    const form = new FormGroup({});

    const bundleFormArray = new FormArray([]);

    let bundle: any = null;

    if (bundleObj)
      bundle = JSON.parse(bundleObj.bundle);


    this.sceneObjects.forEach(sceneObject => {

      let selectedObjectInBundle = false;

      if (bundle) {
     
        if (bundle.some(e => e.objId === sceneObject.id)) {
          selectedObjectInBundle = true;
        }
      }

      const bundleFormGroup = new FormGroup({
        objId: new FormControl(sceneObject.id),
        objTitle: new FormControl({ value: sceneObject.title, disabled: true }, Validators.required),
        objCount: new FormControl(1, Validators.required),
        objSelected: new FormControl(selectedObjectInBundle, Validators.required),
      });


      bundleFormArray.push(bundleFormGroup);

    });

    form.addControl("bundleFormGroups", bundleFormArray)

    return form;
  }

  get bundleFormArray(): AbstractControl[] {
    return (this.bundleEditForm.get('bundleFormGroups') as FormArray).controls;
  }

  public saveBundle(): void {


    const formArray = this.bundleEditForm.controls['bundleFormGroups'] as FormArray;

    const bundle = []

    formArray.controls.forEach(bundleFormGroup => {

      const objSelected = bundleFormGroup.get('objSelected').value;

      if (objSelected) {

        const objId = bundleFormGroup.get('objId').value;
        const objTitle = bundleFormGroup.get('objTitle').value;
        // const objCount = bundleFormGroup.get('id').value;

        bundle.push({ objId, objTitle });
      }
    });


    this.matDialogRef.close(['save', bundle]);
  }



  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
