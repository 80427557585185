<div id="register" fxLayout="column">

    <div id="register-form-wrapper" fxLayout="column" fxLayoutAlign="center center">


        <div id="register-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="lang-select">
                <button mat-button class="language-button" [matMenuTriggerFor]="languageMenu">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage+'.png'">
                        <span class="iso text-uppercase">{{selectedLanguage}}</span>
                    </div>
                </button>

                <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                    <button mat-menu-item *ngFor="let lang of supportedLanguages" (click)="setLanguage(lang)">
                        <span fxLayout="row" fxLayoutAlign="start center">
                            <img class="flag mr-16" [src]="'assets/icons/flags/'+lang+'.png'">
                            <span class="iso">{{lang}}</span>
                        </span>
                    </button>

                </mat-menu>
            </div>


            <div class="logo">
                <img style="width: 50px; margin-left: -25px; display: inline-block; height: 100%; vertical-align: middle;"
                    src="assets/images/logos/fuseold.svg">
                <img style="width: 128px; display: inline-block;  height: 100%; vertical-align: middle;"
                    src="assets/images/logos/logo_black.svg">
            </div>

            <div class="title">{{'REGISTRATION.CREATEACCOUNT' | translate }}</div>

            <!-- <form name="registerForm" [formGroup]="registerForm" novalidate> -->

            <mat-horizontal-stepper [linear]="true" #stepper>
                <mat-step [stepControl]="firstStepFormGroup">
                    <form [formGroup]="firstStepFormGroup" novalidate>
                        <ng-template matStepLabel>{{'REGISTRATION.FILLNAME' | translate }}</ng-template>


                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput (blur)="isEmailAvailable($event.target.value)"
                                (keydown.space)="$event.preventDefault()" formControlName="email">
                            <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                            <mat-error *ngIf="firstStepFormGroup.get('email').hasError('required')">
                                {{'REGISTRATION.EMAILREQUIRED' | translate }}
                            </mat-error>
                            <mat-error *ngIf="firstStepFormGroup.get('email').hasError('email')">
                                {{'REGISTRATION.ENTERVALIDEMAIL' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!firstStepFormGroup.get('email').hasError('email') && firstStepFormGroup.get('email').hasError('pattern')">
                                {{'REGISTRATION.EMAILUNAVAILABLE' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label> {{'REGISTRATION.FIRSTNAME' | translate }}</mat-label>
                            <input matInput formControlName="firstName">
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error>
                                {{'REGISTRATION.FIRSTNAMEREQUIRED' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label> {{'REGISTRATION.MIDDLENAME' | translate }}</mat-label>
                            <input matInput formControlName="middleName">
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label> {{'REGISTRATION.LASTNAME' | translate }}</mat-label>
                            <input matInput formControlName="lastName">
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error>
                                {{'REGISTRATION.LASTNAMEREQUIRED' | translate }}
                            </mat-error>
                        </mat-form-field>



                        <mat-form-field appearance="outline">
                            <mat-label>{{'REGISTRATION.MEDICALREFERALTYPE' | translate }}</mat-label>
                            <mat-select name="type" formControlName="medicalReferalsType">
                                <mat-option [value]="medicalReferalsEnum.Human">
                                    {{'REGISTRATION.HUMAN' | translate }}
                                </mat-option>
                                <mat-option [value]="medicalReferalsEnum.Veterinary">
                                    {{'REGISTRATION.VETERINARY' | translate }}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                {{'REGISTRATION.MEDICALREFERALTYPEREQUIRED' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline"
                            [fxShow]="firstStepFormGroup.get('medicalReferalsType').value == medicalReferalsEnum.Human">
                            <mat-label>{{'REGISTRATION.SPECIALITY' | translate }}</mat-label>
                            <mat-select name="type" formControlName="speciality">
                                <!-- <mat-option [value]="specialityEnum.NotSet">
                                    {{'REGISTRATION.NOTSET' | translate }}
                                </mat-option> -->
                                <mat-option [value]="specialityEnum.TraumatologistOrthopedist">
                                    {{'REGISTRATION.TRAUMATOLOGISTORTHOPEDIST' | translate }}
                                </mat-option>
                                <mat-option [value]="specialityEnum.MaxillofacialSurgeon">
                                    {{'REGISTRATION.MAXILLOFACIALSURGEON' | translate }}
                                </mat-option>
                                <mat-option [value]="specialityEnum.Neurosurgeon">
                                    {{'REGISTRATION.NEUROSURGEON' | translate }}
                                </mat-option>
                                <mat-option [value]="specialityEnum.Other">
                                    {{'REGISTRATION.OTHER' | translate }}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                {{'REGISTRATION.SPECIALITYREQUIRED' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <div class="multiline-error">
                            <mat-form-field appearance="outline">
                                <mat-label> {{'REGISTRATION.PASSWORD' | translate }} </mat-label>
                                <input matInput type="password" formControlName="password">
                                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                                <mat-error *ngIf="firstStepFormGroup.get('password').hasError('required')">
                                    {{'REGISTRATION.PASSWORDREQUIRED' | translate }}
                                </mat-error>
                                <mat-error style="position: relative;"
                                    *ngIf="firstStepFormGroup.get('password').hasError('pattern')">
                                    <span
                                        [innerHtml]="'REGISTRATION.PASSWORDREQUIREMENT' | translate | safe: 'html'"></span>

                                </mat-error>
                            </mat-form-field>
                        </div>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'REGISTRATION.PASSWORDCONFIRM' | translate }}</mat-label>
                            <input matInput type="password" formControlName="passwordConfirm">
                            <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                            <mat-error *ngIf="firstStepFormGroup.get('passwordConfirm').hasError('required')">
                                {{'REGISTRATION.PASSWORDCONFIRMREQUIRED' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!firstStepFormGroup.get('passwordConfirm').hasError('required') &&
                                               firstStepFormGroup.get('passwordConfirm').hasError('passwordsNotMatching')">
                                {{'REGISTRATION.PASSWORDMUSTMATCH' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <div fxLayout="row" class="register" fxLayoutAlign="center center">
                            <span class="text">
                                {{'REGISTRATION.ALREADYHAVEANACCOUNT' | translate }}
                            </span>
                            <a class="link" [routerLink]="'/downloads'">
                                {{'REGISTRATION.CLICKTODOWNLOADSHORT' | translate }}
                            </a>
                        </div>

                        <div fxLayout="row">
                            <div fxFlex fxLayoutAlign="center center">
                                <button mat-raised-button color="accent" matStepperNext class="submit-button">
                                    {{'REGISTRATION.NEXT' | translate }}
                                </button>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="secondStepFormGroup">
                    <form [formGroup]="secondStepFormGroup" novalidate>
                        <ng-template matStepLabel>
                            {{'REGISTRATION.FILLINFO' | translate }}
                        </ng-template>
                        <!-- <mat-form-field>
                          <mat-label>Address</mat-label>
                          <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                                 required>
                        </mat-form-field> -->

                        <mat-form-field appearance="outline">
                            <mat-label>{{'REGISTRATION.COUNTRY' | translate }}

                            </mat-label>
                            <input matInput formControlName="country">
                            <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                            <mat-error>
                                {{'REGISTRATION.COUNTRYREQUIRED' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>
                                {{'REGISTRATION.CITY' | translate }}
                            </mat-label>
                            <input matInput formControlName="city">
                            <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                            <mat-error>
                                {{'REGISTRATION.CITYREQUIRED' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>
                                {{'REGISTRATION.PHONE' | translate }}
                            </mat-label>
                            <input matInput formControlName="phoneNumber">
                            <mat-icon matSuffix class="secondary-text">contact_phone</mat-icon>
                            <mat-error>
                                {{'REGISTRATION.PHONEREQUIRED' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>
                                {{'REGISTRATION.COMPANY' | translate }}
                            </mat-label>
                            <input matInput formControlName="company">
                            <mat-icon matSuffix class="secondary-text">account_balance</mat-icon>
                            <mat-error>
                                {{'REGISTRATION.COMPANYREQUIRED' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <!-- <mat-form-field appearance="outline">
                            <mat-label>How did you hear about our company</mat-label>
                            <input matInput formControlName="marketingInfoes">
                            <mat-icon matSuffix class="secondary-text">info</mat-icon>
                        </mat-form-field> -->

                        <mat-form-field appearance="outline">
                            <mat-label>
                                {{'REGISTRATION.MARKETINGREFERALS' | translate }}
                            </mat-label>
                            <mat-select name="type" formControlName="referalType">
                                <mat-option [value]="marketingReferalTypeEnum.Recommendation">
                                    {{'REGISTRATION.MARKETINGREFERALSRECOMENDATION' | translate }}

                                </mat-option>
                                <mat-option [value]="marketingReferalTypeEnum.SearchQuery">
                                    {{'REGISTRATION.MARKETINGREFERALSSEARCHQUERY' | translate }}
                                </mat-option>
                                <mat-option [value]="marketingReferalTypeEnum.SocialNetwork">
                                    {{'REGISTRATION.MARKETINGREFERALSSOCIALNETWORK' | translate }}

                                </mat-option>
                            </mat-select>
                        </mat-form-field>


                        <mat-form-field appearance="outline">
                            <mat-label>
                                {{'REGISTRATION.LANGUAGE' | translate }}

                            </mat-label>
                            <mat-select (selectionChange)="formLanguageChanged($event)" name="type"
                                formControlName="language">
                                <mat-option [value]="languageEnum.enUS">

                                    {{'REGISTRATION.ENGLISH' | translate }}

                                </mat-option>
                                <mat-option [value]="languageEnum.ruRU">
                                    {{'REGISTRATION.RUSSIAN' | translate }}

                                </mat-option>
                            </mat-select>
                            <mat-error>
                                {{'REGISTRATION.LANGUAGEREQUIRED' | translate }}

                            </mat-error>
                        </mat-form-field>


                        <mat-form-field appearance="outline">
                            <input matInput formControlName="terms" [hidden]="true">
                            <mat-checkbox formControlName="terms" aria-label="I read and accept">
                                <span>
                                    {{'REGISTRATION.TERMS1' | translate }}

                                </span>
                            </mat-checkbox>
                            <a href="{{'REGISTRATION.PRIVACYPOLICY' | translate}}" target="_blank"
                                class="terms-and-conditions">&nbsp; {{'REGISTRATION.TERMS2' | translate }}
                            </a>
                            <mat-error>
                                {{'REGISTRATION.TERMSREQUIRED' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <input matInput formControlName="mailing" [hidden]="true">
                            <mat-checkbox formControlName="mailing" aria-label="I read and accept">
                                <span>
                                    {{'REGISTRATION.ACCEPTMAILILNG' | translate }}

                                </span>
                            </mat-checkbox>

                            <mat-error>
                                {{'REGISTRATION.ACCEPTMAILILNGREQUIRED' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <div fxLayout="row">
                            <div fxFlex="20">
                                <button mat-raised-button matStepperPrevious class="prev-button">
                                    {{'REGISTRATION.BACK' | translate }}
                                </button>
                            </div>
                            <div fxFlex="60" fxLayoutAlign="center center">
                                <button mat-raised-button color="accent" (click)="register()" matStepperNext
                                    class="submit-button">
                                    {{'REGISTRATION.REGISTER' | translate }}

                                </button>
                            </div>
                            <div fxFlex="20">
                                &nbsp;
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>
                        {{'REGISTRATION.DONE' | translate }}
                    </ng-template>
                    <!-- <p>You are now done.</p> -->
                    <div class="registerResult" fxLayout="column" fxLayoutAlign="center center">
                        <!-- <button mat-button matStepperPrevious>Back</button>
                        <button mat-button (click)="stepper.reset()">Reset</button> -->

                        <mat-progress-spinner mode="indeterminate" class="mat-primary" [fxShow]="registeringInProgress">
                        </mat-progress-spinner>

                        <div class="registerSuccess" [fxShow]="!registeringInProgress && isRegistrationSuccess">

                            <p>
                                <span class="mat-display-2">
                                    {{'REGISTRATION.SUCCESS' | translate }}
                                </span>
                            </p>

                            <p>

                                <span class="mat-headline">
                                    {{'REGISTRATION.CLICKTODOWNLOAD' | translate }}
                                </span>
                            </p>

                            <div fxLayout="row" fxLayoutAlign="center center" class="appDists">
                                <div fxFlex>
                                    <a href="https://updates.bonabyte.net/api/getLauncherDist/win64" target="_blank">
                                        <img src="/assets/images/misc/win.png"> </a>
                                </div>
                                <div fxFlex>
                                    <a href="https://updates.bonabyte.net/api/getLauncherDist/darwin"
                                        target="_blank"><img src="/assets/images/misc/mac.png"> </a>
                                </div>
                                <div fxFlex>
                                    <a href="https://updates.bonabyte.net/api/getLauncherDist/linuxastra"
                                        target="_blank"><img src="/assets/images/misc/linux_astra.png"> </a>
                                </div>
                            </div>
                        </div>

                        <div class="registerError" [fxShow]="!registeringInProgress && !isRegistrationSuccess">

                            <p>
                                <span class="mat-display-2">
                                    {{'REGISTRATION.ERROR' | translate }}

                                </span>
                            </p>

                            <p>

                                <span class="mat-headline">
                                    {{'REGISTRATION.TRYAGAINLATER' | translate }}
                                </span>
                            </p>

                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>



            <!-- <div class="register" fxLayout="column" [fxShow]="registerStepper.selectedIndex<2"
                fxLayoutAlign="center center">
                <span class="text">Already have an account?</span>
                <a class="link" [routerLink]="'/login'">Login</a>
            </div> -->
        </div>
    </div>

</div>