import { initialMessagesState, IMessagesState } from '../state/messages.interface';
import { MessagesActions, EMessagesActions } from '../actions/messages.actions';

export function messagesReducers(
    state = initialMessagesState,
    action: MessagesActions
): IMessagesState {
    switch (action.type) {
        case EMessagesActions.GetCaseMessagesSuccess:
            {
                const existedCaseMessages = action.isReload ? [] : (state.caseMessages ? state.caseMessages : []);

                const newMessages = action.messages.reverse().concat(existedCaseMessages);

                console.log(newMessages);

                return {
                    ...state,
                    caseMessages: newMessages
                }
            }
        case EMessagesActions.AddCaseMessageSuccess:
            {
                const existedCaseMessages = state.caseMessages ? state.caseMessages : [];
                const newCaseMessages = existedCaseMessages.concat(action.payload);
                return {
                    ...state,
                    caseMessages: newCaseMessages
                };
            }
        case EMessagesActions.NewMessageFromServerSuccess:
            {
                if (!action.isNeedToAddToCurrentCaseMessages)
                    return state;

                    const existedCaseMessages = state.caseMessages ? state.caseMessages : [];
                    const newCaseMessages = existedCaseMessages.concat(action.newMessageNotification.newMessage);

                return { 
                    ...state,
                    caseMessages: newCaseMessages
                 };
            }
        default:
            return state;
    }
}