import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { routing } from './app.routing';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { ApplyTokenInterceptor, RefreshTokenInterceptor, AngularDateHttpInterceptor } from './core/interceptors';

import { LoggerModule } from 'ngx-logger';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { PagesModule } from 'app/pages/pages.module';

import { AppConfigModule } from './app-config.module';
import { environment } from 'environments/environment';
import { StoreModule } from '@ngrx/store';
import { appReducers } from './core/store/reducers/app.reducers';
import { EffectsModule } from '@ngrx/effects';
import { CasesEffects } from './core/store/effects/cases.effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { CasesResolver, PaidModulesResolver } from './core/resolvers';
import { AppNotificationsComponent } from './xapp-notifications/app-notifications.component';
import { MatSnackBarModule } from '@angular/material';
import { MessagesEffects } from './core/store/effects/messages.effects';
import { UsersEffects as UsersEffects } from './core/store/effects/users.effects';

import { signalrReducer, SignalREffects } from 'ngrx-signalr-core';
import { AppSignalREffects } from './core/store/effects/appsignalr.effects';
import { AppConnectionUidInterceptor } from './core/interceptors/app-connection-uid.interceptor';
import { UsersResolver } from './core/resolvers/users.resolver';
import { AppLogsEffects } from './core/store/effects/appLogs.effects';
import { AppLogsResolver } from './core/resolvers/appLogs.resolver';
import { FilesEffects } from './core/store/effects/files.effects';
import { AuthEffects } from './core/store/effects/auth.effects';
import { AnalyticsEffects } from './core/store/effects/analytics.effects';
import { StorageService } from './core/services/storage/storage.service';
import { S3StorageService } from '@appservices';
import { AzureStorageService } from '@appservices';
import { PaidModulesEffects } from './core/store/effects/paidModules.effects';
import { AppNotificationsModule } from './xapp-notifications/app-notifications.module';
import { QualitySurveyCasesEffects } from './core/store/effects/qualitySurveyCases.effect';

@NgModule({
    declarations: [
        AppComponent,
        //AppNotificationsComponent,
        //  CaseStatusStyleDirective
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        // RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),

        routing,

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
       // MatSnackBarModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        LoggerModule.forRoot({
            serverLoggingUrl: '/api/logs',
            level: environment.logsLogLevel,
            serverLogLevel: environment.logsServerLogLevel,
            disableConsoleLogging: false,
            enableSourceMaps: environment.logsEnableSourceMaps
        }),

        // StoreModule.forRoot({ signalr: signalrReducer }),
        StoreModule.forRoot({ signalr: signalrReducer }),
        StoreModule.forRoot(appReducers),
        EffectsModule.forRoot([AnalyticsEffects, UsersEffects, PaidModulesEffects, AuthEffects, CasesEffects, QualitySurveyCasesEffects, FilesEffects, MessagesEffects, AppLogsEffects, SignalREffects, AppSignalREffects]),

        StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],

        // App config
        AppConfigModule,

        // App modules
        LayoutModule,
        PagesModule,
        AppNotificationsModule
    ],
    providers: [
        CasesResolver,
        UsersResolver,
        PaidModulesResolver,
        AppLogsResolver,

        { provide: HTTP_INTERCEPTORS, useClass: ApplyTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AppConnectionUidInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AngularDateHttpInterceptor, multi: true },
        { provide: StorageService, useClass: S3StorageService }
    
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
