import { MedicalReferals, Language, Speciality } from '../enums/plannerCaseEnums';
import { MarketingReferalType } from '@appmodels';

export class UserCommand {
    public managementOrganizationId: number;
    public firstName: string = null;
    public lastName: string = null;
    public middleName: string = null;
    public medicalReferalsType: MedicalReferals = null;
    public speciality : Speciality = null;
    public phoneNumber: string = null;
    public country: string = null;
    public city: string = null;
    public company: string = null;
    public language: Language = null;
}

export class RegisterUserCommand extends UserCommand {
    public email: string = null;
    public password: string = null;
    public referalType: MarketingReferalType = null;
    public referalDetails: string = null;
}

export class EditUserCommand extends UserCommand {
    public id: string = null;
    public newPassword : string = null;
    public roles: string[] = [];
}

export class DeleteUserCommand {
    public userId: string = null;
}

