import { createSelector } from '@ngrx/store';
import { IAnalyticsState } from '../state/analytics.state';
import { IAppState } from '../state/app.state';

const selectAnalytics = (state: IAppState) => state.analytics;

export const selectGeneralAnalytics = createSelector(
    selectAnalytics,
    (state: IAnalyticsState) => state.generalAnalytics
)

export const selectCasesAnalytics = createSelector(
    selectAnalytics,
    (state: IAnalyticsState) => state.casesAnalytics
)

export const selectUsersAnalytics = createSelector(
    selectAnalytics,
    (state: IAnalyticsState) => state.usersAnalytics
)