import { Action } from '@ngrx/store';
import {
    PlannerCase, PlannerScene, CreatePlannerCaseCommand, SavePlannerSceneCommand, CaseFolderType, RollbackPlannerSceneCommand, UpdatePlannerCaseCommand, SavePlannerSceneVisibilityGroupsCommand, PlannerMessage,
    CaseSortType, PlannerCaseShare, UpdatePlannerCaseSharesCommand, DeletePlannerCaseCommand, ClonePlannerCaseCommand, CaseSceneSavedNotification, PlannerModuleType, ClonePlannerCaseBatchCommand, LongRunningTaskInfo, CaseStatus,
    PlannerQualitySurveyCase
} from '@appmodels';
import { PlannerSceneObject } from 'app/core/models/planner/plannerSceneObject';
import { SavePlannerCaseSceneCommandModel } from 'app/core/models/planner/responses/savePlannerCaseSceneCommandModel';
import { SaveBundleCaseCommand } from 'app/core/models/planner/commands/saveBundleCaseCommand';


export enum ECasesActions {
    SetFiltersFromSettingsSuccess = '[Cases] Set Filters From Settings Success',
    SetModulesType = '[Cases] Set Modules Type',
    SetModulesTypeSuccess = '[Cases] Set Modules Type Success',
    SetQCFilter = '[Cases] Set QC Filter',
    SetQCFilterSuccess = '[Cases] Set QC Filter Success',
    SetCasesFolder = '[Cases] Set Cases Folder',
    SetCasesFolderSuccess = '[Cases] Set Cases Folder Success',
    SetCasesSearchPattern = '[Cases] Set Cases Search Pattern',
    SetCasesSearchPatternSuccess = '[Cases] Set Cases Search Pattern Success',
    SetCasesFilterParams = '[Cases] Set Cases Filter Params',
    SetCasesFilterParamsSuccess = '[Cases] Set Cases Filter Params Success',
    GetCases = '[Cases] Get Cases',
    GetCasesSuccess = '[Cases] Get Cases Success',
    GetCase = '[Cases] Get Case',
    GetCaseSuccess = '[Cases] Get Case Success',
    SelectCase = '[Cases] Select Case',
    SelectCaseSuccess = '[Cases] Select Case Success',
    DeselectCase = '[Cases] Deselect Case',
    DeselectCaseSuccess = '[Cases] Deselect Case Success',
    CreateCase = '[Cases] Create Case',
    CreateCaseSuccess = '[Cases] Create Case Success',
    EditCase = '[Cases] Edit Case',
    EditCaseSuccess = '[Cases] Edit Case Success',
    CloneCase = '[Cases] Clone Case',
    CloneCaseSuccess = '[Cases] Clone Case Success',

    CloneCaseBatch = '[Cases] Clone Case Batch',
    CloneCaseBatchSuccess = '[Cases] Clone Case Batch Success',

    GetSelectedCaseShares = '[Cases] Get Selected Case Shares',
    GetSelectedCaseSharesSuccess = '[Cases] Get Selected Case Shares Success',
    UpdateCaseShares = '[Cases] Update Case Shares',
    UpdateCaseSharesSuccess = '[Cases] Update Case Shares Success',

    DeleteCase = '[Cases] Delete Case',
    DeleteCaseSuccess = '[Cases] Delete Case Success',
    GetSelectedCaseScenes = '[Cases] Get Selected Case Scenes',
    GetSelectedCaseScenesSuccess = '[Cases] Get Selected Case Scenes Success',
    SelectScene = '[Cases] Select Scene',
    SelectSceneSuccess = '[Cases] Select Scene Success',
    AddSceneObjects = '[Cases] Add Scene Objects',
    //AddSceneObjectsSuccess = '[Cases] Add Scene Objects Success',
    AddSceneObjectSuccess = '[Cases] Add Scene Object Success',
    EditSceneObjectSuccess = '[Cases] Edit Scene Object Success',
    DeleteSceneObjectSuccess = '[Cases] Delete Scene Object Success',
    SaveScene = '[Cases] Save Scene',
    SaveSceneSuccess = '[Cases] Save Scene Success',
    RollbackScene = '[Cases] Rollback Scene',
    RollbackSceneSuccess = '[Cases] Rollback Scene Success',
    SaveSceneVisibilityGroups = '[Cases] SaveScene Visibility Groups',
    SaveSceneVisibilityGroupsSuccess = '[Cases] SaveScene Visibility Groups Success',

    SetCaseStatus = '[Cases] Set Case Status',
    SetCaseStatusSuccess = '[Cases] Set Case Status Success',

    SaveCaseBundle = '[Cases] Save Case Bundle',
    SaveCaseBundleSuccess = '[Cases] Save Case Bundle Success',
    GetCaseBundle = '[Cases] Get Case Bundle',
    GetCaseBundleSuccess = '[Cases] Get Case Bundle Success',
    GetCaseBundlePdf = '[Cases] Get Case Bundle Pdf',

    CaseMessagesReaded = '[Cases] Case Messages Readed',
    CaseMessagesReadedSuccess = '[Cases] Case Messages Readed Success',
    CaseReceivedNewMessageSuccess = '[Cases] Case Received New Message Success',

    CaseSceneSavedFromServer = '[Cases] Case Scene Saved From Server'
}

export class SetFiltersFromSettingsSuccess implements Action {
    public readonly type = ECasesActions.SetFiltersFromSettingsSuccess;
    constructor(public modulesTypes: PlannerModuleType[]) { };
}

export class SetModulesType implements Action {
    public readonly type = ECasesActions.SetModulesType;
    constructor(public moduleType: PlannerModuleType) { };
}

export class SetModulesTypeSuccess implements Action {
    public readonly type = ECasesActions.SetModulesTypeSuccess;
    constructor(public modulesTypes: PlannerModuleType[]) { };
}

export class SetQCFilter implements Action {
    public readonly type = ECasesActions.SetQCFilter;
}

export class SetQCFilterSuccess implements Action {
    public readonly type = ECasesActions.SetQCFilterSuccess;
    constructor(public payload: boolean) { };
}

export class SetCasesFolder implements Action {
    public readonly type = ECasesActions.SetCasesFolder;
    constructor(public folderType: CaseFolderType, public sortType?: CaseSortType) { };
}

export class SetCasesFolderSuccess implements Action {
    public readonly type = ECasesActions.SetCasesFolderSuccess;
    constructor(public folderType: CaseFolderType, public sortType: CaseSortType) { };
}

export class SetCasesSearchPattern implements Action {
    public readonly type = ECasesActions.SetCasesSearchPattern;
    constructor(public payload: string) { };
}

export class SetCasesSearchPatternSuccess implements Action {
    public readonly type = ECasesActions.SetCasesSearchPatternSuccess;
    constructor(public payload: string) { };
}

export class SetCasesFilterParams implements Action {
    public readonly type = ECasesActions.SetCasesFilterParams;
    constructor(public folder: CaseFolderType, public modulesFilter: PlannerModuleType[], public notAdmins: boolean, public onlyUncompleted: boolean, public onlyWithGuideOrImplant: boolean, public searchPattern: string) { };
}

export class SetCasesFilterParamsSuccess implements Action {
    public readonly type = ECasesActions.SetCasesFilterParamsSuccess;
    constructor(public folder: CaseFolderType, public modulesFilter: PlannerModuleType[], public notAdmins: boolean, public onlyUncompleted: boolean, public onlyWithGuideOrImplant: boolean, public searchPattern: string) { };
}

export class GetCases implements Action {
    public readonly type = ECasesActions.GetCases;
    isReload: boolean;
    constructor()
    constructor(isReload: boolean)
    constructor(isReload?: boolean) {
        if (isReload)
            this.isReload = isReload;
        else
            this.isReload = false;
    };
}

export class GetCasesSuccess implements Action {
    public readonly type = ECasesActions.GetCasesSuccess;
    constructor(public cases: PlannerCase[], public isReload: boolean) { };
}

export class GetCase implements Action {
    public readonly type = ECasesActions.GetCase;
    constructor(public payload: string) { };
}

export class GetCaseSuccess implements Action {
    public readonly type = ECasesActions.GetCaseSuccess;
    constructor(public payload: PlannerCase) { };
}

export class SelectCase implements Action {
    public readonly type = ECasesActions.SelectCase;
    constructor(public payload: string) { };
}

export class SelectCaseSuccess implements Action {
    public readonly type = ECasesActions.SelectCaseSuccess;
    constructor(public payload: PlannerCase) { };
}

export class DeselectCase implements Action {
    public readonly type = ECasesActions.DeselectCase;
    constructor() { };
}

export class DeselectCaseSuccess implements Action {
    public readonly type = ECasesActions.DeselectCaseSuccess;
    constructor() { };
}

export class CreateCase implements Action {
    public readonly type = ECasesActions.CreateCase;
    constructor(public payload: CreatePlannerCaseCommand) { };
}

export class CreateCaseSuccess implements Action {
    public readonly type = ECasesActions.CreateCaseSuccess;
    constructor(public payload: string) { };
}

export class EditCase implements Action {
    public readonly type = ECasesActions.EditCase;
    constructor(public payload: UpdatePlannerCaseCommand) { };
}

export class EditCaseSuccess implements Action {
    public readonly type = ECasesActions.EditCaseSuccess;
    constructor(public payload: string) { };
}

export class CloneCase implements Action {
    public readonly type = ECasesActions.CloneCase;
    constructor(public payload: ClonePlannerCaseCommand) { };
}

export class CloneCaseSuccess implements Action {
    public readonly type = ECasesActions.CloneCaseSuccess;
    constructor() { };
}

export class CloneCaseBatch implements Action {
    public readonly type = ECasesActions.CloneCaseBatch;
    constructor(public payload: ClonePlannerCaseBatchCommand) { };
}

export class CloneCaseBatchSuccess implements Action {
    public readonly type = ECasesActions.CloneCaseBatchSuccess;
    constructor(public payload: LongRunningTaskInfo) { };
}


export class GetSelectedCaseShares implements Action {
    public readonly type = ECasesActions.GetSelectedCaseShares;
    constructor(public payload: string) { };
}

export class GetSelectedCaseSharesSuccess implements Action {
    public readonly type = ECasesActions.GetSelectedCaseSharesSuccess;
    constructor(public payload: PlannerCaseShare[]) { };
}

export class UpdateCaseShares implements Action {
    public readonly type = ECasesActions.UpdateCaseShares;
    constructor(public payload: UpdatePlannerCaseSharesCommand) { };
}

export class UpdateCaseSharesSuccess implements Action {
    public readonly type = ECasesActions.UpdateCaseSharesSuccess;
    constructor() { };
}


export class DeleteCase implements Action {
    public readonly type = ECasesActions.DeleteCase;
    constructor(public payload: DeletePlannerCaseCommand) { };
}

export class DeleteCaseSuccess implements Action {
    public readonly type = ECasesActions.DeleteCaseSuccess;
    constructor() { };
}

export class GetSelectedCaseScenes implements Action {
    public readonly type = ECasesActions.GetSelectedCaseScenes;
    constructor(public payload: string) { };
}

export class GetSelectedCaseScenesSuccess implements Action {
    public readonly type = ECasesActions.GetSelectedCaseScenesSuccess;
    constructor(public payload: PlannerScene[]) { };
}

export class SelectScene implements Action {
    public readonly type = ECasesActions.SelectScene;
    constructor(public payload: { caseId: string, sceneId: string }) { };
}

export class SelectSceneSuccess implements Action {
    public readonly type = ECasesActions.SelectSceneSuccess;
    constructor(public payload: PlannerScene) { };
}

export class AddSceneObjects implements Action {
    public readonly type = ECasesActions.AddSceneObjects;
    constructor(public payload: PlannerSceneObject[]) { };
}

// export class AddSceneObjectsSuccess implements Action {
//     public readonly type = ECasesActions.AddSceneObjectsSuccess;
//   //  constructor(public payload: PlannerSceneObject[]) { };
// }

export class AddSceneObjectSuccess implements Action {
    public readonly type = ECasesActions.AddSceneObjectSuccess;
    constructor(public payload: PlannerSceneObject) { };
}

export class EditSceneObjectSuccess implements Action {
    public readonly type = ECasesActions.EditSceneObjectSuccess;
    constructor(public payload: PlannerSceneObject) { };
}

export class DeleteSceneObjectSuccess implements Action {
    public readonly type = ECasesActions.DeleteSceneObjectSuccess;
    constructor(public payload: PlannerSceneObject) { };
}

export class SaveScene implements Action {
    public readonly type = ECasesActions.SaveScene;
    constructor(public payload: SavePlannerSceneCommand) { };
}

export class SaveSceneSuccess implements Action {
    public readonly type = ECasesActions.SaveSceneSuccess;
    constructor(public payload: SavePlannerCaseSceneCommandModel) { };
}

export class RollbackScene implements Action {
    public readonly type = ECasesActions.RollbackScene;
    constructor(public payload: RollbackPlannerSceneCommand) { };
}

export class RollbackSceneSuccess implements Action {
    public readonly type = ECasesActions.RollbackSceneSuccess;
    constructor(public payload: string) { };
}

export class SaveSceneVisibilityGroups implements Action {
    public readonly type = ECasesActions.SaveSceneVisibilityGroups;
    constructor(public payload: SavePlannerSceneVisibilityGroupsCommand) { };
}

export class SaveSceneVisibilityGroupsSuccess implements Action {
    public readonly type = ECasesActions.SaveSceneVisibilityGroupsSuccess;
    constructor(public payload: boolean) { };
}


export class SetCaseStatus implements Action {
    public readonly type = ECasesActions.SetCaseStatus;
    constructor(public caseId: string, public status: CaseStatus, public isRemoveCaseFromList: boolean) { };
}

export class SetCaseStatusSuccess implements Action {
    public readonly type = ECasesActions.SetCaseStatusSuccess;
    constructor(public caseId: string, public status: CaseStatus, public isRemoveCaseFromList: boolean) { };
}

export class SaveCaseBundle implements Action {
    public readonly type = ECasesActions.SaveCaseBundle;
    constructor(public payload: SaveBundleCaseCommand) { };
}

export class SaveCaseBundleSuccess implements Action {
    public readonly type = ECasesActions.SaveCaseBundleSuccess;
    constructor(public payload: string) { };
}

export class GetCaseBundle implements Action {
    public readonly type = ECasesActions.GetCaseBundle;
    constructor(public payload: any) { };
}

export class GetCaseBundleSuccess implements Action {
    public readonly type = ECasesActions.GetCaseBundleSuccess;
    constructor(public payload: any) { };
}


export class GetCaseBundlePdf implements Action {
    public readonly type = ECasesActions.GetCaseBundlePdf;
    constructor(public payload: any) { };
}

export class CaseMessagesReaded implements Action {
    public readonly type = ECasesActions.CaseMessagesReaded;
    constructor() { };
}

export class CaseMessagesReadedSuccess implements Action {
    public readonly type = ECasesActions.CaseMessagesReadedSuccess;
    constructor(public payload: string) { };
}

export class CaseReceivedNewMessageSuccess implements Action {
    public readonly type = ECasesActions.CaseReceivedNewMessageSuccess;
    constructor(public caseId: string, public newMessage: PlannerMessage, public isNewMessage: boolean) { };
}

export class CaseSceneSavedFromServer implements Action {
    public readonly type = ECasesActions.CaseSceneSavedFromServer;
    constructor(public payload: CaseSceneSavedNotification) { };
}

export type CasesActions = SetFiltersFromSettingsSuccess | SetModulesType | SetModulesTypeSuccess | SetQCFilter | SetQCFilterSuccess | SetCasesFolder | SetCasesFolderSuccess | SetCasesSearchPattern | SetCasesSearchPatternSuccess | SetCasesFilterParams |
    SetCasesFilterParamsSuccess | GetCases | GetCasesSuccess | GetCase | GetCaseSuccess | SelectCase | SelectCaseSuccess | DeselectCase | DeselectCaseSuccess | CreateCase | CreateCaseSuccess | EditCase | EditCaseSuccess | CloneCase |
    CloneCaseSuccess | CloneCaseBatch | CloneCaseBatchSuccess | GetSelectedCaseShares | GetSelectedCaseSharesSuccess | UpdateCaseShares | UpdateCaseSharesSuccess | DeleteCase | DeleteCaseSuccess | GetSelectedCaseScenes | GetSelectedCaseScenesSuccess | SelectScene | SelectSceneSuccess |
    AddSceneObjects /*| AddSceneObjectsSuccess */ | AddSceneObjectSuccess | EditSceneObjectSuccess | DeleteSceneObjectSuccess | SaveScene | SaveSceneSuccess |
    RollbackScene | RollbackSceneSuccess | SaveSceneVisibilityGroups | SaveSceneVisibilityGroupsSuccess | SetCaseStatus | SetCaseStatusSuccess | SaveCaseBundle | SaveCaseBundleSuccess | GetCaseBundle | GetCaseBundleSuccess | GetCaseBundlePdf |
    CaseMessagesReaded | CaseMessagesReadedSuccess | CaseReceivedNewMessageSuccess | CaseSceneSavedFromServer;
