import { Injectable, Inject } from "@angular/core";
import { AddUserAvailablePaidModuleCommand, GenerateModuleLicenseCommand, PlannerUsersAvailableModules, PlannerUsersAvailableModulesOffline } from "@appmodels";
import { HttpClient, HttpEventType } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from "app/app-config.module";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { EditUserAvailablePaidModuleCommand } from "app/core/models/planner/commands/editUserAvailablePaidModuleCommand";
import { RemoveUserAvailablePaidModuleCommand } from "app/core/models/planner/commands/removeUserAvailablePaidModuleCommand";
import * as FileSaver from 'file-saver';

@Injectable({ providedIn: 'root' })
export class PaidModulesService {

    constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient, private logger: NGXLogger) {
    }

    public getUsersPaidModules(searchPattern: string, skip: number, take: number): Observable<PlannerUsersAvailableModules[]> {

        const url = `${this.config.apiEndpoint}/api/private/PaidServicesManagement/GetUsersAvailablePaidModules?SearchPattern=${searchPattern}&Sort=DaysAllocated&SortOrder=0&Skip=${skip}&Take=${take}`;
        this.logger.debug("HTTP GET users paid modules.", `Url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => {
                    this.logger.debug("Response users paid modules:", response);
                }),
                map(response => response));
    }

    public addUsersPaidModule(addUserAvailablePaidModuleCommand: AddUserAvailablePaidModuleCommand): Observable<string> {

        const url = `${this.config.apiEndpoint}/api/private/PaidServicesManagement/AddUserAvailablePaidModules`;
        this.logger.debug("HTTP POST add user paid module.", `New user paid module: ${addUserAvailablePaidModuleCommand},`, `url: ${url}`);
        return this.http.post<any>(url, addUserAvailablePaidModuleCommand)
            .pipe(
                tap(response => {
                    this.logger.debug("Response add user paid modules:", response);
                }),
                map(response => response));
    }

    public editUsersPaidModule(editUserAvailablePaidModuleCommand: EditUserAvailablePaidModuleCommand): Observable<string> {

        const url = `${this.config.apiEndpoint}/api/private/PaidServicesManagement/EditUserAvailablePaidModules`;
        this.logger.debug("HTTP POST edit user paid module.", `Edit user paid module: ${editUserAvailablePaidModuleCommand},`, `url: ${url}`);
        return this.http.post<any>(url, editUserAvailablePaidModuleCommand)
            .pipe(
                tap(response => {
                    this.logger.debug("Response edit user paid modules:", response);
                }),
                map(response => response));
    }

    public removeUsersPaidModule(removeUserAvailablePaidModuleCommand: RemoveUserAvailablePaidModuleCommand): Observable<string> {

        const url = `${this.config.apiEndpoint}/api/private/PaidServicesManagement/RemoveUserAvailablePaidModules`;
        this.logger.debug("HTTP POST remove user paid module.", `Remove user paid module: ${removeUserAvailablePaidModuleCommand},`, `url: ${url}`);
        return this.http.post<any>(url, removeUserAvailablePaidModuleCommand)
            .pipe(
                tap(response => {
                    this.logger.debug("Response remove user paid modules:", response);
                }),
                map(response => response));
    }



    public getUsersPaidModulesOffline(searchPattern: string, skip: number, take: number): Observable<PlannerUsersAvailableModulesOffline[]> {

        const url = `${this.config.apiEndpoint}/api/private/PaidServicesManagement/GetUsersAvailablePaidModulesOffline?SearchPattern=${searchPattern}&Sort=DaysAllocated&SortOrder=0&Skip=${skip}&Take=${take}`;
        this.logger.debug("HTTP GET users paid modules offline.", `Url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => {
                    this.logger.debug("Response users paid modules offline:", response);
                }),
                map(response => response));
    }




    public generateLicense(generateModuleLicenseCommand: GenerateModuleLicenseCommand): Observable<void> {

        const url = `${this.config.apiEndpoint}/api/private/PaidServicesManagement/GenerateModuleLicense`;
        this.logger.debug("HTTP POST generate user license.", `Generate license: ${generateModuleLicenseCommand},`, `url: ${url}`);
        return this.http.post<any>(url, generateModuleLicenseCommand)
            .pipe(
                tap(response => {
                    this.logger.debug("Generate license:", response);
                }),
                tap(response => {

                    var blob = new Blob([response.token], {type: "text/plain;charset=utf-8"});
                    FileSaver.saveAs(blob, "bonabyte-planner.license");
                }));
    }
}