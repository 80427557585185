<div class="dialog-content-wrapper" [fxHide]="!isHaveData">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

        <mat-table #table [dataSource]="$appLogs | async" [@animateStagger]="{value:'50'}" multiTemplateDataRows>

            <!-- Action type Column -->
            <ng-container matColumnDef="actionType">
                <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Action type</mat-header-cell>
                <mat-cell *matCellDef="let appLog" fxShow.gt-xs>
                    <p class="actionType text-truncate">
                        {{logActionTypeEnum[appLog.actionType]}}
                    </p>
                </mat-cell>
            </ng-container>

            <!-- Info Column -->
            <ng-container matColumnDef="info">
                <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>Info</mat-header-cell>
                <mat-cell *matCellDef="let appLog" fxHide fxShow.gt-md>
                    <p class="info text-truncate">
                        {{appLog.info}}
                    </p>
                </mat-cell>
            </ng-container>

            <!-- Additional info Column -->
            <ng-container matColumnDef="additionalInfo">
                <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-lg>Additional info</mat-header-cell>
                <mat-cell *matCellDef="let appLog" fxHide fxShow.gt-lg>
                    <p class="additionalInfo text-truncate">
                        {{appLog.additionalInfo}}
                    </p>
                </mat-cell>
            </ng-container>

            <!-- Action date Column -->
            <ng-container matColumnDef="creationDate">
                <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Action date</mat-header-cell>
                <mat-cell *matCellDef="let appLog" fxShow.gt-xs>
                    <p class="creationDate text-truncate">
                        {{appLog.creationDate | date : 'HH:mm:ss dd.MM.yyyy'}}
                    </p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="detailsInfo">

                <mat-cell *matCellDef="let appLog" fxShow.gt-xs>
                    <table>
                    <tr>
                        <td>
                            {{appLog.info}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{appLog.additionalInfo}}
                        </td>
                    </tr>
                </table>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

            <mat-row *matRowDef="let appLog; columns: displayedColumns; let i = dataIndex" class="contact" matRipple
                [@animate]="{value:'*',params:{y:'100%'}}" (click)="selectRow(i)"   [ngClass]="{'red-500': appLog.actionType == logActionTypeEnum.ExceptionUnhandled, 'red-200': appLog.actionType == logActionTypeEnum.ExceptionHandled}">
            </mat-row>

            <mat-row *matRowDef="let appLog; columns: ['detailsInfo'];  let i = dataIndex" class="contact" matRipple
                fxHide [fxShow]="selectedIndex == i" [@animate]="{value:'*',params:{y:'100%'}}">
            </mat-row>

        </mat-table>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button *ngIf="action === 'edit'" mat-button class="mr-8" (click)="matDialogRef.close(['cancel',userForm])"
            aria-label="Delete" matTooltip="Delete">
            CLOSE
        </button>

    </div>
</div>