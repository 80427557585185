import { PlannerModuleType, SceneType } from "@appmodels";

export class PlannerUsersAvailableModules {
    public id: string = null;
    public userId: string = null;
    public userEmail: string = null;
    public userFullName: string = null;
    public moduleType: PlannerModuleType = null;
    public startDate: Date = null;
    public daysAllocated: number = null;
    public remainingDays: number = null;
    public hospital: string = null;
}

export class PlannerUsersAvailableModulesOffline {
    public id: string = null;
    public requiestUid: string = null;
    public license: string = null;
    public moduleType: PlannerModuleType = null;
    public startDate: Date = null;
    public daysAllocated: number = null;
    public remainingDays: number = null;
    public hospital: string = null;
}