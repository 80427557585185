import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from 'app/core/helpers';
import { Subject } from 'rxjs';
import { EditSceneObjectDialogComponent } from '../edit-scene-object/edit-scene-object.component';
import { PlannerSceneVisibilityGroup } from '@appmodels';
import { PlannerSceneObject } from 'app/core/models/planner/plannerSceneObject';
import { FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'edit-visability-groups',
  templateUrl: './edit-visability-groups.component.html',
  styleUrls: ['./edit-visability-groups.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class EditVisabilityGroupsComponent implements OnDestroy {

  visabilityGroupsEditForm: FormGroup;


  get visabilityGroupsFormArray(): AbstractControl[] {
    return (this.visabilityGroupsEditForm.get('visibilityGroups') as FormArray).controls;
  }

  public getArrayGroup(control: AbstractControl, name: string): AbstractControl[] {
    return (control.get(name) as FormArray).controls;
  }

  errorText: string;
  visibilityGroups: PlannerSceneVisibilityGroup[];
  sceneObjects: PlannerSceneObject[];

  private _unsubscribeAll: Subject<any>;

  constructor(
    public matDialogRef: MatDialogRef<EditSceneObjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.visibilityGroups = this._data.visibilityGroups;
    this.sceneObjects = _.orderBy(this._data.sceneObjects as PlannerSceneObject[], ['foundationDate', obj => obj.title.toLowerCase()], ['desc', 'asc']);

    this.visabilityGroupsEditForm = this.createVisabilityGroupsEditForm(this.visibilityGroups);

    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
  }

  private createVisabilityGroupsEditForm(visibilityGroups: PlannerSceneVisibilityGroup[]): FormGroup {

    const form = new FormGroup({});

    const visibilityGroupsFormArray = new FormArray([]);

    visibilityGroups.forEach(visibilityGroup => {

      const visibilityGroupFormGroup = new FormGroup({
        id: new FormControl(visibilityGroup.id),
        groupName: new FormControl(visibilityGroup.groupName, Validators.required),
        orderNum: new FormControl(visibilityGroup.orderNum, Validators.required),
      });

      const sceneObjectsVisibilityFormArray = new FormArray([]);

      this.sceneObjects.forEach(sceneObject => {

        const isInGroup = visibilityGroup.sceneObjectsIds.indexOf(sceneObject.id) >= 0;

        const visibilityFormGroup = new FormGroup({
          id: new FormControl(sceneObject.id),
          sceneObjectTitle: new FormControl(sceneObject.title),
          // foundationDate: new FormControl(sceneObject.foundationDate),
          isInGroup: new FormControl(isInGroup)
        });

        sceneObjectsVisibilityFormArray.push(visibilityFormGroup);
      });

      visibilityGroupFormGroup.addControl('objectsVisibility', sceneObjectsVisibilityFormArray);

      visibilityGroupsFormArray.push(visibilityGroupFormGroup);

    });



    form.addControl("visibilityGroups", visibilityGroupsFormArray)

    return form;
  }

  public deleteVisabiltyGroup(groupIndex: number): void {

    const formArray = this.visabilityGroupsEditForm.controls['visibilityGroups'] as FormArray;

    formArray.removeAt(groupIndex);
  }

  public addVisabiltyGroup(): void {
    const formArray = this.visabilityGroupsEditForm.controls['visibilityGroups'] as FormArray;

    const visibilityGroupFormGroup = new FormGroup({
      id: new FormControl(),
      groupName: new FormControl("New group", Validators.required),
      orderNum: new FormControl(0, Validators.required),

    });

    const sceneObjectsVisibilityFormArray = new FormArray([]);

    this.sceneObjects.forEach(sceneObject => {


      const visibilityFormGroup = new FormGroup({
        id: new FormControl(sceneObject.id),
        sceneObjectTitle: new FormControl(sceneObject.title),
        isInGroup: new FormControl(false)
      });

      sceneObjectsVisibilityFormArray.push(visibilityFormGroup);
    });

    visibilityGroupFormGroup.addControl('objectsVisibility', sceneObjectsVisibilityFormArray);

    formArray.push(visibilityGroupFormGroup);
  }

  public saveVisibilityGroups(): void {

    const visabilityGroups: PlannerSceneVisibilityGroup[] = [];

    const formArray = this.visabilityGroupsEditForm.controls['visibilityGroups'] as FormArray;

    formArray.controls.forEach(visabilityGroupFormGroup => {

      const visabilityGroup = new PlannerSceneVisibilityGroup();
      visabilityGroup.id = visabilityGroupFormGroup.get('id').value;
      visabilityGroup.groupName = visabilityGroupFormGroup.get('groupName').value;
      visabilityGroup.orderNum = visabilityGroupFormGroup.get('orderNum').value;

      visabilityGroup.sceneObjectsIds = [];

      const visibilityArray = visabilityGroupFormGroup.get('objectsVisibility') as FormArray;

      visibilityArray.controls.forEach(visibilityFormGroup => {

        const isInGroup = visibilityFormGroup.get('isInGroup').value;

        if (isInGroup) {
          visabilityGroup.sceneObjectsIds.push(visibilityFormGroup.get('id').value);
        }

      });

      visabilityGroups.push(visabilityGroup);
    })

    this.matDialogRef.close(['save', visabilityGroups]);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
