import { CasesAnalytics, GeneralAnalytics, UsersAnalytics } from '@appmodels';

export interface IAnalyticsState
{
    generalAnalytics: GeneralAnalytics,
    casesAnalytics: CasesAnalytics,
    usersAnalytics: UsersAnalytics
}

export const initialAnalyticsState: IAnalyticsState =
{
    generalAnalytics: null,
    casesAnalytics: null,
    usersAnalytics: null
}