import { GenerateModuleLicenseCommand, PlannerUsersAvailableModules, PlannerUsersAvailableModulesOffline } from "@appmodels";
import { Action } from "@ngrx/store";

export enum EPaidModulesActions {
    SetPaidModulesSearchPattern = '[PaidModules] Set Paid Modules Search Pattern',
    SetPaidModulesSearchPatternSuccess = '[PaidModules] Set Paid Modules Search Pattern Success',
    GetUsersPaidModules = '[PaidModules] Get Users Paid Modules',
    GetUsersPaidModulesSuccess = '[PaidModules] Get Users Paid Modules Success',
    AddUsersPaidModules = '[PaidModules] Add Users Paid Modules',
    AddUsersPaidModulesSuccess = '[PaidModules] Add Users Paid Modules Success',
    EditUsersPaidModules = '[PaidModules] Edit Users Paid Modules',
    EditUsersPaidModulesSuccess = '[PaidModules] Edit Users Paid Modules Success',
    RemoveUsersPaidModules = '[PaidModules] Remove Users Paid Modules',
    RemoveUsersPaidModulesSuccess = '[PaidModules] Remove Users Paid Modules Success',

    SetPaidModulesOfflineSearchPattern = '[PaidModules] Set Paid Modules Offline Search Pattern',
    SetPaidModulesOfflineSearchPatternSuccess = '[PaidModules] Set Paid Modules Offline Search Pattern Success',
    GetUsersPaidModulesOffline = '[PaidModules] Get Users Paid Modules Offline',
    GetUsersPaidModulesOfflineSuccess = '[PaidModules] Get Users Paid Modules Offline Success',
    AddUsersPaidModulesOffline = '[PaidModules] Add Users Paid Offline Modules',
    AddUsersPaidModulesOfflineSuccess = '[PaidModules] Add Users Paid Modules Offline Success',
    EditUsersPaidModulesOffline = '[PaidModules] Edit Users Paid Modules Offline',
    EditUsersPaidModulesOfflineSuccess = '[PaidModules] Edit Users Paid Modules Offline Success',
    RemoveUsersPaidModulesOffline = '[PaidModules] Remove Users Paid Modules Offline',
    RemoveUsersPaidModulesOfflineSuccess = '[PaidModules] Remove Users Paid Modules Offline Success',

    GenerateLicense = '[PaidModules] Generate License',
}

export class SetPaidModulesSearchPattern implements Action {
    public readonly type = EPaidModulesActions.SetPaidModulesSearchPattern;
    constructor(public payload: string) { };
}

export class SetPaidModulesSearchPatternSuccess implements Action {
    public readonly type = EPaidModulesActions.SetPaidModulesSearchPatternSuccess;
    constructor(public payload: string) { };
}

export class GetUsersPaidModules implements Action {
    public readonly type = EPaidModulesActions.GetUsersPaidModules;
    constructor() { };
}

export class GetUsersPaidModulesSuccess implements Action {
    public readonly type = EPaidModulesActions.GetUsersPaidModulesSuccess;
    constructor(public payload: PlannerUsersAvailableModules[]) { };
}

export class AddUsersPaidModules implements Action {
    public readonly type = EPaidModulesActions.AddUsersPaidModules;
    constructor(public payload: PlannerUsersAvailableModules) { };
}

export class AddUsersPaidModulesSuccess implements Action {
    public readonly type = EPaidModulesActions.AddUsersPaidModulesSuccess;
    constructor() { };
}

export class EditUsersPaidModules implements Action {
    public readonly type = EPaidModulesActions.EditUsersPaidModules;
    constructor(public payload: PlannerUsersAvailableModules) { };
}

export class EditUsersPaidModulesSuccess implements Action {
    public readonly type = EPaidModulesActions.EditUsersPaidModulesSuccess;
    constructor() { };
}

export class RemoveUsersPaidModules implements Action {
    public readonly type = EPaidModulesActions.RemoveUsersPaidModules;
    constructor(public payload: PlannerUsersAvailableModules) { };
}

export class RemoveUsersPaidModulesSuccess implements Action {
    public readonly type = EPaidModulesActions.RemoveUsersPaidModulesSuccess;
    constructor() { };
}





export class SetPaidModulesOfflineSearchPattern implements Action {
    public readonly type = EPaidModulesActions.SetPaidModulesOfflineSearchPattern;
    constructor(public payload: string) { };
}

export class SetPaidModulesOfflineSearchPatternSuccess implements Action {
    public readonly type = EPaidModulesActions.SetPaidModulesOfflineSearchPatternSuccess;
    constructor(public payload: string) { };
}

export class GetUsersPaidModulesOffline implements Action {
    public readonly type = EPaidModulesActions.GetUsersPaidModulesOffline;
    constructor() { };
}

export class GetUsersPaidModulesOfflineSuccess implements Action {
    public readonly type = EPaidModulesActions.GetUsersPaidModulesOfflineSuccess;
    constructor(public payload: PlannerUsersAvailableModulesOffline[]) { };
}

export class AddUsersPaidModulesOffline implements Action {
    public readonly type = EPaidModulesActions.AddUsersPaidModulesOffline;
    constructor(public payload: GenerateModuleLicenseCommand) { };
}

export class AddUsersPaidModulesOfflineSuccess implements Action {
    public readonly type = EPaidModulesActions.AddUsersPaidModulesOfflineSuccess;
    constructor() { };
}

export class EditUsersPaidModulesOffline implements Action {
    public readonly type = EPaidModulesActions.EditUsersPaidModulesOffline;
    constructor(public payload: PlannerUsersAvailableModules) { };
}

export class EditUsersPaidModulesOfflineSuccess implements Action {
    public readonly type = EPaidModulesActions.EditUsersPaidModulesOfflineSuccess;
    constructor() { };
}

export class RemoveUsersPaidModulesOffline implements Action {
    public readonly type = EPaidModulesActions.RemoveUsersPaidModulesOffline;
    constructor(public payload: PlannerUsersAvailableModules) { };
}

export class RemoveUsersPaidModulesOfflineSuccess implements Action {
    public readonly type = EPaidModulesActions.RemoveUsersPaidModulesOfflineSuccess;
    constructor() { };
}

// export class GenerateLicense implements Action {
//     public readonly type = EPaidModulesActions.GenerateLicense;
//     constructor(public payload: GenerateModuleLicenseCommand) { };
// }

export type PaidModulesActions = SetPaidModulesSearchPattern | SetPaidModulesSearchPatternSuccess | GetUsersPaidModules | GetUsersPaidModulesSuccess | AddUsersPaidModules | AddUsersPaidModulesSuccess
    | EditUsersPaidModules | EditUsersPaidModulesSuccess | RemoveUsersPaidModules | RemoveUsersPaidModulesSuccess
    | SetPaidModulesOfflineSearchPattern | SetPaidModulesOfflineSearchPatternSuccess | GetUsersPaidModulesOffline | GetUsersPaidModulesOfflineSuccess | AddUsersPaidModulesOffline | AddUsersPaidModulesOfflineSuccess
    | EditUsersPaidModulesOffline | EditUsersPaidModulesOfflineSuccess | RemoveUsersPaidModulesOffline | RemoveUsersPaidModulesOfflineSuccess;//| GenerateLicense;