const ɵ0 = {
    USERS: {
        CREATEACCOUNT: 'CREATE AN ACCOUNT',
        FILLNAME: 'Fill out your name',
        EMAILREQUIRED: 'Email is required',
        ENTERVALIDEMAIL: 'Please enter a valid email address',
        EMAILUNAVAILABLE: 'Email is unavailable',
        FIRSTNAME: 'First name',
        FIRSTNAMEREQUIRED: 'First name is required',
        LASTNAME: 'Last name',
        LASTNAMEREQUIRED: 'Last name is required',
        MIDDLENAME: 'Middle name',
        MEDICALREFERALTYPE: 'Medical referal type',
        HUMAN: 'Medecine',
        VETERINARY: 'Veterinary',
        MEDICALREFERALTYPEREQUIRED: 'Medical referal type is required',
        SPECIALITY: 'Speciality',
        NOTSET: 'NOTSET',
        TRAUMATOLOGISTORTHOPEDIST: 'TraumatologistOrthopedist',
        MAXILLOFACIALSURGEON: 'MaxillofacialSurgeon',
        NEUROSURGEON: 'Neurosurgeon',
        OTHER: 'Other',
        SPECIALITYREQUIRED: 'Speciality is required',
        PASSWORD: 'Password',
        PASSWORDREQUIRED: 'Password is required',
        PASSWORDREQUIREMENT: 'Your password must be between 6 and 30 characters <br> Your password must contain at least one lower case letter <br> Your password must contain at least one upper case letter <br> Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.) <br> Your password must contain at least one special character - for example: -, $, #, @, !,%,^,&,*, (,)',
        PASSWORDCONFIRM: 'Password (Confirm)',
        PASSWORDCONFIRMREQUIRED: 'Password confirmation is required',
        PASSWORDMUSTMATCH: 'Passwords must match',
        NEXT: 'Next',
        FILLINFO: 'Fill out your info',
        COUNTRY: 'Country',
        COUNTRYREQUIRED: 'Country is required',
        CITY: 'City',
        CITYREQUIRED: 'City is required',
        PHONE: 'Phone',
        PHONEREQUIRED: 'Phone is required',
        COMPANY: 'Hospital/Company',
        COMPANYREQUIRED: 'Hospital/Company is required',
        MARKETINGREFERALS: 'How did you hear about our company',
        MARKETINGREFERALSRECOMENDATION: 'Recommendation',
        MARKETINGREFERALSSEARCHQUERY: 'Search query',
        MARKETINGREFERALSSOCIALNETWORK: 'Social network',
        LANGUAGE: 'Language',
        ENGLISH: 'English',
        RUSSIAN: 'Русский',
        LANGUAGEREQUIRED: 'Language is required',
        TERMS1: 'I read and accept',
        TERMS2: 'terms and conditions',
        TERMSREQUIRED: 'Read and accept terms and conditions is required',
        BACK: 'Back',
        REGISTER: 'Register',
        DONE: 'Done',
        SUCCESS: 'Success!',
        CLICKTODOWNLOAD: 'Click below to download the Bonabyte planner application for your OS:',
        ERROR: 'Error!',
        TRYAGAINLATER: 'Please try again later...'
    }
};
export const locale = {
    lang: 'en',
    data: ɵ0
};
export { ɵ0 };
