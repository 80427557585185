import * as tslib_1 from "tslib";
import { Effect, ofType, Actions } from '@ngrx/effects';
import { GetCurrentUserSuccess, EUsersActions, GetUsers, GetUsersSuccess, EditUserSuccess, DeleteUserSuccess, GetOnlineUsersSuccess, ResetUsersFilterSuccess, SetUsersFilterSuccess, CreateCaseWebLinkSharesSuccess, RevokeCaseWebLinkSharesSuccess } from '../actions/users.actions';
import { map, switchMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { UsersService } from '@appservices';
import { EditUserCommand, DeleteUserCommand } from 'app/core/models/planner/commands/userCommand';
import { ObjectMapper, StringUtils } from 'app/core/helpers';
import { NewNotification } from '../actions/notifications.actions';
import { selectUsersFilters } from '../selectors/users.selectors';
import { VoidAction } from '../actions/app.actions';
export class UsersEffects {
    constructor(_usersService, _actions$, _store) {
        this._usersService = _usersService;
        this._actions$ = _actions$;
        this._store = _store;
        this.getCurrentUser$ = this._actions$.pipe(ofType(EUsersActions.GetCurrentUser), switchMap(() => {
            return this._usersService.getCurrentUser();
        }), switchMap((user) => of(new GetCurrentUserSuccess(user))));
        this.setFilter$ = this._actions$.pipe(ofType(EUsersActions.SetUsersFilter), map(action => action.payload), switchMap((filterParams) => {
            return [
                new SetUsersFilterSuccess(filterParams),
                new GetUsers(),
            ];
        }));
        this.resetFilters$ = this._actions$.pipe(ofType(EUsersActions.ResetUsersFilter), map(action => action.payload), switchMap((filterParams) => {
            return [
                new ResetUsersFilterSuccess(filterParams || {}),
                (filterParams ? new GetUsers() : new VoidAction()),
            ];
        }));
        this.getUsers$ = this._actions$.pipe(ofType(EUsersActions.GetUsers), map(action => action), withLatestFrom(this._store.select(selectUsersFilters)), switchMap(([action, usersFilters]) => {
            return this._usersService.getUsers(usersFilters);
        }), switchMap((users) => of(new GetUsersSuccess(users))));
        this.exportUsers$ = this._actions$.pipe(ofType(EUsersActions.ExportUsersList), map(action => action), withLatestFrom(this._store.select(selectUsersFilters)), switchMap(([action, usersFilters]) => {
            return this._usersService.exportUsersList(usersFilters);
        }), switchMap(() => of()));
        this.getOnlineUsers$ = this._actions$.pipe(ofType(EUsersActions.GetOnlineUsers), map(action => action), switchMap(() => {
            return this._usersService.getOnlineUsers();
        }), switchMap((onlineUsers) => of(new GetOnlineUsersSuccess(onlineUsers))));
        this.editUser$ = this._actions$.pipe(ofType(EUsersActions.EditUser), 
        //map(action => action.payload),
        switchMap((editUserRequest) => {
            let editedUser = editUserRequest.editedUser;
            let userNewPassword = editUserRequest.newPassword;
            const editUserCommand = new EditUserCommand();
            ObjectMapper.mapObjectToObject(editedUser, editUserCommand);
            if (!StringUtils.isEmptyOrSpaces(userNewPassword))
                editUserCommand.newPassword = userNewPassword;
            return this._usersService.editUser(editUserCommand).pipe(map(result => editedUser));
        }), switchMap((editedUser) => {
            return [
                new EditUserSuccess(editedUser),
                new NewNotification({ type: 'UserSaved', message: 'User saved!', action: 'Ok' })
            ];
        }));
        this.deleteUser$ = this._actions$.pipe(ofType(EUsersActions.DeleteUser), map(action => action.payload), switchMap((deleteUserId) => {
            const deleteUserCommand = new DeleteUserCommand();
            deleteUserCommand.userId = deleteUserId;
            return this._usersService.deleteUser(deleteUserCommand).pipe(map(result => deleteUserId));
        }), switchMap((deleteUserId) => {
            return [
                new DeleteUserSuccess(deleteUserId),
                new NewNotification({ type: 'UserDeleted', message: 'User deleted!', action: 'Ok' })
            ];
        }), catchError(err => {
            new NewNotification({ type: 'UserDeleted', message: 'User delete error!', action: 'Error' });
            return throwError(err);
        }));
        this.createCaseWebLinkShares$ = this._actions$.pipe(ofType(EUsersActions.CreateCaseWebLinkShares), map(action => action.payload), 
        // withLatestFrom(this._store.select(selectUsersFilters)),
        switchMap((createWebLinkTokenCommand) => {
            return this._usersService.createWebLinkToken(createWebLinkTokenCommand);
        }), switchMap((webLinkToken) => of(new CreateCaseWebLinkSharesSuccess(webLinkToken))));
        this.revokeCaseWebLinkShares$ = this._actions$.pipe(ofType(EUsersActions.RevokeCaseWebLinkShares), map(action => action.payload), 
        // withLatestFrom(this._store.select(selectUsersFilters)),
        switchMap((revokeWebLinkTokenCommand) => {
            return this._usersService.revokeWebLinkToken(revokeWebLinkTokenCommand).pipe(map(res => { return { caseId: revokeWebLinkTokenCommand.caseId, count: res }; }));
        }), switchMap((result) => {
            return [
                new NewNotification({ type: 'RevokeCaseWebLinkShare', message: `Revoked ${result.count} tokens!`, action: 'Ok' }),
                new RevokeCaseWebLinkSharesSuccess(result.caseId)
            ];
        }));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], UsersEffects.prototype, "getCurrentUser$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], UsersEffects.prototype, "setFilter$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], UsersEffects.prototype, "resetFilters$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], UsersEffects.prototype, "getUsers$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], UsersEffects.prototype, "exportUsers$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], UsersEffects.prototype, "getOnlineUsers$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], UsersEffects.prototype, "editUser$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], UsersEffects.prototype, "deleteUser$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], UsersEffects.prototype, "createCaseWebLinkShares$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], UsersEffects.prototype, "revokeCaseWebLinkShares$", void 0);
