import { OnDestroy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_DATE_FORMATS, DateValidator, ObjectMapper } from 'app/core/helpers';
import { OperationType, SceneType, Gender, MedicalReferals, PlannerCase, PlannerCaseRelatedData, ICaseSerializedDataObject, ICaseOrder } from '@appmodels';
import { Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
const ɵ0 = APP_DATE_FORMATS;
export class CaseEditDialogComponent {
    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
    // private _casesService: CasesService,
    matDialogRef, _data, _cdr, logger) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._cdr = _cdr;
        this.logger = logger;
        this.medicalReferalsEnum = MedicalReferals;
        this.operationTypeEnum = OperationType;
        this.sceneTypeEnum = SceneType;
        this.genderEnum = Gender;
        this.medicalReferalsTypeEnum = MedicalReferals;
        this.validateUser = (control) => {
            if (!control || !control.parent) {
                return null;
            }
            const userEmail = control.parent.get('owner').value;
            let isValid = false;
            if (!this.foundUsers || this.foundUsers.length == 0) {
                isValid = false;
            }
            else {
                const foundUsersIndex = this.foundUsers.findIndex(user => user.email == userEmail);
                if (foundUsersIndex >= 0)
                    isValid = true;
            }
            return isValid ? null : { wrongUserEmail: true };
        };
        // Set the defaults
        this.caseEditForm = this.createCaseEditForm(_data);
        this.showExtraToFields = false;
        this._unsubscribeAll = new Subject();
        this.onSearchQueryChanged = new EventEmitter();
    }
    set users(value) {
        this.foundUsers = value;
        if (!this._cdr['destroyed']) {
            this._cdr.detectChanges();
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    createCaseEditForm(caseToEdit) {
        var caseEditForm = new FormGroup({
            // owner: new FormControl({
            //     value: 'admin@admin.ru',
            //     disabled: false
            // }),
            owner: new FormControl('', [Validators.required, this.validateUser]),
            title: new FormControl('', Validators.required),
            medicalReferalsType: new FormControl(1, Validators.required),
            operationType: new FormControl(1, Validators.required),
            sceneType: new FormControl(1, Validators.required),
            planning: new FormControl(true, Validators.required),
            anatomicalModel: new FormControl(true, Validators.required),
            guide: new FormControl(true, Validators.required),
            titan: new FormControl(true, Validators.required),
            peek: new FormControl(true, Validators.required),
            surgicalDate: new FormControl(new Date(), [Validators.required, DateValidator.dateVaidator]),
            description: new FormControl(''),
            patientName: new FormControl('Unknown'),
            patientCode: new FormControl(''),
            isAllowAll: new FormControl(false),
            isArchived: new FormControl(false),
            isExcludeFromAnalytics: new FormControl(false),
            gender: new FormControl(''),
            service: new FormControl(''),
            procedures: new FormControl(''),
            birthday: new FormControl(null, DateValidator.dateVaidator),
            surgicalSide: new FormControl(''),
            scanCenter: new FormControl(''),
            shipment: new FormControl(''),
        });
        if (caseToEdit) {
            this.editedCase = caseToEdit;
            this.isEditingCase = true;
            ObjectMapper.mapObjectToFormGroup(caseToEdit, caseEditForm);
            ObjectMapper.mapObjectToFormGroup(caseToEdit.details, caseEditForm);
            let serializedDataObject = JSON.parse(caseToEdit.details.serializedData);
            if (!serializedDataObject || !serializedDataObject.order) {
                serializedDataObject = {};
                serializedDataObject.order = { planning: false, anatomicalModel: false, guide: false, titan: false, peek: false };
            }
            caseEditForm.get('planning').setValue(serializedDataObject.order.planning);
            caseEditForm.get('anatomicalModel').setValue(serializedDataObject.order.anatomicalModel);
            caseEditForm.get('guide').setValue(serializedDataObject.order.guide);
            caseEditForm.get('titan').setValue(serializedDataObject.order.titan);
            caseEditForm.get('peek').setValue(serializedDataObject.order.peek ? serializedDataObject.order.peek : false);
            caseEditForm.get('owner').setValue(this.editedCase.ownerName);
            caseEditForm.get('owner').disable();
        }
        return caseEditForm;
    }
    /**
     * Toggle extra to fields
     */
    toggleExtraToFields() {
        this.showExtraToFields = !this.showExtraToFields;
    }
    createCase() {
        if (this.caseEditForm.invalid)
            return;
        const newCase = new PlannerCase();
        this.fillCaseFields(newCase);
        const userEmail = this.caseEditForm.get('owner').value;
        const selectedUser = this.foundUsers.find(user => user.email == userEmail);
        newCase.ownerId = selectedUser.id;
        this.matDialogRef.close(['create', newCase]);
    }
    editCase() {
        if (this.caseEditForm.invalid)
            return;
        const editedCase = new PlannerCase();
        this.fillCaseFields(editedCase);
        editedCase.id = this.editedCase.id;
        this.matDialogRef.close(['edit', editedCase]);
    }
    fillCaseFields(editedCase) {
        ObjectMapper.mapFormGroupToObject(this.caseEditForm, editedCase);
        editedCase.details = new PlannerCaseRelatedData();
        ////////// serialized data //////////
        const planning = this.caseEditForm.get('planning').value;
        const anatomicalModel = this.caseEditForm.get('anatomicalModel').value;
        const guide = this.caseEditForm.get('guide').value;
        const titan = this.caseEditForm.get('titan').value;
        const peek = this.caseEditForm.get('peek').value;
        editedCase.details.serializedData = JSON.stringify({ order: { planning, anatomicalModel, guide, titan, peek } });
        /////////////////////////////////////
        ObjectMapper.mapFormGroupToObject(this.caseEditForm, editedCase.details);
    }
    onSearchQuery(searchQuery) {
        if (!searchQuery || searchQuery.length < 3)
            return;
        this.onSearchQueryChanged.emit(searchQuery);
    }
    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
export { ɵ0 };
