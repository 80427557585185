import { ResetPasswordStatus } from '@appmodels';

export interface IAuthState
{
    currentManagementOrganizationId: number;
    resetPasswordStatus: ResetPasswordStatus;   
}

export const initialAuthState: IAuthState =
{
    currentManagementOrganizationId: null,
    resetPasswordStatus: ResetPasswordStatus.Default,
}