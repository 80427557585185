<div *ngIf="!selectedCase" fxLayout="column" fxLayoutAlign="center center" fxFlex>
    <mat-icon class="s-128 mb-16 select-message-icon hint-text"
        [@animate]="{value:'*',params:{delay:'300ms',scale:'0.2'}}">
        email
    </mat-icon>
    <span class="select-message-text hint-text" [@animate]="{value:'*',params:{delay:'400ms'}}">
        <span>{{ 'MAIL.SELECT_A_MESSAGE_TO_READ' | translate }}</span>
    </span>
</div>

<div *ngIf="selectedCase" fxLayout="column" fxFlex>

    <div class="case-header">

        <div fxFlex="1 1 auto">

            <div fxLayout="row">

                <div fxLayout="column">

                    <div class="subject">{{selectedCase.title}} [
                        <case-status-translate [caseStatus]="selectedCase.status">
                        </case-status-translate>
                        ]
                    </div>

                    <!-- <div class="labels mt-8" fxLayout="row wrap">
                <div class="label" *ngFor="let labelId of case.labels" fxLayout="row" fxLayoutAlign="start center">
                    <div class="label-color" [ngStyle]="{'background-color': labels | getById:labelId:'color'}"></div>
                    <div class="label-title">{{labels | getById:labelId:'title'}}</div>
                </div>
            </div> -->

                    <a class="toggle-details" (click)="showDetails = !showDetails">
                        <span *ngIf="!showDetails">Show Details</span>
                        <span *ngIf="showDetails">Hide Details</span>
                    </a>

                </div>

                <div class="additional-buttons" fxLayout="column" fxFlex="2 2 auto">
                    <div fx-layout="row">
                        <div fxFlex>
                            <div>
                                <button mat-raised-button class="mat-accent" (click)="openChat()"
                                    aria-label="Open chat">
                                    CHAT
                                </button>
                            </div>
                            <div>
                                <button mat-raised-button class="" (click)="openBundle()" aria-label="Open bundle">
                                    BUNDLE
                                </button>
                            </div>
                        </div>

                        <div fxFlex="100px" fxLayoutAlign="end">
                            <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More"
                                (click)="$event.stopPropagation()">
                                <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #moreMenu="matMenu">

                                <button mat-menu-item aria-label="Edit" (click)="editCase()">
                                    <mat-icon>edit</mat-icon>
                                    <span>EDIT</span>
                                </button>

                                <button mat-menu-item aria-label="Clone" (click)="cloneCase()">
                                    <mat-icon>file_copy</mat-icon>
                                    <span>CLONE</span>
                                </button>

                                <button mat-menu-item aria-label="Share" (click)="shareCase()">
                                    <mat-icon>person_add</mat-icon>
                                    <span>SHARE</span>
                                </button>

                                <button mat-menu-item aria-label="Web link" (click)="createCaseWebLink()">
                                    <mat-icon>link</mat-icon>
                                    <span>WEB LINK</span>
                                </button>

                                <button mat-menu-item aria-label="Delete" (click)="deleteCase(selectedCase.id)">
                                    <mat-icon>delete</mat-icon>
                                    <span>DELETE</span>
                                </button>

                            </mat-menu>
                        </div>
                    </div>
                </div>

            </div>
            <div fxLayout="row">
                <div *ngIf="showDetails" class="details">
                    <!-- <div fxFlex> -->
                    <div fxFlex="1 1 auto" fxLayout="row">

                        <div fxLayout="column" class="details-column">
                            <span class="title">Id:</span>
                            <span class="title">Owner:</span>
                            <span class="title">Creation date:</span>
                            <span class="title" *ngIf="selectedCase.details.description">Desciption:</span>
                        </div>

                        <div fxLayout="column" class="details-column">
                            <span class="detail hand"
                                (click)="copyToClipboard(selectedCase.id)">{{selectedCase.id}}</span>
                            <span class="detail">{{selectedCase.ownerName}}</span>
                            <span class="detail">

                                {{selectedCase.creationDate | date}}

                            </span>
                            <span class="detail"
                                *ngIf="selectedCase.details.description">{{selectedCase.details.description}}</span>
                        </div>

                    </div>
                    <div fxFlex="1 1 auto" fxLayout="row">
                        <div fxLayout="column">
                            <span class="title">Operation type:</span>
                            <span class="title">Operation date:</span>
                            <span class="title">Patient:</span>
                        </div>

                        <div fxLayout="column">
                            <span class="detail">

                                <span [ngSwitch]="selectedCase.operationType">
                                    <span *ngSwitchCase="operationTypeEnum.General">
                                        General
                                    </span>

                                    <span *ngSwitchCase="operationTypeEnum.Knee">
                                        Knee
                                    </span>

                                    <span *ngSwitchCase="operationTypeEnum.Spine">
                                        Spine
                                    </span>

                                    <span *ngSwitchCase="operationTypeEnum.DeformationCorrection">
                                        DeformationCorrection
                                    </span>

                                    <span *ngSwitchCase="operationTypeEnum.Arthrodesis">
                                        Arthrodesis
                                    </span>

                                    <span *ngSwitchCase="operationTypeEnum.GuidesForTransped">
                                        GuidesForTransped
                                    </span>

                                    <span *ngSwitchCase="operationTypeEnum.THR">
                                        THR
                                    </span>

                                </span>

                            </span>
                            <span class="detail">{{selectedCase.surgicalDate | date}}</span>
                            <span class="detail">{{selectedCase.details.patientName}}</span>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->

    <div class="case-content">

        <!-- <scenes-items [caseScenes]="selectedScenes">  </scenes-items> -->
        <scenes-timeline [plannerSceneElements]="selectedCase.scenes" (sceneSelected)="sceneSelected($event)">
        </scenes-timeline>

        <!-- <ng-container *ngIf="selectedScene">
            <div *ngFor="let sceneObject of selectedScene.sceneObjects">1</div>
        </ng-container> -->

        <div class="scene-objects-accordion pb-16" *ngIf="selectedScene && selectedScene.sceneObjects?.length > 0">

            <mat-accordion>
                <mat-expansion-panel [expanded]="panelOpenStateObject3d" (opened)="panelOpenStateObject3d = true"
                    (closed)="panelOpenStateObject3d = false"
                    *ngIf="selectedScene && (selectedScene.sceneObjects | sceneObjectFilter:objectTypeEnum.Object3d ).length>0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            3d Objects
                        </mat-panel-title>
                        <mat-panel-description>
                            &nbsp;
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="cards" fxFlex fxLayout="row wrap" fxLayoutGap="10px grid" fxLayoutAlign="start start">
                        <div class="card"
                            *ngFor="let sceneObject of selectedScene.sceneObjects | sceneObjectFilter:objectTypeEnum.Object3d | orderByExt: ['foundationDate', 'title'] : ['desc', 'asc'] : ['', 'toLower']">
                            <scene-object-item (onShowHideClicked)="sceneObjectShowHideClicked($event)"
                                (onEditClicked)="sceneObjectEditClicked($event)"
                                (onDeleteClicked)="sceneObjectDeleteClicked($event)" [sceneObject]="sceneObject">
                            </scene-object-item>
                        </div>
                    </div>


                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenStateShape = true" (closed)="panelOpenStateShape = false"
                    *ngIf="selectedScene && (selectedScene.sceneObjects | sceneObjectFilter:objectTypeEnum.Shape ).length>0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Shapes
                        </mat-panel-title>
                        <mat-panel-description>
                            &nbsp;
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="cards" fxFlex fxLayout="row wrap" fxLayoutGap="10px grid" fxLayoutAlign="start start">
                        <div class="card"
                            *ngFor="let sceneObject of selectedScene.sceneObjects | sceneObjectFilter:objectTypeEnum.Shape | orderByExt: ['foundationDate', 'title'] : ['desc', 'asc'] : ['', 'toLower']">
                            <scene-object-item (onShowHideClicked)="sceneObjectShowHideClicked($event)"
                                (onEditClicked)="sceneObjectEditClicked($event)"
                                (onDeleteClicked)="sceneObjectDeleteClicked($event)" [sceneObject]="sceneObject">
                            </scene-object-item>
                        </div>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel (opened)="panelOpenStateLabel = true" (closed)="panelOpenStateLabel = false"
                    *ngIf="selectedScene && (selectedScene.sceneObjects | sceneObjectFilter:objectTypeEnum.Label ).length>0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Labels
                        </mat-panel-title>
                        <mat-panel-description>
                            &nbsp;
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="cards" fxFlex fxLayout="row wrap" fxLayoutGap="10px grid" fxLayoutAlign="start start">
                        <div class="card"
                            *ngFor="let sceneObject of selectedScene.sceneObjects | sceneObjectFilter:objectTypeEnum.Label | orderByExt: ['foundationDate', 'title'] : ['desc', 'asc'] : ['', 'toLower']">
                            <scene-object-item (onShowHideClicked)="sceneObjectShowHideClicked($event)"
                                (onEditClicked)="sceneObjectEditClicked($event)"
                                (onDeleteClicked)="sceneObjectDeleteClicked($event)" [sceneObject]="sceneObject">
                            </scene-object-item>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenStateDrawing = true" (closed)="panelOpenStateDrawing = false"
                    *ngIf="selectedScene && (selectedScene.sceneObjects | sceneObjectFilter:objectTypeEnum.Drawing ).length>0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Drawings
                        </mat-panel-title>
                        <mat-panel-description>
                            &nbsp;
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="cards" fxFlex fxLayout="row wrap" fxLayoutGap="10px grid" fxLayoutAlign="start start">
                        <div class="card"
                            *ngFor="let sceneObject of selectedScene.sceneObjects | sceneObjectFilter:objectTypeEnum.Drawing | orderByExt: ['foundationDate', 'title'] : ['desc', 'asc'] : ['', 'toLower']">
                            <scene-object-item (onShowHideClicked)="sceneObjectShowHideClicked($event)"
                                (onEditClicked)="sceneObjectEditClicked($event)"
                                (onDeleteClicked)="sceneObjectDeleteClicked($event)" [sceneObject]="sceneObject">
                            </scene-object-item>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenStateDimension = true"
                    (closed)="panelOpenStateDimension = false"
                    *ngIf="selectedScene && (selectedScene.sceneObjects | sceneObjectFilter:objectTypeEnum.Dimension ).length>0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dimensions
                        </mat-panel-title>
                        <mat-panel-description>
                            &nbsp;
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="cards" fxFlex fxLayout="row wrap" fxLayoutGap="10px grid" fxLayoutAlign="start start">
                        <div class="card"
                            *ngFor="let sceneObject of selectedScene.sceneObjects | sceneObjectFilter:objectTypeEnum.Dimension | orderByExt: ['foundationDate', 'title'] : ['desc', 'asc'] : ['', 'toLower']">
                            <scene-object-item (onShowHideClicked)="sceneObjectShowHideClicked($event)"
                                (onEditClicked)="sceneObjectEditClicked($event)"
                                (onDeleteClicked)="sceneObjectDeleteClicked($event)" [sceneObject]="sceneObject">
                            </scene-object-item>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenStateDICOMImages = true"
                    (closed)="panelOpenStateDICOMImages = false"
                    *ngIf="selectedScene && (selectedScene.sceneObjects | sceneObjectFilter:objectTypeEnum.DICOMImages ).length>0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            DICOMImages
                        </mat-panel-title>
                        <mat-panel-description>
                            &nbsp;
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="cards" fxFlex fxLayout="row wrap" fxLayoutGap="10px grid" fxLayoutAlign="start start">
                        <div class="card"
                            *ngFor="let sceneObject of selectedScene.sceneObjects | sceneObjectFilter:objectTypeEnum.DICOMImages | orderByExt: ['foundationDate', 'title'] : ['desc', 'asc'] : ['', 'toLower']">
                            <scene-object-item (onShowHideClicked)="sceneObjectShowHideClicked($event)"
                                (onEditClicked)="sceneObjectEditClicked($event)"
                                (onDeleteClicked)="sceneObjectDeleteClicked($event)" [sceneObject]="sceneObject">
                            </scene-object-item>
                        </div>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel (opened)="panelOpenStateAttach = true" (closed)="panelOpenStateAttach = false"
                    *ngIf="selectedScene && (selectedScene.sceneObjects | sceneObjectFilter:objectTypeEnum.Attach ).length>0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Attachments
                        </mat-panel-title>
                        <mat-panel-description>
                            &nbsp;
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="cards" fxFlex fxLayout="row wrap" fxLayoutGap="10px grid" fxLayoutAlign="start start">
                        <div class="card"
                            *ngFor="let sceneObject of selectedScene.sceneObjects | sceneObjectFilter:objectTypeEnum.Attach">
                            <scene-object-item (onEditClicked)="sceneObjectEditClicked($event)"
                                (onDeleteClicked)="sceneObjectDeleteClicked($event)" [sceneObject]="sceneObject">
                            </scene-object-item>
                        </div>
                    </div>

                </mat-expansion-panel>

            </mat-accordion>

        </div>

        <div class="scene-manage">

            <div class="pt-8" fxLayout="row" flexGrow="1" fxLayoutAlign="stretch" fxLayoutGap="10px grid"
                *ngIf="isLastScene()">

                <div fxLayout="column" class="w-25-p">
                    <button mat-raised-button (click)="editSceneObjectDialog()" aria-label="Add scene object">
                        ADD SCENE OBJECT
                    </button>
                </div>
                <div fxLayout="column" class="w-25-p">
                    <button mat-raised-button (click)="editVisabilityGroupsDialog()" aria-label="Visability groups">
                        VISABILITY GROUPS
                    </button>
                </div>
                <div fxLayout="column" class="w-25-p">

                    <button mat-raised-button>
                        <mat-select [value]="selectedScene.caseSceneStatus"
                            (selectionChange)="onCaseStatusChange($event.value)">
                            <mat-option [value]="caseStatusEnum.New">
                                <case-status-translate [caseStatus]="caseStatusEnum.New">
                                </case-status-translate>
                            </mat-option>

                            <mat-option [value]="caseStatusEnum.DicomUploading">
                                <case-status-translate [caseStatus]="caseStatusEnum.DicomUploading">
                                </case-status-translate>
                            </mat-option>

                            <mat-option [value]="caseStatusEnum.Segmentation">
                                <case-status-translate [caseStatus]="caseStatusEnum.Segmentation">
                                </case-status-translate>
                            </mat-option>

                            <mat-option [value]="caseStatusEnum.ModelsReady">
                                <case-status-translate [caseStatus]="caseStatusEnum.ModelsReady">
                                </case-status-translate>
                            </mat-option>

                            <mat-option [value]="caseStatusEnum.OperationPlanningSelf">
                                <case-status-translate [caseStatus]="caseStatusEnum.OperationPlanningSelf">
                                </case-status-translate>
                            </mat-option>


                            <mat-option [value]="caseStatusEnum.OperationPlanningCollaboration">
                                <case-status-translate [caseStatus]="caseStatusEnum.OperationPlanningCollaboration">
                                </case-status-translate>
                            </mat-option>
                            <mat-option [value]="caseStatusEnum.ImplantsDesigning">
                                <case-status-translate [caseStatus]="caseStatusEnum.ImplantsDesigning">
                                </case-status-translate>
                            </mat-option>
                            <mat-option [value]="caseStatusEnum.ImplantsDesignApproval">
                                <case-status-translate [caseStatus]="caseStatusEnum.ImplantsDesignApproval">
                                </case-status-translate>
                            </mat-option>
                            <mat-option [value]="caseStatusEnum.GuidesDesigning">
                                <case-status-translate [caseStatus]="caseStatusEnum.GuidesDesigning">
                                </case-status-translate>
                            </mat-option>
                            <mat-option [value]="caseStatusEnum.GuidesDesignApproval">
                                <case-status-translate [caseStatus]="caseStatusEnum.GuidesDesignApproval">
                                </case-status-translate>
                            </mat-option>
                            <mat-option [value]="caseStatusEnum.Manufacturing">
                                <case-status-translate [caseStatus]="caseStatusEnum.Manufacturing">
                                </case-status-translate>
                            </mat-option>
                            <mat-option [value]="caseStatusEnum.Shipping">
                                <case-status-translate [caseStatus]="caseStatusEnum.Shipping">
                                </case-status-translate>
                            </mat-option>



                            <mat-option [value]="caseStatusEnum.Done">
                                <case-status-translate [caseStatus]="caseStatusEnum.Done">
                                </case-status-translate>
                            </mat-option>

                            <mat-option [value]="caseStatusEnum.Canceled">
                                <case-status-translate [caseStatus]="caseStatusEnum.Canceled">
                                </case-status-translate>
                            </mat-option>

                            <mat-option [value]="caseStatusEnum.Deleted">
                                <case-status-translate [caseStatus]="caseStatusEnum.Deleted">
                                </case-status-translate>
                            </mat-option>

                        </mat-select>

                    </button>
                </div>
                <div fxLayout="column" class="w-25-p">
                    <button mat-raised-button class="mat-accent" (click)="saveScene()" aria-label="Save scene">
                        SAVE SCENE
                    </button>
                </div>

            </div>


            <div class="pt-8" fxLayout="row" flexGrow="1" fxLayoutAlign="stretch" fxLayoutGap="10px grid"
                *ngIf="!isLastScene()">

                <div fxLayout="column" class="w-50-p">

                </div>
                <div fxLayout="column" class="w-25-p">


                </div>
                <div fxLayout="column" class="w-25-p">
                    <button mat-raised-button class="mat-accent" (click)="rollbackToScene()" aria-label="Save scene">
                        ROLLBACK TO SCENE
                    </button>
                </div>

            </div>


        </div>
    </div>

    <div *ngIf="selectedCase?.details?.files" class="mail-attachments">

        <div class="attachments-header">

            <div fxFlex="1 1 auto">

                <div fxLayout="row">

                    <div fxLayout="column">

                        <div class="subject"><span>Attachments</span> ({{selectedCase.details.files.length}})
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="attachments-actions">

            <div class="pt-8" fxLayout="row" flexGrow="1" fxLayoutAlign="stretch" fxLayoutGap="10px grid">

                <div fxLayout="column" class="w-25-p">
                    <button mat-raised-button [fxShow]="selectedCase.details.files.length > 0"
                        (click)="downloadFiles(selectedCase.details.files)" aria-label="Download all files">
                        DOWNLOAD ALL
                    </button>
                </div>
                <div fxLayout="column" class="w-25-p" [fxShow]="selectedCase.details.files.length > 0 && selectedFiles.length > 0">
                    <button mat-raised-button 
                        (click)="downloadFiles(selectedFiles)" aria-label="Download selected files">
                        DOWNLOAD ({{selectedFiles.length}})
                    </button>
                </div>
                <div fxLayout="column" class="w-25-p">
                    <button mat-raised-button [fxShow]="selectedCase.details.files.length > 0"
                        (click)="editFilePermissionsDialog()" aria-label="Visability groups">
                        FILE PERMISSIONS
                    </button>
                </div>
            </div>

        </div>

        <div class="attachment-list" fxLayout="row wrap">

            <div fxLayout="column"
                *ngFor="let file of selectedCase.details.files | orderBy: ['-fileType', '-creationDate']">

                <!-- <img class="preview" src="{{file.preview}}"> -->
                <case-file-item [file]="file" (onDownloadClicked)="downloadFile($event)"
                    (onEditClicked)="editFileDialog(file)" (onSelectClicked)="selectFile($event)" (onDeleteClicked)="deleteFile($event)" (onDownloadAsPlyClicked)="downloadFile($event, fileTypeEnum.STLMODEL)"></case-file-item>

            </div>
            <div class="add-btn">
                <div class="add-icon">
                    <a href="#" title="add file" onclick="event.preventDefault()" (click)="addFileDialog(null)">
                        <mat-icon>note_add</mat-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>


</div>