<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">User file permissions</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>


        <form name="composeForm" [formGroup]="editFilePermissionsForm" class="filePermissions-edit-form"
            fxLayout="column" fxFlex>

                    <div class="permission-title">User can export</div>
                    
                    <div  class="files-select">

                        <div *ngFor="let editFilePermissionControl of getArrayGroup(editFilePermissionsForm, 'editFilePermissions'); index as ii"
                            formArrayName="editFilePermissions">

                            <div [formGroupName]="ii" class="filePermissions" [tooltip]="editFilePermissionControl.get('sceneObjectTitle').value" [options]="{maxWidth: 450, placement:'top', showDelay:600, hideDelay:0}">

                                <mat-slide-toggle name="isAllowUserDownload" formControlName="isAllowUserDownload">

                                   <span > {{editFilePermissionControl.get('sceneObjectTitle').value}} </span>

                                </mat-slide-toggle>

                                <!-- {{editFilePermissionControl.get('foundationDate').value | date}} -->
                              
                            </div>
                        </div>
       

            </div>

            <!-- <div fxLayout="row" flexGrow="1" fxLayoutGap="10px" fxLayoutAlign="stretch">

                <div fxLayout="column" style="width: 100%;">&nbsp;</div>
                <div fxLayout="column" style="width: 100%;">&nbsp;</div>

                <div mat-dialog-actions class="group-buttons-wrapper">
                 
                    <button mat-raised-button color="accent" style="width: 74px" (click)="addVisabiltyGroup()">
                        Add
                    </button>
                    
                </div>
            </div> -->
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div>

            <!-- (click)="createCase()"  -->
            <!-- <button mat-raised-button color="accent"> -->
            <!-- (click)="editSceneObject()" class="save-button"
                [disabled]="sceneObjectEditForm.invalid || requestInProgress" aria-label="Create">
                {{isEditingObject ? 'Edit' : 'Add'}} -->
            <!-- Edit
            </button>

            <span class="p-4 red-500-fg">
                {{errorText}}
            </span> -->

            <!-- <button mat-icon-button matTooltip="Attach a file">
                <mat-icon>attach_file</mat-icon>
            </button> -->
        </div>

        <!-- (click)="" -->
        <!-- <button mat-icon-button aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button> -->

        <button mat-raised-button color="accent" [disabled]="editFilePermissionsForm.invalid"
            (click)="saveFilesPermissions()">
            <!-- (click)="editSceneObject()" class="save-button"
            [disabled]="sceneObjectEditForm.invalid || requestInProgress" aria-label="Create">
            {{isEditingObject ? 'Edit' : 'Add'}} -->
            Save
        </button>
    </div>
</div>