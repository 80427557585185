import { Directive, Input, HostBinding, OnInit, Renderer2, ElementRef } from '@angular/core';
import { CaseStatus } from 'app/core/models';

@Directive({
    selector: '[caseStatusStyle]'
})

export class CaseStatusStyleDirective implements OnInit {

    protected _elementClass: string[] = [];

    static  _caseStatusEnum : object;

    get caseStatusIntToStr() : object
    {
        if(!CaseStatusStyleDirective._caseStatusEnum)
        {          
          CaseStatusStyleDirective._caseStatusEnum = CaseStatusStyleDirective.GetCaseStatusIntToStr();
        }
        return CaseStatusStyleDirective._caseStatusEnum;
    }

    caseStatus: CaseStatus;
    caseStatusPrefix: string = '';

    @Input() set caseStatusStyle(status: CaseStatus) {
        this.caseStatus = status;
    }

    @Input() set caseStatusStylePrefix(prefix: string) {
        this.caseStatusPrefix = prefix;
    }


    constructor(private renderer: Renderer2, private hostElement: ElementRef) {
       
      }

    ngOnInit() {


        let prop = this.caseStatusIntToStr[this.caseStatus];//props.filter(prop => CaseStatus[prop] == this.caseStatus);


        let caseStatusStyleClass = this.caseStatusPrefix + 'case-status-style-' + prop;

        this.renderer.addClass(this.hostElement.nativeElement, caseStatusStyleClass);
        //  this._elementClass.push(caseStatusStyleClass);
    }

    static GetCaseStatusIntToStr(): object {
        let properties: Array<string> = new Array<string>();

        for (var property in CaseStatus) {           
            properties.push(property);
        }

        let enumObj: Object = new Object();
        
        let enumValsCount = properties.length / 2;

        for(let i = 0; i< enumValsCount; i++) 
        {
            enumObj[properties[i]] =  properties[enumValsCount+i];
        }

        return enumObj;
    }
}