import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { passwordPattern, confirmPasswordValidator } from 'app/core/helpers';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'app/core/store/state/app.state';
import { SendRecoverPasswordRequest, RecoverPassword } from 'app/core/store/actions/auth.actions';
import { ResetPasswordByCodeCommand } from 'app/core/models/planner/commands/resetPasswordByCodeCommand';
import { selectResetPasswordStatus } from 'app/core/store/selectors/auth.selector';
import { ResetPasswordStatus } from '@appmodels';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

    resetPasswordStatusEnum = ResetPasswordStatus;

    resetPasswordForm: FormGroup;
    public isLinkOk: boolean;
    public requestSent: boolean;
    public passwordResetStatus$: Observable<ResetPasswordStatus>;
    private _user: string;
    private _code: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _route: ActivatedRoute,
        private _store: Store<IAppState>
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        var params = this._route.snapshot.queryParamMap;
        var user = params.get("user");
        var code = params.get("code");

        if (!user || !code)
            this.isLinkOk = false;
        else
            this.isLinkOk = true;

        this._user = user;
        this._code = code;

        this.passwordResetStatus$ = this._store.pipe(select(selectResetPasswordStatus));

        this.resetPasswordForm = this._formBuilder.group({

            email: [{ value: user, disabled: true }, [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.pattern(passwordPattern)]],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.resetPasswordForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
            });
    }

    submit(): void {

        if (this.resetPasswordForm.invalid)
            return;

        const newPassword = this.resetPasswordForm.controls['password'].value;

        const recoverPasswordCommand = new ResetPasswordByCodeCommand();
        recoverPasswordCommand.email = this._user;
        recoverPasswordCommand.confirmCode = this._code;
        recoverPasswordCommand.newPassword = newPassword;

        this._store.dispatch(new RecoverPassword(recoverPasswordCommand));
        this.requestSent = true;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
