<div id="downloads" fxLayout="column">

    <div id="downloads-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="downloads-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">

                <img src="assets/images/logos/logo_black.svg">

            </div>

            <div class="title">
                {{'DOWNLOADS.CLICKTODOWNLOAD' | translate }}
            </div>

            <div fxLayout="column" fxLayoutAlign="center center" class="appDists">
                <div fxLayout="row">
                    <div class="download-item">
                        <a href="https://updates.bonabyte.net/api/getLauncherDist/win64" target="_blank"> <img
                                src="/assets/images/misc/win.png"> </a>
                    </div>
                </div>

                <div fxLayout="row">
                    <div class="download-item">
                        <a href="https://updates.bonabyte.net/api/getLauncherDist/darwin" target="_blank"><img
                                src="/assets/images/misc/mac.png"> </a>
                    </div>
                </div>
                <div fxLayout="row">
                    <div class="download-item">
                        <a href="https://updates.bonabyte.net/api/getLauncherDist/linuxastra" target="_blank"><img
                                src="/assets/images/misc/linux_astra.png"> </a>
                    </div>
                </div>
            </div>

            <div class="title">
                <a target="_blank" href="/assets/docs/user_guide_01.02.2022.pdf"> {{'DOWNLOADS.USERGUIDE' | translate }}
                </a>
            </div>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">{{'DOWNLOADS.DONTHAVEACCOUNT' | translate }}</span>
                <a class="link" [routerLink]="'/register'">{{'DOWNLOADS.CREATEANACCOUNT' | translate }}</a>
            </div>

        </div>
    </div>

</div>