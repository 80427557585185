import { IAppState } from '../state/app.state';
import { createSelector } from '@ngrx/store';
import { IUsersState } from '../state/users.interface';

const selectPlannerUsers = (state: IAppState) => state.users;

export const selectCurrentUser = createSelector(
    selectPlannerUsers,
    (state: IUsersState) => state.currentUser
)

export const selectUsersFilters = createSelector(
    selectPlannerUsers,
    (state: IUsersState) => {
        return state.usersFilter;
    }
)

export const selectUsers = createSelector(
    selectPlannerUsers,
    (state: IUsersState) => state.users
)

export const selectOnlineUsers = createSelector(
    selectPlannerUsers,
    (state: IUsersState) => state.onlineUsers
)

export const selectCreatedWebLinkToken = createSelector(
    selectPlannerUsers,
    (state: IUsersState) => state.createdWebLinkToken
)