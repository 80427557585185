import { Injectable } from "@angular/core";
import { AddUserAvailablePaidModuleCommand } from "@appmodels";
import { AuthenticationService } from "@appservices";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { EditUserAvailablePaidModuleCommand } from "app/core/models/planner/commands/editUserAvailablePaidModuleCommand";
import { RemoveUserAvailablePaidModuleCommand } from "app/core/models/planner/commands/removeUserAvailablePaidModuleCommand";
import { MessagesService } from "app/core/services/planner/messages.service";
import { PaidModulesService } from "app/core/services/planner/paidModules.service";
import { of } from "rxjs";
import { map, mergeMap, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { VoidAction } from "../actions/app.actions";
import { NewNotification } from "../actions/notifications.actions";
import { AddUsersPaidModules, AddUsersPaidModulesOffline, AddUsersPaidModulesOfflineSuccess, AddUsersPaidModulesSuccess, EditUsersPaidModules, EditUsersPaidModulesSuccess, EPaidModulesActions, GetUsersPaidModules, GetUsersPaidModulesOffline, GetUsersPaidModulesOfflineSuccess, GetUsersPaidModulesSuccess, RemoveUsersPaidModules, RemoveUsersPaidModulesSuccess, SetPaidModulesOfflineSearchPattern, SetPaidModulesSearchPattern, SetPaidModulesSearchPatternSuccess } from "../actions/paidModules.actions";
import { selectPaidModulesSearchPattern } from "../selectors/paidModules.selector";
import { IAppState } from "../state/app.state";

@Injectable()
export class PaidModulesEffects {

    @Effect()
    setSearchPattern$ = this._actions$.pipe(
        ofType<SetPaidModulesSearchPattern>(EPaidModulesActions.SetPaidModulesSearchPattern),
        map(action => action.payload),

        switchMap((searchPattern) => {
            return [
                new SetPaidModulesSearchPatternSuccess(searchPattern),
                new GetUsersPaidModules(),
            ];
        })
    );

    @Effect()
    getUsersPaidModules$ = this._actions$.pipe(
        ofType<GetUsersPaidModules>(EPaidModulesActions.GetUsersPaidModules),
        map(action => action),
        withLatestFrom(this._store.select(selectPaidModulesSearchPattern)),
        switchMap(([_, paidModulesSearchPattern]) => {      
            const skip = 0;
            const take = 1000;
            return this._paidModulesServices.getUsersPaidModules(paidModulesSearchPattern, skip, take);
        }),
        switchMap((usersPaidModules) => of(new GetUsersPaidModulesSuccess(usersPaidModules)))
    );

    @Effect()
    addUsersPaidModules$ = this._actions$.pipe(
        ofType<AddUsersPaidModules>(EPaidModulesActions.AddUsersPaidModules),
        map(action => action.payload),
        switchMap((newUserPaidModule) => {

            const addUserPaidModuleCommand = new AddUserAvailablePaidModuleCommand();
            addUserPaidModuleCommand.userId = newUserPaidModule.userId;
            addUserPaidModuleCommand.module = newUserPaidModule.moduleType;
            addUserPaidModuleCommand.startDate = newUserPaidModule.startDate;
            addUserPaidModuleCommand.daysAllocated = newUserPaidModule.daysAllocated;
            addUserPaidModuleCommand.hospital = newUserPaidModule.hospital;

            console.log('sadasdasdasd');

            return this._paidModulesServices.addUsersPaidModule(addUserPaidModuleCommand).pipe(
                map(newUserPaidModuleId =>{
                    newUserPaidModule.id = newUserPaidModuleId;
                    return newUserPaidModule;
                })
            );
        }),
        mergeMap((_) => {
            return [
                new AddUsersPaidModulesSuccess(),
                new SetPaidModulesSearchPatternSuccess(''),
                new GetUsersPaidModules(),                
                new NewNotification({ message: 'User paid module created!', type: 'UsersPaidModulesSaved', action: 'Ok' })
            ];
        })
    );

    @Effect()
    editUsersPaidModules$ = this._actions$.pipe(
        ofType<EditUsersPaidModules>(EPaidModulesActions.EditUsersPaidModules),
        map(action => action.payload),
        switchMap((editedUserPaidModule) => {

            const editUserPaidModuleCommand = new EditUserAvailablePaidModuleCommand();
            editUserPaidModuleCommand.userId = editedUserPaidModule.userId;
            editUserPaidModuleCommand.availablePaidModuleId = editedUserPaidModule.id;
            editUserPaidModuleCommand.module = editedUserPaidModule.moduleType;
            editUserPaidModuleCommand.startDate = editedUserPaidModule.startDate;
            editUserPaidModuleCommand.daysAllocated = editedUserPaidModule.daysAllocated;
            editUserPaidModuleCommand.hospital = editedUserPaidModule.hospital;


            return this._paidModulesServices.editUsersPaidModule(editUserPaidModuleCommand);
        }),
        mergeMap((_) => {
            return [
                new EditUsersPaidModulesSuccess(),
                new GetUsersPaidModules(),                
                new NewNotification({ message: 'User paid module edited!', type: 'UsersPaidModulesSaved', action: 'Ok' })
            ];
        })
    );

    @Effect()
    removeUsersPaidModules$ = this._actions$.pipe(
        ofType<RemoveUsersPaidModules>(EPaidModulesActions.RemoveUsersPaidModules),
        map(action => action.payload),
        switchMap((removedUserPaidModule) => {

            const editUserPaidModuleCommand = new RemoveUserAvailablePaidModuleCommand();
            editUserPaidModuleCommand.userId = removedUserPaidModule.userId;
            editUserPaidModuleCommand.availablePaidModuleId = removedUserPaidModule.id;
            

            return this._paidModulesServices.removeUsersPaidModule(editUserPaidModuleCommand);
        }),
        mergeMap((_) => {
            return [
                new RemoveUsersPaidModulesSuccess(),
                new GetUsersPaidModules(),                
                new NewNotification({ message: 'User paid module removed!', type: 'UsersPaidModulesSaved', action: 'Ok' })
            ];
        })
    );





    @Effect()
    setSearchPatternOffline$ = this._actions$.pipe(
        ofType<SetPaidModulesOfflineSearchPattern>(EPaidModulesActions.SetPaidModulesOfflineSearchPattern),
        map(action => action.payload),
        switchMap((searchPattern) => {
            return [
                new SetPaidModulesSearchPatternSuccess(searchPattern),
                new GetUsersPaidModulesOffline(),
            ];
        })
    );

    @Effect()
    getUsersPaidModulesOffline$ = this._actions$.pipe(
        ofType<GetUsersPaidModulesOffline>(EPaidModulesActions.GetUsersPaidModulesOffline),
        map(action => action),
        withLatestFrom(this._store.select(selectPaidModulesSearchPattern)),
        switchMap(([_, paidModulesSearchPattern]) => {      
            const skip = 0;
            const take = 1000;
            return this._paidModulesServices.getUsersPaidModulesOffline(paidModulesSearchPattern, skip, take);
        }),
        switchMap((usersPaidModules) => of(new GetUsersPaidModulesOfflineSuccess(usersPaidModules)))
    );

    @Effect()
    addUsersPaidModulesOffline$ = this._actions$.pipe(
        ofType<AddUsersPaidModulesOffline>(EPaidModulesActions.AddUsersPaidModulesOffline),
        map(action => action.payload),
        switchMap((generateLicenseCommand) => {

            return this._paidModulesServices.generateLicense(generateLicenseCommand).pipe(
            )
        }),
        mergeMap((_) => {
            return [
                new AddUsersPaidModulesOfflineSuccess(),
                new SetPaidModulesSearchPatternSuccess(''),
                new GetUsersPaidModulesOffline(),                
                new NewNotification({ message: 'User paid module offline created!', type: 'UsersPaidModulesSaved', action: 'Ok' })
            ];
        })
    );

    // @Effect()
    // editUsersPaidModules$ = this._actions$.pipe(
    //     ofType<EditUsersPaidModules>(EPaidModulesActions.EditUsersPaidModules),
    //     map(action => action.payload),
    //     switchMap((editedUserPaidModule) => {

    //         const editUserPaidModuleCommand = new EditUserAvailablePaidModuleCommand();
    //         editUserPaidModuleCommand.userId = editedUserPaidModule.userId;
    //         editUserPaidModuleCommand.availablePaidModuleId = editedUserPaidModule.id;
    //         editUserPaidModuleCommand.module = editedUserPaidModule.moduleType;
    //         editUserPaidModuleCommand.startDate = editedUserPaidModule.startDate;
    //         editUserPaidModuleCommand.daysAllocated = editedUserPaidModule.daysAllocated;
    //         editUserPaidModuleCommand.hospital = editedUserPaidModule.hospital;


    //         return this._paidModulesServices.editUsersPaidModule(editUserPaidModuleCommand);
    //     }),
    //     mergeMap((_) => {
    //         return [
    //             new EditUsersPaidModulesSuccess(),
    //             new GetUsersPaidModules(),                
    //             new NewNotification({ message: 'User paid module edited!', type: 'UsersPaidModulesSaved', action: 'Ok' })
    //         ];
    //     })
    // );

    // @Effect()
    // removeUsersPaidModules$ = this._actions$.pipe(
    //     ofType<RemoveUsersPaidModules>(EPaidModulesActions.RemoveUsersPaidModules),
    //     map(action => action.payload),
    //     switchMap((removedUserPaidModule) => {

    //         const editUserPaidModuleCommand = new RemoveUserAvailablePaidModuleCommand();
    //         editUserPaidModuleCommand.userId = removedUserPaidModule.userId;
    //         editUserPaidModuleCommand.availablePaidModuleId = removedUserPaidModule.id;
            

    //         return this._paidModulesServices.removeUsersPaidModule(editUserPaidModuleCommand);
    //     }),
    //     mergeMap((_) => {
    //         return [
    //             new RemoveUsersPaidModulesSuccess(),
    //             new GetUsersPaidModules(),                
    //             new NewNotification({ message: 'User paid module removed!', type: 'UsersPaidModulesSaved', action: 'Ok' })
    //         ];
    //     })
    // );







    // @Effect()
    // GenerateLicense$ = this._actions$.pipe(
    //     ofType<GenerateLicense>(EPaidModulesActions.GenerateLicense),
    //     map(action => action.payload),
    //     switchMap((generateLicenseCommand) => {

    //         return this._paidModulesServices.generateLicense(generateLicenseCommand);
    //     }),
    //     mergeMap(_ => {   
    //         return [
    //             new VoidAction()
    //         ];
    //     })
    // );

    constructor(
        private _paidModulesServices: PaidModulesService,
        private _actions$: Actions,
        private _store: Store<IAppState>
    ) { }
}