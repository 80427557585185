export const locale = {
    lang: 'tr',
    data: {
        CASESTATUS: {
            New : 'Новый',
            DicomUploading : 'Загрузка КТ',
            Segmentation : 'Сегментация',
            ModelsReady: '3д модели готовы',
            OperationPlanningSelf: 'Личное планирование операции',
            OperationPlanningCollaboration : 'Совместное планирование операции',
            ImplantsDesigning : 'Разработка дизайна имплантов',
            ImplantsDesignApproval : 'Согласование дизайна имплантатов',
            GuidesDesigning : 'Разработка дизайна гайдов',
            GuidesDesignApproval : 'Согласование дизайна гайдов',
            Manufacturing : 'Производство',
            Shipping : 'Доставка',
            Done : 'Готов',
            Canceled : 'Отменен',
            Deleted : 'Удален'    
        }
    }
};