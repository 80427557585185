import { BufferOffset } from "app/core/models/planner/buffer/buffer-offset";
import { Mesh } from "app/core/models/planner/geometry/mesh";
import { Vector3 } from "app/core/models/planner/geometry/vector3";
import { BitUtils } from "app/core/utils/bit-utils";

export class PLYReader {

    public Read(fileBytes: Uint8Array): Mesh {

        var mesh = new Mesh();

        const offsetObj = new BufferOffset();
        const dataView = new DataView(fileBytes as ArrayBuffer);

        const textDecoder = new TextDecoder();


        const plyHeaderBytes =  BitUtils.ReadBytes(dataView, 3, offsetObj); 

        const plyHeader = textDecoder.decode(plyHeaderBytes);

        if (plyHeader != "ply") {
            throw ("\"ply\" header not found!");
        }

        var flag = true;
        var length = 0;
        var num = 0;

        while (flag) {
            var str = BitUtils.ReadLine(dataView, offsetObj);
            
            if (str.indexOf("element vertex") > -1)                
                length = Number.parseInt(str.split(' ')[2]);

            if (str.indexOf("element face") > -1)
                num = Number.parseInt(str.split(' ')[2]);

            if (str == "end_header")
                flag = false;
        }
        
        if (length < 3)
        {
            throw(`Bad mesh, only ${length} vertices`);
        }

        if (num < 3)
        {   
            throw(`Bad mesh, only ${num} faces`);
        }
        
        var vector3Array: Vector3[] = new Array<Vector3>(length);

        var numArray: number[] = new Array<number>(num * 3);

        for (var index = 0; index < length; ++index) {

            const single1 = BitUtils.ReadSingle(dataView, offsetObj);
            const single2 = BitUtils.ReadSingle(dataView, offsetObj);
            const single3 = BitUtils.ReadSingle(dataView, offsetObj);

            const vector3 = new Vector3(single1, single2, single3);

            vector3Array[index] = vector3;
        }

        for (var index = 0; index < num; ++index) {

            offsetObj.offset += 3;
            const fileByte4 = BitUtils.ReadByte(dataView, offsetObj);

            if (fileByte4 != 3)
            {
                throw(`Bad mesh, face ${index} has ${fileByte4} verts`);
            }

            const int32_1 = BitUtils.ReadInt32(dataView, offsetObj);
            const int32_2 = BitUtils.ReadInt32(dataView, offsetObj);
            const int32_3 = BitUtils.ReadInt32(dataView, offsetObj);

            numArray[3 * index] = int32_1;
            numArray[3 * index + 1] = int32_2;
            numArray[3 * index + 2] = int32_3;
        }

        mesh.Vertices = vector3Array;
        mesh.Indices = numArray;
        return mesh;
    }

    public Write(mesh: Mesh) {

        const vertices = mesh.Vertices;
        const indices = mesh.Indices;
        const num1 = indices.length / 3;

        if (vertices.length < 3) {
            throw (`Bad mesh, only ${vertices.length} vertices`);
        }

        if (num1 < 3) {
            throw (`Bad mesh, only ${num1} faces`);
        }

        const strArray: string[] = [
            "ply",
            "format binary_little_endian 1.0",
            "comment created by Bonabyte planner",
            `element vertex ${vertices.length}`,
            "property float x",
            "property float y",
            "property float z",
            `element face ${num1}`,
            "property uchar red",
            "property uchar green",
            "property uchar blue",
            "property list uchar int vertex_indices",
            "end_header"
        ];

        const textEncoder = new TextEncoder();

        const s_uint8Arrays: Uint8Array[] = []

        let s_uint8Arrays_len = 0;
        for (let i = 0; i < strArray.length; i++) {
            const uint8Array = textEncoder.encode(strArray[i]);
            s_uint8Arrays_len += uint8Array.byteLength;
            s_uint8Arrays.push(uint8Array);
        }

        const newLine = textEncoder.encode('\n');

        s_uint8Arrays_len += s_uint8Arrays.length * newLine.byteLength;

        let v_bytes_l = vertices.length * 3 * 4;
        let f_bytes_l = num1 * (4 + 3 * 4);

        const fileBytes = new ArrayBuffer(s_uint8Arrays_len + v_bytes_l + f_bytes_l);

        const dataView = new DataView(fileBytes);

        const offsetObj = new BufferOffset();

        for (let i = 0; i < s_uint8Arrays.length; i++) {
            BitUtils.WriteBytes(dataView, s_uint8Arrays[i], offsetObj);
            BitUtils.WriteBytes(dataView, newLine, offsetObj);            
        }

        for (let i = 0; i < vertices.length; i++) {
            BitUtils.WriteSingle(dataView, vertices[i].x, offsetObj);
            BitUtils.WriteSingle(dataView, vertices[i].y, offsetObj);
            BitUtils.WriteSingle(dataView, vertices[i].z, offsetObj);
        }

        for (let i = 0; i < num1; i++) {

            BitUtils.WriteByte(dataView, 255, offsetObj);
            BitUtils.WriteByte(dataView, 240, offsetObj);
            BitUtils.WriteByte(dataView, 206, offsetObj);
            BitUtils.WriteByte(dataView, 3, offsetObj);

            var index2 = i * 3;

            BitUtils.WriteInt32(dataView, indices[index2], offsetObj);
            BitUtils.WriteInt32(dataView, indices[index2+1], offsetObj);
            BitUtils.WriteInt32(dataView, indices[index2+2], offsetObj);
        }

        const arrayBuffer = dataView.buffer;

        return arrayBuffer as Uint8Array;
    }    
}