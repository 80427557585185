import * as tslib_1 from "tslib";
import { GetAppLogsGroups, EAppLogsActions, GetAppLogsGroupsSuccess, GetAppLogsByConnectionSuccess, SetAppLogsFilterParamsSuccess, SetAppLogsSearchPatternSuccess } from '../actions/appLogs.actions';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppLogsService } from 'app/core/services/planner/appLogs.service';
import { NGXLogger } from 'ngx-logger';
import { SortOrder } from 'app/core/models/planner/enums/commonEnums';
import { selectAppLogsFilterParams, selectAppLogsGroups, selectAppLogsSearchPattern } from '../selectors/appLogs.selector';
export class AppLogsEffects {
    constructor(_appLogsService, _actions$, _store, _logger) {
        this._appLogsService = _appLogsService;
        this._actions$ = _actions$;
        this._store = _store;
        this._logger = _logger;
        this.setFilterParams$ = this._actions$.pipe(ofType(EAppLogsActions.SetAppLogsFilterParams), map(action => action.isWithAdmins), switchMap((filterParams) => {
            return [
                new SetAppLogsFilterParamsSuccess(filterParams),
                new GetAppLogsGroups(true),
            ];
        }));
        this.setSearchPattern$ = this._actions$.pipe(ofType(EAppLogsActions.SetAppLogsSearchPattern), map(action => action.payload), switchMap((filterParams) => {
            return [
                new SetAppLogsSearchPatternSuccess(filterParams),
                new GetAppLogsGroups(true),
            ];
        }));
        this.getAppLogsGroups$ = this._actions$.pipe(ofType(EAppLogsActions.GetAppLogsGroups), map(action => action.isReload), 
        //map
        switchMap((isReload) => {
            this._logger.info(`GetAppLogsGroups, is reload: ${isReload}`);
            if (isReload) {
                return of([]);
            }
            else {
                return this._store.pipe(select(selectAppLogsGroups), take(1));
            }
        }), withLatestFrom(this._store.select(selectAppLogsFilterParams), this._store.select(selectAppLogsSearchPattern)), switchMap(([existedAppLogsGroups, appLogsFilterParams, appLogsSearchPattern]) => {
            const skip = existedAppLogsGroups ? existedAppLogsGroups.length : 0;
            const take = 20;
            return this._appLogsService.getAppLogsGroups(appLogsFilterParams.isWithAdmins, appLogsSearchPattern, 'LastActivity', SortOrder.Descending, skip, take);
        }), switchMap((appLogsGroups) => of(new GetAppLogsGroupsSuccess(appLogsGroups))));
        this.getAppLogsByConnection$ = this._actions$.pipe(ofType(EAppLogsActions.GetAppLogsByConnection), map(action => action.payload), //map
        switchMap((connectionId) => {
            return this._appLogsService.getAppLogsByConnection(connectionId);
        }), switchMap((appLogs) => of(new GetAppLogsByConnectionSuccess(appLogs))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AppLogsEffects.prototype, "setFilterParams$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AppLogsEffects.prototype, "setSearchPattern$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AppLogsEffects.prototype, "getAppLogsGroups$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AppLogsEffects.prototype, "getAppLogsByConnection$", void 0);
