export const locale = {
    lang: 'en',
    data: {
        REGISTRATION: {
            CREATEACCOUNT: 'CREATE AN ACCOUNT',
            FILLNAME: 'Fill out your name',
            EMAILREQUIRED: 'Email is required',
            ENTERVALIDEMAIL: 'Please enter a valid email address',
            EMAILUNAVAILABLE: 'Email is unavailable',
            FIRSTNAME: 'Name',
            FIRSTNAMEREQUIRED: 'Name is required',
            LASTNAME: 'Surname',
            LASTNAMEREQUIRED: 'Surname is required',
            MIDDLENAME: 'Second name',            
            MEDICALREFERALTYPE: 'Medical referal type',
            HUMAN: 'Medicine',
            VETERINARY: 'Veterinary',
            MEDICALREFERALTYPEREQUIRED: 'Medical referal type is required',
            SPECIALITY: 'Speciality',
            NOTSET: 'NOTSET',
            TRAUMATOLOGISTORTHOPEDIST: 'Traumatologist orthopedist',
            MAXILLOFACIALSURGEON: 'Maxillofacial surgeon',
            NEUROSURGEON: 'Neurosurgeon',
            OTHER: 'Other',
            SPECIALITYREQUIRED: 'Speciality is required',
            PASSWORD: 'Password',
            PASSWORDREQUIRED: 'Password is required',
            PASSWORDREQUIREMENT: 'Your password must be between 6 and 30 characters <br> Your password must contain at least one lower case letter <br> Your password must contain at least one upper case letter <br> Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.) <br> Your password must contain at least one special character - for example: -, $, #, @, !,%,^,&,*, (,)',
            PASSWORDCONFIRM: 'Password (Confirm)',
            PASSWORDCONFIRMREQUIRED: 'Password confirmation is required',
            PASSWORDMUSTMATCH: 'Passwords must match',
            ALREADYHAVEANACCOUNT: 'Already have an account?',
            CLICKTODOWNLOADSHORT: 'Click to download planner',
            NEXT: 'Next',
            FILLINFO: 'Fill out your info',
            COUNTRY: 'Country',
            COUNTRYREQUIRED: 'Country is required',
            CITY: 'City',
            CITYREQUIRED: 'City is required',
            PHONE: 'Phone',
            PHONEREQUIRED: 'Phone is required',
            COMPANY: 'Hospital/Company',
            COMPANYREQUIRED: 'Hospital/Company is required',
            MARKETINGREFERALS: 'How did you hear about our company',
            MARKETINGREFERALSRECOMENDATION: 'Recommendation',
            MARKETINGREFERALSSEARCHQUERY: 'Search query',
            MARKETINGREFERALSSOCIALNETWORK: 'Social network',
            LANGUAGE: 'Language',
            ENGLISH: 'English',
            RUSSIAN: 'Русский',
            LANGUAGEREQUIRED: 'Language is required',
            TERMS1: 'I read and accept',
            TERMS2: 'terms and conditions',
            PRIVACYPOLICY: 'https://bonabyte.net/Privacy-policy.pdf',
            TERMSREQUIRED: 'Read and accept terms and conditions is required',
            ACCEPTMAILILNG: 'I agree to receive information emails',
            ACCEPTMAILILNGREQUIRED: 'Agree to receive information emails is required',
            BACK: 'Back',
            REGISTER: 'Register',
            DONE: 'Done',
            SUCCESS: 'Success!',
            CLICKTODOWNLOAD: 'Click below to download the Bonabyte planner application for your OS:',
            ERROR: 'Error!',
            TRYAGAINLATER: 'Please try again later...'
        }
    }
};
