<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Create web link</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fxLayout="column">

        <div class="text-center web-link-token">
            WEB LINK TOKEN: <span>{{webLinkToken}}</span>
        </div>


    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="text-center token-buttons">

            <button type="submit" mat-raised-button color="" class="save-button center"
                [disabled]="!webLinkToken || webLinkToken == ''" aria-label="Create" (click)="onSubmit('follow')">
                FOLLOW
            </button>

            <button type="submit" mat-raised-button color="accent" class="save-button center"
                [disabled]="!webLinkToken || webLinkToken == ''" aria-label="Create" (click)="onSubmit('copy')">
                COPY
            </button>

            <button type="submit" mat-raised-button color="warn" class="save-button center"
                [disabled]="!webLinkToken || webLinkToken == ''" aria-label="Create" (click)="onSubmit('revoke')">
                REVOKE
            </button>
            <!-- <span class="p-4 red-500-fg">
               {{errorText}}
            </span> -->


        </div>

        <!-- (click)="matDialogRef.close(['delete',caseEditForm])"  -->
        <!-- <button mat-icon-button aria-label="Delete"
            matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button> -->


    </div>
</div>