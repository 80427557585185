import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '@fuse/shared.module';

//import { ChatService } from 'app/pages/chat/chat.service';
import { ChatComponent } from 'app/pages/chat/chat.component';
import { ChatViewComponent } from 'app/pages/chat/chat-panel/chat-view/chat-view.component';
import { ChatChatsSidenavComponent } from 'app/pages/chat/sidenavs/left/chats/chats.component';
import { ChatUserSidenavComponent } from 'app/pages/chat/sidenavs/left/user/user.component';
import { ChatLeftSidenavComponent } from 'app/pages/chat/sidenavs/left/left.component';
import { ChatRightSidenavComponent } from 'app/pages/chat/sidenavs/right/right.component';
import { ChatContactSidenavComponent } from 'app/pages/chat/sidenavs/right/contact/contact.component';
import { MessagesService } from 'app/core/services/planner/messages.service';
import { ChatStartComponent } from './chat-panel/chat-start/chat-start.component';
import { ChatPanelComponent } from './chat-panel/chat-panel.component';
import { SharedModule } from 'app/core/shared/shared.module';
import { MatProgressSpinnerModule, MatSlideToggleModule } from '@angular/material';
import { ngfModule } from 'angular-file';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
    declarations: [
        ChatComponent,
        ChatPanelComponent,
        ChatViewComponent,
        ChatStartComponent,
        ChatChatsSidenavComponent,
        ChatUserSidenavComponent,
        ChatLeftSidenavComponent,
        ChatRightSidenavComponent,
        ChatContactSidenavComponent,
    ],
    imports     : [
       
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatRadioModule,
        MatSidenavModule,
        MatToolbarModule,
        MatSlideToggleModule,
        FuseSharedModule,
        ngfModule,
        SharedModule,
        InfiniteScrollModule,
    ],
    providers   : [
        MessagesService
    ]
})
export class ChatModule
{
}
