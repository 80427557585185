import { PlannerQualitySurveyCase } from "@appmodels";

export interface IQualitySurveyCasesState
{    
    notAdmins: boolean;
    searchPattern: string;
    qualitySurveyCases: PlannerQualitySurveyCase[];    
}

export const initialQualitySurveyCasesState: IQualitySurveyCasesState =
{
    notAdmins: false,
    searchPattern: null,
    qualitySurveyCases: null    
}