import * as moment from 'moment';

export class ObjectDateFieldsFormatter {
    // Migrated from AngularJS https://raw.githubusercontent.com/Ins87/angular-date-interceptor/master/src/angular-date-interceptor.js
    private static iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

    public static convertFieldsDate(body) {
        if (body === null || body === undefined) {
            return body;
        }

        if (typeof body !== 'object') {
            return body;
        }

        for (const key of Object.keys(body)) {
            const value = body[key];

            if (this.isIso8601(value)) {

                body[key] = moment.parseZone(value).toDate();
            } else if (typeof value === 'object') {
                this.convertFieldsDate(value);
            }
        }
    }

    public static isIso8601(value) {
        if (value === null || value === undefined) {
            return false;
        }

        return this.iso8601.test(value);
    }
}