/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./chat-start/chat-start.component.ngfactory";
import * as i3 from "./chat-start/chat-start.component";
import * as i4 from "./chat-view/chat-view.component.ngfactory";
import * as i5 from "./chat-view/chat-view.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../core/services/planner/files.sevice";
import * as i8 from "ngx-lightbox/lightbox.service";
import * as i9 from "@angular/material/dialog";
import * as i10 from "ngx-logger";
import * as i11 from "@angular/common";
import * as i12 from "./chat-panel.component";
var styles_ChatPanelComponent = [i0.styles];
var RenderType_ChatPanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ChatPanelComponent, data: {} });
export { RenderType_ChatPanelComponent as RenderType_ChatPanelComponent };
function View_ChatPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "chat-start", [], null, null, null, i2.View_ChatStartComponent_0, i2.RenderType_ChatStartComponent)), i1.ɵdid(1, 49152, null, 0, i3.ChatStartComponent, [], null, null)], null, null); }
function View_ChatPanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "chat-view", [], null, [[null, "onNewMessage"], [null, "onMessagesReaded"], [null, "onGetMoreMessages"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onNewMessage" === en)) {
        var pd_0 = (_co.newMessage($event) !== false);
        ad = (pd_0 && ad);
    } if (("onMessagesReaded" === en)) {
        var pd_1 = (_co.messagesReaded($event) !== false);
        ad = (pd_1 && ad);
    } if (("onGetMoreMessages" === en)) {
        var pd_2 = (_co.getMoreMessages() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_ChatViewComponent_0, i4.RenderType_ChatViewComponent)), i1.ɵdid(1, 4440064, null, 0, i5.ChatViewComponent, [i6.Router, i1.ChangeDetectorRef, i7.FilesService, i8.Lightbox, i9.MatDialog, i10.NGXLogger], { currentUser: [0, "currentUser"], selectedCase: [1, "selectedCase"], messages: [2, "messages"] }, { onNewMessage: "onNewMessage", onMessagesReaded: "onMessagesReaded", onGetMoreMessages: "onGetMoreMessages" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentUser; var currVal_1 = _co.selectedCase; var currVal_2 = _co.messages; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ChatPanelComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatPanelComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatPanelComponent_2)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.selectedCase; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.selectedCase; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ChatPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "chat-panel", [], null, null, null, View_ChatPanelComponent_0, RenderType_ChatPanelComponent)), i1.ɵdid(1, 114688, null, 0, i12.ChatPanelComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatPanelComponentNgFactory = i1.ɵccf("chat-panel", i12.ChatPanelComponent, View_ChatPanelComponent_Host_0, { currentUser: "currentUser", selectedCase: "selectedCase", messages: "messages" }, { onNewMessage: "onNewMessage", onMessagesReaded: "onMessagesReaded", onGetMoreMessages: "onGetMoreMessages" }, []);
export { ChatPanelComponentNgFactory as ChatPanelComponentNgFactory };
