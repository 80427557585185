import { OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { APP_DATE_FORMATS } from 'app/core/helpers';
import { Subject } from 'rxjs';
import { PlannerSceneVisibilityGroup } from '@appmodels';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
const ɵ0 = APP_DATE_FORMATS;
export class EditVisabilityGroupsComponent {
    constructor(matDialogRef, _data) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this.visibilityGroups = this._data.visibilityGroups;
        this.sceneObjects = _.orderBy(this._data.sceneObjects, ['foundationDate', obj => obj.title.toLowerCase()], ['desc', 'asc']);
        this.visabilityGroupsEditForm = this.createVisabilityGroupsEditForm(this.visibilityGroups);
        this._unsubscribeAll = new Subject();
    }
    get visabilityGroupsFormArray() {
        return this.visabilityGroupsEditForm.get('visibilityGroups').controls;
    }
    getArrayGroup(control, name) {
        return control.get(name).controls;
    }
    ngOnInit() {
    }
    createVisabilityGroupsEditForm(visibilityGroups) {
        const form = new FormGroup({});
        const visibilityGroupsFormArray = new FormArray([]);
        visibilityGroups.forEach(visibilityGroup => {
            const visibilityGroupFormGroup = new FormGroup({
                id: new FormControl(visibilityGroup.id),
                groupName: new FormControl(visibilityGroup.groupName, Validators.required),
                orderNum: new FormControl(visibilityGroup.orderNum, Validators.required),
            });
            const sceneObjectsVisibilityFormArray = new FormArray([]);
            this.sceneObjects.forEach(sceneObject => {
                const isInGroup = visibilityGroup.sceneObjectsIds.indexOf(sceneObject.id) >= 0;
                const visibilityFormGroup = new FormGroup({
                    id: new FormControl(sceneObject.id),
                    sceneObjectTitle: new FormControl(sceneObject.title),
                    // foundationDate: new FormControl(sceneObject.foundationDate),
                    isInGroup: new FormControl(isInGroup)
                });
                sceneObjectsVisibilityFormArray.push(visibilityFormGroup);
            });
            visibilityGroupFormGroup.addControl('objectsVisibility', sceneObjectsVisibilityFormArray);
            visibilityGroupsFormArray.push(visibilityGroupFormGroup);
        });
        form.addControl("visibilityGroups", visibilityGroupsFormArray);
        return form;
    }
    deleteVisabiltyGroup(groupIndex) {
        const formArray = this.visabilityGroupsEditForm.controls['visibilityGroups'];
        formArray.removeAt(groupIndex);
    }
    addVisabiltyGroup() {
        const formArray = this.visabilityGroupsEditForm.controls['visibilityGroups'];
        const visibilityGroupFormGroup = new FormGroup({
            id: new FormControl(),
            groupName: new FormControl("New group", Validators.required),
            orderNum: new FormControl(0, Validators.required),
        });
        const sceneObjectsVisibilityFormArray = new FormArray([]);
        this.sceneObjects.forEach(sceneObject => {
            const visibilityFormGroup = new FormGroup({
                id: new FormControl(sceneObject.id),
                sceneObjectTitle: new FormControl(sceneObject.title),
                isInGroup: new FormControl(false)
            });
            sceneObjectsVisibilityFormArray.push(visibilityFormGroup);
        });
        visibilityGroupFormGroup.addControl('objectsVisibility', sceneObjectsVisibilityFormArray);
        formArray.push(visibilityGroupFormGroup);
    }
    saveVisibilityGroups() {
        const visabilityGroups = [];
        const formArray = this.visabilityGroupsEditForm.controls['visibilityGroups'];
        formArray.controls.forEach(visabilityGroupFormGroup => {
            const visabilityGroup = new PlannerSceneVisibilityGroup();
            visabilityGroup.id = visabilityGroupFormGroup.get('id').value;
            visabilityGroup.groupName = visabilityGroupFormGroup.get('groupName').value;
            visabilityGroup.orderNum = visabilityGroupFormGroup.get('orderNum').value;
            visabilityGroup.sceneObjectsIds = [];
            const visibilityArray = visabilityGroupFormGroup.get('objectsVisibility');
            visibilityArray.controls.forEach(visibilityFormGroup => {
                const isInGroup = visibilityFormGroup.get('isInGroup').value;
                if (isInGroup) {
                    visabilityGroup.sceneObjectsIds.push(visibilityFormGroup.get('id').value);
                }
            });
            visabilityGroups.push(visabilityGroup);
        });
        this.matDialogRef.close(['save', visabilityGroups]);
    }
    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
export { ɵ0 };
