import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { PlannerCaseRelatedData, CreatePlannerCaseCommand, SavePlannerSceneCommand, CaseFolderType, RollbackPlannerSceneCommand, UpdatePlannerCaseCommand, SavePlannerSceneVisibilityGroupsCommand, PlannerCaseRelatedDataContainer, CaseSortType,
      PlannerCaseShare, 
      UpdatePlannerCaseSharesCommand,
      ClonePlannerCaseCommand,
      DeletePlannerCaseCommand,
      ClonePlannerCaseBatchCommand,
      SetPlannerCaseStatusCommand,
      QualitySurvey,
      PlannerQualitySurveyCase,
      SaveBundleCaseCommand,
      PlannerCaseBundle} from 'app/core/models';
import { PlannerCase } from '../../models';
import { APP_CONFIG, AppConfig } from '../../../app-config.module';
import { Observable} from 'rxjs';
import { PlannerCaseCurrentSceneContainer } from 'app/core/models/planner/plannerCaseCurrentSceneContainer';
import { PlannerScene } from 'app/core/models/planner/plannerScene';
import { PlannerCaseScenesContainer } from 'app/core/models/planner/plannerCaseScenesContainer';
import { PlannerSceneObject } from 'app/core/models/planner/plannerSceneObject';
import { NGXLogger } from 'ngx-logger';
import { SavePlannerCaseSceneCommandModel } from 'app/core/models/planner/responses/savePlannerCaseSceneCommandModel';
import { EnumHelpers } from 'app/core/helpers';
import { forEach } from 'lodash';

@Injectable({ providedIn: 'root' })
export class CasesService {  

    constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient, private logger: NGXLogger) {       
    }

    public getCases(casesRequestArgs: any, skip: number, take: number): Observable<PlannerCase[]> {

        let modulesFilterParam = '';

        if(casesRequestArgs.modulesFilter && casesRequestArgs.modulesFilter.length>0)
        {
            modulesFilterParam = '';

            for(let i=0; i<casesRequestArgs.modulesFilter.length; i++)
            {
                if(i>0)
                {
                    modulesFilterParam += '&';
                }

                modulesFilterParam += `Modules=${casesRequestArgs.modulesFilter[i]}`;
            }            
        }        

        const folderType = casesRequestArgs.folder;
        const sortType = EnumHelpers.getValAsString(CaseSortType, casesRequestArgs.sortType);
        const notAdmins = casesRequestArgs.notAdmins;
        const onlyUncompleted = casesRequestArgs.onlyUncompleted;
        const onlyWithGuideOrImplant = casesRequestArgs.onlyWithGuideOrImplant;
        const forQualityControl = casesRequestArgs.forQualityControl;
        const searchPattern = casesRequestArgs.searchPattern ? casesRequestArgs.searchPattern : '';
        
        const url = `${this.config.apiEndpoint}/api/public/Cases/Get?${modulesFilterParam}&FolderType=${folderType}&NotAdmins=${notAdmins}&OnlyUncompleted=${onlyUncompleted}&OnlyWithGuideOrImplant=${onlyWithGuideOrImplant}&ForQualityControl=${forQualityControl}
        &SearchPattern=${searchPattern}&Skip=${skip}&Take=${take}&Sort=${sortType}`;
        this.logger.debug("HTTP GET cases list.", `Url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => {
                    this.logger.debug("Response cases list:", response);                    
                }),
                map(response => response.cases));
    }

    public getCase(caseId: string): Observable<PlannerCase> {      

        const url = `${this.config.apiEndpoint}/api/public/Cases/GetCase?CaseId=${caseId}`;
        this.logger.debug("HTTP GET case.", `Url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => {
                    this.logger.debug("Response case:", response);                    
                }),
                map(response => response));
    }

    public getCaseRelatedData(caseId: string): Observable<PlannerCaseRelatedDataContainer> {

        if (!caseId)
            throw new Error('getRelatedData: caseId is null');

        const url = `${this.config.apiEndpoint}/api/public/Cases/GetRelatedData?caseId=` + caseId;
        this.logger.debug("HTTP GET case related data.", `CaseId: ${caseId},`, `url: ${url}`);
        return this.http.get<PlannerCaseRelatedDataContainer>(url)
            .pipe(
                tap(response => this.logger.debug("Response case related data:", response)),
                map(response => {                    
                    return response;
                })
            );
    }   

    public createCase(createCaseCommand: CreatePlannerCaseCommand): Observable<string> {
        const url = `${this.config.apiEndpoint}/api/public/Cases/Add`;
        this.logger.debug("HTTP POST create case.", `New case: ${createCaseCommand},`, `url: ${url}`);
        return this.http.post<any>(url, createCaseCommand)
            .pipe(tap(response => {
                this.logger.debug("Response create case:", response);
            }));
    } 

    public editCase(updateCaseCommand: UpdatePlannerCaseCommand): Observable<string> {
        const url = `${this.config.apiEndpoint}/api/public/Cases/Update`;
        this.logger.debug("HTTP POST update case.", `Update case: ${updateCaseCommand},`, `url: ${url}`);
        return this.http.post<any>(url, updateCaseCommand)
            .pipe(tap(response => {
                this.logger.debug("Response update case:", response);
            }));
    } 
    
    public cloneCase(clonePlannerCaseCommand: ClonePlannerCaseCommand): Observable<boolean> {
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/CloneCase`;
        this.logger.debug("HTTP POST clone case.", `Command: ${clonePlannerCaseCommand},`, `url: ${url}`);
        return this.http.post<any>(url, clonePlannerCaseCommand)
            .pipe(tap(response => {
                this.logger.debug("Response clone case:", response);
            }));
    } 

    public cloneCaseBatch(clonePlannerCaseBatchCommand: ClonePlannerCaseBatchCommand): Observable<string> {
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/CloneCaseBatch`;
        this.logger.debug("HTTP POST clone case batch.", `Command: ${clonePlannerCaseBatchCommand},`, `url: ${url}`);
        return this.http.post<any>(url, clonePlannerCaseBatchCommand)
            .pipe(tap(response => {
                this.logger.debug("Response clone case batch:", response);
            }));
    } 

    public getCaseShares(caseId: string): Observable<PlannerCaseShare[]> {
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/GetCaseShares?caseId=` + caseId;
        this.logger.debug("HTTP GET case shares.", `CaseId: ${caseId},`, `url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => this.logger.debug("Response case shares:", response)),
                map(response => response.shares)
            );
    }

    public updateCaseShares(updateCaseSharesCommand: UpdatePlannerCaseSharesCommand): Observable<boolean> {
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/UpdateCaseShares`;
        this.logger.debug("HTTP POST update case shares.", `Command: ${updateCaseSharesCommand},`, `url: ${url}`);
        return this.http.post<any>(url, updateCaseSharesCommand)
            .pipe(tap(response => {
                this.logger.debug("Response update case shares:", response);
            }));
    } 
    
    public deleteCase(deletePlannerCaseCommand: DeletePlannerCaseCommand): Observable<boolean> {
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/DeleteCase`;
        this.logger.debug("HTTP POST delete case.", `Command: ${deletePlannerCaseCommand},`, `url: ${url}`);
        return this.http.post<any>(url, deletePlannerCaseCommand)
            .pipe(tap(response => {
                this.logger.debug("Response delete case:", response);
            }));
    } 

    public getCaseScenes(caseId: string): Observable<PlannerScene[]> {
        const url = `${this.config.apiEndpoint}/api/public/Scenes/GetCaseScenes?caseId=` + caseId;
        this.logger.debug("HTTP GET case scenes.", `CaseId: ${caseId},`, `url: ${url}`);
        return this.http.get<PlannerCaseScenesContainer>(url)
            .pipe(
                tap(response => this.logger.debug("Response scenes:", response)),
                map(response => response.caseScenes)
            );
    }

    public getCaseScene(caseId: string, sceneId: string): Observable<PlannerScene> {
        let getCaseUrl = '';

        if (!sceneId) {
            getCaseUrl = `/api/public/Scenes/GetCurrentScene?caseId=` + caseId;
        }
        else {
            getCaseUrl = `/api/public/Scenes/GetScene?caseId=` + caseId + `&sceneId=` + sceneId;
        }

        const url = `${this.config.apiEndpoint}` + getCaseUrl;

        this.logger.debug("HTTP GET case scene.", `CaseId: ${caseId},`, `sceneId: ${sceneId},`, `url: ${url}`);
        return this.http.get<PlannerCaseCurrentSceneContainer>(url)
            .pipe(
                tap(response => this.logger.debug("Response scene:", response)),
                map(response => response.scene)
            );
    }

    public saveSceneVisibilityGroups(savePlannerSceneVisibilityGroupsCommand: SavePlannerSceneVisibilityGroupsCommand) : Observable<boolean>
    {
        const url = `${this.config.apiEndpoint}/api/private/ScenesManagement/SaveSceneVisibilityGroups`;
        this.logger.debug("HTTP POST save scene visibility groups.", `CaseId: ${savePlannerSceneVisibilityGroupsCommand.caseId}`, `sceneId: ${savePlannerSceneVisibilityGroupsCommand.sceneId},`, `url: ${url}`);
        return this.http.post<boolean>(url, savePlannerSceneVisibilityGroupsCommand)
            .pipe(tap(response => {
                this.logger.debug("Response save scene visibility groups:", response);
            }));
    }

    public saveScene(savePlannerCaseSceneCommand: SavePlannerSceneCommand) : Observable<SavePlannerCaseSceneCommandModel>
    {
        const url = `${this.config.apiEndpoint}/api/private/ScenesManagement/SaveScene`;
        this.logger.debug("HTTP POST save scene.", `CaseId: ${savePlannerCaseSceneCommand.caseId}`, `scene: ${savePlannerCaseSceneCommand.scene},`, `url: ${url}`);
        return this.http.post<SavePlannerCaseSceneCommandModel>(url, savePlannerCaseSceneCommand)
            .pipe(tap(response => {
                this.logger.debug("Response save scene:", response);
            }));
    }

    public setPlannerCaseStatus(setPlannerCaseStatusCommand: SetPlannerCaseStatusCommand) : Observable<boolean>
    {
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/SetPlannerCaseStatus`;
        this.logger.debug("HTTP POST set planner case status.", `CaseId: ${setPlannerCaseStatusCommand.caseId}`, `status: ${setPlannerCaseStatusCommand.status},`, `url: ${url}`);
        return this.http.post<boolean>(url, setPlannerCaseStatusCommand)
            .pipe(tap(response => {
                this.logger.debug("Response set planner case status:", response);
            }));
    }

    public rollbackScene(rollbackPlannerCaseSceneCommand: RollbackPlannerSceneCommand) : Observable<string>
    {
        const url = `${this.config.apiEndpoint}/api/private/ScenesManagement/RollbackScene`;
        this.logger.debug("HTTP POST rollback scene.", `CaseId: ${rollbackPlannerCaseSceneCommand.caseId}`, `sceneId: ${rollbackPlannerCaseSceneCommand.sceneId},`, `url: ${url}`);
        return this.http.post<string>(url, rollbackPlannerCaseSceneCommand)
            .pipe(tap(response => {
                this.logger.debug("Response rollback scene:", response);
            }));
    }

    public getQualitySurveyCases(casesRequestArgs: any, skip: number, take: number): Observable<PlannerQualitySurveyCase[]> {

        const notAdmins = casesRequestArgs.notAdmins;

        const searchPattern = casesRequestArgs.searchPattern ? casesRequestArgs.searchPattern : '';
        
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/GetQualitySurveyCase?NotAdmins=${notAdmins}&SearchPattern=${searchPattern}&Skip=${skip}&Take=${take}&Sort=CreationDate`;
        this.logger.debug("HTTP GET quality surveys cases list.", `Url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => {
                    this.logger.debug("Response quality surveys cases list:", response);                    
                }),
                map(response => response.qualitySurveys));
    }
    
    public saveCaseBundle(saveBundleCaseCommand: SaveBundleCaseCommand) : Observable<boolean>
    {
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/SaveBundleCase`;
        this.logger.debug("HTTP POST save planner case bundle.", `CaseId: ${saveBundleCaseCommand.caseId}`, `bundle: ${saveBundleCaseCommand.bundle},`, `url: ${url}`);
        return this.http.post<boolean>(url, saveBundleCaseCommand)
            .pipe(tap(response => {
                this.logger.debug("Response save planner case bundle:", response);
            }));
    }

    public getPlannerCaseBundle(caseId: string): Observable<PlannerCaseBundle> {        
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/GetPlannerCaseBundle?CaseId=${caseId}`;
        this.logger.debug("HTTP GET case bundle.", `Url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => {
                    this.logger.debug("Response case bundle:", response);                    
                }));
               
    }
}