<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Add file</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>



        <form name="composeForm" [formGroup]="filesEditForm" class="case-edit-form" fxLayout="column" fxFlex>



            <div [fxShow]="!files || files.length == 0" class="file-drop-zone" ngfDrop selectable="1" multiple="1" [(files)]="files" (filesChange)="filesSelected($event)" [(validDrag)]="validComboDrag" [(invalidDrag)]="invalidComboDrag" [ngClass]="{'goodDragClass':validComboDrag, 'badDragClass':invalidComboDrag}">

                <div>Open file select or drag and drop files here!</div>

            </div>

            <div class="fileForms">

                <div *ngFor="let fileIndex of filesIndices; let i = index" class="fileForm" formGroupName="{{fileIndex.groupName}}" fxLayout="column" fxLayoutAlign="center stretch">



                    <div class="attachment" fxLayout="row" fxFill="100%" fxLayoutAlign="space-between center">

                        <div>
                            <span class="filename">{{fileIndex.file.name}}</span>
                            <span class="size ml-4">({{fileIndex.file.size/1000}}} Kb)</span>
                        </div>
                      
                        <button mat-icon-button aria-label="Delete attachment">
                                        <mat-icon class="s-16">close</mat-icon>
                                    </button>

                    </div>

                    <div class="p-4 red-800-fg" [fxShow]="fileIndex.file.name.indexOf('.stl')>0">
                        !!! It seems like you are going to upload STL model, bonabyte planner supports STL models in test mode !!!
                    </div>


                    <div class="uploadProgress">
                        {{fileIndex.fileResponse?.status}}

                        <mat-progress-bar [fxShow]="fileIndex.fileResponse?.progress" mode="determinate" [value]="fileIndex.fileResponse?.progress"></mat-progress-bar>
                    </div>
                    <div [fxShow]="!fileIndex.fileResponse">
                        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                            <mat-form-field fxLayout="column" appearance="outline">
                                <mat-label>Title</mat-label>
                                <input matInput name="title" formControlName="title" type="text">
                            </mat-form-field>


                            <mat-form-field fxLayout="column" appearance="outline">
                                <mat-label>File type</mat-label>
                                <mat-select name="fileType" formControlName="fileType">
                                    <mat-option [value]="fileTypeEnum.DICOM">
                                        DICOM
                                    </mat-option>
                                    <mat-option [value]="fileTypeEnum.DICOMImages">
                                        DICOMImages
                                    </mat-option>
                                    <mat-option [value]="fileTypeEnum.STLMODEL">
                                        STLMODEL
                                    </mat-option>
                                    <mat-option [value]="fileTypeEnum.PLANNERMODEL">
                                        PLANNERMODEL
                                    </mat-option>
                                    <mat-option [value]="fileTypeEnum.PDF">
                                        PDF
                                    </mat-option>
                                    <mat-option [value]="fileTypeEnum.PICTURE">
                                        PICTURE
                                    </mat-option>
                                    <mat-option [value]="fileTypeEnum.VIDEO">
                                        VIDEO
                                    </mat-option>

                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div formGroupName="fileAdditionalInfoGroup">
                            <div [fxShow]="filesEditForm.controls[fileIndex.groupName].controls['fileType'].value == fileTypeEnum.DICOM">

                                <div fxLayout="row" fxLayoutGap="2px" fxLayoutAlign="start center">
                                    <mat-slide-toggle name="isUseIn3d" formControlName="isUseIn3d">
                                        Use in 3d scene
                                    </mat-slide-toggle>
                                </div>
                            </div>

                            <div [fxShow]="filesEditForm.controls[fileIndex.groupName].controls['fileType'].value == fileTypeEnum.STLMODEL">

                                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">

                                    <mat-form-field fxLayout="column" appearance="outline">
                                        <mat-label>Material type</mat-label>
                                        <mat-select name="materialType" formControlName="materialType">
                                            <mat-option [value]="modelMaterialTypeEnum.Bone">
                                                Bone
                                            </mat-option>
                                            <mat-option [value]="modelMaterialTypeEnum.Plastic">
                                                Plastic
                                            </mat-option>

                                            <mat-option [value]="modelMaterialTypeEnum.Titanium">
                                                Titanium
                                            </mat-option>
                                            <mat-option [value]="modelMaterialTypeEnum.Gypsum">
                                                Gypsum
                                            </mat-option>
                                            <mat-option [value]="modelMaterialTypeEnum.PEEK">
                                                PEEK
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" [fxShow]="filesEditForm.controls[fileIndex.groupName].controls['fileAdditionalInfoGroup'].controls['materialType'].value == modelMaterialTypeEnum.Plastic">

                                    <mat-form-field fxLayout="column" appearance="outline">
                                        <mat-label>Saw</mat-label>
                                        <input matInput name="saw" formControlName="saw" type="text">
                                    </mat-form-field>

                                    <mat-form-field fxLayout="column" appearance="outline">
                                        <mat-label>Pin</mat-label>
                                        <input matInput name="pin" formControlName="pin" type="text">
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayoutGap="2px" fxLayoutAlign="start center" [fxShow]="filesEditForm.controls[fileIndex.groupName].controls['fileAdditionalInfoGroup'].controls['materialType'].value == modelMaterialTypeEnum.Titanium">

                                    <mat-form-field fxLayout="column" appearance="outline">
                                        <mat-label>Screw</mat-label>
                                        <input matInput name="screw" formControlName="screw" type="text">
                                    </mat-form-field>


                                </div>
                            </div>
                        </div>
                    </div>


                    <mat-divider [fxShow]="i < filesIndices.length - 1"></mat-divider>
                </div>
            </div>

        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div>

            <!-- (click)="createCase()"  -->
            <button mat-raised-button color="accent" (click)="addFiles(false)" class="save-button" [disabled]="filesEditForm.invalid || requestInProgress" aria-label="Create">
                Upload
            </button>
            <button mat-raised-button color="accent" (click)="addFiles(true)" class="save-button" [disabled]="filesEditForm.invalid || requestInProgress" aria-label="Create">
                Upload and add to scene
            </button>

            <span class="p-4 red-500-fg">
                {{errorText}}
            </span>

            <!-- <button mat-icon-button matTooltip="Attach a file">
                <mat-icon>attach_file</mat-icon>
            </button> -->
        </div>

        <button mat-icon-button (click)="files = []" aria-label="Delete" matTooltip="Delete" (click)="removeFiles()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>