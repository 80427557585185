import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { Language, MedicalReferals, Speciality } from '@appmodels';
import { IUsersFilter } from 'app/core/models/usersFilter';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'contacts-main-sidebar',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsMainSidebarComponent implements OnInit, OnDestroy {
    user: any;
    filterBy: string;

    @Input()
    usersFilter: IUsersFilter;

    @Output()
    onUsersFilterChanged: EventEmitter<any>;

    @Output()
    onExportUsersClicked: EventEmitter<object>;

    medicalReferalsEnum = MedicalReferals;
    specialityEnum = Speciality;
    languageEnum = Language;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UsersService} _contactsService
     */
    constructor(

    ) {
        this.onUsersFilterChanged = new EventEmitter();
        this.onExportUsersClicked = new EventEmitter();
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.filterBy = null;
        // this.filterBy = this._contactsService.filterBy || 'all';

        // this._contactsService.onUserDataChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(user => {
        //         this.user = user;
        //     });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Change the filter
     *
     * @param filter
     */
    changeIsWithAdmins(isWithAdmins): void {
        this.onUsersFilterChanged.emit({ isWithAdmins: isWithAdmins });

    }

    changeMedicalReferals(medicalReferals: MedicalReferals): void {

        if (this.usersFilter.medicalReferals && this.usersFilter.medicalReferals == medicalReferals)
            medicalReferals = null;

        const filter: any = { medicalReferals: medicalReferals };

        if (!medicalReferals || medicalReferals == MedicalReferals.Veterinary) {
            filter.speciality = null;
        }

        console.log(filter);

        this.onUsersFilterChanged.emit(filter);
    }

    changeSpeciality(speciality: Speciality): void {

        if (this.usersFilter.speciality && this.usersFilter.speciality == speciality)
            speciality = null;

        this.onUsersFilterChanged.emit({ speciality: speciality });
    }

    changeLanguage(language: Language): void {

        if (this.usersFilter.language && this.usersFilter.language == language)
            language = null;

        this.onUsersFilterChanged.emit({ language: language });
    }

    exportUsersList(): void {
        this.onExportUsersClicked.emit(null);
    }
}
