<div class="dialog-content-wrapper" [fxHide]="!isHaveData">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

        <div class="survey-answer" [fxShow]="qualitySurveyCase.order.guide">

            <h3>Вопросы по шаблонам</h3>

            <table class="mat-table">

                <tr class="mat-row">
                    <td class="question mat-cell">
                        <p class="text-truncate">
                            Удалось ли добиться точного прилегания шаблонов?
                        </p>
                    </td>
                    <td class="answer mat-cell">
                        <p class="text-truncate">
                            <span [fxShow]="qualitySurveyCase.survey.guidesPreciseFit">Да</span>
                            <span [fxShow]="!qualitySurveyCase.survey.guidesPreciseFit">Нет</span>
                        </p>
                    </td>
                </tr>

                <tr class="mat-row">
                    <td class="question mat-cell">
                        <p class="text-truncate">
                            Удобство конструкции шаблонов
                        </p>
                    </td>
                    <td class="answer mat-cell">
                        <p class="text-truncate">
                            {{qualitySurveyCase.survey.guidesConvenientDesign}}
                        </p>
                    </td>
                </tr>

                <tr class="mat-row">
                    <td class="question mat-cell">
                        <p class="text-truncate">
                            Удалось ли применить шаблоны согласно предоперационному планированию?
                        </p>
                    </td>
                    <td class="answer mat-cell">
                        <p class="text-truncate">
                            <span [fxShow]="qualitySurveyCase.survey.guidesAppliedAsPlanned">Да</span>
                            <span [fxShow]="!qualitySurveyCase.survey.guidesAppliedAsPlanned">Нет</span>
                        </p>
                    </td>
                </tr>

            </table>

        </div>

        <div class="survey-answer" [fxShow]="qualitySurveyCase.order.titan || qualitySurveyCase.order.peek">

            <h3>Вопросы по имплантам</h3>

            <table class="mat-table">

                <tr class="mat-row">
                    <td class="question mat-cell">
                        <p class="text-truncate">
                            Удалось ли зафиксировать имплант согласно предоперационному планированию?
                        </p>
                    </td>
                    <td class="answer mat-cell">
                        <p class="text-truncate">
                            <span [fxShow]="qualitySurveyCase.survey.implantSecuredAsPlanning">Да</span>
                            <span [fxShow]="!qualitySurveyCase.survey.implantSecuredAsPlanning">Нет</span>
                        </p>
                    </td>
                </tr>

                <tr class="mat-row">
                    <td class="question mat-cell">
                        <p class="text-truncate">
                            Оцените удобство установки
                        </p>
                    </td>
                    <td class="answer mat-cell">
                        <p class="text-truncate">
                            {{qualitySurveyCase.survey.implantInstallationConvenience}}
                        </p>
                    </td>
                </tr>

                <tr class="mat-row">
                    <td class="question mat-cell">
                        <p class="text-truncate">
                            Удалось ли добиться точного прилегания импланта?
                        </p>
                    </td>
                    <td class="answer mat-cell">
                        <p class="text-truncate">
                            <span [fxShow]="qualitySurveyCase.survey.implantPreciseFit">Да</span>
                            <span [fxShow]="!qualitySurveyCase.survey.implantPreciseFit">Нет</span>
                        </p>
                    </td>
                </tr>

            </table>

        </div>

        <div class="survey-answer">

            <h3>Общие вопросы</h3>

            <table class="mat-table">

                <tr class="mat-row">
                    <td class="question mat-cell">
                        <p class="text-truncate">
                            Содержит ли отчет необходимую и достаточную информацию?
                        </p>
                    </td>
                    <td class="answer mat-cell">
                        <p class="text-truncate">
                            <span [fxShow]="qualitySurveyCase.survey.reportContainSufficientInformation">Да</span>
                            <span [fxShow]="!qualitySurveyCase.survey.reportContainSufficientInformation">Нет</span>
                        </p>
                    </td>
                </tr>

                <tr class="mat-row">
                    <td class="question mat-cell">
                        <p class="text-truncate">
                            Были ли корректно подобраны длины использованных винтов?
                        </p>
                    </td>
                    <td class="answer mat-cell">
                        <p class="text-truncate">
                            <span [fxShow]="qualitySurveyCase.survey.screwsLengthsCorrectlySelected">Да</span>
                            <span [fxShow]="!qualitySurveyCase.survey.screwsLengthsCorrectlySelected">Нет</span>
                        </p>
                    </td>
                </tr>

                <tr class="mat-row">
                    <td class="question mat-cell">
                        <p class="text-truncate">
                            Комплектация поставки представлена в необходимом объеме?
                        </p>
                    </td>
                    <td class="answer mat-cell">
                        <p class="text-truncate">
                            <span [fxShow]="qualitySurveyCase.survey.entireSupplyHasBeenDelivered">Да</span>
                            <span [fxShow]="!qualitySurveyCase.survey.entireSupplyHasBeenDelivered">Нет</span>
                        </p>
                    </td>
                </tr>


                <tr class="mat-row">
                    <td class="question mat-cell">
                        <p class="text-truncate">
                            Комментарий
                        </p>
                    </td>
                    <td class="answer mat-cell">
                        <p class="text-truncate">
                            {{qualitySurveyCase.survey.feedback}}
                        </p>
                    </td>
                </tr>

            </table>

        </div>


    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button *ngIf="action === 'edit'" mat-button class="mr-8" (click)="matDialogRef.close(['cancel',userForm])"
            aria-label="Delete" matTooltip="Delete">
            CLOSE
        </button>

    </div>
</div>