import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { AuthenticationService } from '@appservices';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    isLoading: boolean;
    error: any;
    private returnUrl: string;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private logger: NGXLogger
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });

        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    onSubmit() {

        this.logger.info('login');

        const controls = this.loginForm.controls;

        /** Проверяем форму на валидность */
        if (this.loginForm.invalid) {
            /** Если форма не валидна, то помечаем все контролы как touched*/
            Object.keys(controls)
                .forEach(controlName => controls[controlName].markAsTouched());

            /** Прерываем выполнение метода*/
            return;
        }

        /** TODO: Обработка данных формы */
        this.logger.info(this.loginForm.value);

      
        this.error = null;
        this.isLoading = true;

        this.authenticationService.login(controls['email'].value, controls['password'].value)
            .pipe(first())
            .subscribe(
                data => {

                    this.logger.info('Login success');

                    if (this.returnUrl) {
                        this.router.navigate([this.returnUrl]);
                    }
                    else {
                        this.router.navigate(['/cases']);
                    }
                },
                err => {

                    let error = 'Unknown error';

                    if(err.status === 401)
                    {
                        error = 'Wrong login or password!'
                    }

                    this.error = error;
                    this.isLoading = false;
                });
    }
}
