import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewChildren, ViewEncapsulation, Input, ChangeDetectionStrategy, EventEmitter, Output, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, of, Subject, Subscriber } from 'rxjs';
import { catchError, map, startWith, takeUntil, tap } from 'rxjs/operators';

import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { PlannerMessage, PlannerCase, IUser, FileResponse, FileType, PlannerFile } from '@appmodels';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog, MatSlideToggle } from '@angular/material';
import { FilesService } from 'app/core/services/planner/files.sevice';
import { Lightbox } from 'ngx-lightbox';
import { DownloadDialogComponent } from 'app/core/shared';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';

//import { ChatService } from 'app/pages/chat/chat.service';

@Component({
    selector: 'chat-view',
    templateUrl: './chat-view.component.html',
    styleUrls: ['./chat-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class ChatViewComponent implements OnInit, OnDestroy, AfterViewInit {
    user: any;
    chat: any;
    contact: any;
    replyInput: any;

    isRequestInProgress: boolean = false;
    // replyIsMessageEmail: boolean = true;
    selectedChat: any;

    fileTypeEnum = FileType;

    public selectedFile: File;

    private _messages: PlannerMessage[];

    @Input()
    currentUser: IUser;
    @Input()
    selectedCase: PlannerCase;

    @Input()

    get messages(): PlannerMessage[] {
        return this._messages;
    }
    set messages(value: PlannerMessage[]) {
        this.isRequestInProgress = false;
        if (value.length <= 20) {
            this.readyToReply();
        }
        this.messageReaded();
        this._messages = value;
    }


    @Output()
    onNewMessage: EventEmitter<any>;

    @Output()
    onMessagesReaded: EventEmitter<any>;

    @Output()
    onGetMoreMessages: EventEmitter<any>;

    @ViewChild(FusePerfectScrollbarDirective, { static: false })
    directiveScroll: FusePerfectScrollbarDirective;

    @ViewChildren('replyInput')
    replyInputField;

    @ViewChildren('replyIsMessageEmail')
    replyIsMessageEmailField: QueryList<MatSlideToggle>;

    @ViewChild('replyForm', { static: false })
    replyForm: NgForm;

    // Private
    private _caseMessagesReadedTimer: NodeJS.Timer;
    private _unsubscribeAll: Subject<any>;

    private _hasFocus: Boolean = true;
    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     */
    constructor(
        private _router: Router,
        private _cdr: ChangeDetectorRef,
        private _filesService: FilesService,
        private _lightbox: Lightbox,

        private _downloadFileDialog: MatDialog,

        private logger: NGXLogger
        // private _chatService: ChatService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.onNewMessage = new EventEmitter();
        this.onMessagesReaded = new EventEmitter();
        this.onGetMoreMessages = new EventEmitter();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.selectedChat = this.selectedCase;

        this.user = this.currentUser;

        this.contact = {};

        setInterval(() => {

            const currentHasFocus = document.hasFocus();

            if (!this._hasFocus && currentHasFocus) {
                this.scrollToBottom();
            }

            this._hasFocus = currentHasFocus;

        }, 300);
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this.replyInput = this.replyInputField.first.nativeElement;

        //console.log(this.replyIsMessageEmailField);
        this.replyIsMessageEmailField.first.checked = true;
        //  this.replyIsMessageEmail = this.replyIsMessageEmailField.first.checked;
        // //console.log('asdasda');
        this.readyToReply();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Decide whether to show or not the contact's avatar in the message row
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    shouldShowContactAvatar(message, i): boolean {
        return (
            message.userNameFrom !== this.user.userName &&
            ((this.messages[i + 1] && this.messages[i + 1].userNameFrom === this.user.userName) || !this.messages[i + 1])
        );
    }

    /**
     * Check if the given message is the first message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isFirstMessageOfGroup(message, i): boolean {
        return (i === 0 || this.messages[i - 1] && this.messages[i - 1].userNameFrom !== message.userNameFrom);
    }

    /**
     * Check if the given message is the last message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isLastMessageOfGroup(message, i): boolean {
        return (i === this.messages.length - 1 || this.messages[i + 1] && this.messages[i + 1].userNameFrom !== message.userNameFrom);
    }

    /**
     * Select contact
     */
    goToCase(caseId: string): void {

        this._router.navigate(['/cases/all/' + caseId]);
        //this._chatService.selectContact(this.contact);
    }

    /**
     * Ready to reply
     */
    readyToReply(): void {
        setTimeout(() => {
            this.focusReplyInput();
            this.scrollToBottom();
        });
    }


    private messageReaded(): void {
        const dispatchFunc = () => this.onMessagesReaded.emit();
        clearTimeout(this._caseMessagesReadedTimer);
        this._caseMessagesReadedTimer = setTimeout(dispatchFunc, 2000);
    }

    /**
     * Focus to the reply input
     */
    focusReplyInput(): void {
        setTimeout(() => {
            this.replyInput.focus();
        });
    }

    /**
     * Scroll to the bottom
     *
     * @param {number} speed
     */
    scrollToBottom(speed?: number): void {
        speed = speed || 400;
        if (this.directiveScroll) {
            this.directiveScroll.update();

            setTimeout(() => {
                this.directiveScroll.scrollToBottom(0, speed);
            });
        }
    }

    onChatScroll() {
        console.log(123);
        this.onGetMoreMessages.emit();
    }

    /**
     * Reply
     */
    reply(event): void {
        event.preventDefault();

        if (!this.replyForm.form.value.message && !this.selectedFile) {
            return;
        }

        this.isRequestInProgress = true;

        const message = this.replyForm.form.value.message ? this.replyForm.form.value.message : '';

        const sendToId = this.selectedCase.ownerId;

        const isMessageEmail = this.replyIsMessageEmailField.first.checked; //this.replyForm.form.value.isMessageEmail;

        // console.log(isMessageEmail);

        if (this.selectedFile) {
            this.uploadFile(this.selectedFile).pipe(takeUntil(this._unsubscribeAll))
                .subscribe((messageFile) => {

                    if (messageFile) {

                        //console.log(fileResponse);
                        //const attachedFileId = messageFile.id;
                        this.onNewMessage.emit({ message, sendToId, isMessageEmail, messageFile });
                        this.replyForm.reset();
                        this.removeAttachment();
                    }
                });
        }
        else {
            this.onNewMessage.emit({ message, sendToId, isMessageEmail });
            this.replyForm.reset();
        }
    }

    attachedImgUrl: string | ArrayBuffer;
    selectedFileImg: boolean = false;
    ngfModuleFiles: File[];

    filesSelected(files: File[]): void {

        //  console.log(files.length);

        if (files.length <= 0)
            return;

        this.selectedFile = files[files.length - 1];

        this.selectedFileImg = this.isFileImage(this.selectedFile);

        if (this.selectedFileImg) {
            var reader = new FileReader();

            reader.readAsDataURL(this.selectedFile);

            let observable = new Observable((observer: Subscriber<string | ArrayBuffer>): void => {
                // if success
                reader.onload = ((_event): void => {

                    observer.next(reader.result);
                    observer.complete();
                });
            });

            observable
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(value => {
                    this.attachedImgUrl = value;
                    this._cdr.detectChanges();
                    this.scrollToBottom(1);
                });
        }
    }

    removeAttachment(): void {
        this.selectedFile = null;
        this.ngfModuleFiles = [];
        this.attachedImgUrl = null;
    }

    uploadFile(file: File): Observable<PlannerFile> {


        // const fileGroup = this.filesEditForm.controls[filesIndex.groupName] as FormGroup;
        // const file = this.selectedFile;

        const title = file.name;
        const fileType = this.isFileImage(file) ? FileType.PICTURE : FileType.DOC;
        const additionalInfoObj: any = {};


        let needToZip = false;

        const additionalInfo = JSON.stringify(additionalInfoObj);


        return this._filesService.uploadFile(file, this.selectedCase.id, title, fileType, additionalInfo, needToZip).pipe(

            map(fileResponse => {

                if (fileResponse.status == 'done') {
                    const messageFile = new PlannerFile();
                    messageFile.id = fileResponse.fileId;
                    messageFile.title = title;
                    messageFile.fileName = title;
                    messageFile.fileType = fileType;
                    messageFile.additionalInfo = additionalInfo;
                    messageFile.fileSize = file.size;

                    return messageFile;
                }
                else {
                    return null;
                }
            }),
            catchError(err => {
                throw err;
            })
        );

    }

    downloadFile(plannerFile: PlannerFile): void {
        const fileId = plannerFile.id;
        const fileName = plannerFile.fileName;

        let sub = this._filesService.downloadFile(this.selectedCase.id, fileId, fileName)
            .pipe(takeUntil(this._unsubscribeAll));

        const dialogRef = this._downloadFileDialog.open(DownloadDialogComponent, {
            width: '250px',
            data: sub
        });

        dialogRef.afterClosed().subscribe(result => {
            this.logger.info('The dialog was closed');
        });
    }

    private _cachedUrls = {};
    private _getUrlSubs = {};

    getFileUrl(plannerFile: PlannerFile): string {
        const fileId = plannerFile.id;

        const key = fileId;

        return this._cachedUrls[key];
    }

    getFileUrlAsync(plannerFile: PlannerFile): Observable<string> {

        const fileId = plannerFile.id;
        const fileName = plannerFile.fileName;

        const key = fileId;

        if (!this._cachedUrls[key]) {

            if (!this._getUrlSubs[key]) {

                var getUrlSub = this._filesService.getFileDownloadUrl(this.selectedCase.id, fileId, fileName)
                    .pipe(

                        takeUntil(this._unsubscribeAll),

                        tap(sasLink => {

                            this._cachedUrls[key] = sasLink;

                        })
                    );

                this._getUrlSubs[key] = getUrlSub;

            }

            return this._getUrlSubs[key];

        }
        else {
            return of(this._cachedUrls[key]);
        }

    }

    openImageLightBox(plannerFile: PlannerFile) {
        const fileId = plannerFile.id;
        const fileName = plannerFile.fileName;

        const key = fileId;

        const url = this._cachedUrls[key];

        const album = {
            src: url,
            caption: fileName,
            thumb: ''
        };

        this._lightbox.open([album], 0, { centerVertically: true, showZoom: true, showRotate: true, showDownloadButton: true });
    }

    isFileImage(file: File): boolean {

        const dotExt = file.name.lastIndexOf(".");

        if (dotExt < 0)
            return false;

        const ext = file.name.slice(dotExt + 1, file.name.length).toLowerCase();

        if (ext == "jpeg" || ext == "jpg" || ext == "png" || ext == "bmp")
            return true;

        return false;
    }
}
