import { IAppState } from '../state/app.state'
import { createSelector } from '@ngrx/store';
import { ICasesState } from '../state/cases.interface';

const selectCases = (state: IAppState) => state.cases;

export const selectCasesRequestArgs = createSelector(
    selectCases,
    (state: ICasesState) => {
        const modulesFilter = state.modulesFilter;
        const folder = state.folder;
        const sortType = state.sortType;
        const notAdmins = state.notAdmins;
        const onlyUncompleted = state.onlyUncompleted;
        const onlyWithGuideOrImplant = state.onlyWithGuideOrImplant;
        const forQualityControl = state.forQualityControl;
        const searchPattern = state.searchPattern;
        return { modulesFilter, folder, sortType, searchPattern, notAdmins, onlyUncompleted, onlyWithGuideOrImplant, forQualityControl };
    }
)

export const selectCasesList = createSelector(
    selectCases,
    (state: ICasesState) => state.cases
)

export const selectSelectedCase = createSelector(
    selectCases,
    (state: ICasesState) => state.selectedCase
)

export const selectSelectedCaseShares = createSelector(
    selectCases,
    (state: ICasesState) => state.selectedCaseShares
)

export const selectSelectedCaseBundle = createSelector(
    selectCases,
    (state: ICasesState) => state.selectedCaseBundle
)

export const selectSelectedScene = createSelector(
    selectCases,
    (state: ICasesState) => state.selectedScene
)

export const selectCaseCloneBatchInfo = createSelector(
    selectCases,
    (state: ICasesState) => state.cloneBatchTaskInfo
)

// export interface ICasesRequestArgs {
//     folder: string,
//     searchPattern: string
// }