<div id="cases" class="page-layout carded left-sidebar inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- SIDEBAR -->
    <fuse-sidebar class="sidebar" name="mail-main-sidebar" position="left" lockedOpen="gt-md">
        <cases-main-sidebar [users]="users$ | async"  (onCaseCreate)="createCase($event)" (onUsersSearch)="usersSearch($event)" ></cases-main-sidebar>
    </fuse-sidebar>
    <!-- / SIDEBAR -->

    <!-- CENTER -->
    <div class="center">

        <!-- CONTENT HEADER -->
        <div class="header" fxLayout="row" fxLayoutAlign="start center">

            <div class="search-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="sidebar-toggle" fxHide.gt-md
                    (click)="toggleSidebar('cases-main-sidebar')">
                    <mat-icon>menu</mat-icon>
                </button>

                <div class="search" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="secondary-text">search</mat-icon>
                    
                    <input autocomplete="off" [formControl]="searchInput" matInput (input)="onSearchQuery($event)" [placeholder]="'MAIL.SEARCH_PLACEHOLDER' | translate" fxFlex>
                </div>

            </div>

        </div>
        <!-- / CONTENT HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card" [ngClass]="{'current-mail-selected': selectedCase}">

            <!-- CONTENT TOOLBAR -->

            <tool-bar [selectedCase]="selectedCase$ | async" [selectCasesRequestArgs]="selectCasesRequestArgs$ | async" (onModulesFilterSelected)="modulesFilterSelected($event)" (onQCFilterSelected)="qcFilterSelected()" (onCaseDeselected)="deselectCase()"></tool-bar>
            <!-- / CONTENT TOOLBAR -->

            <!-- CONTENT -->
            <div class="content" fxLayout="row">

                <cases-list fxLayout="column" fxFlex="1 0 30%" [cases]="cases$ | async"
                    [selectedCase]="selectedCase$ | async" (onListScrolled)="getMoreCases()"
                    (onCaseSelected)="selectCase($event)"></cases-list>
                <cases-details fxLayout="column" fxFlex="1 1 70%" [selectedCase]="selectedCase$ | async" [selectedCaseShares]="selectedCaseShares$ | async" [selectedCaseBundle]="selectedCaseBundle$ | async"
                    [selectedScene]="selectedScene$ | async" [users]="users$ | async" (onSceneSelected)="selectScene(selectedCase.id, $event)"
                    [selectCreatedWebLinkToken$]="selectCreatedWebLinkToken$"
                    (onFilesUploaded)="filesUploaded()"    (onFilesUploadedAndAdded)="filesUploadedAndAdded($event)"  (onSceneObjectEvent)="sceneObjectEvent($event)" (onSceneVisibilityGroupsEvent)="sceneVisibilityGroupsEvent($event)"
                     (onFilesPermissionsEvent)="filesPermissionsEvent($event)" (onChatClick)="openChat($event)"
                    (onSaveScene)="saveScene($event)" (onRollbackToScene)="rollbackToScene($event)" (onUsersSearch)="usersSearch($event)" (onCaseEdit)="caseEdit($event)" (onCaseClone)="caseClone($event)" (onCaseSharesUpdate)="caseSharesUpdate($event)"
                    (onCreateCaseWebLink)="createCaseWebLink($event)" (onRevokeCaseWebLink)="revokeCaseWebLink($event)" (onCaseDelete)="caseDelete($event)" (onPlannerFileEdit)="editFile($event)" (onBundleSaved)="saveBundle($event)" (onPlannerFileDelete)="deleteFile($event)" (onNotification)="notify($event)" fusePerfectScrollbar>
                </cases-details>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>