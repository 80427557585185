import { OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_DATE_FORMATS } from 'app/core/helpers';
import { OperationType, SceneType, Gender, MedicalReferals } from '@appmodels';
import { Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
const ɵ0 = APP_DATE_FORMATS;
export class CaseCloneDialogComponent {
    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
    // private _casesService: CasesService,
    matDialogRef, _data, _cdr, logger) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._cdr = _cdr;
        this.logger = logger;
        this.medicalReferalsEnum = MedicalReferals;
        this.operationTypeEnum = OperationType;
        this.sceneTypeEnum = SceneType;
        this.genderEnum = Gender;
        this.medicalReferalsTypeEnum = MedicalReferals;
        this.validateUser = (control) => {
            if (!control || !control.parent) {
                return null;
            }
            const userEmail = control.parent.get('userToClone').value;
            let isValid = false;
            if (!this.foundUsers || this.foundUsers.length == 0) {
                isValid = false;
            }
            else {
                const foundUsersIndex = this.foundUsers.findIndex(user => user.email == userEmail);
                if (foundUsersIndex >= 0)
                    isValid = true;
            }
            return isValid ? null : { wrongUserEmail: true };
        };
        // Set the defaults
        _data.title;
        this.caseCloneForm = this.createCaseCloneForm(_data.title);
        this._unsubscribeAll = new Subject();
        this.onSearchQueryChanged = new EventEmitter();
    }
    set users(value) {
        this.foundUsers = value;
        if (!this._cdr['destroyed']) {
            this._cdr.detectChanges();
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    createCaseCloneForm(title) {
        return new FormGroup({
            newTitle: new FormControl(title, Validators.required),
            userToClone: new FormControl('', [Validators.required, this.validateUser])
        });
    }
    onSearchQuery(searchQuery) {
        if (!searchQuery || searchQuery.length < 3)
            return;
        this.onSearchQueryChanged.emit(searchQuery);
    }
    onSubmit() {
        if (this.caseCloneForm.invalid)
            return;
        const newTitle = this.caseCloneForm.get('newTitle').value;
        const userEmail = this.caseCloneForm.get('userToClone').value;
        const selectedUser = this.foundUsers.find(user => user.email == userEmail);
        this.matDialogRef.close(['clone', newTitle, selectedUser]);
    }
    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
export { ɵ0 };
