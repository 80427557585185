<h1 mat-dialog-title>Downloaded files</h1>
<div *ngIf="fileResponses">
    <div *ngFor="let fileResponse of fileResponses">
        <div mat-dialog-content *ngIf="fileResponse">
            <p>{{fileResponse.fileName}}</p>


            <mat-progress-bar mode="determinate" [value]="fileResponse.progress"></mat-progress-bar>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>

</div>