import { SceneObjectType, PlannerFile, MaterialQuality } from '..';

export class PlannerSceneObject {
    id: string = null;
    uid: string= null;
    title: string= null;
    description: string= null;
    position: string= null;
    rotation: string= null;    
    scale: string= null;
    reflection: string= null;
    text: string= null;
    objectType: SceneObjectType= null;
    color: string= null;
    serializedData: string= null;
    isAutoSegment: boolean= false;
    isVisible: boolean= false;
    hierarchyInfo: string= null;    
    isForPrinting: boolean= false;
    materialQuality: MaterialQuality = null;
    objectFile: PlannerFile= null;
    objectFileAdditional: PlannerFile= null;
    foundationDate: Date = null;
    //objectVisabilityGroupIds: string[]= null;
}