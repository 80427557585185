import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { AppLogsService } from '@appservices';
import { MatSelectModule, MatChipsModule, MatAutocompleteModule } from '@angular/material';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CaseStatusTranslateModule } from 'app/core/helpers';
import { CaseQualitySurveysComponent } from './caseQualitySurveys.component';
import { CaseQualitySurveysListComponent } from './caseQualitySurveys-list/caseQualitySurveys-list.component';
import { CaseQualitySurveyDetailsComponent } from './dialogs/caseQualitySurveys-details.component';
import { CaseManagementDetailsComponent } from '../caseManagement/dialogs/caseManagement-details.component';
import { CaseQualitySurveySelectedBarComponent } from './selected-bar/selected-bar.component';
import { CaseQualitySurveySidebarComponent } from './sidebars/main/main.component';
import { SharedModule } from 'app/core/shared/shared.module';



// const routes: Routes = [
//     {
//         path     : '**',
//         component: ContactsComponent,
//         resolve  : {
//             contacts: ContactsService
//         }
//     }
// ];

@NgModule({
    declarations   : [
        CaseQualitySurveysComponent,
        CaseQualitySurveysListComponent,
        CaseQualitySurveySelectedBarComponent,
        CaseQualitySurveySidebarComponent,
        CaseQualitySurveyDetailsComponent
    ],
    imports        : [
        RouterModule,       
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatSelectModule,
        MatChipsModule,
        MatAutocompleteModule,
        FuseSharedModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,
        InfiniteScrollModule,
        CaseStatusTranslateModule,
        SharedModule
    ],
    providers      : [
        AppLogsService
    ],
    entryComponents: [
        CaseQualitySurveyDetailsComponent
    ]
})
export class CaseQualitySurveysModule
{
}
