import { AbstractControl } from "@angular/forms";

export class NumPositiveValidator {
    static numPositiveValidator(AC: AbstractControl) {

      if (Number(AC.value) <= 0) {
        return {'numPositiveValidator': true};
      } else {
        return null;
      }
    }
  }
  