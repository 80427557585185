import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[setBGColor]'
})
export class BGColorDirective  implements OnInit {   

    private  _bgColor: string;
    private _elementRef: ElementRef;

    constructor(el: ElementRef) {
        this._elementRef = el;
    }

    @Input() set setBGColor(bgColor: string) {
        this._bgColor = bgColor;
    }

    ngOnInit(): void {
        this._elementRef.nativeElement.style.backgroundColor = this._bgColor;
    }
}