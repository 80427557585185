import { PlannerCase, PlannerScene, CaseFolderType, CaseSortType, PlannerCaseShare, PlannerModuleType, LongRunningTaskInfo, PlannerCaseBundle } from '@appmodels';

export interface ICasesState
{
    modulesFilter: PlannerModuleType[];
    folder: CaseFolderType;
    sortType: CaseSortType;
    notAdmins: boolean;
    onlyUncompleted : boolean;
    onlyWithGuideOrImplant : boolean;
    forQualityControl: boolean;
    searchPattern: string;
    cases: PlannerCase[];
    cloneBatchTaskInfo: LongRunningTaskInfo;
    selectedCase: PlannerCase;  
    selectedCaseShares: PlannerCaseShare[]; 
    selectedCaseBundle: PlannerCaseBundle;
    selectedScene: PlannerScene;    
}

export const initialCasesState: ICasesState =
{
    modulesFilter: [],
    folder: null,
    sortType: CaseSortType.CreationDate,
    notAdmins: false,
    onlyUncompleted : false,
    onlyWithGuideOrImplant : false,
    forQualityControl: false,
    searchPattern: null,
    cases: null,
    cloneBatchTaskInfo: null,
    selectedCase: null,
    selectedCaseShares : null,
    selectedCaseBundle: null,
    selectedScene: null
}