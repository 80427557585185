import { NotificationsActions, ENotificationsActions } from '../actions/notifications.actions';
import { INotificationsState, initialNotificationState } from '../state/notifications.interface';

export function notificationsReducers(
    state = initialNotificationState,
    action: NotificationsActions
): INotificationsState {
    switch (action.type) {
        case ENotificationsActions.NewNotification:
            return {
                ...state,
                notification: action.payload
            }
        default:
            return state;
    }
}