import { PlannerAppLogsGroup } from 'app/core/models/planner/plannerAppLogsGroup';
import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { SortOrder } from 'app/core/models/planner/enums/commonEnums';
import { PlannerAppLog } from 'app/core/models/planner/plannerAppLog';

@Injectable({ providedIn: 'root' })
export class AppLogsService {  

    constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient, private logger: NGXLogger) {       
    }

    public getAppLogsGroups(isWithAdmins: boolean, searchPattern:string, sort: string, sortOrder: SortOrder, skip: number, take: number): Observable<PlannerAppLogsGroup[]> 
    {
        const logsSearchPattern = searchPattern ? searchPattern : '';

        const url = `${this.config.apiEndpoint}/api/private/LogsManagement/GetLogsGroups?isWithAdmins=${isWithAdmins}&SearchPattern=${logsSearchPattern}&Sort=${sort}&SortOrder=${sortOrder}&Skip=${skip}&Take=${take}`;
        this.logger.debug("HTTP GET app logs groups.", `Url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => {
                    this.logger.debug("Response app logs groups:", response);                    
                }),
                map(response => response.appLogsGroups));
    }

    public getAppLogsByConnection(connectionId: string): Observable<PlannerAppLog[]> {

        const url = `${this.config.apiEndpoint}/api/private/LogsManagement/GetLogsByConnection?AppConnectionUID=${connectionId}`;
        this.logger.debug("HTTP GET app logs by connection.", `Url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => {
                    this.logger.debug("Response app logs by connection:", response);                    
                }),
                map(response => response.appLogs));
    }
}