import { PlannerSceneObject } from '../planner/plannerSceneObject';

export enum ObjectOperationType
{
    Add,
    Edit,
    Delete
} 

export class ObjectOperationArgs<T>
{
    public get obj(): T {
        return this._obj;
    }

    public get operationType() : ObjectOperationType
    {
        return this._operationType;
    }

    private _obj: T;
    private _operationType: ObjectOperationType;

    constructor(obj: T, operationType: ObjectOperationType)
    {
        this._obj = obj;
        this._operationType = operationType;
    }
}

export class PlannerSceneObjectOperationArgs extends ObjectOperationArgs<PlannerSceneObject>
{
    
}