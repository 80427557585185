import { Action } from '@ngrx/store';
import { PlannerAppLogsGroup } from 'app/core/models/planner/plannerAppLogsGroup';
import { PlannerAppLog } from 'app/core/models/planner/plannerAppLog';

export enum EAppLogsActions {
    SetAppLogsFilterParams  = '[AppLogs] Set App Logs Filter Params',
    SetAppLogsFilterParamsSuccess  = '[AppLogs] Set App Logs Filter Params Success',
    SetAppLogsSearchPattern = '[AppLogs] Set App Logs Search Pattern',
    SetAppLogsSearchPatternSuccess = '[AppLogs] Set App Logs Search Pattern Success',
    GetAppLogsGroups = '[AppLogs] Get App Logs Groups',
    GetAppLogsGroupsSuccess = '[AppLogs] Get App Logs Groups Success',
    GetAppLogsByConnection = '[AppLogs] Get App Logs By Connection',
    GetAppLogsByConnectionSuccess = '[AppLogs] Get App Logs By Connection Success'
}

export class SetAppLogsFilterParams implements Action {
    public readonly type = EAppLogsActions.SetAppLogsFilterParams;

    isWithAdmins: boolean;   
    
    constructor(isWithAdmins: boolean) {      
            this.isWithAdmins = isWithAdmins;
    };
}

export class SetAppLogsFilterParamsSuccess implements Action {
    public readonly type = EAppLogsActions.SetAppLogsFilterParamsSuccess;

    isWithAdmins: boolean;   
    
    constructor(isWithAdmins: boolean) {      
            this.isWithAdmins = isWithAdmins;
    };
}

export class SetAppLogsSearchPattern implements Action {
    public readonly type = EAppLogsActions.SetAppLogsSearchPattern;
    constructor(public payload: string) { };
}

export class SetAppLogsSearchPatternSuccess implements Action {
    public readonly type = EAppLogsActions.SetAppLogsSearchPatternSuccess;
    constructor(public payload: string) { };
}

export class GetAppLogsGroups implements Action {
    public readonly type = EAppLogsActions.GetAppLogsGroups;
    isReload: boolean;
    constructor()
    constructor(isReload: boolean)
    constructor(isReload?: boolean) {
        if (isReload)
            this.isReload = isReload;
        else
            this.isReload = false;
    };
}

export class GetAppLogsGroupsSuccess implements Action {
    public readonly type = EAppLogsActions.GetAppLogsGroupsSuccess;
    constructor(public payload: PlannerAppLogsGroup[]) { 
       
    };
}

export class GetAppLogsByConnection implements Action {
    public readonly type = EAppLogsActions.GetAppLogsByConnection;
    constructor(public payload: string) { 
       
    };
}

export class GetAppLogsByConnectionSuccess implements Action {
    public readonly type = EAppLogsActions.GetAppLogsByConnectionSuccess;
    constructor(public payload: PlannerAppLog[]) { 
       
    };
}


export type AppLogsActions = SetAppLogsFilterParams | SetAppLogsFilterParamsSuccess  | SetAppLogsSearchPattern | SetAppLogsSearchPatternSuccess| GetAppLogsGroups | GetAppLogsGroupsSuccess | GetAppLogsByConnection | GetAppLogsByConnectionSuccess;