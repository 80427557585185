import { BufferOffset } from "app/core/models/planner/buffer/buffer-offset";
import { Mesh } from "app/core/models/planner/geometry/mesh";
import { Vector3 } from "app/core/models/planner/geometry/vector3";
import { BitUtils } from "app/core/utils/bit-utils";

export class STLReader {

    public Read(fileBytes: Uint8Array): Mesh {
        const dstLength = 80;
        const num = 0;

        const srcOffset = num + dstLength;

        const offsetObj = new BufferOffset();

        offsetObj.offset = srcOffset;

        const dataView = new DataView(fileBytes as ArrayBuffer);
        
        const uint32 = BitUtils.ReadUint32(dataView, offsetObj)

        const mesh = new Mesh(uint32 * 3, uint32 * 3);

        for (let index = 0; index < uint32; ++index) {

            BitUtils.ReadVector3(dataView, offsetObj);
            const vector3_1 = BitUtils.ReadVector3(dataView, offsetObj);
            mesh.Vertices[index * 3] = vector3_1;
            const vector3_2 = BitUtils.ReadVector3(dataView, offsetObj);
            mesh.Vertices[index * 3 + 1] = vector3_2;
            const vector3_3 = BitUtils.ReadVector3(dataView, offsetObj);
            mesh.Vertices[index * 3 + 2] = vector3_3;
            mesh.Indices[index * 3] = index * 3;
            mesh.Indices[index * 3 + 1] = index * 3 + 1;
            mesh.Indices[index * 3 + 2] = index * 3 + 2;

            offsetObj.offset += 2;
        }
        return mesh;
    }

    public Write(mesh: Mesh): Uint8Array {

        const trisCount = mesh.Indices.length / 3;

        const fileBytes = new ArrayBuffer(80 + 4 + trisCount * (12+12+12+12+2));

        const dataView = new DataView(fileBytes);

        const offsetObj = new BufferOffset();   

        const headerBuffer = new Uint8Array(80);

        BitUtils.WriteBytes(dataView, headerBuffer, offsetObj);

        BitUtils.WriteUint32(dataView, trisCount, offsetObj);

        const bytesTriEnd = new Uint8Array(2);

        for (let i = 0; i < trisCount; i++) {
            
            const firstV = mesh.Vertices[mesh.Indices[i * 3]];
            const secondV = mesh.Vertices[mesh.Indices[i * 3 + 2]];
            const thirdV = mesh.Vertices[mesh.Indices[i * 3 + 1]];

            const n1v = secondV.Sub(firstV);
            const n2v = secondV.Sub(thirdV);

            const normal = Vector3.Cross(n1v, n2v);

            normal.Normalize();

            BitUtils.WriteVector3(dataView, normal, offsetObj);
            BitUtils.WriteVector3(dataView, firstV, offsetObj);
            BitUtils.WriteVector3(dataView, thirdV, offsetObj);
            BitUtils.WriteVector3(dataView, secondV, offsetObj);
            BitUtils.WriteBytes(dataView, bytesTriEnd, offsetObj);
        }

        return fileBytes as Uint8Array;
    }   
}