import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { PlannerAppLogsGroup } from 'app/core/models/planner/plannerAppLogsGroup';
import { CaseStatus, PlannerCase, PlannerQualitySurveyCase } from '@appmodels';
import { CaseQualitySurveyDetailsComponent } from '../dialogs/caseQualitySurveys-details.component';


@Component({
    selector: 'case-quality-surveys-list',
    templateUrl: './caseQualitySurveys-list.component.html',
    styleUrls: ['./caseQualitySurveys-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseQualitySurveysListComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent', { static: false })
    dialogContent: TemplateRef<any>;

    caseStatusEnum = CaseStatus;


    contacts: any;
    user: any;
    displayedColumns = ['link', 'caseId', 'ownerName', 'order', 'creationDate'];//, 'buttons'];
    selectedContacts: any[];
    checkboxes: {};
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    // Private
    private _unsubscribeAll: Subject<any>;

    @Input()
    qualitySurveyCases: PlannerQualitySurveyCase[] = [];

    @Output()
    onLinkClick: EventEmitter<any>;

    // @Output()
    // onUserDelete: EventEmitter<PlannerUser>;

    /**
     * Constructor
     *
     * @param {UsersService} _contactsService
     * @param {MatDialog} _matDialog
     */
    constructor(

        public _matDialog: MatDialog
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.onLinkClick = new EventEmitter();
      
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

    }

    linkClick(event, caseId: string): void{
        event.stopPropagation();
        this.onLinkClick.emit(caseId);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // viewLogsGroup(appLogsGroup: PlannerAppLogsGroup)
    // {
    //     this.onViewAppLogsGroup.emit(appLogsGroup);
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // public setCaseStatus(caseId: string, caseStatus: CaseStatus):void{
        
    //     this.onSetCaseStatus.emit({caseId, caseStatus});

    // }

    /**
     * Edit user
     *
     * @param user
     */
    showSurvey(qualitySurveyCase): void {
        this.dialogRef = this._matDialog.open(CaseQualitySurveyDetailsComponent, {
            panelClass: 'quality-surveys-cases-form-dialog',
            data: {
                qualitySurveyCase: qualitySurveyCase,
                action: 'edit'
            }
        });

        // this.dialogRef.afterClosed()
        //     .subscribe(response => {
        //         if (!response) {
        //             return;
        //         }

        //         // console.log(response);

        //         const actionType: string = response[0];
        //         const editedUser: PlannerUser = response[1];

        //         switch (actionType) {
        //             /**
        //              * Save
        //              */
        //             case 'save':


        //                 // this._contactsService.updateContact(formData.getRawValue());
        //                 this.onUserEdit.emit(editedUser);


        //                 break;
        //             /**
        //              * Delete
        //              */
        //             case 'cancel':

        //                 //this.deleteContact(user);

        //                 break;
        //         }
        //     });
    }

    // deleteUser(user: PlannerUser): void {
    //     this.onUserDelete.emit(user);
    // }

    /**
     * Delete Contact
     */
    // deleteContact(contact): void {
    //     this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
    //         disableClose: false
    //     });

    //     this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

    //     this.confirmDialogRef.afterClosed().subscribe(result => {
    //         if (result) {
    //             // this._contactsService.deleteContact(contact);
    //         }
    //         this.confirmDialogRef = null;
    //     });

    // }

    /**
     * On selected change
     *
     * @param contactId
     */
    onSelectedChange(contactId): void {
        //  this._contactsService.toggleSelectedContact(contactId);
    }

    /**
     * Toggle star
     *
     * @param contactId
     */
    toggleStar(contactId): void {
        if (this.user.starred.includes(contactId)) {
            this.user.starred.splice(this.user.starred.indexOf(contactId), 1);
        }
        else {
            this.user.starred.push(contactId);
        }

        //  this._contactsService.updateUserData(this.user);
    }
}
