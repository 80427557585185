import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

//import { Mail } from '../../mail.model';
//import { MailService } from '../../mail.service';
import { PlannerCase, SceneType } from 'app/core/models';

@Component({
    selector     : 'cases-list-item',
    templateUrl  : './cases-list-item.component.html',
    styleUrls    : ['./cases-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CasesListItemComponent implements OnInit, OnDestroy
{
    @Input() case: PlannerCase;
    labels: any[];

    @HostBinding('class.selected')
    selected: boolean;

    sceneTypeEnum = SceneType;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {MailService} _mailService
     */
    constructor(
      //  private _mailService: MailService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Set the initial values
        this.case = this.case;//new Mail(this.case);

        // Subscribe to update on selected mail change
        // this._mailService.onSelectedMailsChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(selectedMails => {
        //         this.selected = false;

        //         if ( selectedMails.length > 0 )
        //         {
        //             for ( const mail of selectedMails )
        //             {
        //                 if ( mail.id === this.case.id )
        //                 {
        //                     this.selected = true;
        //                     break;
        //                 }
        //             }
        //         }
        //     });

        // Subscribe to update on label change
        // this._mailService.onLabelsChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(labels => {
        //         this.labels = labels;
        //     });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On selected change
     */
    onSelectedChange(): void
    {
        //this._mailService.toggleSelectedMail(this.case.id);

      
    }

    /**
     * Toggle star
     *
     * @param event
     */
    // toggleStar(event): void
    // {
    //     event.stopPropagation();

    //     //this.case.toggleStar();

    //     this._mailService.updateMail(this.case);
    // }

    /**
     * Toggle Important
     *
     * @param event
     */
    // toggleImportant(event): void
    // {
    //     event.stopPropagation();

    //     //this.case.toggleImportant();

    //     this._mailService.updateMail(this.case);
    // }
}
