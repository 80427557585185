import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { CreatePlannerCaseCommand, SavePlannerSceneCommand, RollbackPlannerSceneCommand, UpdatePlannerCaseCommand, SavePlannerSceneVisibilityGroupsCommand, PlannerCaseRelatedDataContainer, CaseSortType, PlannerCaseShare, UpdatePlannerCaseSharesCommand, ClonePlannerCaseCommand, DeletePlannerCaseCommand, ClonePlannerCaseBatchCommand, SetPlannerCaseStatusCommand, PlannerQualitySurveyCase, SaveBundleCaseCommand, PlannerCaseBundle } from 'app/core/models';
import { AppConfig } from '../../../app-config.module';
import { NGXLogger } from 'ngx-logger';
import { EnumHelpers } from 'app/core/helpers';
import * as i0 from "@angular/core";
import * as i1 from "../../../app-config.module";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-logger";
export class CasesService {
    constructor(config, http, logger) {
        this.config = config;
        this.http = http;
        this.logger = logger;
    }
    getCases(casesRequestArgs, skip, take) {
        let modulesFilterParam = '';
        if (casesRequestArgs.modulesFilter && casesRequestArgs.modulesFilter.length > 0) {
            modulesFilterParam = '';
            for (let i = 0; i < casesRequestArgs.modulesFilter.length; i++) {
                if (i > 0) {
                    modulesFilterParam += '&';
                }
                modulesFilterParam += `Modules=${casesRequestArgs.modulesFilter[i]}`;
            }
        }
        const folderType = casesRequestArgs.folder;
        const sortType = EnumHelpers.getValAsString(CaseSortType, casesRequestArgs.sortType);
        const notAdmins = casesRequestArgs.notAdmins;
        const onlyUncompleted = casesRequestArgs.onlyUncompleted;
        const onlyWithGuideOrImplant = casesRequestArgs.onlyWithGuideOrImplant;
        const forQualityControl = casesRequestArgs.forQualityControl;
        const searchPattern = casesRequestArgs.searchPattern ? casesRequestArgs.searchPattern : '';
        const url = `${this.config.apiEndpoint}/api/public/Cases/Get?${modulesFilterParam}&FolderType=${folderType}&NotAdmins=${notAdmins}&OnlyUncompleted=${onlyUncompleted}&OnlyWithGuideOrImplant=${onlyWithGuideOrImplant}&ForQualityControl=${forQualityControl}
        &SearchPattern=${searchPattern}&Skip=${skip}&Take=${take}&Sort=${sortType}`;
        this.logger.debug("HTTP GET cases list.", `Url: ${url}`);
        return this.http.get(url)
            .pipe(tap(response => {
            this.logger.debug("Response cases list:", response);
        }), map(response => response.cases));
    }
    getCase(caseId) {
        const url = `${this.config.apiEndpoint}/api/public/Cases/GetCase?CaseId=${caseId}`;
        this.logger.debug("HTTP GET case.", `Url: ${url}`);
        return this.http.get(url)
            .pipe(tap(response => {
            this.logger.debug("Response case:", response);
        }), map(response => response));
    }
    getCaseRelatedData(caseId) {
        if (!caseId)
            throw new Error('getRelatedData: caseId is null');
        const url = `${this.config.apiEndpoint}/api/public/Cases/GetRelatedData?caseId=` + caseId;
        this.logger.debug("HTTP GET case related data.", `CaseId: ${caseId},`, `url: ${url}`);
        return this.http.get(url)
            .pipe(tap(response => this.logger.debug("Response case related data:", response)), map(response => {
            return response;
        }));
    }
    createCase(createCaseCommand) {
        const url = `${this.config.apiEndpoint}/api/public/Cases/Add`;
        this.logger.debug("HTTP POST create case.", `New case: ${createCaseCommand},`, `url: ${url}`);
        return this.http.post(url, createCaseCommand)
            .pipe(tap(response => {
            this.logger.debug("Response create case:", response);
        }));
    }
    editCase(updateCaseCommand) {
        const url = `${this.config.apiEndpoint}/api/public/Cases/Update`;
        this.logger.debug("HTTP POST update case.", `Update case: ${updateCaseCommand},`, `url: ${url}`);
        return this.http.post(url, updateCaseCommand)
            .pipe(tap(response => {
            this.logger.debug("Response update case:", response);
        }));
    }
    cloneCase(clonePlannerCaseCommand) {
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/CloneCase`;
        this.logger.debug("HTTP POST clone case.", `Command: ${clonePlannerCaseCommand},`, `url: ${url}`);
        return this.http.post(url, clonePlannerCaseCommand)
            .pipe(tap(response => {
            this.logger.debug("Response clone case:", response);
        }));
    }
    cloneCaseBatch(clonePlannerCaseBatchCommand) {
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/CloneCaseBatch`;
        this.logger.debug("HTTP POST clone case batch.", `Command: ${clonePlannerCaseBatchCommand},`, `url: ${url}`);
        return this.http.post(url, clonePlannerCaseBatchCommand)
            .pipe(tap(response => {
            this.logger.debug("Response clone case batch:", response);
        }));
    }
    getCaseShares(caseId) {
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/GetCaseShares?caseId=` + caseId;
        this.logger.debug("HTTP GET case shares.", `CaseId: ${caseId},`, `url: ${url}`);
        return this.http.get(url)
            .pipe(tap(response => this.logger.debug("Response case shares:", response)), map(response => response.shares));
    }
    updateCaseShares(updateCaseSharesCommand) {
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/UpdateCaseShares`;
        this.logger.debug("HTTP POST update case shares.", `Command: ${updateCaseSharesCommand},`, `url: ${url}`);
        return this.http.post(url, updateCaseSharesCommand)
            .pipe(tap(response => {
            this.logger.debug("Response update case shares:", response);
        }));
    }
    deleteCase(deletePlannerCaseCommand) {
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/DeleteCase`;
        this.logger.debug("HTTP POST delete case.", `Command: ${deletePlannerCaseCommand},`, `url: ${url}`);
        return this.http.post(url, deletePlannerCaseCommand)
            .pipe(tap(response => {
            this.logger.debug("Response delete case:", response);
        }));
    }
    getCaseScenes(caseId) {
        const url = `${this.config.apiEndpoint}/api/public/Scenes/GetCaseScenes?caseId=` + caseId;
        this.logger.debug("HTTP GET case scenes.", `CaseId: ${caseId},`, `url: ${url}`);
        return this.http.get(url)
            .pipe(tap(response => this.logger.debug("Response scenes:", response)), map(response => response.caseScenes));
    }
    getCaseScene(caseId, sceneId) {
        let getCaseUrl = '';
        if (!sceneId) {
            getCaseUrl = `/api/public/Scenes/GetCurrentScene?caseId=` + caseId;
        }
        else {
            getCaseUrl = `/api/public/Scenes/GetScene?caseId=` + caseId + `&sceneId=` + sceneId;
        }
        const url = `${this.config.apiEndpoint}` + getCaseUrl;
        this.logger.debug("HTTP GET case scene.", `CaseId: ${caseId},`, `sceneId: ${sceneId},`, `url: ${url}`);
        return this.http.get(url)
            .pipe(tap(response => this.logger.debug("Response scene:", response)), map(response => response.scene));
    }
    saveSceneVisibilityGroups(savePlannerSceneVisibilityGroupsCommand) {
        const url = `${this.config.apiEndpoint}/api/private/ScenesManagement/SaveSceneVisibilityGroups`;
        this.logger.debug("HTTP POST save scene visibility groups.", `CaseId: ${savePlannerSceneVisibilityGroupsCommand.caseId}`, `sceneId: ${savePlannerSceneVisibilityGroupsCommand.sceneId},`, `url: ${url}`);
        return this.http.post(url, savePlannerSceneVisibilityGroupsCommand)
            .pipe(tap(response => {
            this.logger.debug("Response save scene visibility groups:", response);
        }));
    }
    saveScene(savePlannerCaseSceneCommand) {
        const url = `${this.config.apiEndpoint}/api/private/ScenesManagement/SaveScene`;
        this.logger.debug("HTTP POST save scene.", `CaseId: ${savePlannerCaseSceneCommand.caseId}`, `scene: ${savePlannerCaseSceneCommand.scene},`, `url: ${url}`);
        return this.http.post(url, savePlannerCaseSceneCommand)
            .pipe(tap(response => {
            this.logger.debug("Response save scene:", response);
        }));
    }
    setPlannerCaseStatus(setPlannerCaseStatusCommand) {
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/SetPlannerCaseStatus`;
        this.logger.debug("HTTP POST set planner case status.", `CaseId: ${setPlannerCaseStatusCommand.caseId}`, `status: ${setPlannerCaseStatusCommand.status},`, `url: ${url}`);
        return this.http.post(url, setPlannerCaseStatusCommand)
            .pipe(tap(response => {
            this.logger.debug("Response set planner case status:", response);
        }));
    }
    rollbackScene(rollbackPlannerCaseSceneCommand) {
        const url = `${this.config.apiEndpoint}/api/private/ScenesManagement/RollbackScene`;
        this.logger.debug("HTTP POST rollback scene.", `CaseId: ${rollbackPlannerCaseSceneCommand.caseId}`, `sceneId: ${rollbackPlannerCaseSceneCommand.sceneId},`, `url: ${url}`);
        return this.http.post(url, rollbackPlannerCaseSceneCommand)
            .pipe(tap(response => {
            this.logger.debug("Response rollback scene:", response);
        }));
    }
    getQualitySurveyCases(casesRequestArgs, skip, take) {
        const notAdmins = casesRequestArgs.notAdmins;
        const searchPattern = casesRequestArgs.searchPattern ? casesRequestArgs.searchPattern : '';
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/GetQualitySurveyCase?NotAdmins=${notAdmins}&SearchPattern=${searchPattern}&Skip=${skip}&Take=${take}&Sort=CreationDate`;
        this.logger.debug("HTTP GET quality surveys cases list.", `Url: ${url}`);
        return this.http.get(url)
            .pipe(tap(response => {
            this.logger.debug("Response quality surveys cases list:", response);
        }), map(response => response.qualitySurveys));
    }
    saveCaseBundle(saveBundleCaseCommand) {
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/SaveBundleCase`;
        this.logger.debug("HTTP POST save planner case bundle.", `CaseId: ${saveBundleCaseCommand.caseId}`, `bundle: ${saveBundleCaseCommand.bundle},`, `url: ${url}`);
        return this.http.post(url, saveBundleCaseCommand)
            .pipe(tap(response => {
            this.logger.debug("Response save planner case bundle:", response);
        }));
    }
    getPlannerCaseBundle(caseId) {
        const url = `${this.config.apiEndpoint}/api/private/CasesManagement/GetPlannerCaseBundle?CaseId=${caseId}`;
        this.logger.debug("HTTP GET case bundle.", `Url: ${url}`);
        return this.http.get(url)
            .pipe(tap(response => {
            this.logger.debug("Response case bundle:", response);
        }));
    }
}
CasesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CasesService_Factory() { return new CasesService(i0.ɵɵinject(i1.APP_CONFIG), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.NGXLogger)); }, token: CasesService, providedIn: "root" });
