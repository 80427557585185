<div id="reset-password" fxLayout="column">

    <div id="reset-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="reset-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/logo_black.svg">
            </div>

            <div class="title">RESET YOUR PASSWORD</div>

            <div [fxShow]="!isLinkOk">
                <div class="request-sent red-fg">
                    <span class="mat-subheading-2">Error: wrong reset password link!</span>
                </div>
            </div>
            <div [fxShow]="isLinkOk">
                <div [fxShow]="!requestSent">
                    <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>

                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput formControlName="email">
                            <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                            <mat-error *ngIf="resetPasswordForm.get('email').hasError('required')">
                                Email is required
                            </mat-error>
                            <mat-error *ngIf="resetPasswordForm.get('email').hasError('email')">
                                Please enter a valid email address
                            </mat-error>
                        </mat-form-field>


                        <div class="multiline-error">
                            <mat-form-field appearance="outline">
                                <mat-label>Password</mat-label>
                                <input matInput type="password" formControlName="password">
                                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                                <mat-error *ngIf="resetPasswordForm.get('password').hasError('required')">
                                    Password is required
                                </mat-error>
                                <mat-error style="position: relative;"
                                    *ngIf="resetPasswordForm.get('password').hasError('pattern')">
                                    Your password must be between 6 and 30 characters <br>
                                    Your password must contain at least one lower case letter <br>
                                    Your password must contain at least one upper case letter <br>
                                    Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.) <br>
                                    Your password must contain at least one special character -for example: -, $, #, @,
                                    !,%,^,&,*,(,)
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <mat-form-field appearance="outline">
                            <mat-label>Password (Confirm)</mat-label>
                            <input matInput type="password" formControlName="passwordConfirm">
                            <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                            <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')">
                                Password confirmation is required
                            </mat-error>
                            <mat-error *ngIf="!resetPasswordForm.get('passwordConfirm').hasError('required') &&
                        resetPasswordForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                                Passwords must match
                            </mat-error>
                        </mat-form-field>

                        <button mat-raised-button class="submit-button" (click)="submit()" color="accent"
                            aria-label="RESET MY PASSWORD" [disabled]="resetPasswordForm.invalid">
                            RESET MY PASSWORD
                        </button>
                    </form>
                </div>
                <div [fxShow]="requestSent">
                    <div [fxShow]="(passwordResetStatus$ | async)  == resetPasswordStatusEnum.Default">
                        <div class="request-sent">
                            <span class="mat-subheading-2">Request processing...</span>
                        </div>
                    </div>
                    <div [fxShow]="(passwordResetStatus$ | async)  == resetPasswordStatusEnum.ResetSuccess">
                        <div class="request-sent">
                            <span class="mat-subheading-2">Password has been changed successful!</span>
                        </div>
                    </div>
                    <div [fxShow]="(passwordResetStatus$ | async)  == resetPasswordStatusEnum.ResetError">
                        <div class="request-sent  red-fg">
                            <span class="mat-subheading-2">Error: something went wrong</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/pages/auth/login'">Go back to login</a>
            </div> -->
        </div>
    </div>
</div>