<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Visability groups</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>

        <!-- <div fxLayout="row" flexGrow="1" fxLayoutGap="10px" fxLayoutAlign="stretch"
            *ngFor="let visibilityGroup of visibilityGroups">
            1
        </div> -->


        <form name="composeForm" [formGroup]="visabilityGroupsEditForm" class="visability-groups-edit-form"
            fxLayout="column" fxFlex>

            <div class="p-12" formArrayName="visibilityGroups" *ngFor="let control of visabilityGroupsFormArray; index as i">
                <div [formGroupName]="i">
                    <div fxLayout="row" flexGrow="1" fxLayoutGap="10px">

                        <mat-form-field fxLayout="column" appearance="outline">
                            <mat-label>Group name</mat-label>
                            <input matInput name="groupName" formControlName="groupName" type="text">
                        </mat-form-field>

                        <mat-form-field fxLayout="column" appearance="outline">
                            <mat-label>Order</mat-label>
                            <input matInput name="orderNum" formControlName="orderNum" type="number">
                        </mat-form-field>

                        <div mat-dialog-actions class="group-buttons-wrapper">
                            <!-- <div class="group-buttons">  -->
                            <button mat-raised-button color="basic" (click)="deleteVisabiltyGroup(i)">
                                Delete
                            </button>
                            <!-- </div> -->
                        </div>

                    </div>

 
                    <div  class="visibility-objects">

                        <div *ngFor="let visabilityControl of getArrayGroup(control, 'objectsVisibility'); index as ii"
                            formArrayName="objectsVisibility">

                            <div [formGroupName]="ii" class="objectVisibility" [tooltip]="visabilityControl.get('sceneObjectTitle').value" [options]="{maxWidth: 450, placement:'top', showDelay:600, hideDelay:0}">

                                <mat-slide-toggle name="isInGroup" formControlName="isInGroup">

                                   <span > {{visabilityControl.get('sceneObjectTitle').value}} </span>

                                </mat-slide-toggle>

                                <!-- {{visabilityControl.get('foundationDate').value | date}} -->
                              
                            </div>
                        </div>
                    </div>

                </div>


            </div>

            <div fxLayout="row" flexGrow="1" fxLayoutGap="10px" fxLayoutAlign="stretch">

                <div fxLayout="column" style="width: 100%;">&nbsp;</div>
                <div fxLayout="column" style="width: 100%;">&nbsp;</div>

                <div mat-dialog-actions class="group-buttons-wrapper">
                    <!-- <div class="group-buttons">  -->
                    <button mat-raised-button color="accent" style="width: 74px" (click)="addVisabiltyGroup()">
                        Add
                    </button>
                    <!-- </div> -->
                </div>
            </div>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div>

            <!-- (click)="createCase()"  -->
            <!-- <button mat-raised-button color="accent"> -->
            <!-- (click)="editSceneObject()" class="save-button"
                [disabled]="sceneObjectEditForm.invalid || requestInProgress" aria-label="Create">
                {{isEditingObject ? 'Edit' : 'Add'}} -->
            <!-- Edit
            </button>

            <span class="p-4 red-500-fg">
                {{errorText}}
            </span> -->

            <!-- <button mat-icon-button matTooltip="Attach a file">
                <mat-icon>attach_file</mat-icon>
            </button> -->
        </div>

        <!-- (click)="" -->
        <!-- <button mat-icon-button aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button> -->

        <button mat-raised-button color="accent" [disabled]="visabilityGroupsEditForm.invalid"
            (click)="saveVisibilityGroups()">
            <!-- (click)="editSceneObject()" class="save-button"
            [disabled]="sceneObjectEditForm.invalid || requestInProgress" aria-label="Create">
            {{isEditingObject ? 'Edit' : 'Add'}} -->
            Save
        </button>
    </div>
</div>