const ɵ0 = {
    DOWNLOADS: {
        CLICKTODOWNLOAD: 'CLICK TO DOWNLOAD',
        USERGUIDE: 'User guide',
        DONTHAVEACCOUNT: 'Don\'t have an account?',
        CREATEANACCOUNT: 'Create an account'
    }
};
export const locale = {
    lang: 'en',
    data: ɵ0
};
export { ɵ0 };
