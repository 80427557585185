import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient } from '@angular/common/http';
import { Observable, throwError, Subscriber } from 'rxjs';
import { catchError, first, finalize } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';
import { AppConfig, APP_CONFIG } from 'app/app-config.module';


class CallerRequest {
    subscriber: Subscriber<any>;
    failedRequest: HttpRequest<any>;
};

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

    private refreshInProgress: boolean;
    private requests: CallerRequest[] = [];

    constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient, private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //перехватываем только "наши" запросы
        if (!request.url.startsWith(this.config.apiEndpoint)) {
            return next.handle(request);
        }

        //оборачиваем Observable из вызывающего кода своим, внутренним Observable
        // далее вернем вызывающему коду Observable, который под нашим контролем здесь
        let observable = new Observable<HttpEvent<any>>((subscriber) => {
            //как только вызывающий код сделает подписку мы попадаем сюда и подписываемся на наш HttpRequest
            //тобишь выполняем оригинальный запрос
            let originalRequestSubscription = next.handle(request)
                .subscribe((response) => {
                    //оповещаем в инициатор (success) ответ от сервера 
                    subscriber.next(response);
                },
                    (err) => {
                        
                        if (err.status === 401 && !request.url.includes('login')) {

                            //если споймали 401ую - обрабатываем далее по нашему алгоритму
                            this.handleUnauthorizedError(subscriber, request);
                        } else {
                            //оповещаем об ошибке
                            subscriber.error(err);
                        }
                    },
                    () => {
                        //комплит запроса, отрабатывает finally() инициатора
                        subscriber.complete();
                    });

            return () => {
                // на случай если в вызывающем коде мы сделали отписку от запроса
                // если не сделать отписку и здесь, в dev tools браузера не увидим отмены запросов, т.к инициатор (например Controller) делает отписку от нашего враппера, а не от исходного запроса
                originalRequestSubscription.unsubscribe();
            };
        });

        //вернем вызывающему коду Observable, пусть сам решает когда делать подписку.
        return observable;
    }

    private handleUnauthorizedError(subscriber: Subscriber<any>, request: HttpRequest<any>) {

        //запоминаем "401ый" запрос
        this.requests.push({ subscriber, failedRequest: request });
        if (!this.refreshInProgress) {
            //делаем запрос на восстанавливение токена, и установим флаг, дабы следующие "401ые"
            //просто запоминались но не инициировали refresh
            this.refreshInProgress = true;

            this.authenticationService.tokenLogin().pipe(
                finalize(() => this.refreshInProgress = false)
            )
                .subscribe({
                    //если токен рефрешнут успешно, повторим запросы которые накопились пока мы ждали ответ от рефреша
                    next: () => { this.repeatFailedRequests()},
                    error: () => {
                  
                        //если по каким - то причинам запрос на рефреш не отработал, то делаем логаут
                        this.requests = [];
                        this.authenticationService.logout();
                        location.reload();
                    }
                });
        }
    }

    private repeatFailedRequests() {

        let currentUser = this.authenticationService.currentUser.value;

        this.requests.forEach((c) => {
            //клонируем наш "старый" запрос, с добавлением новенького токена
            const requestWithNewToken = c.failedRequest.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });

            //и повторяем (помним с.subscriber - subscriber вызывающего кода)
            this.repeatRequest(requestWithNewToken, c.subscriber);
        });
        this.requests = [];
    }

    private repeatRequest(requestWithNewToken: HttpRequest<any>, subscriber: Subscriber<any>) {

        //и собственно сам процесс переотправки
        this.http.request(requestWithNewToken).subscribe((res) => {
            subscriber.next(res);
        },
            (err) => {
                if (err.status === 401) {
                    // if just refreshed, but for unknown reasons we got 401 again - logout user
                    this.requests = [];
                    this.authenticationService.logout();
                    location.reload();
                }
                subscriber.error(err);
            },
            () => {
                subscriber.complete();
            });
    }
}