import { GetCaseMessages, EMessagesActions, GetCaseMessagesSuccess, AddCaseMessage, AddCaseMessageSuccess, NewMessageFromServer, NewMessageFromServerSuccess } from '../actions/messages.actions';
import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { CaseReceivedNewMessageSuccess, ECasesActions, GetCases, GetCasesSuccess } from '../actions/cases.actions';
import { switchMap, take, map, tap, mergeMap, takeUntil, filter, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectCasesList, selectSelectedCase } from '../selectors/cases.selector';
import { of, forkJoin, merge, pipe, combineLatest } from 'rxjs';
import { MessagesService } from 'app/core/services/planner/messages.service';
import { IAppState } from '../state/app.state';
import { PlannerMessage } from '@appmodels';
import { selectCurrentUser } from '../selectors/users.selectors';
import { mergeMapHubToAction, startSignalRHub, SIGNALR_HUB_UNSTARTED } from 'ngrx-signalr-core';
import { NewNotification } from '../actions/notifications.actions';
import { AuthenticationService } from '@appservices';
import { NGXLogger } from 'ngx-logger';
import { selectCaseMessages } from '../selectors/messages.selector';

@Injectable()
export class MessagesEffects {

    @Effect()
    getCaseMessages$ = this._actions$.pipe(
        ofType<GetCaseMessages>(EMessagesActions.GetCaseMessages),   
        switchMap(action => {

            this._logger.info(`GetCaseMessages, is reload: ${action.isReload}`);

            if (action.isReload) {
                return of([]).pipe(
                    map(() => {
                        return { caseId: action.caseId, existedMessagesCount: 0, isReload: action.isReload }
                    })
                );
            }
            else {
                return this._store.pipe(
                    select(selectCaseMessages),
                    take(1),
                    map(messages => {
                        return { caseId: action.caseId, existedMessagesCount: messages ? messages.length : 0, isReload: action.isReload }
                    })
                )
            }
        }),
        switchMap((getMessagesRequest) => {

            const skip = getMessagesRequest.isReload ? 0 : getMessagesRequest.existedMessagesCount; //? existedCases.length : 0;
            const take = 20;
            return this._messagesService.getCaseMessages(getMessagesRequest.caseId, skip, take).pipe(
                map(messages => { return { messages: messages, isReload: getMessagesRequest.isReload } })
            );
        }),  
        switchMap((messagesResult) => of(new GetCaseMessagesSuccess(messagesResult.messages, messagesResult.isReload)))  
    );

    @Effect()
    addCaseMessage$ = this._actions$.pipe(
        ofType<AddCaseMessage>(EMessagesActions.AddCaseMessage),
        //map(action => action.payload),
        withLatestFrom(
            this._store.select(selectSelectedCase)
        ),
        map(([addMessageArgs, selectedCase]) => {
            addMessageArgs.messageCreateCommand.PlannerCaseId = selectedCase.id;
            return addMessageArgs;
        }),
        switchMap((addMessageArgs) => {
            return this._messagesService.addCaseMessage(addMessageArgs.messageCreateCommand).pipe(
                withLatestFrom(
                    this._store.select(selectCurrentUser),
                    of(addMessageArgs)

                ));
        }),
        map(([newMessageId, currentUser, addMessageArgs]) => {

            const newMessage = new PlannerMessage();
            newMessage.id = newMessageId;
            newMessage.title = addMessageArgs.messageCreateCommand.Title;
            newMessage.text = addMessageArgs.messageCreateCommand.Text;
            newMessage.userNameFrom = currentUser.userName;

            if (addMessageArgs.messageFile) {
                newMessage.messageFile = addMessageArgs.messageFile;
            }

            newMessage.creationDate = new Date();

            return { newMessage, caseId: addMessageArgs.messageCreateCommand.PlannerCaseId };
        }),
        switchMap((newMessageResult) => [
            new AddCaseMessageSuccess(newMessageResult.newMessage)
        ])
    );

    @Effect()
    newMessageFromServer$ = this._actions$.pipe(
        ofType<NewMessageFromServer>(EMessagesActions.NewMessageFromServer),
        map(action => action.payload),
        withLatestFrom(
            this._store.select(selectSelectedCase)
        ),
        map(([newMessageNotification, selectedCase]) => {

            let isFromAnotherConnectionApp = this._authenticationService.appConnectionUid != newMessageNotification.senderAppConnectionUid;

            if (isFromAnotherConnectionApp) {
                this._store.dispatch(new NewNotification({ type: 'NewMessage', message: `${newMessageNotification.newMessage.userNameFrom}: ${newMessageNotification.newMessage.text}`,
                 action: 'Link', additionalParams:{caseId: newMessageNotification.caseId} }));
            }

            let isFromCurrentCase = selectedCase && selectedCase.id == newMessageNotification.caseId;

            let isNeedToAddToCurrentCaseMessages = isFromAnotherConnectionApp && isFromCurrentCase;

            newMessageNotification.newMessage.isNew = true;
            // console.log(newMessageNotification);

            return { newMessageNotification, isNeedToAddToCurrentCaseMessages };
        }),
        switchMap((newMessageNotificationResult) => [
            new CaseReceivedNewMessageSuccess(newMessageNotificationResult.newMessageNotification.caseId, newMessageNotificationResult.newMessageNotification.newMessage, this._authenticationService.appConnectionUid != newMessageNotificationResult.newMessageNotification.senderAppConnectionUid),
            new NewMessageFromServerSuccess(newMessageNotificationResult.newMessageNotification, newMessageNotificationResult.isNeedToAddToCurrentCaseMessages)
        ])
    );

    constructor(
        private _messagesService: MessagesService,
        private _authenticationService: AuthenticationService,
        private _actions$: Actions,
        private _store: Store<IAppState>,
        private _logger: NGXLogger
    ) { }

}