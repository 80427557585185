<div class="toolbar px-24 py-8">

    <div class="mail-selection" fxFlex="row" fxLayoutAlign="start center">
        <!-- (click)="toggleSelectAll()"   -->
        <!-- <mat-checkbox [checked]="false" [indeterminate]="false"></mat-checkbox> -->

        <button mat-icon-button class="module-filters-button"  [matMenuTriggerFor]="selectMenu">
            <span [ngClass]="{'text-bold':selectCasesRequestArgs.modulesFilter.length>0}">Module filters
                <span
                    *ngIf="selectCasesRequestArgs.modulesFilter.length>0">({{selectCasesRequestArgs.modulesFilter.length}})</span>
            </span>
            <mat-icon>arrow_drop_down</mat-icon>
        </button>

        <mat-checkbox (checked)="selectCasesRequestArgs.forQualityControl" class="qc-button" (click)="selectQCFilter()" aria-label="For QC">
            For QC
        </mat-checkbox>
        <mat-menu #selectMenu="matMenu">
            <div (click)="$event.stopPropagation()">
                <button mat-menu-item (click)="selectModules(plannerModuleType.General)">
                    <mat-icon *ngIf="isModuleSelected(plannerModuleType.General);else menu_else_general">done</mat-icon>
                    <ng-template #menu_else_general><mat-icon></mat-icon> </ng-template>
                    <span>General</span>
                </button>
                <button mat-menu-item (click)="selectModules(plannerModuleType.Spine)">
                    <mat-icon *ngIf="isModuleSelected(plannerModuleType.Spine);else menu_else_spine">done</mat-icon>
                    <ng-template #menu_else_spine><mat-icon></mat-icon> </ng-template>
                    <span>Spine</span>
                </button>
                <button mat-menu-item (click)="selectModules(plannerModuleType.Modules2D)">
                    <mat-icon *ngIf="isModuleSelected(plannerModuleType.Modules2D);else menu_else_2d">done</mat-icon>
                    <ng-template #menu_else_2d><mat-icon></mat-icon> </ng-template>
                    <span>Modules 2D</span>
                </button>
                <button mat-menu-item (click)="selectModules(plannerModuleType.Maxillofacial)">
                    <mat-icon
                        *ngIf="isModuleSelected(plannerModuleType.Maxillofacial);else menu_else_maxillofacial">done</mat-icon>
                    <ng-template #menu_else_maxillofacial><mat-icon></mat-icon> </ng-template>
                    <span>Maxillofacial</span>
                </button>
            </div>
        </mat-menu>



        <div class="toolbar-separator" *ngIf="false"></div>



        <!-- <button mat-icon-button (click)="setFolderOnSelectedMails(4)" *ngIf="hasSelectedMails">
            <mat-icon class="secondary-text">delete</mat-icon>
        </button>

        <button mat-icon-button [matMenuTriggerFor]="folderMenu" *ngIf="hasSelectedMails">
            <mat-icon class="secondary-text">folder</mat-icon>
        </button>
        <mat-menu #folderMenu="matMenu">
            <button mat-menu-item *ngFor="let folder of folders" (click)="setFolderOnSelectedMails(folder.id)">{{folder.title}}
            </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="labelMenu" *ngIf="hasSelectedMails">
            <mat-icon class="secondary-text">label</mat-icon>
        </button>
        <mat-menu #labelMenu="matMenu">
            <button mat-menu-item *ngFor="let label of labels" (click)="toggleLabelOnSelectedMails(label.id)">{{label.title}}
            </button>
        </mat-menu> -->

    </div>

    <div *ngIf="selectedCase" fxHide.gt-xs>
        <!-- (click)="deselectCurrentMail()" -->
        <button mat-icon-button (click)="deselectCase()">
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>
    </div>
</div>