import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { UserFormDialogComponent } from './user-form/user-form.component';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'app/core/store/state/app.state';
import { selectUsers, selectUsersFilters } from 'app/core/store/selectors/users.selectors';
import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { EditUser, DeleteUser, GetUsers, ExportUsersList, SetUsersFilter } from 'app/core/store/actions/users.actions';
import { IUsersFilter } from 'app/core/models/usersFilter';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as russian } from './i18n/ru';

@Component({
    selector: 'users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class UsersComponent implements OnInit, OnDestroy {
    dialogRef: any;
    hasSelectedContacts: boolean;
    searchInput: FormControl;

    usersFilter$: Observable<IUsersFilter>;
    users$: Observable<PlannerUser[]>;

    private _changeSearchPatternTimer: NodeJS.Timer;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UsersService} _contactsService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _matDialog: MatDialog,
        private _store: Store<IAppState>,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, russian);
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this._contactsService.onSelectedContactsChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(selectedContacts => {
        //         this.hasSelectedContacts = selectedContacts.length > 0;
        //     });

        // this.searchInput.valueChanges
        //     .pipe(
        //         takeUntil(this._unsubscribeAll),
        //         debounceTime(300),
        //         distinctUntilChanged()
        //     )
        //     .subscribe(searchText => {
        //         this._contactsService.onSearchTextChanged.next(searchText);
        //     });

        this.usersFilter$ = this._store.pipe(select(selectUsersFilters));
        this.users$ = this._store.pipe(select(selectUsers));
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    onSearchQuery(event: any): void {
        const searchQuery: string = event.target.value;

        const dispatchFunc = () => this._store.dispatch(new SetUsersFilter({ searchQuery: searchQuery }));

        clearTimeout(this._changeSearchPatternTimer);
        this._changeSearchPatternTimer = setTimeout(dispatchFunc, 2000);
    }

    editUser(editUserArgs: any): void {

        const editedUser: PlannerUser = editUserArgs.editedUser;
        const newUserPassword: string = editUserArgs.newPassword;

        console.log(editedUser);

        if (!editedUser)
            return;

        this._store.dispatch(new EditUser(editedUser, newUserPassword));
    }

    deleteUser(deletedUser: PlannerUser): void {

        console.log(deletedUser);

        if (!deletedUser)
            return;

        this._store.dispatch(new DeleteUser(deletedUser.id));
    }

    /**
     * New contact
     */
    newContact(): void {
        this.dialogRef = this._matDialog.open(UserFormDialogComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if (!response) {
                    return;
                }

                //this._contactsService.updateContact(response.getRawValue());
            });
    }

    usersFilterChanged(filter: any): void {


        this._store.dispatch(new SetUsersFilter(filter));

    }

    exportUsers(): void {
        this._store.dispatch(new ExportUsersList());
    }

    /**
     * Toggle the sidebar 
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
}
