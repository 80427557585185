<div class="card-preview">

    <!-- <div class="case-card">

    

        <div>
            <div class="h4 secondary-text">{{sceneObject.title}}</div>
            <div class="h4 secondary-text">{{sceneObject?.objectFile?.title}}</div>

            <mat-icon>remove_red_eye</mat-icon>
            <mat-icon>check_box</mat-icon>
          
            <mat-icon>arrow_downward</mat-icon>
            <mat-icon (click)="editClicked(sceneObject)">edit</mat-icon>
            <mat-icon >delete_forever</mat-icon>
        </div>


    </div> -->


    <div class="case-card" [matMenuTriggerFor]="sceneObjectMenu">

        <mat-menu #sceneObjectMenu="matMenu" overlapTrigger>
            <button mat-menu-item (click)="editClicked(sceneObject)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
            </button>
            <button mat-menu-item [fxShow]="!sceneObject.isVisible" (click)="showHideClicked(sceneObject)">
                <mat-icon>visibility</mat-icon>
                <span>Show</span>
            </button>
            <button mat-menu-item [fxShow]="sceneObject.isVisible" (click)="showHideClicked(sceneObject)">
                <mat-icon>visibility_off</mat-icon>
                <span>Hide</span>
            </button>
            <button mat-menu-item (click)="deleteClicked(sceneObject)">
                <mat-icon>delete_forever</mat-icon>
                <span>Delete</span>
            </button>
        </mat-menu>

        <div [class.light-blue-600]="mouseOvered" (mouseover)="mouseOvered=true" (mouseout)="mouseOvered=false">

            <div class="object-visibility">
                <mat-icon class="secondary-text" [fxShow]="sceneObject.isVisible">visibility</mat-icon>
                <mat-icon class="secondary-text" [fxShow]="!sceneObject.isVisible">visibility_off</mat-icon>
            </div>

            <div class="p-8">
                <div class="h3 font-weight-300 text-rows">
                    <div>{{sceneObject.title}}</div>
                </div>
                <div class="mt-4 h5 secondary-text text-rows">
                    <div *ngIf="sceneObject?.objectFile">File: {{sceneObject?.objectFile?.title}}</div>
                </div>
            </div>
        </div>
    </div>

</div>