/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./content.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./content.component";
var styles_ContentComponent = [i0.styles];
var RenderType_ContentComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ContentComponent, data: {} });
export { RenderType_ContentComponent as RenderType_ContentComponent };
function View_ContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "content", [], null, null, null, View_ContentComponent_0, RenderType_ContentComponent)), i1.ɵdid(1, 49152, null, 0, i4.ContentComponent, [], null, null)], null, null); }
var ContentComponentNgFactory = i1.ɵccf("content", i4.ContentComponent, View_ContentComponent_Host_0, {}, {}, []);
export { ContentComponentNgFactory as ContentComponentNgFactory };
