import { Injectable } from '@angular/core';import { Store } from '@ngrx/store';import { IAppState } from '../store/state/app.state';import { NGXLogger } from 'ngx-logger';import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';import { Observable, of, forkJoin } from 'rxjs';import { selectUsers } from '../store/selectors/users.selectors';import { filter, take, tap } from 'rxjs/operators';import { GetUsers } from '../store/actions/users.actions';
import { selectAppLogsGroups } from '../store/selectors/appLogs.selector';
import { GetAppLogsGroups, SetAppLogsFilterParams } from '../store/actions/appLogs.actions';

@Injectable({ providedIn: 'root' })
export class AppLogsResolver {
    constructor(private _store: Store<IAppState>, private logger: NGXLogger) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        const routeParams = route.params;

        this.logger.info('App logs route resolver: ', route.params)

        const obsCollection: Observable<any>[] = [];    

        obsCollection.push(this._store.select(selectAppLogsGroups).pipe(
            filter(appLogsGroups => appLogsGroups != null),           
            take(1)
        ));

        obsCollection.push(of(this._store.dispatch(new SetAppLogsFilterParams(false))));       

        return forkJoin(obsCollection);
    }
}