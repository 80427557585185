import { FormGroup } from '@angular/forms';

export class ObjectMapper {
  static mapFormGroupToObject<T>(formGroup: FormGroup, object: T) {

    let objectKeys = Object.keys(object);

    Object.keys(formGroup.controls).forEach(control => {
      if (objectKeys.includes(control)) {
        object[control] = formGroup.controls[control].value;
      }
    });

  }

  static mapObjectToFormGroup<T>(object: T, formGroup: FormGroup) {

    let objectKeys = Object.keys(object);

    Object.keys(formGroup.controls).forEach(control => {
      if (objectKeys.includes(control)) {
        formGroup.controls[control].setValue(object[control]);
      }
    });

  }

  static mapObjectToObject<T1, T2>(object1: T1, object2: T2) {

    let object1Keys = Object.keys(object1);
    let object2Keys = Object.keys(object2);

    object2Keys.forEach(field => {
      if (object1Keys.includes(field)) {
        object2[field] = object1[field];
      }
    });

  }

  static mapObjectToURLParams<T>(object: T) {

    let urlParamsString: string = '';

    let objectKeys = Object.keys(object);

    objectKeys.forEach(objectKey => {

      if (urlParamsString.length > 0)
        urlParamsString += '&';

      urlParamsString += objectKey + '=' + object[objectKey];

    });

    return urlParamsString;
  }
}
