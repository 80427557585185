export enum LogActionType {
    Default = 0,

    Error = 1,
    ExceptionHandled = 2,
    ExceptionUnhandled = 3,

    Login = 11,
    Logout = 12,

    AppUserConfig = 21,
    AppPing = 22,
    AppTelemetry = 23,

    SignalRReconnected = 31,
    SignalRСonnected = 32,
    SignalRDisconnected = 33,

    CaseSelected = 41,
    CaseEntered = 42,
    CaseModelsLoaded = 43,
    CaseExited = 44,
    CaseCreated = 45,
    CaseEdited = 46,
    CaseDicomUploaded = 47,

    SceneSaved = 51,
    SceneRollbacked = 52,
    SceneUserCommitted = 53,

    UserPlannerCommand = 61,
    UserMessageSended = 62,

    PacsCheckServer = 71,
    PacsSetServer = 72,
    PacsSerachDicomStudies = 73,
    PacsSerachDicomSeries = 74,
    PacsDownloadDicoms = 75
}