import { selectUsers } from '../store/selectors/users.selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/state/app.state';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { GetUsers, ResetUsersFilter, SetUsersFilter } from '../store/actions/users.actions';

@Injectable({ providedIn: 'root' })
export class UsersResolver {
    constructor(private _store: Store<IAppState>, private logger: NGXLogger) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        const routeParams = route.params;

        this.logger.info('Users route resolver: ', route.params)

        const obsCollection: Observable<any>[] = [];

        obsCollection.push(this._store.select(selectUsers).pipe(
            filter(allUsers => allUsers != null),
            take(1)
        ));

        obsCollection.push(of(this._store.dispatch(new ResetUsersFilter({ isWithAdmins: false }))));

        return forkJoin(obsCollection);
    }
}