import { Component, Inject, ViewEncapsulation, OnDestroy, ChangeDetectionStrategy, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MatAutocompleteSelectedEvent } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS, DateValidator, ObjectMapper } from 'app/core/helpers';
import { OperationType, SceneType, Gender, MedicalReferals, CreatePlannerCaseCommand, PlannerCaseShare } from '@appmodels';
import { CasesService } from '@appservices';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { PlannerUser } from 'app/core/models/planner/plannerUser';

@Component({
    selector: 'case-share',
    templateUrl: './case-share.component.html',
    styleUrls: ['./case-share.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class CaseShareDialogComponent implements OnDestroy {

    //medicalReferalsEnum = MedicalReferals;
    //operationTypeEnum = OperationType;
    //sceneTypeEnum = SceneType;
    //genderEnum = Gender;
    //medicalReferalsTypeEnum = MedicalReferals;

    caseShares: PlannerCaseShare[];

    caseShareForm: FormGroup;
    addCaseShareForm: FormGroup;
    errorText: string;
    requestInProgress: boolean;

    @Input()
    set users(value: PlannerUser[]) {
        this.foundUsers = value;
        if (!this._cdr['destroyed']) {
            this._cdr.detectChanges();
        }
    }

    foundUsers: PlannerUser[];

    @Output()
    onSearchQueryChanged: EventEmitter<string>;

    private _unsubscribeAll: Subject<any>;

    get caseSharesEntries(): AbstractControl[] {
        return (this.caseShareForm.get('caseSharesEntries') as FormArray).controls;
    }

    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        // private _casesService: CasesService,
        public matDialogRef: MatDialogRef<CaseShareDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _cdr: ChangeDetectorRef,
        private logger: NGXLogger
    ) {
        // Set the defaults
        _data.title;

        this.caseShares = _data.caseShares;

        this.caseShareForm = this.createCaseShareForm(this.caseShares);
        this.addCaseShareForm = this.createAddCaseShareForm();

        this._unsubscribeAll = new Subject();

        this.onSearchQueryChanged = new EventEmitter();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    createCaseShareForm(caseShares: PlannerCaseShare[]): FormGroup {

        const caseSharesFormGroups = new FormGroup({});

        const caseSharesFormGroupsArray = new FormArray([]);

        caseShares.forEach(caseShare => {

            var shareFormGroup = new FormGroup({
                // newTitle: new FormControl(title, Validators.required),
                userToShare: new FormControl({ value: caseShare.shareToName, disabled: true }, [Validators.required]),
                userToShareId: new FormControl({ value: caseShare.shareToId, disabled: true }, [Validators.required])
            });

            caseSharesFormGroupsArray.push(shareFormGroup);
        });

        caseSharesFormGroups.addControl('caseSharesEntries', caseSharesFormGroupsArray);

        return caseSharesFormGroups;
    }

    createAddCaseShareForm(): FormGroup {


        var shareFormGroup = new FormGroup({
            // newTitle: new FormControl(title, Validators.required),
            userToShare: new FormControl('', [Validators.required])
        });

        return shareFormGroup;
    }

    onSearchQuery(searchQuery) {

        if (!searchQuery || searchQuery.length < 3)
            return;

        this.onSearchQueryChanged.emit(searchQuery);
    }

    addShareEntry() {

        if (this.addCaseShareForm.invalid)
            return;

        const formArray = this.caseSharesEntries;



        //  const newTitle = this.caseShareForm.get('newTitle').value;
        const userEmail = this.addCaseShareForm.get('userToShare').value;

        const selectedUser = this.foundUsers.find(user => user.email == userEmail);

        var shareFormGroup = new FormGroup({
            // newTitle: new FormControl(title, Validators.required),
            userToShare: new FormControl({ value: userEmail, disabled: true }, [Validators.required]),
            userToShareId: new FormControl({ value: selectedUser.id, disabled: true }, [Validators.required])
        });

        formArray.push(shareFormGroup);
    }

    deleteShareEntry(index) {
        const formArray = this.caseSharesEntries;

        formArray.splice(index, 1);
    }


    onSubmit() {

        //console.log(this.caseShareForm);

        if (this.caseShareForm.invalid)
            return;

        //  const newTitle = this.caseShareForm.get('newTitle').value;
        //   const userEmail = this.addCaseShareForm.get('userToShare').value;

        //  const selectedUser = this.foundUsers.find(user => user.email == userEmail);

        const formArray = this.caseSharesEntries;

        const usersToShare = [];

        formArray.forEach(control => {
            const userToShareId = control.get('userToShareId').value;

            //const userToShare = this.foundUsers.find(user => user.email == userToShareName);

            usersToShare.push(userToShareId);
        })

        this.matDialogRef.close(['share', usersToShare]);
    }

    validateUser: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

        if (!control || !control.parent) {
            return null;
        }

        const userEmail = control.parent.get('userToShare').value;

        let isValid = false;

        if (!this.foundUsers || this.foundUsers.length == 0) {
            isValid = false;
        }
        else {

            const foundUsersIndex = this.foundUsers.findIndex(user => user.email == userEmail);

            if (foundUsersIndex >= 0)
                isValid = true;
        }

        return isValid ? null : { wrongUserEmail: true };
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
