import { Component, Inject, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ElementRef, OnInit, ViewChild, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { MedicalReferals, Language, PlannerRoles, Speciality, PlannerUsersAvailableModules, SceneType, PlannerModuleType, GenerateModuleLicenseCommand } from '@appmodels';
import { confirmPasswordValidator, DateValidator, NumPositiveValidator, ObjectMapper, passwordPattern } from 'app/core/helpers';
import { MatOption, MatAutocomplete, MatAutocompleteSelectedEvent, MatInput } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../../../i18n/en';
import { locale as russian } from '../../../i18n/ru';
import { AfterViewInit } from '@angular/core';
import { ViewChildren } from '@angular/core';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'paidModulesOffline-form-dialog',
    templateUrl: './paidModulesOffline-form.component.html',
    styleUrls: ['./paidModulesOffline-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PaidModulesOfflineFormDialogComponent implements OnDestroy {
    @Input()
    set users(value: PlannerUser[]) {
        this.foundUsers = value;
        if (!this._cdr['destroyed']) {
            this._cdr.detectChanges();
        }
    }

    foundUsers: PlannerUser[];

    @Output()
    onSearchQueryChanged: EventEmitter<string>;

    // medicalReferalsEnum = MedicalReferals;
    // operationTypeEnum = OperationType;
    moduleTypeEnum = PlannerModuleType;


    // showExtraToFields: boolean;
    generateLicenseForm: FormGroup;
    errorText: string;
    requestInProgress: boolean;

    isEditingCase: boolean;

    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {MatDialogRef<GenerateLicenseDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        // private _casesService: CasesService,
        public matDialogRef: MatDialogRef<PaidModulesOfflineFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _cdr: ChangeDetectorRef,
        private logger: NGXLogger
    ) {

        // Set the defaults
        this.generateLicenseForm = this.createGenerateLicenseForm();
        // this.showExtraToFields = false;
        this._unsubscribeAll = new Subject();

        this.onSearchQueryChanged = new EventEmitter();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    createGenerateLicenseForm(): FormGroup {

        var generateLicenseForm = new FormGroup({

            owner: new FormControl({
                value: '',
                disabled: true
            }),

            requestUid: new FormControl('', Validators.required),

            moduleType: new FormControl('', Validators.required),

            startDate: new FormControl(new Date(), [Validators.required, DateValidator.dateVaidator]),

            days: new FormControl(0, [Validators.required, NumPositiveValidator.numPositiveValidator]),

            hospital: new FormControl('', [Validators.required]),
        });


        return generateLicenseForm;
    }

    validateUser: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

        if (!control || !control.parent) {
            return null;
        }

        const userEmail = control.parent.get('owner').value;

        let isValid = false;

        if (!this.foundUsers || this.foundUsers.length == 0) {
            isValid = false;
        }
        else {

            const foundUsersIndex = this.foundUsers.findIndex(user => user.email == userEmail);

            if (foundUsersIndex >= 0)
                isValid = true;
        }

        return isValid ? null : { wrongUserEmail: true };
    }

    generateLicense() {

        if (this.generateLicenseForm.invalid)
            return;

        var generateLicenseCommand = new GenerateModuleLicenseCommand();
        generateLicenseCommand.RequestUid = this.generateLicenseForm.get('requestUid').value;
        generateLicenseCommand.ModuleType = this.generateLicenseForm.get('moduleType').value;
        generateLicenseCommand.StartDate = this.generateLicenseForm.get('startDate').value;
        generateLicenseCommand.Days = this.generateLicenseForm.get('days').value;
        generateLicenseCommand.Hospital = this.generateLicenseForm.get('hospital').value;

        this.matDialogRef.close(['save', generateLicenseCommand]);
    }


    onSearchQuery(searchQuery) {

        if (!searchQuery || searchQuery.length < 3)
            return;

        this.onSearchQueryChanged.emit(searchQuery);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
