
import { NgModule } from '@angular/core';
import { CaseStatusStyleDirective,  SafePipe } from '../helpers';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { DownloadDialogComponent, ConfirmDialogComponent } from './';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { BGColorDirective } from '../helpers/bg-color/bg-color.directive';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatMenuModule, MatProgressSpinner, MatProgressSpinnerModule, MatRippleModule, MatSelectModule,  ProgressSpinnerMode, TooltipComponent } from '@angular/material';
import { FuseWidgetModule, FuseSidebarModule } from '@fuse/components';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgArrayPipesModule, NgPipesModule, OrderByPipe } from 'ngx-pipes';
import { TooltipModule } from 'ng2-tooltip-directive';
import { OrderByExtPipe } from '../helpers/pipes/orderByExt';
import { LightboxModule } from 'ngx-lightbox';
import { ReplaceNewLinePipe } from '../helpers/pipes/replace-new-line.pipe';

@NgModule({
    declarations: [
        CaseStatusStyleDirective,
        BGColorDirective,
        ConfirmDialogComponent,
        DownloadDialogComponent,
        SafePipe,
        OrderByExtPipe,
        ReplaceNewLinePipe
       
    ],
    imports        : [
        CommonModule,
        BrowserModule,
        MatDialogModule,
        MatInputModule,
        MatProgressBarModule,
        MatIconModule,

        MatButtonModule,
        MatCheckboxModule,        
        MatFormFieldModule,       
        MatMenuModule,
        MatRippleModule,
        MatSelectModule,        
        FuseWidgetModule,
        TranslateModule,
        FuseSharedModule,
        FuseSidebarModule,
        NgArrayPipesModule,
        TooltipModule,
        LightboxModule ,
        MatProgressSpinnerModule,
    ],
    exports: [
        CaseStatusStyleDirective,
        BGColorDirective,
        DownloadDialogComponent,
        SafePipe,
        OrderByPipe,
        OrderByExtPipe,
        ReplaceNewLinePipe,
        TooltipModule,
        MatProgressSpinnerModule
    ],
    entryComponents: [
        ConfirmDialogComponent,
        DownloadDialogComponent
    ]
})
export class SharedModule{}