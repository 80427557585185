import { FileAllowAction, PlannerFile } from '@appmodels';
import { Action } from '@ngrx/store';

export enum EFilesActions {
    EditFile = '[Files] Edit File',
    EditFileSuccess = '[Files] Edit File Success',
    DeleteFile = '[Files] Delete File',
    DeleteFileSuccess = '[Files] Delete File Success',
    EditFilesPermissions = '[Files] Edit Files Permissions',
    EditFilesPermissionsSuccess = '[Files] Edit Files Permissions Success',
}

export class EditFile implements Action {
    public readonly type = EFilesActions.EditFile;
    constructor(public payload: PlannerFile) { };
}

export class EditFileSuccess implements Action {
    public readonly type = EFilesActions.EditFileSuccess;
    constructor(public payload: PlannerFile) { };
}

export class DeleteFile implements Action {
    public readonly type = EFilesActions.DeleteFile;
    constructor(public payload: PlannerFile) { };
}

export class DeleteFileSuccess implements Action {
    public readonly type = EFilesActions.DeleteFileSuccess;
    constructor(public payload: PlannerFile[]) { };
}

export class EditFilesPermissions implements Action {
    public readonly type = EFilesActions.EditFilesPermissions;
    constructor(public payload: FileAllowAction[]) { };
}

export class EditFilesPermissionsSuccess implements Action {
    public readonly type = EFilesActions.EditFilesPermissionsSuccess;
    constructor(public payload: boolean) { };
}


export type FilesActions = EditFile | EditFileSuccess | DeleteFile | DeleteFileSuccess | EditFilesPermissions | EditFilesPermissionsSuccess;