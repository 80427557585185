import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { ProjectDashboardComponent } from './project.component';
import { ProjectDashboardService } from './project.service';
import { OnlineUsersComponent } from './presentation/online-users/online-users.component';
import { HeaderComponent } from './presentation/header/header.component';
import { AnalyticsComponent } from './presentation/analytics/analytics.component';
import { MatButtonToggleModule } from '@angular/material';
import { ChartsModule } from 'ng2-charts';
import { SharedModule } from 'app/core/shared/shared.module';

// const routes: Routes = [
//     {
//         path     : '**',
//         component: ProjectDashboardComponent,
//         resolve  : {
//             data: ProjectDashboardService
//         }
//     }
// ];

@NgModule({
    declarations: [
       
        ProjectDashboardComponent,
        OnlineUsersComponent,
        HeaderComponent,
        AnalyticsComponent
    ],
    imports     : [
       // RouterModule.forChild(routes),
MatButtonToggleModule,
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTableModule,
        MatTabsModule,

        ChartsModule,
        NgxChartsModule,

        FuseSharedModule,
        FuseSidebarModule,
        FuseWidgetModule,
        SharedModule
    ],
    providers   : [
        ProjectDashboardService
    ]
})
export class ProjectDashboardModule
{
}

