import { Component, ChangeDetectionStrategy, OnInit, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material";

@Component({
    selector: 'notification-snackbar',
    templateUrl: './notification-snackbar.component.html',
    styleUrls: ['./notification-snackbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
  })  
  export class NotificationSnackbarComponent implements OnInit {

public message:string;
public action: string;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) private _data) {
          
this.message=this._data.message;
this.action=this._data.action;
        }

    ngOnInit() {
    }

    public actionClicked():void
    {
        
        this._data.onAction();
    }

    onBarClicked(){
        this._data.onBarClicked(); //access pclose function when you want to close snackbar
      }
  }