import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';

import { TranslateModule } from '@ngx-translate/core';

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule, FuseWidgetModule } from '@fuse/components';

import { MatStepperModule } from '@angular/material/stepper';

import { CaseStatusTranslateModule } from 'app/core/helpers';
import { CasesComponent } from './cases.component';
import { CasesListComponent } from './cases-list/cases-list.component';
import { CasesListItemComponent } from './cases-list/cases-list-item/cases-list-item.component';
import { CasesDetailsComponent } from './cases-details/cases-details.component';
import { CasesMainSidebarComponent } from './sidebars/main/main-sidebar.component';
import { CaseEditDialogComponent } from './dialogs/case-edit/case-edit.component';
import { ngfModule, ngf } from "angular-file";
import { CasesService } from '@appservices';
import { SceneObjectItemComponent } from './cases-details/presentation/scene-object-item/scene-object-item.component';
import { SceneObjectFilterPipe } from './cases-details/scene-object-filter.pipe';
import { ScenesTimeLineComponent } from './cases-details/presentation/scenes-timeline/scenes-timeline.component';
import { SharedModule } from 'app/core/shared/shared.module';
import { FilesService } from 'app/core/services/planner/files.sevice';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule, MatDividerModule, MatProgressBarModule, MatAutocompleteModule } from '@angular/material';
import { AddFileDialogComponent } from './dialogs/add-file/add-file.component';
import { EditSceneObjectDialogComponent } from './dialogs/edit-scene-object/edit-scene-object.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CasesToolBarComponent } from './cases-tool-bar/cases-tool-bar.component';
import { CaseCloneDialogComponent } from './dialogs/case-clone/case-clone.component';
import { EditVisabilityGroupsComponent } from './dialogs/edit-visability-groups/edit-visability-groups.component';
import { CaseFileItemComponent } from './cases-details/presentation/case-file-item/case-file-item.component';
import { NgArrayPipesModule } from 'ngx-pipes';
import { CaseShareDialogComponent } from './dialogs/case-share/case-share.component';
import { EditFileDialogComponent } from './dialogs/edit-file/edit-file.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { EditFilePermissionsComponent } from './dialogs/edit-file-permissions/edit-file-permissions.component';
import { CaseWebLinkDialogComponent } from './dialogs/case-weblink/case-weblink.component';
import { EditBundleComponent } from './dialogs/edit-bundle/edit-bundle.component';

@NgModule({
    declarations: [
        CasesComponent,
        CasesToolBarComponent,
        CasesListComponent,
        CasesListItemComponent,
        CasesDetailsComponent,
        CasesMainSidebarComponent,
        CaseEditDialogComponent,
        CaseCloneDialogComponent,
        CaseShareDialogComponent,
        CaseWebLinkDialogComponent,
        AddFileDialogComponent,
        EditFileDialogComponent,
        EditSceneObjectDialogComponent,
        SceneObjectItemComponent,
        SceneObjectFilterPipe,
        CaseFileItemComponent,
        ScenesTimeLineComponent,
        EditVisabilityGroupsComponent,
        EditBundleComponent,
        EditFilePermissionsComponent
    ],
    imports: [        
        RouterModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatSelectModule,
        MatToolbarModule,
        FuseWidgetModule,
        TranslateModule,

        FuseSharedModule,
        FuseSidebarModule,

        MatExpansionModule,
        MatCardModule,

        MatStepperModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        InfiniteScrollModule,

        ngfModule,

        CaseStatusTranslateModule,
      // NgArrayPipesModule,
        SharedModule

    ],
    providers: [
        CasesService,
        FilesService        
    ],
    entryComponents: [
        CaseEditDialogComponent,
        CaseCloneDialogComponent,
        CaseShareDialogComponent,
        CaseWebLinkDialogComponent,
        AddFileDialogComponent,
        EditFileDialogComponent,
        EditBundleComponent,
        EditSceneObjectDialogComponent,
        EditVisabilityGroupsComponent,
        EditFilePermissionsComponent
    ]
})

export class CasesModule {
}
