/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import * as i5 from "../../../../../@fuse/services/config.service";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/router";
import * as i8 from "@angular/material/core";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./main.component";
var styles_CaseManagementSidebarComponent = [i0.styles];
var RenderType_CaseManagementSidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CaseManagementSidebarComponent, data: {} });
export { RenderType_CaseManagementSidebarComponent as RenderType_CaseManagementSidebarComponent };
export function View_CaseManagementSidebarComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "sidebar-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "header p-24"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(4, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "avatar mr-16"], ["src", "/assets/images/avatars/profile.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "h5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Case management"])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "content py-16"], ["fusePerfectScrollbar", ""]], null, [["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._updateOnResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).documentClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 4407296, null, 0, i4.FusePerfectScrollbarDirective, [i1.ElementRef, i5.FuseConfigService, i6.Platform, i7.Router], { enabled: [0, "enabled"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "nav material2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["aria-label", "inbox"], ["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "a", [["class", "nav-link active accent mat-ripple"], ["matRipple", ""]], [[2, "mat-ripple-unbounded", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeFilter(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 212992, null, 0, i8.MatRipple, [i1.ElementRef, i1.NgZone, i6.Platform, [2, i8.MAT_RIPPLE_GLOBAL_OPTIONS], [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Users only cases"]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "start center"; _ck(_v, 4, 0, currVal_1); var currVal_2 = ""; _ck(_v, 9, 0, currVal_2); _ck(_v, 13, 0); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 13).unbounded; _ck(_v, 12, 0, currVal_3); }); }
export function View_CaseManagementSidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "contacts-main-sidebar", [], null, null, null, View_CaseManagementSidebarComponent_0, RenderType_CaseManagementSidebarComponent)), i1.ɵdid(1, 245760, null, 0, i10.CaseManagementSidebarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CaseManagementSidebarComponentNgFactory = i1.ɵccf("contacts-main-sidebar", i10.CaseManagementSidebarComponent, View_CaseManagementSidebarComponent_Host_0, { appLogsFilterParams: "appLogsFilterParams" }, { onIsWithAdminsChanged: "onIsWithAdminsChanged" }, []);
export { CaseManagementSidebarComponentNgFactory as CaseManagementSidebarComponentNgFactory };
