import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'app/core/store/state/app.state';
import { selectUsers } from 'app/core/store/selectors/users.selectors';
import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { EditUser, DeleteUser } from 'app/core/store/actions/users.actions';
import { selectAppLogsGroups, selectAppLogsByConnection, selectAppLogsFilterParams } from 'app/core/store/selectors/appLogs.selector';
import { PlannerAppLogsGroup } from 'app/core/models/planner/plannerAppLogsGroup';
import { GetAppLogsGroups, GetAppLogsByConnection, SetAppLogsFilterParams, SetAppLogsSearchPattern } from 'app/core/store/actions/appLogs.actions';
import { CaseManagementDetailsComponent } from './dialogs/caseManagement-details.component';
import { selectCasesList } from 'app/core/store/selectors/cases.selector';
import { PlannerCase } from '@appmodels';
import { GetCases, SetCaseStatus, SetCasesSearchPattern } from 'app/core/store/actions/cases.actions';
import { FusePerfectScrollbarPosition } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.interfaces';
import PerfectScrollbar from 'perfect-scrollbar';

@Component({
    selector: 'case-management',
    templateUrl: './caseManagement.component.html',
    styleUrls: ['./caseManagement.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class CaseManagementComponent implements OnInit, OnDestroy {
    dialogRef: any;
    hasSelectedContacts: boolean;
    searchInput: FormControl;

    //appLogsFilterParams$: Observable<any>;
    cases$: Observable<PlannerCase[]>;

    private _changeSearchPatternTimer: NodeJS.Timer;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UsersService} _contactsService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _matDialog: MatDialog,
        private _store: Store<IAppState>
    ) {
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {


        //this.appLogsFilterParams$ = this._store.pipe(select(selectAppLogsFilterParams));
        this.cases$ = this._store.pipe(select(selectCasesList));
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    filterParamsChanged(isWithAdmins: boolean): void {

        this._store.dispatch(new SetAppLogsFilterParams(isWithAdmins));
    }

    getMoreCases(): void {
        this._store.dispatch(new GetCases());
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    setCaseStatus(setCaseStatusArgs: any): void {


        if (!setCaseStatusArgs)
            return;

        // this.dialogRef = this._matDialog.open(CaseManagementDetailsComponent, {
        //     panelClass: 'logs-group-details-dialog',
        //     data: {
        //         action: 'logsGroupDetails',
        //         $appLogs: this._store.pipe(select(selectAppLogsByConnection))
        //     }
        // });

        this._store.dispatch(new SetCaseStatus(setCaseStatusArgs.caseId, setCaseStatusArgs.caseStatus, true));

    }

    onSearchQuery(event: any): void {
        const searchQuery: string = event.target.value;

        const dispatchFunc = () => this._store.dispatch(new SetCasesSearchPattern(searchQuery));

        clearTimeout(this._changeSearchPatternTimer);
        this._changeSearchPatternTimer = setTimeout(dispatchFunc, 2000);
    }

    // deleteUser(deletedUser: PlannerUser): void {

    //     console.log(deletedUser);

    //     if (!deletedUser)
    //         return;

    //         this._store.dispatch(new DeleteUser(deletedUser.id));
    // }

    /**
     * New contact
     */
    // newContact(): void {
    //     this.dialogRef = this._matDialog.open(AppLogsGroupDetailsComponent, {
    //         panelClass: 'contact-form-dialog',
    //         data: {
    //             action: 'new'
    //         }
    //     });

    //     this.dialogRef.afterClosed()
    //         .subscribe((response: FormGroup) => {
    //             if (!response) {
    //                 return;
    //             }

    //             //this._contactsService.updateContact(response.getRawValue());
    //         });
    // }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
}
