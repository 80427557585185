import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { GetCurrentUser, GetCurrentUserSuccess, EUsersActions, GetUsers, GetUsersSuccess, EditUser, EditUserSuccess, DeleteUser, DeleteUserSuccess, GetOnlineUsersSuccess, ExportUsersList, GetOnlineUsers, ResetUsersFilter, ResetUsersFilterSuccess, SetUsersFilterSuccess, SetUsersFilter, CreateCaseWebLinkShares, CreateCaseWebLinkSharesSuccess, RevokeCaseWebLinkShares, RevokeCaseWebLinkSharesSuccess } from '../actions/users.actions';
import { EMessagesActions } from '../actions/messages.actions';
import { map, switchMap, catchError, withLatestFrom, tap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { UsersService } from '@appservices';
import { EditUserCommand, DeleteUserCommand } from 'app/core/models/planner/commands/userCommand';
import { ObjectMapper, StringUtils } from 'app/core/helpers';
import { NewNotification } from '../actions/notifications.actions';
import { selectUsersFilters } from '../selectors/users.selectors';
import { VoidAction } from '../actions/app.actions';

@Injectable()
export class UsersEffects {

    @Effect()
    getCurrentUser$ = this._actions$.pipe(
        ofType<GetCurrentUser>(EUsersActions.GetCurrentUser),

        switchMap(() => {
            return this._usersService.getCurrentUser();
        }),
        switchMap((user) => of(new GetCurrentUserSuccess(user)))
    );


    @Effect()
    setFilter$ = this._actions$.pipe(
        ofType<SetUsersFilter>(EUsersActions.SetUsersFilter),
        map(action => action.payload),
        switchMap((filterParams) => {
            return [
                new SetUsersFilterSuccess(filterParams),
                new GetUsers(),
            ];
        })
    );


    @Effect()
    resetFilters$ = this._actions$.pipe(
        ofType<ResetUsersFilter>(EUsersActions.ResetUsersFilter),
        map(action => action.payload),
        switchMap((filterParams) => {
            return [
                new ResetUsersFilterSuccess(filterParams || {}),
                (filterParams ? new GetUsers(): new VoidAction()),
            ];
        })
    );


    @Effect()
    getUsers$ = this._actions$.pipe(
        ofType<GetUsers>(EUsersActions.GetUsers),
        map(action => action),
        withLatestFrom(this._store.select(selectUsersFilters)),
        switchMap(([action, usersFilters]) => {

            return this._usersService.getUsers(usersFilters);
        }),
        switchMap((users) => of(new GetUsersSuccess(users)))
    );

    @Effect({ dispatch: false })
    exportUsers$ = this._actions$.pipe(
        ofType<ExportUsersList>(EUsersActions.ExportUsersList),
        map(action => action),
        withLatestFrom(this._store.select(selectUsersFilters)),
        switchMap(([action, usersFilters]) => {
            return this._usersService.exportUsersList(usersFilters);
        }),
        switchMap(() => of())
    );

    @Effect()
    getOnlineUsers$ = this._actions$.pipe(
        ofType<GetOnlineUsers>(EUsersActions.GetOnlineUsers),
        map(action => action),
        switchMap(() => {

            return this._usersService.getOnlineUsers();

        }),
        switchMap((onlineUsers) => of(new GetOnlineUsersSuccess(onlineUsers)))
    );

    @Effect()
    editUser$ = this._actions$.pipe(
        ofType<EditUser>(EUsersActions.EditUser),
        //map(action => action.payload),
        switchMap((editUserRequest) => {

            let editedUser = editUserRequest.editedUser;
            let userNewPassword = editUserRequest.newPassword;

            const editUserCommand = new EditUserCommand();

            ObjectMapper.mapObjectToObject(editedUser, editUserCommand);

            if (!StringUtils.isEmptyOrSpaces(userNewPassword))
                editUserCommand.newPassword = userNewPassword;

            return this._usersService.editUser(editUserCommand).pipe(map(result => editedUser));
        }),
        switchMap((editedUser) => {
            return [
                new EditUserSuccess(editedUser),
                new NewNotification({ type: 'UserSaved', message: 'User saved!', action: 'Ok' })
            ]
        })
    );

    @Effect()
    deleteUser$ = this._actions$.pipe(
        ofType<DeleteUser>(EUsersActions.DeleteUser),
        map(action => action.payload),
        switchMap((deleteUserId) => {
            const deleteUserCommand = new DeleteUserCommand();
            deleteUserCommand.userId = deleteUserId;
            return this._usersService.deleteUser(deleteUserCommand).pipe(map(result => deleteUserId));
        }),
        switchMap((deleteUserId) => {
            return [
                new DeleteUserSuccess(deleteUserId),
                new NewNotification({ type: 'UserDeleted', message: 'User deleted!', action: 'Ok' })
            ]
        }),
        catchError(err => {
            new NewNotification({ type: 'UserDeleted', message: 'User delete error!', action: 'Error' })
            return throwError(err);
        })
    );

    @Effect()
    createCaseWebLinkShares$ = this._actions$.pipe(
        ofType<CreateCaseWebLinkShares>(EUsersActions.CreateCaseWebLinkShares),
        map(action => action.payload),
       // withLatestFrom(this._store.select(selectUsersFilters)),
        switchMap((createWebLinkTokenCommand) => {

            return this._usersService.createWebLinkToken(createWebLinkTokenCommand);
        }),
        switchMap((webLinkToken) => of(new CreateCaseWebLinkSharesSuccess(webLinkToken)))
    );

    @Effect()
    revokeCaseWebLinkShares$ = this._actions$.pipe(
        ofType<RevokeCaseWebLinkShares>(EUsersActions.RevokeCaseWebLinkShares),
        map(action => action.payload),
       // withLatestFrom(this._store.select(selectUsersFilters)),
        switchMap((revokeWebLinkTokenCommand) => {

            return this._usersService.revokeWebLinkToken(revokeWebLinkTokenCommand).pipe(
                map(res=>{return {caseId: revokeWebLinkTokenCommand.caseId, count: res};}),            
            );
        }),
        switchMap((result) => {
            return [
                new NewNotification({ type: 'RevokeCaseWebLinkShare', message: `Revoked ${result.count} tokens!`, action: 'Ok' }),
                new RevokeCaseWebLinkSharesSuccess(result.caseId)
            ];
        })
    );

    constructor(
        private _usersService: UsersService,
        private _actions$: Actions,
        private _store: Store<IAppState>
    ) { }

}