import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "ngx-logger";
export class LanguageService {
    constructor(translateService, logger) {
        this.translateService = translateService;
        this.logger = logger;
        this._storageLangSettingName = 'userLang';
        this._defaultLang = 'en';
        this._supportedLangs = ['en', 'ru'];
        let lang = localStorage.getItem(this._storageLangSettingName);
        if (!lang) {
            lang = this.getUserLang();
        }
        this.setLanguage(lang);
        logger.log(`user lang: ${this._selectedLang}`);
    }
    get selectedLang() {
        return this._selectedLang;
    }
    get supportedLangs() {
        return this._supportedLangs;
    }
    setLanguage(lang) {
        lang = this.selectSupportedLangs(lang);
        if (this._selectedLang != lang) {
            this._selectedLang = lang;
            localStorage.setItem(this._storageLangSettingName, lang);
            this.translateService.use(this._selectedLang);
        }
    }
    getUserLang() {
        let userLang = this.getBrowserLang();
        userLang = this.selectSupportedLangs(userLang);
        return userLang;
    }
    getBrowserLang() {
        let userLang = navigator.language || window.navigator.language;
        userLang = this.formatLangString(userLang);
        return userLang;
    }
    selectSupportedLangs(lang) {
        if (this._supportedLangs.includes(lang))
            return lang;
        return this._defaultLang;
    }
    formatLangString(lang) {
        if (!lang)
            return lang;
        // const hyphenIndex = lang.indexOf('-');
        // if(hyphenIndex >= 0)
        // {
        //     lang = lang.substr(0, lang.length - hyphenIndex - 1);
        // }
        lang = lang.substr(0, 2);
        return lang;
    }
}
LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.NGXLogger)); }, token: LanguageService, providedIn: "root" });
