export const locale = {
    lang: 'en',
    data: {
        DOWNLOADS: {
            CLICKTODOWNLOAD: 'CLICK TO DOWNLOAD',
            USERGUIDE: 'User guide',
            DONTHAVEACCOUNT: 'Don\'t have an account?',
            CREATEANACCOUNT: 'Create an account'
        }
    }
};
