import { Vector3 } from "./vector3";

export class Mesh{

    constructor();
    constructor(verticesCount: number, indicesCount: number);
    constructor(verticesCount?: number, indicesCount?: number)
    {
        if(verticesCount)
            this.Vertices = new Array<Vector3>(verticesCount);

        if(indicesCount)
            this.Indices = new Array<number>(indicesCount);    
    }


    public Vertices: Vector3[];
    public Indices: number[];
}