<mat-table #table [dataSource]="paidModulesOffline" [@animateStagger]="{value:'50'}">


    <!-- Avatar Column -->
    <ng-container matColumnDef="avatar">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let paidModule">
            <img class="avatar" [alt]="paidModule.userEmail" src="/assets/images/avatars/profile.jpg" />
        </mat-cell>
    </ng-container>


    <!-- Email Column -->
    <ng-container matColumnDef="requestUid">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm>Requiest Uid</mat-header-cell>
        <mat-cell *matCellDef="let paidModule" fxHide fxShow.gt-sm>
            <p class="email text-truncate">
                {{paidModule.requestUid}}
            </p>
        </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="hospital">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm>Hospital</mat-header-cell>
        <mat-cell *matCellDef="let paidModule" fxHide fxShow.gt-sm>
            <p class="name text-truncate">
                {{paidModule.hospital}}
            </p>
        </mat-cell>
    </ng-container>


    <!-- Module Column -->
    <ng-container matColumnDef="moduleType">
        <mat-header-cell *matHeaderCellDef>Module</mat-header-cell>
        <mat-cell *matCellDef="let paidModule">
            <p class="company text-truncate">

                <span [ngSwitch]="paidModule.moduleType">
                    <span *ngSwitchCase="moduleTypeEnum.Modules2D">
                        Modules2D
                    </span>

                </span>
            </p>
        </mat-cell>
    </ng-container>

    <!-- Start date Column -->
    <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef>Start date</mat-header-cell>
        <mat-cell *matCellDef="let paidModule">
            <p class="company text-truncate">

                {{paidModule.startDate | date}}
            </p>
        </mat-cell>
    </ng-container>

    <!-- Days allocated Column -->
    <ng-container matColumnDef="daysAllocated">
        <mat-header-cell *matHeaderCellDef>Days allocated</mat-header-cell>
        <mat-cell *matCellDef="let paidModule">
            <p class=" text-truncate centered">

                <span [fxShow]="paidModule.daysAllocated">
                    {{paidModule.daysAllocated}}
                </span>
                <span [fxHide]="paidModule.daysAllocated">
                    &infin;
                </span>

            </p>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="daysRemaining">
        <mat-header-cell *matHeaderCellDef>Days remaining</mat-header-cell>
        <mat-cell *matCellDef="let paidModule">
            <p class=" text-truncate centered">

                <span [fxShow]="paidModule.daysAllocated">
                    {{paidModule.remainingDays}}
                </span>
                <span [fxHide]="paidModule.daysAllocated">
                    &infin;
                </span>

            </p>
        </mat-cell>
    </ng-container>

    <!-- Buttons Column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let paidModule">
            <div fxFlex="row" fxLayoutAlign="end center">


                <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More"
                    (click)="$event.stopPropagation();">
                    <mat-icon class="secondary-text">more_vert</mat-icon>
                </button>

                <mat-menu #moreMenu="matMenu">
                    <button mat-menu-item aria-label="download" (click)="downloadUsersPaidModuleOffline(paidModule)">
                        <mat-icon>cloud_download</mat-icon>
                        <span>Download</span>
                    </button>
                    <button mat-menu-item aria-label="remove" (click)="deleteUsersPaidModule(paidModule)">
                        <mat-icon>delete</mat-icon>
                        <span>Remove</span>
                    </button>
                </mat-menu>
                <!-- 
                <mat-menu #moreMenu="matMenu">
                    <button mat-menu-item aria-label="remove" (click)="deleteUsersPaidModule(paidModule)">
                        <mat-icon>delete</mat-icon>
                        <span>Remove</span>
                    </button>
                </mat-menu> -->
            </div>

        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let user; columns: displayedColumns;" class="contact" (click)="editUser(user)" matRipple
        [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>