import { PlannerAppLogsGroup } from 'app/core/models/planner/plannerAppLogsGroup';
import { PlannerAppLog } from 'app/core/models/planner/plannerAppLog';

export interface IAppLogsState
{
    isWithAdmins: boolean,
    searchPattern: string,
    appLogsGroups: PlannerAppLogsGroup[],
    appLogsByConnection: PlannerAppLog[] 
}

export const initialAppLogsState: IAppLogsState =
{
    isWithAdmins: false,
    searchPattern: null,
    appLogsGroups: null,
    appLogsByConnection: null
}