import { BacketSpace } from "app/core/models/planner/geometry/backet-space";
import { Mesh } from "app/core/models/planner/geometry/mesh";
import { Vector3 } from "app/core/models/planner/geometry/vector3";

export class MeshWeld {

    public FullWeld(oldMesh: Mesh): Mesh {

        const threshold = 1e-05;
        const bucketStep = null

        var vertices = oldMesh.Vertices;

        var indices = oldMesh.Indices;
        var vector3Array1 = new Array<Vector3>(vertices.length);
        var numArray1: number[] = new Array<number>(vertices.length);
        let length1: number = 0;
        var vector3_1 = new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE);
        var vector3_2 = new Vector3(Number.MIN_VALUE, Number.MIN_VALUE, Number.MIN_VALUE);
        for (var index = 0; index < vertices.length; ++index) {
            var vector3_3 = vertices[index];
            if (vector3_3.x < vector3_1.x)
                vector3_1.x = vector3_3.x;
            if (vector3_3.y < vector3_1.y)
                vector3_1.y = vector3_3.y;
            if (vector3_3.z < vector3_1.z)
                vector3_1.z = vector3_3.z;
            if (vector3_3.x > vector3_2.x)
                vector3_2.x = vector3_3.x;
            if (vector3_3.y > vector3_2.y)
                vector3_2.y = vector3_3.y;
            if (vector3_3.z > vector3_2.z)
                vector3_2.z = vector3_3.z;
        }

        var backetSpace = new BacketSpace();
        let num1: number = 0;
        if (bucketStep) {
            num1 = bucketStep;
        }
        else {
            var length2 = oldMesh.Vertices.length;
            num1 = length2 > 150000.0
                ? (length2 <= 150000.0 || length2 > 500000.0
                    ? (length2 <= 500000.0 || length2 > 1500000.0 ? 0.1 : 0.25)
                    : 0.5)
                : 1;
        }

        label_28:
        for (var index1 = 0; index1 < vertices.length; ++index1) {
            var vector3_4 = vertices[index1];
            var x = Math.floor((vector3_4.x - vector3_1.x) / num1);
            var y = Math.floor((vector3_4.y - vector3_1.y) / num1);
            var z = Math.floor((vector3_4.z - vector3_1.z) / num1);
            var bucket = backetSpace.GetBucket(x, y, z);
            for (var index2 = 0; index2 < bucket.length; ++index2) {
                var index3 = bucket[index2];

                if (Vector3.SqrMagnitude(vector3Array1[index3].Sub(vector3_4)) < threshold) {
                    numArray1[index1] = index3;
                        continue label_28;
                }
            }

            vector3Array1[length1] = vector3_4;
            bucket.push(length1);
            numArray1[index1] = length1;
            ++length1;
        }

        const intList: number[] = [];
        for (var index = 0; index < indices.length; index += 3) {
            var num2 = numArray1[indices[index]];
            var num3 = numArray1[indices[index + 1]];
            var num4 = numArray1[indices[index + 2]];
            if (num2 != num3 && num3 != num4 && num4 != num2) {
                intList.push(num2);
                intList.push(num3);
                intList.push(num4);
            }
        }

        var numArray2: number[] = new Array<number>(intList.length);
        for (var index = 0; index < intList.length; ++index)
            numArray2[index] = intList[index];
        var vector3Array2 = new Array<Vector3>(length1);
        //var vector3Array3 = new Vector3[length1];
        for (var index = 0; index < length1; ++index)
            vector3Array2[index] = vector3Array1[index];
        const newmesh = new Mesh();

        newmesh.Vertices = vector3Array2;
        newmesh.Indices = numArray2;

        return newmesh;
    }
}