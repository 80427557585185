import { Gender } from '..';
import { PlannerFile } from './plannerFile';
import { PlannerMessage } from './plannerMessage';

export class PlannerCaseRelatedData {

   patientName: string = null;
   patientGender: Gender = null;
   patientBirthday: Date = null;
   service: string = null;
   procedures: string = null;
   surgicalSide: string = null;
   scanCenter: string = null;
   shipment: string = null;
   description: string = null;

   isAllowAll: boolean = null;
   isArchived: boolean = null;
   isExcludeFromAnalytics: boolean = null;
   serializedData: string = null;

   files: PlannerFile[] = null;
   //messages: PlannerMessage[];
}

export interface ICaseSerializedDataObject {
   order: ICaseOrder;
}

export interface ICaseOrder {
   planning: boolean;
   anatomicalModel: boolean;
   guide: boolean;
   titan: boolean;
   peek: boolean;
}