import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CasesAnalytics, DateInterval, DateMonth, GeneralAnalytics, Language, MedicalReferals, Speciality, UsersAnalytics } from '@appmodels';
import { fuseAnimations } from '@fuse/animations';
import { PlotData } from 'app/core/models/planner/analytics/plotData';
import * as moment from 'moment';

@Component({
  selector: 'analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  // changeDetection: ChangeDetectionStrategy.Default
})
export class AnalyticsComponent implements OnInit {


  // widget5SelectedDay: 'today';

  casesWidget: any = {

    chartType: 'line',
    dataset: [{
      label: 'Cases',
      data: [],
      fill: 'start'
    }],
    labels: [],
    colors: [
      {
        borderColor: 'rgba(30, 136, 229, 0.87)',
        backgroundColor: 'rgba(30, 136, 229, 0.87)',
        pointBackgroundColor: 'rgba(30, 136, 229, 0.87)',
        pointHoverBackgroundColor: 'rgba(30, 136, 229, 0.87)',
        pointBorderColor: '#ffffff',
        pointHoverBorderColor: '#ffffff'
      }
    ],
    options: {
      spanGaps: false,
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      tooltips: {
        position: 'nearest',
        mode: 'index',
        intersect: false
      },
      layout: {
        padding: {
          left: 24,
          right: 32
        }
      },
      elements: {
        point: {
          radius: 4,
          borderWidth: 2,
          hoverRadius: 4,
          hoverBorderWidth: 2
        }
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              fontColor: 'rgba(0,0,0,0.54)'
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              tickMarkLength: 16
            },
            ticks: {
              stepSize: 1000
            }
          }
        ]
      },
      plugins: {
        filler: {
          propagate: false
        }
      }
    }
  }

  loginsWidget: any = {

    chartType: 'line',
    dataset: [{
      label: 'Cases',
      data: [],
      fill: 'start'
    }],
    labels: [],
    colors: [
      {
        borderColor: '#3949ab',
        backgroundColor: '#3949ab',
        pointBackgroundColor: '#3949ab',
        pointHoverBackgroundColor: '#3949ab',
        pointBorderColor: '#ffffff',
        pointHoverBorderColor: '#ffffff'
      },
      {
        borderColor: 'rgba(30, 136, 229, 0.87)',
        backgroundColor: 'rgba(30, 136, 229, 0.87)',
        pointBackgroundColor: 'rgba(30, 136, 229, 0.87)',
        pointHoverBackgroundColor: 'rgba(30, 136, 229, 0.87)',
        pointBorderColor: '#ffffff',
        pointHoverBorderColor: '#ffffff'
      },

    ],
    options: {
      spanGaps: false,
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      tooltips: {
        position: 'nearest',
        mode: 'index',
        intersect: false
      },
      layout: {
        padding: {
          left: 24,
          right: 32
        }
      },
      elements: {
        point: {
          radius: 4,
          borderWidth: 2,
          hoverRadius: 4,
          hoverBorderWidth: 2
        }
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              fontColor: 'rgba(0,0,0,0.54)'
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              tickMarkLength: 16
            },
            ticks: {
              stepSize: 1000
            }
          }
        ]
      },
      plugins: {
        filler: {
          propagate: false
        }
      }
    }
  }

  usersWidget: any = {

    chartType: 'line',
    dataset: [{
      label: 'Cases',
      data: [],
      fill: 'start'
    }],
    labels: [],
    colors: [
      {
        borderColor: 'rgba(30, 136, 229, 0.87)',
        backgroundColor: 'rgba(30, 136, 229, 0.87)',
        pointBackgroundColor: 'rgba(30, 136, 229, 0.87)',
        pointHoverBackgroundColor: 'rgba(30, 136, 229, 0.87)',
        pointBorderColor: '#ffffff',
        pointHoverBorderColor: '#ffffff'
      }
    ],
    options: {
      spanGaps: false,
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      tooltips: {
        position: 'nearest',
        mode: 'index',
        intersect: false
      },
      layout: {
        padding: {
          left: 24,
          right: 32
        }
      },
      elements: {
        point: {
          radius: 4,
          borderWidth: 2,
          hoverRadius: 4,
          hoverBorderWidth: 2
        }
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              fontColor: 'rgba(0,0,0,0.54)'
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              tickMarkLength: 16
            },
            ticks: {
              stepSize: 1000
            }
          }
        ]
      },
      plugins: {
        filler: {
          propagate: false
        }
      }
    }
  }

  @Input()
  public generalAnalytics: GeneralAnalytics;

  @Input()
  public set casesAnalytics(value: CasesAnalytics) {
    this._casesAnalytics = value;

    this.plotCasesChart();
  }
  public get casesAnalytics() {
    return this._casesAnalytics;
  }


  @Input()
  public set usersAnalytics(value: UsersAnalytics) {
    this._usersAnalytics = value;

    this.plotUsersCharts();
  }
  public get usersAnalytics() {
    return this._usersAnalytics;
  }

  private _casesAnalytics: CasesAnalytics;
  private _usersAnalytics: UsersAnalytics;
  //  = { logins: [],
  //   uniqLogins: [],
  //   newUsers: [],
  //   loginsPlotData : new PlotData(),
  //   uniqLoginsPlotData :  new PlotData(),
  //   newUsersPlotData : new PlotData() };

  @Output()
  onCasesDateIntervalChanged: EventEmitter<any>;

  dateIntervalEnum = DateInterval;
  dateMonthEnum = DateMonth;

  public currentYear: number = +moment(new Date()).format('YYYY');
  public currentMonth: number = +moment().format('MM');

  public selectedDateInterval: DateInterval;
  public selectedYear: number = -1;
  public selectedQuarter: number = -1;
  public selectedMonth: number = -1;

  public yearsIntervals = [];
  public quarterIntervals = [];
  public monthIntervals = [];

  medicalReferalsEnum = MedicalReferals;
  specialityEnum = Speciality;
  languageEnum = Language;

  public selectedAnalytics = 'Cases';

  constructor() {
    this.onCasesDateIntervalChanged = new EventEmitter();

    this._registerCustomChartJSPlugin();
  }

  ngOnInit() {

    this.selectedDateInterval = DateInterval.CalendarMonth;
    this.selectedYear = this.currentYear;
    this.selectedQuarter = Math.ceil(this.currentMonth / 3);

    this.selectedMonth = this.currentMonth;

    this.yearsIntervals = [this.currentYear - 2, this.currentYear - 1, this.currentYear];

    this.quarterIntervals = [1, 2, 3, 4];

    // const montsIntervals = [];

    for (let i = 0; i < 12; i++) {
      const monthIndex = i + 1;

      const monthName = moment().subtract(-i + this.currentMonth - 1, "month").startOf("month").format('MMMM');

      this.monthIntervals.push({ monthName, monthIndex });

    }


    //console.log( this.monthIntervals);


  }

  public casesDateIntervalChanged(dateInterval: DateInterval): void {

    this.selectedDateInterval = dateInterval;

    this.emitEvent();
  }

  public yearIntervalChanged(selectingYear: number): void {
    this.selectedYear = selectingYear;

    this.emitEvent();

    console.log(this.generalAnalytics);
  }

  public quarterIntervalChanged(selectingQuarter: number): void {

    this.selectedQuarter = selectingQuarter;

    this.emitEvent();
  }

  public monthIntervalChanged(selectingMonth: number): void {
    this.selectedMonth = selectingMonth;

    this.emitEvent();
  }

  public selectedAnalyticsChanged(selectedAnalytics: string): void {

    if (this.selectedAnalytics == selectedAnalytics)
      return;

    this.selectedAnalytics = selectedAnalytics;

    this.casesAnalytics = null;
    this.usersAnalytics = null;

    this.emitEvent();
  }

  private emitEvent(): void {

    this.onCasesDateIntervalChanged.emit({ selectedDateInterval: this.selectedDateInterval, selectedMonth: this.selectedMonth, selectedQuarter: this.selectedQuarter, selectedYear: this.selectedYear });
  }

  private plotCasesChart(): void {

    //console.log(this.casesAnalytics);

    if (!this.casesAnalytics)
      return;

    const widget = this.casesWidget;

    const newDataset = [{
      label: 'Cases',
      data: this.casesAnalytics.plotData.points.map(x => x.y),
      fill: 'start'
    }];


    widget.dataset = newDataset;
    widget.labels = this.casesAnalytics.plotData.points.map(x => x.label);
  }

  private plotUsersCharts(): void {

    //console.log(this.usersAnalytics);

    if (!this.usersAnalytics)
      return;

    const loginsWidget = this.loginsWidget;

    const loginsDataset = [{
      label: 'Unique logins',
      data: this.usersAnalytics.uniqLoginsPlotData.points.map(x => x.y),
      fill: 'start'
    },
    {
      label: 'Logins',
      data: this.usersAnalytics.loginsPlotData.points.map(x => x.y),
      fill: 'start'
    },];


    loginsWidget.dataset = loginsDataset;
    loginsWidget.labels = this.usersAnalytics.loginsPlotData.points.map(x => x.label);

    const usersWidget = this.usersWidget;

    const usersDataset = [{
      label: 'New users',
      data: this.usersAnalytics.newUsersPlotData.points.map(x => x.y),
      fill: 'start'
    }];


    usersWidget.dataset = usersDataset;
    usersWidget.labels = this.usersAnalytics.newUsersPlotData.points.map(x => x.label);
  }

  private _registerCustomChartJSPlugin(): void {

    (window as any).Chart.plugins.register({

      afterDatasetsDraw: function (chart, easing): any {
        // Only activate the plugin if it's made available
        // in the options
        if (
          !chart.options.plugins.xLabelsOnTop ||
          (chart.options.plugins.xLabelsOnTop && chart.options.plugins.xLabelsOnTop.active === false)
        ) {
          return;
        }


        // To only draw at the end of animation, check for easing === 1
        const ctx = chart.ctx;

        chart.data.datasets.forEach(function (dataset, i): any {
          const meta = chart.getDatasetMeta(i);
          if (!meta.hidden) {
            meta.data.forEach(function (element, index): any {

              // Draw the text in black, with the specified font
              ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
              const fontSize = 13;
              const fontStyle = 'normal';
              const fontFamily = 'Roboto, Helvetica Neue, Arial';
              ctx.font = (window as any).Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

              // Just naively convert to string for now
              const dataString = dataset.data[index].toString() + 'k';

              // Make sure alignment settings are correct
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              const padding = 15;
              const startY = 24;
              const position = element.tooltipPosition();
              ctx.fillText(dataString, position.x, startY);

              ctx.save();

              ctx.beginPath();
              ctx.setLineDash([5, 3]);
              ctx.moveTo(position.x, startY + padding);
              ctx.lineTo(position.x, position.y - padding);
              ctx.strokeStyle = 'rgba(255,255,255,0.12)';
              ctx.stroke();

              ctx.restore();


            });
          }
        });
      }
    });
  }

}
