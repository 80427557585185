import { initialUsersFilterState, initialUsersState, IUsersState } from '../state/users.interface';
import { UsersActions, EUsersActions } from '../actions/users.actions';
import { IUsersFilter } from 'app/core/models/usersFilter';

export function usersReducers(
    state = initialUsersState,
    action: UsersActions
): IUsersState {
    switch (action.type) {
        case EUsersActions.GetCurrentUserSuccess:
            {
                const user = action.payload;
                return {
                    ...state,
                    currentUser: user
                }
            }
        case EUsersActions.SetUsersFilterSuccess:
            {
                const filterParam = action.payload;

                const usersFilter = { ...state.usersFilter }

                setFilterParams(filterParam, usersFilter);

                return {
                    ...state,
                    usersFilter: usersFilter
                }
            }

        case EUsersActions.ResetUsersFilterSuccess:
            {
                const filterParam = action.payload;

                const usersFilter = { ...initialUsersFilterState };

                setFilterParams(filterParam, usersFilter);

                return {
                    ...state,
                    usersFilter: usersFilter
                }
            }

        case EUsersActions.GetUsersSuccess:
            {
                const users = action.payload;
                return {
                    ...state,
                    users: users
                }
            }
        case EUsersActions.GetOnlineUsersSuccess:
            {
                const onlineUsers = action.payload;
                return {
                    ...state,
                    onlineUsers: onlineUsers
                }
            }
        case EUsersActions.EditUserSuccess:
            {
                const editedUser = action.payload;
                const users = state.users.concat([]);
                const editedUserIndex = users.findIndex(user => user.id == editedUser.id);
                users[editedUserIndex] = editedUser;
                return {
                    ...state,
                    users: users
                }
            }
        case EUsersActions.DeleteUserSuccess:
            {
                const deleteedUserId = action.payload;
                const users3 = state.users.concat([]);
                const deletedUserIndex = users3.findIndex(user => user.id == deleteedUserId);
                users3.splice(deletedUserIndex, 1);
                return {
                    ...state,
                    users: users3
                }
            }
        case EUsersActions.CreateCaseWebLinkSharesSuccess: {
            const webLinkToken = action.payload;

            return {
                ...state,
                createdWebLinkToken: webLinkToken
            }
        }
        case EUsersActions.RevokeCaseWebLinkSharesSuccess: {
            const webLinkToken = action.payload;

            return {
                ...state,
                createdWebLinkToken: null
            }
        }
        default:
            {
                return state;
            }
    }

    function setFilterParams(filterParam: any, usersFilter: IUsersFilter) {

        if (filterParam.hasOwnProperty('isWithAdmins')) {
            usersFilter.isWithAdmins = filterParam.isWithAdmins;
        }

        if (filterParam.hasOwnProperty('medicalReferals')) {
            usersFilter.medicalReferals = filterParam.medicalReferals;
        }

        if (filterParam.hasOwnProperty('speciality')) {
            usersFilter.speciality = filterParam.speciality;
        }

        if (filterParam.hasOwnProperty('language')) {
            usersFilter.language = filterParam.language;
        }

        if (filterParam.hasOwnProperty('searchQuery')) {
            usersFilter.searchQuery = filterParam.searchQuery;
        }
    }
}