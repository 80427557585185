import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { AuthReasponse as AuthResponse } from '../models';
import { APP_CONFIG, AppConfig } from '../../app-config.module';
import { NGXLogger } from 'ngx-logger';
import { Guid } from '../helpers/classes';
import { ResetPasswordByCodeCommand } from '../models/planner/commands/resetPasswordByCodeCommand';


@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    private authCredentials: BehaviorSubject<AuthResponse>;
    private appConnectionUidString: string;
    // public currentUser: Observable<AuthResponse>;

    constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient, private logger: NGXLogger) {

        this.authCredentials = new BehaviorSubject<AuthResponse>(JSON.parse(localStorage.getItem('currentUser')));

        this.setNewAppConnectionUid();
        //     this.currentUser = this.authCredentials.asObservable();
    }

    public get currentUser(): BehaviorSubject<AuthResponse> {
        return this.authCredentials;
    }

    public get appConnectionUid(): string {
        return this.appConnectionUidString;
    }

    login(username: string, password: string) {

        const url = `${this.config.apiEndpoint}/api/login`;
        this.logger.debug("HTTP POST login", `url: ${url}`);

        return this.http.post<AuthResponse>(url, { UserName: username, Password: password, GrantType: 'password', MacAddress: '30-5A-3A-46-03-4C' })
            .pipe(
                tap(response => this.logger.debug("Response login:", response)),
                map(authCredentials => {
                // login successful if there's a jwt token in the response
                if (authCredentials && authCredentials.token) {   
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(authCredentials));
                    this.setNewAppConnectionUid();
                    this.authCredentials.next(authCredentials);
                }
                return;
            }),
                catchError(err => {
                    this.logger.info(err.status);
                    return throwError(err);
                })

            );
    }

    tokenLogin() {
        this.logger.info('tokenLogin');       
        return this.http.post<AuthResponse>(`${this.config.apiEndpoint}/api/login`, { UserName: this.authCredentials.value.userName, Password: this.authCredentials.value.refreshToken, GrantType: 'token', MacAddress: '30-5A-3A-46-03-4C' })
        .pipe(map(authCredentials => {

            this.logger.info(authCredentials);

            // login successful if there's a jwt token in the response
            if (authCredentials && authCredentials.token) {   

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(authCredentials));
                this.setNewAppConnectionUid();
                this.authCredentials.next(authCredentials);
            }

            return;
        }),
            catchError(err => {
                this.logger.info('token login err');

                this.logger.info(err.status);
                return throwError(err);
            })

        );
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.authCredentials.next(null);
    }

    sendRecoverPasswordRequest(userEmail: string, returnUrl: string)   : Observable<boolean> {
        const url = `${this.config.apiEndpoint}/api/public/Users/RequestRecoverPassword`;
        this.logger.debug("HTTP POST RequestRecoverPassword", `url: ${url}`);

        return this.http.post<boolean>(url, { Email: userEmail, ReturnUrl: returnUrl })
            .pipe(
                tap(response => this.logger.debug("Response request recover password:", response)),
                catchError(err => {
                    this.logger.info(err.status);
                    return throwError(err);
                })

            );
    }

    recoverPassword(recoverPasswordCommand: ResetPasswordByCodeCommand)   : Observable<boolean> {
        const url = `${this.config.apiEndpoint}/api/public/Users/RecoverPassword`;
        this.logger.debug("HTTP POST RecoverPassword", `url: ${url}`);

        return this.http.post<boolean>(url, recoverPasswordCommand)
            .pipe(
                tap(response => this.logger.debug("Response request recover password:", response)),
                catchError(err => {
                    this.logger.info(err.status);
                    return throwError(err);
                })

            );
    }

    private setNewAppConnectionUid()
    {
        this.appConnectionUidString = Guid.newGuid();
    }
}