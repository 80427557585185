<!-- CHAT -->
<div class="chat" fxFlex fxLayout="column">

    <!-- CHAT TOOLBAR -->
    <mat-toolbar class="chat-toolbar">

        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">

            <div fxLayout="row" fxLayoutAlign="start center">

                <!-- RESPONSIVE CHATS BUTTON-->
                <button mat-icon-button fxHide.gt-md class="responsive-chats-button mr-16"
                    fuseMatSidenavToggler="chat-left-sidenav" aria-label="chats button">
                    <mat-icon>chat</mat-icon>
                </button>
                <!-- / RESPONSIVE CHATS BUTTON-->

                <!-- CHAT CONTACT-->
                <div class="chat-contact" fxLayout="row" fxLayoutAlign="start center"
                    (click)="goToCase(selectedCase.id)">
                    <!-- fuseMatSidenavToggler="chat-right-sidenav"  -->
                    <div class="avatar-wrapper">

                        <img src="/assets/images/avatars/profile.jpg" class="avatar" alt="{{contact.name}}" />

                        <mat-icon class="s-16 status" [ngClass]="contact.status">
                        </mat-icon>
                    </div>

                    <div class="chat-contact-name">
                       <!-- <a href="{{'/cases/all/'+selectedCase.id}}">{{selectedCase.title}}</a>  -->
                       {{selectedCase.title}}
                    </div>

                </div>
                <!-- / CHAT CONTACT-->
            </div>

            <div>
                <button mat-icon-button [matMenuTriggerFor]="contactMenu" aria-label="more">
                    <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #contactMenu="matMenu">
                    <button mat-menu-item fuseMatSidenavToggler="chat-right-sidenav" (click)="goToCase(selectedCase.id)">
                        View case
                    </button>
                </mat-menu>
            </div>

        </div>
    </mat-toolbar>
    <!-- / CHAT TOOLBAR -->

    <!-- CHAT CONTENT -->
    <div id="chat-content" fxFlex="1 1 auto" fusePerfectScrollbar  [@animateStagger]="{value:'5'}" [scrollWindow]="false" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="300" (scrolledUp)="onChatScroll()">

        <!-- <div class="cases-list" [@animateStagger]="{value:'5'}" [scrollWindow]="false" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="300" (scrolled)="onCasesListScroll()">
            <cases-list-item matRipple *ngFor="let case of cases" [case]="case" (click)="selectCase(case.id)" [ngClass]="{'current-mail':case?.id == selectedCase?.id}" [@animate]="{value:'*',params:{y:'100%'}}">
            </cases-list-item>
        </div> -->


        <!-- CHAT MESSAGES -->
        <div class="chat-messages">

            <!-- MESSAGE -->
            <div *ngFor="let message of messages; let i = index" class="message-row" (mouseover)="message.isNew=false"
                [ngClass]="{
              
                            'me': message.userNameFrom === user.userName,
                            'contact': message.userNameFrom !== user.userName,
                            'first-of-group': isFirstMessageOfGroup(message, i),
                            'last-of-group': isLastMessageOfGroup(message, i)                         
                            }">

                <!-- src="{{contact.avatar}}" -->
                <img *ngIf="shouldShowContactAvatar(message, i)" src="/assets/images/avatars/profile.jpg"
                    class="avatar">

                <div class="bubble" [ngClass]="{  'is-new-message': message.isNew}">
                    <div class="message">
                        <div class="message-from" [ngClass]="{'sender-admin': message.userFromIsAdmin}"
                            [fxShow]="isFirstMessageOfGroup(message, i) && message.userNameFrom !== user.userName">
                            {{message.userNameFrom}}
                        </div>

                        <div class="message-text" [innerHTML]="message.text | replaceNewLine">
                          
                        </div>

                        <div *ngIf="message.messageFile" class="message-attachment">
                            <!-- {{message.messageFile?.fileName}} -->

                            <div *ngIf="message.messageFile.fileType == fileTypeEnum.PICTURE">
                                <img *ngIf="getFileUrlAsync(message.messageFile) | async as string"
                                    [src]="getFileUrlAsync(message.messageFile) | async"
                                    (click)="openImageLightBox(message.messageFile)" (load)="scrollToBottom()">
                            </div>
                            <div class="doc-attachment" *ngIf="message.messageFile.fileType == fileTypeEnum.DOC">
                                <div class="doc-img" fxLayout="row" fxLayoutAlign="end">
                                    <img src="/assets/images/case/FileTypes/doc.png"
                                        (click)="downloadFile(message.messageFile)" (load)="scrollToBottom()">
                                </div>
                                <div class="doc-info" fxLayout="row" fxLayoutAlign="right">
                                    Title: {{message.messageFile.fileName}}
                                    <br>
                                    Size: {{message.messageFile.fileSize/1024/1024 | number:'1.2-2'}}Mb
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="time secondary-text">{{message.creationDate | date:'short'}}</div>
                </div>

            </div>
            <!-- / MESSAGE -->

        </div>
        <!-- CHAT MESSAGES -->

    </div>
    <!-- / CHAT CONTENT -->

    <!-- CHAT FOOTER -->
    <div class="chat-footer" fxFlex="0 0 auto" fxLayout="column">

        <!-- REPLY FORM -->
        <div class="reply-form" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center">

            <form #replyForm="ngForm" (ngSubmit)="reply($event)" (keydown.enter)="reply($event)" fxFlex
                fxLayout="column" fxLayoutAlign="start">

                <div class="message-additionals">
                    <div class="mat-form-toggle is-message-email" fxLayout="column" flexGrow="1">
                        <mat-slide-toggle name="isMessageEmail" #replyIsMessageEmail>
                            Is message email
                        </mat-slide-toggle>
                    </div>
                    <div class="image-preview" *ngIf="selectedFile" fxLayout="column" flexGrow="1">
                        <div fxLayout="row">
                            <div fxLayout="column" flexGrow="1">
                                Title: {{selectedFile?.name}}<br>
                                Size: {{selectedFile?.size/1024/1024 | number:'1.2-2'}}Mb
                            </div>
                            <div class="image-src" fxLayout="column" flexGrow="1">
                                <mat-icon *ngIf="(selectedFileImg ? (attachedImgUrl && attachedImgUrl!=null) : true)"
                                    (click)="removeAttachment()">close</mat-icon>
                                <img *ngIf="selectedFileImg && (attachedImgUrl && attachedImgUrl!=null)"
                                    [src]="attachedImgUrl" style="height: 100px;">
                                <img *ngIf="!selectedFileImg" src="/assets/images/case/FileTypes/doc.png"
                                    style="height: 100px;">
                            </div>

                        </div>
                    </div>
                </div>
                <div ngfDrop fxFlex fxLayout="row" fxLayoutAlign="start center" [(files)]="ngfModuleFiles"
                    (filesChange)="filesSelected($event)">
                    <mat-form-field class="message-text" fxFlex floatLabel="never" appearance="standard">
                        <textarea matInput #replyInput placeholder="Type your message" ngModel name="message" [rows]="1"
                            [matTextareaAutosize]="true"></textarea>
                    </mat-form-field>

                    <div class="message-buttons">
                        <button mat-icon-button [fxShow] = "isRequestInProgress">
                            <mat-spinner [diameter]="20"></mat-spinner>
                        </button>
                        <button [fxHide] = "isRequestInProgress" mat-icon-button aria-label="Attach image" ngfSelect
                            (filesChange)="filesSelected($event)" [(files)]="ngfModuleFiles">
                            <mat-icon class="secondary-text">add_photo_alternate</mat-icon>
                        </button>
                        <button  [fxHide] = "isRequestInProgress"  mat-icon-button type="submit" aria-label="Send message">
                            <mat-icon class="secondary-text">send</mat-icon>
                        </button>
                    </div>
                </div>
            </form>

        </div>

        <!-- [(files)]="files"
        [(validDrag)]="validComboDrag" [(invalidDrag)]="invalidComboDrag"  -->
        <!-- [fxShow]="!files || files.length == 0"
        (filesChange)="filesSelected($event)" [ngClass]="{'goodDragClass':validComboDrag, 'badDragClass':invalidComboDrag}" -->
        <!-- <div  class="file-drop-zone" ngfDrop  >

            <div>Open file select or drag and drop files here!</div>

        </div> -->

        <!-- / REPLY FORM -->

    </div>
    <!-- / CHAT FOOTER-->

</div>
<!-- / CHAT -->