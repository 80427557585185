/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./layout/vertical/layout-1/layout-1.component.ngfactory";
import * as i3 from "./layout/vertical/layout-1/layout-1.component";
import * as i4 from "../@fuse/services/config.service";
import * as i5 from "../@fuse/components/progress-bar/progress-bar.component.ngfactory";
import * as i6 from "../@fuse/components/progress-bar/progress-bar.component";
import * as i7 from "../@fuse/components/progress-bar/progress-bar.service";
import * as i8 from "@angular/common";
import * as i9 from "./xapp-notifications/app-notifications.component.ngfactory";
import * as i10 from "./xapp-notifications/app-notifications.component";
import * as i11 from "@angular/material/snack-bar";
import * as i12 from "@angular/router";
import * as i13 from "./app.component";
import * as i14 from "../@fuse/components/navigation/navigation.service";
import * as i15 from "../@fuse/components/sidebar/sidebar.service";
import * as i16 from "../@fuse/services/splash-screen.service";
import * as i17 from "../@fuse/services/translation-loader.service";
import * as i18 from "@ngx-translate/core";
import * as i19 from "@angular/cdk/platform";
import * as i20 from "@ngrx/store";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vertical-layout-1", [], null, null, null, i2.View_VerticalLayout1Component_0, i2.RenderType_VerticalLayout1Component)), i1.ɵdid(2, 245760, null, 0, i3.VerticalLayout1Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-progress-bar", [], null, null, null, i5.View_FuseProgressBarComponent_0, i5.RenderType_FuseProgressBarComponent)), i1.ɵdid(1, 245760, null, 0, i6.FuseProgressBarComponent, [i7.FuseProgressBarService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-notifications", [], null, null, null, i9.View_AppNotificationsComponent_0, i9.RenderType_AppNotificationsComponent)), i1.ɵdid(5, 114688, null, 0, i10.AppNotificationsComponent, [i11.MatSnackBar, i12.Router], { notification: [0, "notification"] }, null), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = (_co.fuseConfig.layout.style === "vertical-layout-1"); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.notifications$)); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i13.AppComponent, [i8.DOCUMENT, i4.FuseConfigService, i14.FuseNavigationService, i15.FuseSidebarService, i16.FuseSplashScreenService, i17.FuseTranslationLoaderService, i18.TranslateService, i19.Platform, i20.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
