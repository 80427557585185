import { Component, Inject, ViewEncapsulation, OnDestroy, ChangeDetectionStrategy, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS, ObjectMapper, } from 'app/core/helpers';
import { PlannerCase, FileType, ModelMaterialType, FileResponse, PlannerFile } from '@appmodels';
import { Subject, Observable, forkJoin } from 'rxjs';
import { takeUntil, finalize, map, catchError } from 'rxjs/operators';
import { FilesService } from 'app/core/services/planner/files.sevice';
import { } from 'tunnel';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'edit-file',
    templateUrl: './edit-file.component.html',
    styleUrls: ['./edit-file.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class EditFileDialogComponent implements OnDestroy {

    fileTypeEnum = FileType;
    modelMaterialTypeEnum = ModelMaterialType;

    // files: any;

    //dialogRef: any;

    // validComboDrag: any;
    //  invalidComboDrag: any;
    plannerFile: PlannerFile;
    //filesIndices: any[];
    fileEditForm: FormGroup;

    get fileAdditionalInfoGroup(): FormGroup {
        return this.fileEditForm.controls['fileAdditionalInfoGroup'] as FormGroup;
    }

    errorText: string;
    requestInProgress: boolean;
    private _selectedCase: PlannerCase;

    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        private _filesService: FilesService,
        public matDialogRef: MatDialogRef<EditFileDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private logger: NGXLogger
    ) {
        // Set the defaults
        this._selectedCase = _data.selectedCase;
        this.plannerFile = _data.plannerFile;

        this.fileEditForm = this.createFilesEditForm(this.plannerFile);

        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */


    createFilesEditForm(plannerFile: PlannerFile): FormGroup {

        if (!plannerFile)
            return null;

        let fileFormGroup = new FormGroup({
            title: new FormControl(plannerFile.title, Validators.required)
        });

        fileFormGroup.addControl('fileType', new FormControl(plannerFile.fileType, Validators.required));

        const fileAdditionalInfoGroupName = 'fileAdditionalInfoGroup';


        let additionalInfo = JSON.parse(plannerFile.additionalInfo);

        if (!additionalInfo)
            additionalInfo = {};

        let fileAdditionalInfoGroup = new FormGroup({
            isUseIn3d: new FormControl(additionalInfo.isUseIn3d),
            materialType: new FormControl(additionalInfo.materialType),
            saw: new FormControl(additionalInfo.saw),
            pin: new FormControl(additionalInfo.pin),
            screw: new FormControl(additionalInfo.screw)
        });

        fileFormGroup.addControl(fileAdditionalInfoGroupName, fileAdditionalInfoGroup);

        const changes$ = fileFormGroup.controls.fileType.valueChanges;

        changes$.subscribe(newFileType => {

            this.updateValidatorOnFileType(fileAdditionalInfoGroup, newFileType);

        });

        this.updateValidatorOnFileType(fileAdditionalInfoGroup, plannerFile.fileType);


        return fileFormGroup;

    }

    private updateValidatorOnFileType(fileAdditionalInfoGroup: FormGroup, newFileType: any) {
        this.logger.info('File type changed!');

        fileAdditionalInfoGroup.controls['materialType'].clearValidators();

        switch (newFileType) {

            case FileType.STLMODEL:

                fileAdditionalInfoGroup.controls['materialType'].setValidators(Validators.required);

                break;
            default:
                break;
        }

        fileAdditionalInfoGroup.controls['materialType'].updateValueAndValidity();
    }

    editFile() {

        if (this.fileEditForm.invalid)
            return;

        this.requestInProgress = true;

        const editedPLannerFile = new PlannerFile();
        editedPLannerFile.id = this.plannerFile.id;


        // editedPLannerFile.fileName = this.plannerFile.fileName;
        // editedPLannerFile.fileExtension = this.plannerFile.fileExtension;
        // editedPLannerFile.fileSize = this.plannerFile.fileSize;

        // editedPLannerFile. creationDate = this.plannerFile. creationDate;
        // editedPLannerFile.md5hash = this.plannerFile.md5hash;

        // editedPLannerFile.version = this.plannerFile.version;
        // editedPLannerFile.isZipped = this.plannerFile.isZipped;
        // editedPLannerFile.fileSizeZipped = this.plannerFile.fileSizeZipped;
        // editedPLannerFile.fileAeskey = this.plannerFile.fileAeskey;


        ObjectMapper.mapFormGroupToObject(this.fileEditForm, editedPLannerFile);

        const additionalInfoObj: any = {};
        const fileAdditionalInfoGroup = this.fileAdditionalInfoGroup;

        let needToZip = false;

        switch (editedPLannerFile.fileType) {
            case FileType.DICOM:
                additionalInfoObj.isUseIn3d = fileAdditionalInfoGroup.controls['isUseIn3d'].value;
                break;
            case FileType.STLMODEL:

                needToZip = true;

                additionalInfoObj.materialType = fileAdditionalInfoGroup.controls['materialType'].value;
                additionalInfoObj.saw = fileAdditionalInfoGroup.controls['saw'].value;
                additionalInfoObj.pin = fileAdditionalInfoGroup.controls['pin'].value;
                additionalInfoObj.screw = fileAdditionalInfoGroup.controls['screw'].value;
                break;
            default:
                break;
        }

        const additionalInfo = JSON.stringify(additionalInfoObj);

        editedPLannerFile.additionalInfo = additionalInfo;


        this.matDialogRef.close(['edit', editedPLannerFile]);
    }



    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
