import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { PlannerCase, PlannerMessage, CreatePlannerCaseMessageCommand } from '@appmodels';
import { tap, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MessagesService {  

    constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient, private logger: NGXLogger) {       
    }

    public getCaseMessages(caseId: string, skip: number, take: number): Observable<PlannerMessage[]> {

        const url = `${this.config.apiEndpoint}/api/public/Messages/Get?PlannerCaseId=${caseId}&Skip=${skip}&Take=${take}`;
        this.logger.debug("HTTP GET case messages.", `Url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => {
                    this.logger.debug("Response case messages:", response);                    
                }),
                map(response => response.messages));
    }

    public addCaseMessage(createPlannerCaseMessageCommand: CreatePlannerCaseMessageCommand): Observable<string> {
        
        const url = `${this.config.apiEndpoint}/api/public/Messages/Add`;
        this.logger.debug("HTTP POST add case message.", `New message: ${createPlannerCaseMessageCommand},`, `url: ${url}`);

        return this.http.post<any>(url, createPlannerCaseMessageCommand)
            .pipe(
                tap(response => {
                    this.logger.debug("Response add case message:", response);                    
                }),
                map(response => response.messages));
    }
}