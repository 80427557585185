import { Component, ViewEncapsulation, Input, ChangeDetectionStrategy } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { PlannerCase } from '@appmodels';

@Component({
    selector     : 'chat-start',
    templateUrl  : './chat-start.component.html',
    styleUrls    : ['./chat-start.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush, 
})
export class ChatStartComponent
{
    
    constructor()
    {
    }
}
