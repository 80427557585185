import { Component, OnDestroy, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';

//import { Mail } from '../mail.model';
//import { MailService } from '../mail.service';
import { PlannerCase } from 'app/core/models';
import { CasesService } from '@appservices';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'cases-list',
    templateUrl: './cases-list.component.html',
    styleUrls: ['./cases-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CasesListComponent implements OnInit, OnDestroy {

    //cases: PlannerCase[];



    // Private
    private _unsubscribeAll: Subject<any>;

    @Input()
    cases: PlannerCase[];

    @Input()
    selectedCase: PlannerCase;

    @Output()
    onListScrolled: EventEmitter<any>;

    @Output()
    onCaseSelected: EventEmitter<string>;
    /**
     * Constructor
     *
     * @param {ActivatedRoute} _activatedRoute
     * @param {MailService} _mailService
     * @param {Location} _location
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        // private _casesService: CasesService,
        private _location: Location,
        private logger: NGXLogger
    ) {

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.onListScrolled = new EventEmitter();
        this.onCaseSelected = new EventEmitter();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // // Subscribe to update mails on changes
        // this._casesService.onCasesChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(cases => {
        //         this.cases = cases;
        //     });

        // this._casesService.onSelectedCaseChanged.pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(selectedCase => {

        //         this.selectedCase = selectedCase;
        //     });
        // 

        // // Subscribe to update current mail on changes
        // this._mailService.onCurrentMailChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(currentMail => {
        //         if ( !currentMail )
        //         {
        //             // Set the current mail id to null to deselect the current mail
        //             

        //             // Handle the location changes
        //             const labelHandle  = this._activatedRoute.snapshot.params.labelHandle,
        //                   filterHandle = this._activatedRoute.snapshot.params.filterHandle,
        //                   folderHandle = this._activatedRoute.snapshot.params.folderHandle;

        //             if ( labelHandle )
        //             {
        //                 this._location.go('apps/mail/label/' + labelHandle);
        //             }
        //             else if ( filterHandle )
        //             {
        //                 this._location.go('apps/mail/filter/' + filterHandle);
        //             }
        //             else
        //             {
        //                 this._location.go('apps/mail/' + folderHandle);
        //             }
        //         }
        //         else
        //         {
        //             this.currentCase = currentMail;
        //         }
        //     });

        //    this._mailService.getMails();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onCasesListScroll() {
        this.logger.trace("Cases list scrolled");

        this.onListScrolled.emit();
        //this._casesService.loadCases();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Read mail
     *
     * @param caseId
     */
    selectCase(caseId): void {

        if (this.selectedCase && this.selectedCase.id == caseId)
            return;

        const labelHandle = this._activatedRoute.snapshot.params.labelHandle,
            filterHandle = this._activatedRoute.snapshot.params.filterHandle,
            folderHandle = this._activatedRoute.snapshot.params.folderHandle;

        // if ( labelHandle )
        // {
        //     this._location.go('apps/mail/label/' + labelHandle + '/' + caseId);
        // }
        // else if ( filterHandle )
        // {
        //     this._location.go('mail/filter/' + filterHandle + '/' + caseId);
        // }
        // else
        // {
        this._location.go('cases/' + folderHandle + '/' + caseId);
        //}

        this.onCaseSelected.emit(caseId);
        //Set current mail    

        //this._casesService.selectCase(caseId);
    }
}
