const ɵ0 = {
    CASESTATUS: {
        New: 'New',
        DicomUploading: 'Dicom uploading',
        Segmentation: 'Segmentation',
        ModelsReady: 'Models ready',
        OperationPlanningSelf: 'Self operation planning',
        OperationPlanningCollaboration: 'Collaboration operation planning',
        ImplantsDesigning: 'Implants designing',
        ImplantsDesignReady: 'Implants desig ready',
        ImplantsDesignApproval: 'Implants design approval',
        GuidesDesigning: 'Guides designing',
        GuidesDesignReady: 'Guides design ready',
        GuidesDesignApproval: 'Guides design approval',
        Manufacturing: 'Manufacturing',
        Shipping: 'Shipping',
        Done: 'Done',
        Canceled: 'Canceled',
        Deleted: 'Deleted'
    }
};
export const locale = {
    lang: 'en',
    data: ɵ0
};
export { ɵ0 };
