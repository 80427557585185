import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
    development: false,   
    staging: false,
    production: false,
    docker: true,
    hmr: false,

    logsLogLevel: NgxLoggerLevel.TRACE,
    logsServerLogLevel: NgxLoggerLevel.OFF,
    logsEnableSourceMaps: true,

    apiEndpoint: window["env"]["apiEndpoint"] || "default",
    signalREndpoint: window["env"]["signalREndpoint"] || "default",
    visualizationEndpoint: window["env"]["visualizationEndpoint"] || "default",
    documentsEndpoint: window["env"]["documentsEndpoint"] || "default",
};
