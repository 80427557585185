import { OnDestroy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_DATE_FORMATS, DateValidator, NumPositiveValidator } from 'app/core/helpers';
import { PlannerCase, PlannerModuleType, GenerateModuleLicenseCommand } from '@appmodels';
import { Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
const ɵ0 = APP_DATE_FORMATS;
export class GenerateLicenseDialogComponent {
    /**
     * Constructor
     *
     * @param {MatDialogRef<GenerateLicenseDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
    // private _casesService: CasesService,
    matDialogRef, _data, _cdr, logger) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._cdr = _cdr;
        this.logger = logger;
        // medicalReferalsEnum = MedicalReferals;
        // operationTypeEnum = OperationType;
        this.moduleTypeEnum = PlannerModuleType;
        this.validateUser = (control) => {
            if (!control || !control.parent) {
                return null;
            }
            const userEmail = control.parent.get('owner').value;
            let isValid = false;
            if (!this.foundUsers || this.foundUsers.length == 0) {
                isValid = false;
            }
            else {
                const foundUsersIndex = this.foundUsers.findIndex(user => user.email == userEmail);
                if (foundUsersIndex >= 0)
                    isValid = true;
            }
            return isValid ? null : { wrongUserEmail: true };
        };
        // Set the defaults
        this.generateLicenseForm = this.createGenerateLicenseForm();
        // this.showExtraToFields = false;
        this._unsubscribeAll = new Subject();
        this.onSearchQueryChanged = new EventEmitter();
    }
    set users(value) {
        this.foundUsers = value;
        if (!this._cdr['destroyed']) {
            this._cdr.detectChanges();
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    createGenerateLicenseForm() {
        var generateLicenseForm = new FormGroup({
            owner: new FormControl({
                value: '',
                disabled: true
            }),
            requestUid: new FormControl('', Validators.required),
            moduleType: new FormControl('', Validators.required),
            startDate: new FormControl(new Date(), [Validators.required, DateValidator.dateVaidator]),
            days: new FormControl(0, [Validators.required, NumPositiveValidator.numPositiveValidator]),
            hospital: new FormControl('', [Validators.required]),
        });
        return generateLicenseForm;
    }
    generateLicense() {
        if (this.generateLicenseForm.invalid)
            return;
        var generateLicenseCommand = new GenerateModuleLicenseCommand();
        generateLicenseCommand.RequestUid = this.generateLicenseForm.get('requestUid').value;
        generateLicenseCommand.ModuleType = this.generateLicenseForm.get('moduleType').value;
        generateLicenseCommand.StartDate = this.generateLicenseForm.get('startDate').value;
        generateLicenseCommand.Days = this.generateLicenseForm.get('days').value;
        generateLicenseCommand.Hospital = this.generateLicenseForm.get('hospital').value;
        this.matDialogRef.close([generateLicenseCommand]);
    }
    onSearchQuery(searchQuery) {
        if (!searchQuery || searchQuery.length < 3)
            return;
        this.onSearchQueryChanged.emit(searchQuery);
    }
    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
export { ɵ0 };
