<div class="mat-simple-snackbar" (click)="onBarClicked()">
    <div class="mat-simple-snackbar-label">
        <span>{{message}}</span>
 
    </div>
    <div class="mat-simple-snackbar-action">
        <button mat-button="" class="mat-button" (click)="actionClicked()">
            <span class="mat-button-wrapper">{{action}}</span>
        </button>
    </div>
</div>