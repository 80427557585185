export class UserCommand {
    constructor() {
        this.firstName = null;
        this.lastName = null;
        this.middleName = null;
        this.medicalReferalsType = null;
        this.speciality = null;
        this.phoneNumber = null;
        this.country = null;
        this.city = null;
        this.company = null;
        this.language = null;
    }
}
export class RegisterUserCommand extends UserCommand {
    constructor() {
        super(...arguments);
        this.email = null;
        this.password = null;
        this.referalType = null;
        this.referalDetails = null;
    }
}
export class EditUserCommand extends UserCommand {
    constructor() {
        super(...arguments);
        this.id = null;
        this.newPassword = null;
        this.roles = [];
    }
}
export class DeleteUserCommand {
    constructor() {
        this.userId = null;
    }
}
