<mat-table #table [dataSource]="cases" [@animateStagger]="{value:'50'}">
    <!-- Checkbox Column -->
    <!-- <ng-container matColumnDef="checkbox">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let user">
            <mat-checkbox  (ngModelChange)="onSelectedChange(user.id)"
                          (click)="$event.stopPropagation()">
            </mat-checkbox>
        </mat-cell>
    </ng-container> -->

    <!-- Avatar Column -->
    <ng-container matColumnDef="avatar">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let caseItem">
            <img class="avatar" [alt]="caseItem.userName" src="/assets/images/avatars/profile.jpg" />
        </mat-cell>
    </ng-container>

    <!-- Title Column -->
    <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm>Title</mat-header-cell>
        <mat-cell *matCellDef="let caseItem" fxHide fxShow.gt-sm>
            <p class="title text-truncate">
                {{caseItem.title}}
            </p>
        </mat-cell>
    </ng-container>


    <!-- UserName Column -->
    <ng-container matColumnDef="ownerName">
        <mat-header-cell *matHeaderCellDef>Owner name</mat-header-cell>
        <mat-cell *matCellDef="let caseItem">
            <p class="ownerName text-truncate">
                {{caseItem.ownerName}}
            </p>
        </mat-cell>
    </ng-container>



    <!-- Phone Column -->
    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>Status</mat-header-cell>
        <mat-cell *matCellDef="let caseItem" fxHide fxShow.gt-md>
            <p class="company text-truncate">
                <case-status-translate [caseStatus]="caseItem.status">
                </case-status-translate>
            </p>
        </mat-cell>
    </ng-container>


    <!-- Residence Column -->
    <!-- <ng-container matColumnDef="residence">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-lg>Residence</mat-header-cell>
        <mat-cell *matCellDef="let caseItem" fxHide fxShow.gt-lg>
            <p class="residence text-truncate">
                {{caseItem.residence}}
            </p>
        </mat-cell>
    </ng-container> -->

    <!-- Last activity Column -->
    <ng-container matColumnDef="creationDate">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-lg>Creation date</mat-header-cell>
        <mat-cell *matCellDef="let caseItem" fxHide fxShow.gt-lg>
            <p class="lastActivity text-truncate">
                {{caseItem.creationDate | date : 'HH:mm dd.MM.yyyy'}}
            </p>
        </mat-cell>
    </ng-container>

    <!-- Buttons Column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let caseItem">
            <div fxFlex="row" fxLayoutAlign="end center">




                <button mat-raised-button class="mat-accent" (click)="setCaseStatus(caseItem.id, caseStatusEnum.Done)" aria-label="Set Done">
                    DONE
                </button>
                &nbsp;&nbsp;&nbsp;
                <button mat-raised-button class="" (click)="setCaseStatus(caseItem.id, caseStatusEnum.Canceled)" aria-label="Set Canceled">
                    CANCEL
                </button>


                <!-- <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More"
                        (click)="$event.stopPropagation();">
                    <mat-icon class="secondary-text">more_vert</mat-icon>
                </button>

                <mat-menu #moreMenu="matMenu">
                    <button mat-menu-item aria-label="remove" (click)="deleteUser(user)">
                        <mat-icon>delete</mat-icon>
                        <span>Remove</span>
                    </button>
                </mat-menu> -->
            </div>

        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <!-- (click)="viewLogsGroup(appLogGroup)" -->
    <mat-row *matRowDef="let appLogGroup; columns: displayedColumns;"
        [ngClass]="{'red-500': appLogGroup.hasUnhandledExceptions, 'red-200': appLogGroup.hasHandledExceptions}"
        matRipple [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>