import { DateInterval } from '@appmodels';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of, forkJoin } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { GetGeneralAnalytics } from '../store/actions/analytics.actions';
import { selectGeneralAnalytics } from '../store/selectors/analytics.selector';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "ngx-logger";
export class AnalyticsResolver {
    constructor(_store, logger) {
        this._store = _store;
        this.logger = logger;
    }
    resolve(route, state) {
        const routeParams = route.params;
        this.logger.info('Analytics route resolver: ', route.params);
        const obsCollection = [];
        obsCollection.push(this._store.select(selectGeneralAnalytics).pipe(filter(val => val != null), take(1)));
        obsCollection.push(of(this._store.dispatch(new GetGeneralAnalytics(DateInterval.CalendarMonth))));
        return forkJoin(obsCollection);
    }
}
AnalyticsResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsResolver_Factory() { return new AnalyticsResolver(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.NGXLogger)); }, token: AnalyticsResolver, providedIn: "root" });
