<div class="sidebar-content">

    <div class="card">

        <!-- SIDEBAR HEADER -->
        <div class="header p-24" fxLayout="row" fxLayoutAlign="start center">

            <!-- USER -->
            <img src="/assets/images/avatars/profile.jpg" class="avatar mr-16" [alt]=""/>
            <span class="h5">Case management</span>
            <!-- / USER -->

        </div>
        <!-- / SIDEBAR HEADER -->

        <!-- SIDEBAR CONTENT -->
        <div class="content py-16" fusePerfectScrollbar>

            <div class="nav material2">

                 <!-- [ngClass]="{'active accent':appLogsFilterParams.isWithAdmins == false}" -->
                <div class="nav-item" aria-label="inbox">
                    <a class="nav-link active accent" matRipple (click)="changeFilter(false)">
                        <span class="title">Users only cases</span>
                    </a>
                </div>

                <!-- <div class="nav-item" aria-label="inbox">
                    <a class="nav-link" matRipple (click)="changeFilter(true)"
                       [ngClass]="{'active accent':appLogsFilterParams.isWithAdmins == true}">
                        <span class="title">Users and admins logs</span>
                    </a>
                </div> -->
               
            </div>

        </div>

    </div>

</div>
<!-- / SIDEBAR CONTENT -->
