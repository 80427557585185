<div id="caseManagement" class="page-layout simple left-sidebar inner-sidebar inner-scroll">

    <!-- HEADER -->
    <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="start"
         fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

        <!-- APP TITLE -->
        <div fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="sidebar-toggle mr-12" fxHide.gt-sm
                    (click)="toggleSidebar('contacts-main-sidebar')">
                <mat-icon>menu</mat-icon>
            </button>

            <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16"
                          [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">email
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Case management
                </span>
            </div>

        </div>
        <!-- / APP TITLE -->

        <!-- SEARCH -->
        <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

            <div class="search-wrapper mt-16 mt-sm-0">

                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input [formControl]="searchInput" placeholder="Search for a user">
                </div>

                <div class="search" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="secondary-text">search</mat-icon>
                    
                    <input autocomplete="off" [formControl]="searchInput" matInput (input)="onSearchQuery($event)" placeholder="Search for a case" fxFlex>
                </div>

            </div>

        </div>
        <!-- / SEARCH -->

    </div>
    <!-- / HEADER -->

    <!-- SELECTED BAR -->
    <selected-bar class="accent-600" *ngIf="hasSelectedContacts" [@slideInTop]></selected-bar>
    <!-- / SELECTED BAR -->

    <!-- CONTENT -->
    <div class="content">

        <!-- SIDEBAR -->
        <fuse-sidebar class="sidebar" name="contacts-main-sidebar" position="left" lockedOpen="gt-sm">

            <!-- SIDEBAR CONTENT -->
            <div class="content" fusePerfectScrollbar>
                <contacts-main-sidebar   (onIsWithAdminsChanged)="filterParamsChanged($event)" [@animate]="{value:'*'}"></contacts-main-sidebar>
            </div>
            <!-- / SIDEBAR CONTENT -->

        </fuse-sidebar>
        <!-- / SIDEBAR -->

        <!-- CENTER -->
        <div id="perfectScrollbar" class="center p-24 pb-56 pr-sm-92" fusePerfectScrollbar [scrollWindow]="false" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="300" (scrolled)="getMoreCases()">

            <!-- CONTENT -->
            <div class="content">
                <!-- (onUserEdit)="editUser($event)" (onUserDelete)="deleteUser($event)" -->
                <case-management-list [cases] = "cases$ | async" (onSetCaseStatus) = "setCaseStatus($event)" ></case-management-list>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CENTER -->

    </div>
    <!-- / CONTENT-->

</div>

<!-- ADD CONTACT BUTTON -->
<!-- <button mat-fab class="accent" id="add-contact-button" (click)="newContact()" aria-label="add contact"
        [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
    <mat-icon>person_add</mat-icon>
</button> -->
<!-- / ADD CONTACT BUTTON -->
