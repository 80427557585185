import { Component, Inject, ViewEncapsulation, OnDestroy, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS, ObjectMapper } from 'app/core/helpers';
import { PlannerCase, SceneObjectType, PlannerFile, MaterialQuality, FileType } from '@appmodels';
import { CasesService } from '@appservices';
import { Subject } from 'rxjs';
import { PlannerSceneObject } from 'app/core/models/planner/plannerSceneObject';

@Component({
    selector: 'edit-scene-object',
    templateUrl: './edit-scene-object.component.html',
    styleUrls: ['./edit-scene-object.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class EditSceneObjectDialogComponent implements OnDestroy {

    sceneObjectTypeEnum = SceneObjectType;
    //  modelMaterialTypeEnum = ModelMaterialType;

    //  files: any;

    //dialogRef: any;
    fileTypeEnum = FileType;

    sceneObjectEditForm: FormGroup;
    errorText: string;
    requestInProgress: boolean;
    selectedCase: PlannerCase;

    showExtraToFields: boolean;

    editedSceneObject: PlannerSceneObject;
    isEditingObject: boolean;

    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        private _caseService: CasesService,
        public matDialogRef: MatDialogRef<EditSceneObjectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        // Set the defaults

        this.selectedCase = this._data.selectedCase;
        this.editedSceneObject = this._data.sceneObject;

        if (this.editedSceneObject)
            this.isEditingObject = true;

        this.sceneObjectEditForm = this.createSceneObjectEditForm(this.editedSceneObject);

        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */


    createSceneObjectEditForm(editedSceneObject: PlannerSceneObject): FormGroup {

        const form = new FormGroup({
            id: new FormControl(null),
            title: new FormControl('', Validators.required),
            objectType: new FormControl(null, Validators.required),
            isVisible: new FormControl(true),
            objectFile: new FormControl(''),
            objectFileAdditional: new FormControl(''),
            description: new FormControl(''),
            color: new FormControl(''),            
            text: new FormControl(''),            
            position: new FormControl(''),
            rotation: new FormControl(''),
            scale: new FormControl(''),
            reflection: new FormControl(''),
            hierarchyInfo: new FormControl(''),
            materialQuality : new FormControl(MaterialQuality.Good),
            idForSelectionGroup: new FormControl(''),
        });



        const objectTypeValChanges$ = form.controls.objectType.valueChanges;

        objectTypeValChanges$.subscribe(newObjectType => {

            form.controls['objectFile'].clearValidators();

            switch (newObjectType) {
                case SceneObjectType.Object3d:
                    form.controls['objectFile'].setValidators(Validators.required);
                    break;
                default:
                    break;
            }

            form.controls['objectFile'].updateValueAndValidity();
        });
        

        if (editedSceneObject) {

            ObjectMapper.mapObjectToFormGroup(editedSceneObject, form);

            if (editedSceneObject.objectFile) {
                const fileToAttach = this.selectedCase.details.files.filter(x => x.id == editedSceneObject.objectFile.id)[0];
                form.controls['objectFile'].setValue(fileToAttach);
            }

            if (editedSceneObject.objectFileAdditional) {
                const fileAdditionalToAttach = this.selectedCase.details.files.filter(x => x.id == editedSceneObject.objectFileAdditional.id)[0];
                form.controls['objectFileAdditional'].setValue(fileAdditionalToAttach);
            }
        }

        return form;
    }

    editSceneObject(): void {
        if (!this.editedSceneObject)
        {
            this.editedSceneObject = new PlannerSceneObject();
        }

        ObjectMapper.mapFormGroupToObject(this.sceneObjectEditForm, this.editedSceneObject);
        this.matDialogRef.close([this.isEditingObject ? 'edit' : 'add', this.editedSceneObject]);
    }

    setSceneObjectColor(color: string) {
        this.sceneObjectEditForm.controls['color'].setValue(color);
    }

    filterByFileType(plannerFiles: PlannerFile[]) {
        return plannerFiles.sort((a, b) => a.fileType > b.fileType ? 1 : a.fileType == b.fileType ? 0 : -1);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
