import { AuthenticationService, UsersService } from '@appservices';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { Injectable } from '@angular/core';
import { SendRecoverPasswordRequest, EAuthActions, SendRecoverPasswordRequestSuccess, RecoverPassword, RecoverPasswordSuccess, SetManagementOrganizationIdSuccess } from '../actions/auth.actions';
import { switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
//import { Router, UrlTree, UrlSegmentGroup, PRIMARY_OUTLET, UrlSegment } from '@angular/router';

@Injectable()
export class AuthEffects {

    @Effect()
    setManagementOrganizationByName$ = this._actions$.pipe(
        ofType<SendRecoverPasswordRequest>(EAuthActions.SetManagementOrganizationByName),
        map(action => action.payload),
        switchMap((organizationTitle) => {
            return this._usersService.getPlannerUsersOrganization(organizationTitle);
        }),
        switchMap((organization) => of(new SetManagementOrganizationIdSuccess(organization.id)))
    );

    @Effect()
    sendRecoverPasswordRequest$ = this._actions$.pipe(
        ofType<SendRecoverPasswordRequest>(EAuthActions.SendRecoverPasswordRequest),
        map(action => action.payload),
        switchMap((userEmail) => {

             const origin = window.location.origin;
            const requestConfirmUrl = origin + "/resetpassword";

            console.log(requestConfirmUrl);

            return this._authService.sendRecoverPasswordRequest(userEmail, requestConfirmUrl);
        }),
        switchMap((user) => of(new SendRecoverPasswordRequestSuccess(user)))
    );

    @Effect()
    recoverPassword$ = this._actions$.pipe(
        ofType<RecoverPassword>(EAuthActions.RecoverPassword),
        map(action => action.payload),
        switchMap((recoverPasswordCommand) => {
         
            return this._authService.recoverPassword(recoverPasswordCommand);
        }),
        switchMap((result) => of(new RecoverPasswordSuccess(result)))
    );

    constructor(
        private _authService: AuthenticationService,
        private _usersService: UsersService,
        private _actions$: Actions,
        private _store: Store<IAppState>,
        //private _router: Router,
       // private _location: Location,
       // private _route: ActivatedRoute
    ) { }

}