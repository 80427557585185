import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'app/core/store/state/app.state';
import { selectUsers, selectUsersFilters } from 'app/core/store/selectors/users.selectors';
import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { EditUser, DeleteUser, GetUsers, ExportUsersList, SetUsersFilter } from 'app/core/store/actions/users.actions';
import { IUsersFilter } from 'app/core/models/usersFilter';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as russian } from './i18n/ru';
import { PaidModulesFormDialogComponent } from './presentation/online-licenses/paidModules-form/paidModules-form.component';
import { selectUsersPaidModules } from 'app/core/store/selectors/paidModules.selector';
import { GenerateModuleLicenseCommand, PlannerUsersAvailableModules } from '@appmodels';
import { AddUsersPaidModules, AddUsersPaidModulesOffline, EditUsersPaidModules, RemoveUsersPaidModules, SetPaidModulesOfflineSearchPattern, SetPaidModulesSearchPattern } from 'app/core/store/actions/paidModules.actions';

@Component({
    selector: 'paidModules',
    templateUrl: './paidModules.component.html',
    styleUrls: ['./paidModules.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class PaidModulesComponent implements OnInit, OnDestroy {
    dialogRef: any;
    hasSelectedContacts: boolean;
    searchInput: FormControl;
    activeTab: string;
   // paidModules$: Observable<PlannerUsersAvailableModules[]>;

    private _changeSearchPatternTimer: NodeJS.Timer;

    // Private
    private _unsubscribeAll: Subject<any>;

    users$: Observable<PlannerUser[]>;
    /**
     * Constructor
     *
     * @param {UsersService} _contactsService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _matDialog: MatDialog,
        private _store: Store<IAppState>,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, russian);
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.activeTab = 'online';

        this.users$ = this._store.pipe(select(selectUsers));

       // this.paidModules$ = this._store.pipe(select(selectUsersPaidModules));
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    onTabChanged(event: any): void {
        this.searchInput.setValue('');
        this.activeTab = event.index == 0 ? 'online' : 'offline';

        if(this.activeTab == 'online')
        {
            this._store.dispatch(new SetPaidModulesSearchPattern(this.searchInput.value));
        }
        else{
            this._store.dispatch(new SetPaidModulesOfflineSearchPattern(this.searchInput.value));
        }
    }


    onSearchQuery(event: any): void {
        const searchQuery: string = event.target.value;

        console.log(this.activeTab);

        const dispatchFunc = () => this.activeTab == 'online' ? this._store.dispatch(new SetPaidModulesSearchPattern(searchQuery)) :
        this._store.dispatch(new SetPaidModulesOfflineSearchPattern(searchQuery)) ;

        clearTimeout(this._changeSearchPatternTimer);
        this._changeSearchPatternTimer = setTimeout(dispatchFunc, 2000);
    }

    


    // exportUsers(): void {
    //     this._store.dispatch(new ExportUsersList());
    // }

    // generateLicenseHandler(generateLicenseCommand: GenerateModuleLicenseCommand): void {

    //     this._store.dispatch(new AddUsersPaidModulesOffline(generateLicenseCommand));

    // }

    /**
     * Toggle the sidebar 
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
}
