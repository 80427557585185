import * as i0 from "@angular/core";
export class FuseMatSidenavHelperService {
    /**
     * Constructor
     */
    constructor() {
        this.sidenavInstances = [];
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    /**
     * Set sidenav
     *
     * @param id
     * @param instance
     */
    setSidenav(id, instance) {
        this.sidenavInstances[id] = instance;
    }
    /**
     * Get sidenav
     *
     * @param id
     * @returns {any}
     */
    getSidenav(id) {
        return this.sidenavInstances[id];
    }
}
FuseMatSidenavHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FuseMatSidenavHelperService_Factory() { return new FuseMatSidenavHelperService(); }, token: FuseMatSidenavHelperService, providedIn: "root" });
