<mat-table #table [dataSource]="users"
           [@animateStagger]="{value:'50'}">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="checkbox">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let user">
            <mat-checkbox  (ngModelChange)="onSelectedChange(user.id)"
                          (click)="$event.stopPropagation()">
            </mat-checkbox>
        </mat-cell>
    </ng-container>

    <!-- Avatar Column -->
    <ng-container matColumnDef="avatar">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let user">
            <img class="avatar" [alt]="user.email"
                 src="/assets/images/avatars/profile.jpg"/>
        </mat-cell>
    </ng-container>

     
    <!-- Email Column -->
    <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm>Email</mat-header-cell>
        <mat-cell *matCellDef="let user" fxHide fxShow.gt-sm>
            <p class="email text-truncate">
                {{user.email}}
            </p>
        </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm>Name</mat-header-cell>
        <mat-cell *matCellDef="let user" fxHide fxShow.gt-sm>
            <p class="name text-truncate">
                {{user.lastName}} {{user.firstName}}
            </p>
        </mat-cell>
    </ng-container>

    <!-- Phone Column -->
    <ng-container matColumnDef="phoneNumber">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>Phone</mat-header-cell>
        <mat-cell *matCellDef="let user" fxHide fxShow.gt-md>
            <p class="phone text-truncate">
                {{user.phoneNumber}}
            </p>
        </mat-cell>
    </ng-container>


    <!-- Company Column -->
    <ng-container matColumnDef="company">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-lg>Company</mat-header-cell>
        <mat-cell *matCellDef="let user" fxHide fxShow.gt-lg>
            <p class="company text-truncate">
                {{user.company}}
            </p>
        </mat-cell>
    </ng-container>

    <!-- Buttons Column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef ></mat-header-cell>
        <mat-cell *matCellDef="let user">
            <div fxFlex="row" fxLayoutAlign="end center">
            

                <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More"
                        (click)="$event.stopPropagation();">
                    <mat-icon class="secondary-text">more_vert</mat-icon>
                </button>

                <mat-menu #moreMenu="matMenu">
                    <button mat-menu-item aria-label="remove" (click)="deleteUser(user)">
                        <mat-icon>delete</mat-icon>
                        <span>Remove</span>
                    </button>
                </mat-menu>
            </div>

        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let user; columns: displayedColumns;"
             class="contact"
             (click)="editUser(user)"
             
             matRipple
             [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>

