import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/app-config.module';
import { NGXLogger } from 'ngx-logger';
import { tap, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../../app-config.module";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-logger";
export class AnalyticsService {
    constructor(config, http, logger) {
        this.config = config;
        this.http = http;
        this.logger = logger;
    }
    getGeneralAnalytics(dateInterval, selectedMonth, selectedQuarter, selectedYear) {
        const url = `${this.config.apiEndpoint}/api/private/Analytics/GetGeneralAnalytics?DateInterval=${dateInterval}&SelectedMonth=${selectedMonth ? selectedMonth : ''}&SelectedQuarter=${selectedQuarter ? selectedQuarter : ''}&SelectedYear=${selectedYear ? selectedYear : ''}`;
        this.logger.debug("HTTP GET general analytics.", `Url: ${url}`);
        return this.http.get(url)
            .pipe(tap(response => {
            this.logger.debug("Response general analytics:", response);
        }), map(response => response));
    }
    getCasesAnalytics(dateInterval, selectedMonth, selectedQuarter, selectedYear) {
        const url = `${this.config.apiEndpoint}/api/private/Analytics/GetCasesAnalytics?DateInterval=${dateInterval}&SelectedMonth=${selectedMonth ? selectedMonth : ''}&SelectedQuarter=${selectedQuarter ? selectedQuarter : ''}&SelectedYear=${selectedYear ? selectedYear : ''}`;
        this.logger.debug("HTTP GET cases analytics.", `Url: ${url}`);
        return this.http.get(url)
            .pipe(tap(response => {
            this.logger.debug("Response cases analytics:", response);
        }), map(response => response));
    }
    getUsersAnalytics(dateInterval, selectedMonth, selectedQuarter, selectedYear) {
        const url = `${this.config.apiEndpoint}/api/private/Analytics/GetUsersAnalytics?DateInterval=${dateInterval}&SelectedMonth=${selectedMonth ? selectedMonth : ''}&SelectedQuarter=${selectedQuarter ? selectedQuarter : ''}&SelectedYear=${selectedYear ? selectedYear : ''}`;
        this.logger.debug("HTTP GET users analytics.", `Url: ${url}`);
        return this.http.get(url)
            .pipe(tap(response => {
            this.logger.debug("Response users analytics:", response);
        }), map(response => response));
    }
}
AnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.ɵɵinject(i1.APP_CONFIG), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.NGXLogger)); }, token: AnalyticsService, providedIn: "root" });
