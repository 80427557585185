import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CaseStatusTranslateComponent } from './case-status-translate.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations   : [
       
        CaseStatusTranslateComponent
        
    ],
    imports        : [
        CommonModule,

        TranslateModule
       
    ],
    exports :
    [
        CaseStatusTranslateComponent
    ],
    providers      : [
       
    ],
    entryComponents: [
       
    ]
})
export class CaseStatusTranslateModule
{
}