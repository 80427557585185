import { AppConfig } from 'app/app-config.module';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { tap, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../../app-config.module";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-logger";
export class AppLogsService {
    constructor(config, http, logger) {
        this.config = config;
        this.http = http;
        this.logger = logger;
    }
    getAppLogsGroups(isWithAdmins, searchPattern, sort, sortOrder, skip, take) {
        const logsSearchPattern = searchPattern ? searchPattern : '';
        const url = `${this.config.apiEndpoint}/api/private/LogsManagement/GetLogsGroups?isWithAdmins=${isWithAdmins}&SearchPattern=${logsSearchPattern}&Sort=${sort}&SortOrder=${sortOrder}&Skip=${skip}&Take=${take}`;
        this.logger.debug("HTTP GET app logs groups.", `Url: ${url}`);
        return this.http.get(url)
            .pipe(tap(response => {
            this.logger.debug("Response app logs groups:", response);
        }), map(response => response.appLogsGroups));
    }
    getAppLogsByConnection(connectionId) {
        const url = `${this.config.apiEndpoint}/api/private/LogsManagement/GetLogsByConnection?AppConnectionUID=${connectionId}`;
        this.logger.debug("HTTP GET app logs by connection.", `Url: ${url}`);
        return this.http.get(url)
            .pipe(tap(response => {
            this.logger.debug("Response app logs by connection:", response);
        }), map(response => response.appLogs));
    }
}
AppLogsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppLogsService_Factory() { return new AppLogsService(i0.ɵɵinject(i1.APP_CONFIG), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.NGXLogger)); }, token: AppLogsService, providedIn: "root" });
