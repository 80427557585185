<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

        <form [formGroup]="paidModuleForm" novalidate>

            <!-- <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                
                <input matInput formControlName="userEmail">
                <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                <mat-error *ngIf="paidModuleForm.get('userEmail').hasError('required')">
                    Email is required
                </mat-error>
                <mat-error *ngIf="paidModuleForm.get('userEmail').hasError('email')">
                    Please enter a valid email address
                </mat-error>
           
            </mat-form-field> -->

            <mat-form-field appearance="outline">
                <mat-label>Select user</mat-label>
                <input type="text" placeholder="Search user..." aria-label="User email" matInput
                    formControlName="userEmail" #userEmail (input)="onSearchQuery(userEmail.value)"
                    [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let user of foundUsers" [value]="user.email">
                        {{user.email}}
                    </mat-option>
                </mat-autocomplete>

                <mat-error *ngIf="paidModuleForm.get('userEmail').hasError('required')">
                    User is required
                </mat-error>
                <mat-error
                    *ngIf="!paidModuleForm.get('userEmail').hasError('required') && paidModuleForm.get('userEmail').hasError('wrongUserEmail')">
                    Wrong user email
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Hospital</mat-label>
                <input matInput name="hospital" formControlName="hospital">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Module</mat-label>
                <mat-select name="type" formControlName="moduleType">
                    <mat-option [value]="moduleTypeEnum.Modules2D">
                        Modules2D
                    </mat-option>     
                    <mat-option [value]="moduleTypeEnum.Maxillofacial">
                        Maxillofacial
                    </mat-option>              
                </mat-select>
                <mat-error>
                    Scene type is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Start date</mat-label>
                <input matInput name="startDate" formControlName="startDate" [matDatepicker]="startDatePicker"
                    placeholder="choose a start date">
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
                <mat-error>
                    Start date
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Days allocated (empty = infinite)</mat-label>
                <input matInput name="daysAllocated" formControlName="daysAllocated" type="number">
            </mat-form-field>


        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button *ngIf="action === 'edit'" mat-button class="mr-8" (click)="matDialogRef.close(['cancel',paidModuleForm])"
            aria-label="Delete" matTooltip="Delete">
            CANCEL
        </button>

        <button *ngIf="action !== 'edit'" mat-button class="save-button" (click)="onSave()"
            [disabled]="paidModuleForm.invalid" aria-label="SAVE">
            ADD
        </button>

        <button *ngIf="action === 'edit'" mat-button class="save-button" (click)="onSave()"
            [disabled]="paidModuleForm.invalid" aria-label="SAVE">
            SAVE
        </button>

    </div>
</div>