import * as tslib_1 from "tslib";
import { Effect, ofType, Actions } from '@ngrx/effects';
//import { SIGNALR_HUB_UNSTARTED, mergeMapHubToAction, startSignalRHub, SIGNALR_CONNECTED, createSignalRHub, stopSignalRHub, selectHubsStatuses, SignalRHub, signalrHubUnstarted, SignalRHubState, SIGNALR_DISCONNECTED } from 'ngrx-signalr-core';
import { tap, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { VoidAction } from '../actions/app.actions';
import { selectCurrentUser } from '../selectors/users.selectors';
import { EAppSignalRActions } from '../actions/appsignalr.actions';
import { AppConfig } from 'app/app-config.module';
import { AuthenticationService } from '@appservices';
import { NewMessageFromServer } from '../actions/messages.actions';
import * as signalr from '@microsoft/signalr';
import { ObjectDateFieldsFormatter } from 'app/core/helpers';
import { CaseSceneSavedFromServer } from '../actions/cases.actions';
import { from } from 'rxjs';
export class AppSignalREffects {
    constructor(config, _authenticationService, logger, _actions$, _store) {
        this.config = config;
        this._authenticationService = _authenticationService;
        this.logger = logger;
        this._actions$ = _actions$;
        this._store = _store;
        this.signalRCreateHub$ = this._actions$.pipe(ofType(EAppSignalRActions.CreateHub), tap(x => this.logger.log('SIGNALR_HUB_CREATING')), switchMap(_ => {
            return this._store.select(selectCurrentUser);
        }), switchMap(user => {
            //TODO: check valid token
            if (user != null) {
                const options = {
                    accessTokenFactory: () => {
                        return user.token;
                    }
                };
                this._hubConnection = new signalr.HubConnectionBuilder()
                    .withUrl(this.config.signalREndpoint, options)
                    .withAutomaticReconnect()
                    .configureLogging(this.logger)
                    .build();
                this._hubConnection.on("OnServerNewMessage", (data) => {
                    ObjectDateFieldsFormatter.convertFieldsDate(data);
                    this.logger.log('SignalR', 'OnServerNewMessage', data);
                    this._store.dispatch(new NewMessageFromServer(data));
                });
                this._hubConnection.on("OnServerCaseSceneSaved", (data) => {
                    ObjectDateFieldsFormatter.convertFieldsDate(data);
                    this.logger.log('SignalR', 'OnServerCaseSceneSaved', data);
                    this._store.dispatch(new CaseSceneSavedFromServer(data));
                });
                return from(this._hubConnection.start()).pipe(tap(_ => this.logger.log("SIGNALR_CONNECTED")), map(_ => new VoidAction()));
            }
            return [new VoidAction()];
        }));
        this.signalRDisconnect$ = this._actions$.pipe(ofType(EAppSignalRActions.DisconnectHub), tap(x => this.logger.log('SIGNALR_HUB_DISCONNECTING')), switchMap(() => {
            return from(this._hubConnection.stop()).pipe(tap(_ => this.logger.log("SIGNALR_DISCONNECTED")), map(_ => new VoidAction()));
        }));
        //    this._customState = new Subject<boolean>();
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AppSignalREffects.prototype, "signalRCreateHub$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AppSignalREffects.prototype, "signalRDisconnect$", void 0);
