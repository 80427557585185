<mat-table #table [dataSource]="qualitySurveyCases" [@animateStagger]="{value:'50'}">
    <!-- Checkbox Column -->
    <!-- <ng-container matColumnDef="checkbox">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let user">
            <mat-checkbox  (ngModelChange)="onSelectedChange(user.id)"
                          (click)="$event.stopPropagation()">
            </mat-checkbox>
        </mat-cell>
    </ng-container> -->

    <!-- Avatar Column -->
    <ng-container matColumnDef="link">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let caseItem">
            <p class="avatar">
                <span class="link" (click)="linkClick($event, caseItem.caseId)">link</span>
            </p>
        </mat-cell>
    </ng-container>

    <!-- Title Column -->
    <ng-container matColumnDef="caseId">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm>Case id</mat-header-cell>
        <mat-cell *matCellDef="let caseItem" fxHide fxShow.gt-sm>
            <p class="title text-truncate">
                {{caseItem.caseId}}
            </p>
        </mat-cell>
    </ng-container>


    <!-- UserName Column -->
    <ng-container matColumnDef="ownerName">
        <mat-header-cell *matHeaderCellDef>Owner name</mat-header-cell>
        <mat-cell *matCellDef="let caseItem">
            <p class="ownerName text-truncate">                
                {{caseItem.ownerName}}
            </p>
        </mat-cell>
    </ng-container>



    <!-- Phone Column -->
    <ng-container matColumnDef="order">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>Order</mat-header-cell>
        <mat-cell *matCellDef="let caseItem" fxHide fxShow.gt-md>
            <p class="company text-truncate">
                <span class="comma" fxShow="caseItem.order.planning">Planning</span>
                <span class="comma" fxShow="caseItem.order.anatomicalModel">AnatomicalModel</span>
                <span class="comma" fxShow="caseItem.order.guide">Guide</span>
                <span class="comma" fxShow="caseItem.order.titan">Titan</span>
                <span class="comma" fxShow="caseItem.order.peek">Peek</span>
            </p>
        </mat-cell>
    </ng-container>


    <!-- Residence Column -->
    <!-- <ng-container matColumnDef="residence">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-lg>Residence</mat-header-cell>
        <mat-cell *matCellDef="let caseItem" fxHide fxShow.gt-lg>
            <p class="residence text-truncate">
                {{caseItem.residence}}
            </p>
        </mat-cell>
    </ng-container> -->

    <!-- Last activity Column -->
    <ng-container matColumnDef="creationDate">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-lg>Creation date</mat-header-cell>
        <mat-cell *matCellDef="let caseItem" fxHide fxShow.gt-lg>
            <p class="lastActivity text-truncate">
                {{caseItem.creationDate | date : 'HH:mm dd.MM.yyyy'}}
            </p>
        </mat-cell>
    </ng-container>

    <!-- Buttons Column -->
    <!-- <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let caseItem">
            <div fxFlex="row" fxLayoutAlign="end center">




                <button mat-raised-button class="mat-accent" (click)="setCaseStatus(caseItem.id, caseStatusEnum.Done)" aria-label="Set Done">
                    DONE
                </button>
                &nbsp;&nbsp;&nbsp;
                <button mat-raised-button class="" (click)="setCaseStatus(caseItem.id, caseStatusEnum.Canceled)" aria-label="Set Canceled">
                    CANCEL
                </button>

            </div>

        </mat-cell>
    </ng-container> -->

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <!-- (click)="viewLogsGroup(appLogGroup)" -->
    <mat-row *matRowDef="let qualitySurveyCase; columns: displayedColumns;"
    (click)="showSurvey(qualitySurveyCase)"
        matRipple [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>
</mat-table>