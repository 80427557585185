import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { PlannerCase, PlannerMessage, IUser } from '@appmodels';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'chat-panel',
  templateUrl: './chat-panel.component.html',
  styleUrls: ['./chat-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatPanelComponent implements OnInit {

  @Input()
  currentUser: IUser;
  @Input()
  selectedCase: PlannerCase;
  @Input()
  messages: PlannerMessage[];
  @Output()
  onNewMessage: EventEmitter<string>;
  @Output()
  onMessagesReaded: EventEmitter<void>;
  @Output()
  onGetMoreMessages: EventEmitter<void>;

  constructor() {
    this.onNewMessage = new EventEmitter();
    this.onMessagesReaded = new EventEmitter();
    this.onGetMoreMessages = new EventEmitter();
  }

  ngOnInit() {
  }

  newMessage(text: string): void {
    this.onNewMessage.emit(text);
  }

  messagesReaded(): void {
    this.onMessagesReaded.emit();
  }

  getMoreMessages(): void {
    this.onGetMoreMessages.emit();
  }
}
