import * as tslib_1 from "tslib";
import { AuthenticationService, UsersService } from '@appservices';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EAuthActions, SendRecoverPasswordRequestSuccess, RecoverPasswordSuccess, SetManagementOrganizationIdSuccess } from '../actions/auth.actions';
import { switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
//import { Router, UrlTree, UrlSegmentGroup, PRIMARY_OUTLET, UrlSegment } from '@angular/router';
export class AuthEffects {
    constructor(_authService, _usersService, _actions$, _store) {
        this._authService = _authService;
        this._usersService = _usersService;
        this._actions$ = _actions$;
        this._store = _store;
        this.setManagementOrganizationByName$ = this._actions$.pipe(ofType(EAuthActions.SetManagementOrganizationByName), map(action => action.payload), switchMap((organizationTitle) => {
            return this._usersService.getPlannerUsersOrganization(organizationTitle);
        }), switchMap((organization) => of(new SetManagementOrganizationIdSuccess(organization.id))));
        this.sendRecoverPasswordRequest$ = this._actions$.pipe(ofType(EAuthActions.SendRecoverPasswordRequest), map(action => action.payload), switchMap((userEmail) => {
            const origin = window.location.origin;
            const requestConfirmUrl = origin + "/resetpassword";
            console.log(requestConfirmUrl);
            return this._authService.sendRecoverPasswordRequest(userEmail, requestConfirmUrl);
        }), switchMap((user) => of(new SendRecoverPasswordRequestSuccess(user))));
        this.recoverPassword$ = this._actions$.pipe(ofType(EAuthActions.RecoverPassword), map(action => action.payload), switchMap((recoverPasswordCommand) => {
            return this._authService.recoverPassword(recoverPasswordCommand);
        }), switchMap((result) => of(new RecoverPasswordSuccess(result))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "setManagementOrganizationByName$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "sendRecoverPasswordRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "recoverPassword$", void 0);
