<div>
    <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom [@animateStagger]="{value:'50'}">


        <fuse-widget style="" [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
            fxFlex.gt-xs="100" fxFlex.gt-md="100">

            <div class="fuse-card auto-width">

                <div class="position-relative p-24" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="column" class="batch-clone-page" fxLayoutAlign="start start">
                        <span class="h2">Batch clone</span>

                        <form name="composeForm" class="auto-width" [formGroup]="batchCloneForm"
                            class="batch-clone-form" fxLayout="column" fxFlex>

                            <mat-label>Case id:</mat-label>
                            <mat-form-field class="small-width" appearance="outline">

                                <input matInput name="caseId" formControlName="caseId" type="text">
                            </mat-form-field>

                            <mat-label>New title (leave blank if same):</mat-label>
                            <mat-form-field class="small-width" appearance="outline">

                                <input matInput name="newTitle" formControlName="newTitle" type="text">
                            </mat-form-field>

                            <div class="slideToggleGroup">

                                <mat-slide-toggle name="isExcludeFromAnalytics"
                                    formControlName="isExcludeFromAnalytics">
                                    Exclude from analytics
                                </mat-slide-toggle>
                            </div>

                            <mat-label>User emails:</mat-label>
                            <mat-form-field appearance="outline">
                                <textarea matInput name="usersEmails" formControlName="usersEmails"
                                    type="text"></textarea>
                            </mat-form-field>
                        </form>

                        <div class="controls">
                            <button mat-raised-button color="primary" (click)="batchClone()" class="save-button"
                                [disabled]="batchCloneForm.invalid || requestInProgress" aria-label="Create">
                                Batch clone
                            </button>


                        </div>

                        <div [fxShow]="lastCaseCloneBatchInfo" class="batch-task-log">
                        <!-- <div class="batch-task-log"> -->

                            <span>Progress: {{lastCaseCloneBatchInfo != null ? lastCaseCloneBatchInfo.progress*100 :
                                ''}}%</span>

                            <br> <br>
                            Log:
                            <blockquote class="task-log">{{lastCaseCloneBatchInfo != null ? lastCaseCloneBatchInfo.log : ''}}
                            </blockquote>
                        </div>
                    </div>

                </div>

            </div>

        </fuse-widget>

    </div>

</div>