const ɵ0 = {
    MAIL: {
        CASECREATE: 'CASE CREATE',
        FOLDERS: 'FOLDERS',
        FILTERS: 'FILTERS',
        LABELS: 'LABELS',
        NO_MESSAGES: 'There are no cases!',
        SELECT_A_MESSAGE_TO_READ: 'Select a case to view info',
        SEARCH_PLACEHOLDER: 'Search for an e-mail or contact'
    }
};
export const locale = {
    lang: 'en',
    data: ɵ0
};
export { ɵ0 };
