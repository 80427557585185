<!-- WIDGET GROUP  -->
<div class="widget-group" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom [@animateStagger]="{value:'50'}">

    <!-- WIDGET 11 -->
    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="row" fxFlex="100">

        <!-- Front -->
        <div class="fuse-widget-front">

            <div class="p-24 mb-8 border-bottom" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="h2">Online users</div>
                <div class="text-boxed accent m-0">
                    {{onlineUsers.length}}
                    online
                </div>
            </div>

            <mat-table #table [dataSource]="onlineUsers" [@animateStagger]="{value:'50'}">
                <!-- Checkbox Column -->
                <!-- <ng-container matColumnDef="checkbox"> -->
                    <!-- <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <mat-checkbox (ngModelChange)="onSelectedChange(user.id)" (click)="$event.stopPropagation()">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container> -->

                <!-- Avatar Column -->
                <ng-container matColumnDef="avatar">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let connection">
                        <img class="avatar" [alt]="connection.client.email" src="/assets/images/avatars/profile.jpg" />
                    </mat-cell>
                </ng-container>


                <!-- Email Column -->
                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
                    <mat-cell *matCellDef="let connection" >
                        <p class="email text-truncate">
                            {{connection.client.userName}}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="language">
                    <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-lg>Language</mat-header-cell>
                    <mat-cell *matCellDef="let connection"  fxHide fxShow.gt-lg>
                        <p class="name text-truncate">

                            <span [fxShow]="!connection.client.language">Not set</span> 
                           <span [fxShow]="connection.client.language == languageEnum.ruRU">Русский</span> 
                           <span [fxShow]="connection.client.language == languageEnum.enUS">English</span> 


                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Phone Column -->
                <ng-container matColumnDef="med-ref">
                    <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-lg>Med ref</mat-header-cell>
                    <mat-cell *matCellDef="let connection" fxHide fxShow.gt-lg>
                        <p class="phone text-truncate">
                                                      <span [fxShow]="!connection.client.medicalReferalsType">Not set</span> 
                            <span [fxShow]="connection.client.medicalReferalsType == medicalReferalsEnum.Human">Medicine</span> 
                            <span [fxShow]="connection.client.medicalReferalsType == medicalReferalsEnum.Veterinary">Veterinary</span> 
                        </p>
                    </mat-cell>
                </ng-container>


                <!-- Company Column -->
                <ng-container matColumnDef="app-origin">
                    <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-lg>App origin</mat-header-cell>
                    <mat-cell *matCellDef="let connection" fxHide fxShow.gt-lg>
                        <p class="company text-truncate">     
                            <span [fxShow]="!connection.appInfo.originApp">Not set</span> 
                            <span [fxShow]="connection.appInfo.originApp == originAppEnum.Unknown">Unknown</span> 
                            <span [fxShow]="connection.appInfo.originApp == originAppEnum.PlannerApp">Planner app</span> 
                            <span [fxShow]="connection.appInfo.originApp == originAppEnum.WebApp">Web app</span> 
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="case">
                    <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md >In case</mat-header-cell>
                    <mat-cell *matCellDef="let connection" fxHide fxShow.gt-md >
                        <p class="company text-truncate">
                            {{connection.currentSelectedCaseTitle}}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Buttons Column -->
                <ng-container matColumnDef="buttons">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <div fxFlex="row" fxLayoutAlign="end center">


                            <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More"
                                (click)="$event.stopPropagation();">
                                <mat-icon class="secondary-text">more_vert</mat-icon>
                            </button>

                            <mat-menu #moreMenu="matMenu">
                                <!-- (click)="deleteUser(user)" -->
                                <button mat-menu-item aria-label="remove" >
                                    <mat-icon>delete</mat-icon>
                                    <span>Remove</span>
                                </button>
                            </mat-menu>
                        </div>

                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <!-- (click)="editUser(user)" -->
                <mat-row *matRowDef="let connection; columns: displayedColumns;" class="contact"
                    matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                </mat-row>
            </mat-table>
        </div>
        <!-- Front  -->

    </fuse-widget>
    <!-- WIDGET 11  -->

</div>
<!-- WIDGET GROUP  -->