import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { DateInterval, DateMonth, GeneralAnalytics, UsersAnalytics } from '@appmodels';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { CasesAnalytics } from 'app/core/models/planner/casesAnalytics';
import { SortOrder } from 'app/core/models/planner/enums/commonEnums';
import { PlannerAppLog } from 'app/core/models/planner/plannerAppLog';
import { PlannerAppLogsGroup } from 'app/core/models/planner/plannerAppLogsGroup';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {  

    constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient, private logger: NGXLogger) {       
    }

    public getGeneralAnalytics(dateInterval: DateInterval, selectedMonth?: DateMonth, selectedQuarter?: number, selectedYear?: number): Observable<GeneralAnalytics> {

        const url = `${this.config.apiEndpoint}/api/private/Analytics/GetGeneralAnalytics?DateInterval=${dateInterval}&SelectedMonth=${selectedMonth ? selectedMonth : ''}&SelectedQuarter=${selectedQuarter ? selectedQuarter : ''}&SelectedYear=${selectedYear ? selectedYear : ''}`;
        this.logger.debug("HTTP GET general analytics.", `Url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => {
                    this.logger.debug("Response general analytics:", response);                    
                }),
                map(response => response));
    }    

    public getCasesAnalytics(dateInterval: DateInterval, selectedMonth?: DateMonth, selectedQuarter?: number, selectedYear?: number): Observable<CasesAnalytics> {

        const url = `${this.config.apiEndpoint}/api/private/Analytics/GetCasesAnalytics?DateInterval=${dateInterval}&SelectedMonth=${selectedMonth ? selectedMonth : ''}&SelectedQuarter=${selectedQuarter ? selectedQuarter : ''}&SelectedYear=${selectedYear ? selectedYear : ''}`;
        this.logger.debug("HTTP GET cases analytics.", `Url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => {
                    this.logger.debug("Response cases analytics:", response);                    
                }),
                map(response => response));
    }  


    public getUsersAnalytics(dateInterval: DateInterval, selectedMonth?: DateMonth, selectedQuarter?: number, selectedYear?: number): Observable<UsersAnalytics> {

        const url = `${this.config.apiEndpoint}/api/private/Analytics/GetUsersAnalytics?DateInterval=${dateInterval}&SelectedMonth=${selectedMonth ? selectedMonth : ''}&SelectedQuarter=${selectedQuarter ? selectedQuarter : ''}&SelectedYear=${selectedYear ? selectedYear : ''}`;
        this.logger.debug("HTTP GET users analytics.", `Url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => {
                    this.logger.debug("Response users analytics:", response);                    
                }),
                map(response => response));
    }  
}