import { Component, OnDestroy, OnInit, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { PlannerCase } from '@appmodels';

//import { ChatService } from 'app/pages/chat/chat.service';

@Component({
    selector     : 'chat-left-sidenav',
    templateUrl  : './left.component.html',
    styleUrls    : ['./left.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ChatLeftSidenavComponent implements OnInit, OnDestroy
{
    @Input()
    cases: PlannerCase[];

    @Input()
    selectedCase: PlannerCase;

    @Output()
    onCaseSelected: EventEmitter<string>;

    view: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     */
    constructor(
       // private _chatService: ChatService
    )
    {
        // Set the defaults
        this.view = 'chats';

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.onCaseSelected = new EventEmitter();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    selectCase(caseId: string): void {
        if (!caseId)
            return;

        this.onCaseSelected.emit(caseId);
        // this._chatService.getChat(contact);
    }

    /**
     * On init
     */
    ngOnInit(): void
    {
        // this._chatService.onLeftSidenavViewChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(view => {
        //         this.view = view;
        //     });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
