/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./widget.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./widget.component";
var styles_FuseWidgetComponent = [i0.styles];
var RenderType_FuseWidgetComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FuseWidgetComponent, data: {} });
export { RenderType_FuseWidgetComponent as RenderType_FuseWidgetComponent };
export function View_FuseWidgetComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_FuseWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "fuse-widget", [], [[2, "flipped", null]], null, null, View_FuseWidgetComponent_0, RenderType_FuseWidgetComponent)), i1.ɵdid(1, 1097728, null, 1, i2.FuseWidgetComponent, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵqud(603979776, 1, { toggleButtons: 1 })], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).flipped; _ck(_v, 0, 0, currVal_0); }); }
var FuseWidgetComponentNgFactory = i1.ɵccf("fuse-widget", i2.FuseWidgetComponent, View_FuseWidgetComponent_Host_0, {}, {}, ["*"]);
export { FuseWidgetComponentNgFactory as FuseWidgetComponentNgFactory };
