import { CasesAnalytics, DateInterval, DateMonth, GeneralAnalytics, UsersAnalytics } from '@appmodels';
import { Action } from '@ngrx/store';

export enum EAnalyticsActions {
    GetGeneralAnalytics = '[AppLogs] Get General Analytics',
    GetGeneralAnalyticsSuccess = '[AppLogs] Get General Analytics Success'   ,
    GetCasesAnalytics = '[AppLogs] Get Cases Analytics',
    GetCasesAnalyticsSuccess = '[AppLogs] Get Cases Analytics Success',
    GetUsersAnalytics = '[AppLogs] Get Users Analytics',
    GetUsersAnalyticsSuccess = '[AppLogs] Get Users Analytics Success'   
}

export class GetGeneralAnalytics implements Action {
    public readonly type = EAnalyticsActions.GetGeneralAnalytics;
    public dateInterval: DateInterval;
    public selectedMonth: DateMonth;
    public selectedQuarter: number;
    public selectedYear: number;

    constructor(dateInterval: DateInterval, selectedMonth?: DateMonth, selectedQuarter?: number, selectedYear?: number) {       
            this.dateInterval = dateInterval;       
            this.selectedMonth = selectedMonth;
            this.selectedQuarter = selectedQuarter;
            this.selectedYear = selectedYear;
    };
}

export class GetGeneralAnalyticsSuccess implements Action {
    public readonly type = EAnalyticsActions.GetGeneralAnalyticsSuccess;
    constructor(public payload: GeneralAnalytics) { 
       
    };
}

export class GetCasesAnalytics implements Action {
    public readonly type = EAnalyticsActions.GetCasesAnalytics;
    public dateInterval: DateInterval;
    public selectedMonth: DateMonth;
    public selectedQuarter: number;
    public selectedYear: number;

    constructor(dateInterval: DateInterval, selectedMonth?: DateMonth, selectedQuarter?: number, selectedYear?: number) {       
            this.dateInterval = dateInterval;       
            this.selectedMonth = selectedMonth;
            this.selectedQuarter = selectedQuarter;
            this.selectedYear = selectedYear;
    };
}

export class GetCasesAnalyticsSuccess implements Action {
    public readonly type = EAnalyticsActions.GetCasesAnalyticsSuccess;
    constructor(public payload: CasesAnalytics) { 
       
    };
}



export class GetUsersAnalytics implements Action {
    public readonly type = EAnalyticsActions.GetUsersAnalytics;
    public dateInterval: DateInterval;
    public selectedMonth: DateMonth;
    public selectedQuarter: number;
    public selectedYear: number;

    constructor(dateInterval: DateInterval, selectedMonth?: DateMonth, selectedQuarter?: number, selectedYear?: number) {       
            this.dateInterval = dateInterval;       
            this.selectedMonth = selectedMonth;
            this.selectedQuarter = selectedQuarter;
            this.selectedYear = selectedYear;
    };
}

export class GetUsersAnalyticsSuccess implements Action {
    public readonly type = EAnalyticsActions.GetUsersAnalyticsSuccess;
    constructor(public payload: UsersAnalytics) { 
       
    };
}



export type AnalyticsActions = GetGeneralAnalytics | GetGeneralAnalyticsSuccess | GetCasesAnalytics | GetCasesAnalyticsSuccess
| GetUsersAnalytics | GetUsersAnalyticsSuccess;