<span [ngSwitch]="caseStatus">
  <span *ngSwitchCase="caseStatusEnum.New">
    {{ 'CASESTATUS.New' | translate }}
  </span>
  <span *ngSwitchCase="caseStatusEnum.DicomUploading">
    {{ 'CASESTATUS.DicomUploading' | translate }}
  </span>
  <span *ngSwitchCase="caseStatusEnum.Segmentation">
    {{ 'CASESTATUS.Segmentation' | translate }}
  </span>
  <span *ngSwitchCase="caseStatusEnum.ModelsReady">
    {{ 'CASESTATUS.ModelsReady' | translate }}
  </span>  
  <span *ngSwitchCase="caseStatusEnum.OperationPlanningSelf">
    {{ 'CASESTATUS.OperationPlanningSelf' | translate }}
  </span>
  <span *ngSwitchCase="caseStatusEnum.OperationPlanningCollaboration">
    {{ 'CASESTATUS.OperationPlanningCollaboration' | translate }}
  </span>
  <span *ngSwitchCase="caseStatusEnum.ImplantsDesigning">
    {{ 'CASESTATUS.ImplantsDesigning' | translate }}
  </span>
  <span *ngSwitchCase="caseStatusEnum.ImplantsDesignReady">
    {{ 'CASESTATUS.ImplantsDesignReady' | translate }}
  </span>
  <span *ngSwitchCase="caseStatusEnum.ImplantsDesignApproval">
    {{ 'CASESTATUS.ImplantsDesignApproval' | translate }}
  </span>
  <span *ngSwitchCase="caseStatusEnum.GuidesDesigning">
    {{ 'CASESTATUS.GuidesDesigning' | translate }}
  </span>
  <span *ngSwitchCase="caseStatusEnum.GuidesDesignReady">
    {{ 'CASESTATUS.GuidesDesignReady' | translate }}
  </span>
  <span *ngSwitchCase="caseStatusEnum.GuidesDesignApproval">
    {{ 'CASESTATUS.GuidesDesignApproval' | translate }}
  </span>
  <span *ngSwitchCase="caseStatusEnum.Manufacturing">
    {{ 'CASESTATUS.Manufacturing' | translate }}
  </span>
  <span *ngSwitchCase="caseStatusEnum.Shipping">
    {{ 'CASESTATUS.Shipping' | translate }}
  </span>
  <span *ngSwitchCase="caseStatusEnum.Done">
    {{ 'CASESTATUS.Done' | translate }}
  </span>
  <span *ngSwitchCase="caseStatusEnum.Canceled">
    {{ 'CASESTATUS.Canceled' | translate }}
  </span>
  <span *ngSwitchCase="caseStatusEnum.Deleted">
    {{ 'CASESTATUS.Deleted' | translate }}
  </span>
</span>