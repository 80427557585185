import { Injectable } from "@angular/core";
import { Actions, Effect, OnInitEffects, ofType } from "@ngrx/effects";
import { Action, Store, select } from "@ngrx/store";
import { ECasesActions, SetModulesTypeSuccess } from "../actions/cases.actions";
import { CasesService, AuthenticationService } from "@appservices";
import { LongRunningTasksService } from "app/core/services/planner/longRunningTasks.service";
import { SettingsService } from "app/core/services/settings.service";
import { NGXLogger } from "ngx-logger";
import { IAppState } from "../state/app.state";
import { of } from "rxjs";
import { map, switchMap, take, withLatestFrom } from "rxjs/operators";
import { SetQualitySurveysCasesFilterParams, SetQualitySurveysCasesFilterParamsSuccess, GetQualitySurveysCases, GetQualitySurveysCasesSuccess, EQualitySurveyCasesActions, SetQualitySurveyCasesSearchPattern, SetQualitySurveyCasesSearchPatternSuccess, SetQualitySurveyCasesIsWidthAdminsSuccess, SetQualitySurveyCasesIsWidthAdmins } from "../actions/qualitySurveyCases.actions";
import { selectQualitySurveyCasesList, selectQualitySurveyCasesRequestArgs } from "../selectors/qualitySurveyCases.selector";

@Injectable()
export class QualitySurveyCasesEffects implements OnInitEffects {

    ngrxOnInitEffects(): Action {
        const modulesFilters = this._settingsService.getModulesFilters();
        return new SetModulesTypeSuccess(modulesFilters);
    }


    @Effect()
    setQualitySurveyCasesSearchPattern$ = this._actions$.pipe(
        ofType<SetQualitySurveyCasesSearchPattern>(EQualitySurveyCasesActions.SetQualitySurveyCasesSearchPattern),
        map(action => action.payload),
        switchMap((searchPattern) => {
            return [
                new SetQualitySurveyCasesSearchPatternSuccess(searchPattern),
                new GetQualitySurveysCases(),
            ];
        })
    );

    @Effect()
    setQualitySurveyCasesIsWidthAdmins$ = this._actions$.pipe(
        ofType<SetQualitySurveyCasesIsWidthAdmins>(EQualitySurveyCasesActions.SetQualitySurveyCasesIsWidthAdmins),
        map(action => action.isWidthAdmins),
        switchMap((isWidthAdmins) => {
            return [
                new SetQualitySurveyCasesIsWidthAdminsSuccess(isWidthAdmins),
                new GetQualitySurveysCases(),
            ];
        })
    );

    
    @Effect()
    setQualitySurveysCasesFilterParams$ = this._actions$.pipe(
        ofType<SetQualitySurveysCasesFilterParams>(EQualitySurveyCasesActions.SetQualitySurveysCasesFilterParams),
        switchMap((searchParams) => {
            return [
                new SetQualitySurveysCasesFilterParamsSuccess(searchParams.notAdmins, searchParams.searchPattern),
                new GetQualitySurveysCases(),
            ];
        })
    );

    @Effect()
    getQulitySurveysCases$ = this._actions$.pipe(
        ofType<GetQualitySurveysCases>(EQualitySurveyCasesActions.GetQualitySurveysCases),
        map(action => action.isReload),
        //map
        switchMap((isReload) => {

            this._logger.info(`Get Quality Surveys Cases, is reload: ${isReload}`);

            if (isReload) {
                return of([]).pipe(
                    map(() => {
                        return { existedQualitySurveyCasesCount: 0, isReload: isReload }
                    })
                );
            }
            else {
                return this._store.pipe(
                    select(selectQualitySurveyCasesList),
                    take(1),
                    map(qualitySurveyCases => {
                        return { existedQualitySurveyCasesCount: qualitySurveyCases ? qualitySurveyCases.length : 0, isReload: isReload }
                    })
                )
            }
        }),
        withLatestFrom(this._store.select(selectQualitySurveyCasesRequestArgs)),
        switchMap(([existedQualitySurveyCases, casesRequestArgs]) => {

            const skip = existedQualitySurveyCases.isReload ? 0 : existedQualitySurveyCases.existedQualitySurveyCasesCount; //? existedCases.length : 0;
            const take = 20;
            return this._casesService.getQualitySurveyCases(casesRequestArgs, skip, take).pipe(
                map(cases => { return { cases: cases, isReload: existedQualitySurveyCases.isReload } })
            );
        }),
        switchMap((casesResult) => of(new GetQualitySurveysCasesSuccess(casesResult.cases, casesResult.isReload)))
    );


    constructor(
        private _settingsService: SettingsService,
        private _casesService: CasesService,
        private _longRunningTasksService: LongRunningTasksService,
        private _authenticationService: AuthenticationService,
        private _actions$: Actions,
        private _store: Store<IAppState>,
        private _logger: NGXLogger
    ) { }

}