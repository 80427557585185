import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
//import {do} from 'rxjs/add/operator/do';
// import 'rxjs/Rx';
// import {do} from 'rxjs/operators';
import { tap } from 'rxjs/operators';


import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ObjectDateFieldsFormatter } from '../helpers';

@Injectable()
export class AngularDateHttpInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        const body = event.body;
        ObjectDateFieldsFormatter.convertFieldsDate(body);
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
        }
      }
    }));
  }
}