<!-- SIDEBAR HEADER -->
<div fxLayout="column" fxLayoutAlign="space-between start" class="header accent p-24 pb-4">

    <div class="logo" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon s-32" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">mail
        </mat-icon>
        <span class="logo-text" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">Cases</span>
    </div>

    <div class="account" fxLayout="column" [@animate]="{value:'*',params:{delay:'300ms'}}">
        <div class="title">User</div>


        <mat-form-field floatLabel="never">
            <mat-select class="account-selection" placeholder="Mail Selection" [ngModel]="selectedAccount">
                <mat-option *ngFor="let account of (accounts | keys)" [value]="account.key">
                    {{account.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</div>
<!-- / SIDEBAR HEADER -->

<!-- SIDEBAR CONTENT -->
<div class="content" fusePerfectScrollbar [@animate]="{value:'*',params:{y:'50px'}}">

    <div class="px-24 pt-32 pb-12">
        <button mat-raised-button class="mat-accent compose-dialog-button w-100-p" (click)="caseEditDialog()" aria-label="Case edit">
            {{ 'MAIL.CASECREATE' | translate }}
        </button>
    </div>

    <div class="nav material2">

        <div class="nav-subheader">{{ 'MAIL.FOLDERS' | translate }}</div>

        <div class="nav-item" *ngFor="let folder of folders">
            <a class="nav-link" matRipple [routerLink]="'/cases/' + folder.handle" [routerLinkActive]="['active', 'accent']">
                <mat-icon class="nav-link-icon" *ngIf="folder.icon">{{folder.icon}}</mat-icon>
                <span>{{folder.title}}</span>
            </a>
        </div>

        <!-- <div class="nav-subheader">{{ 'MAIL.FILTERS' | translate }}</div>

        <div class="nav-item" *ngFor="let filter of filters">
            <a class="nav-link" matRipple [routerLink]="'/apps/mail/filter/' + filter.handle" [routerLinkActive]="['active', 'accent']">
                <mat-icon class="nav-link-icon" [ngClass]="filter.color" *ngIf="filter.icon">{{filter.icon}}</mat-icon>
                <span>{{filter.title}}</span>
            </a>
        </div>

        <div class="nav-subheader">{{ 'MAIL.LABELS' | translate }}</div>

        <div class="nav-item" *ngFor="let label of labels">
            <a class="nav-link" matRipple [routerLink]="'/apps/mail/label/' + label.handle" [routerLinkActive]="['active', 'accent']">
                <mat-icon class="nav-link-icon" [ngStyle]="{'color':label.color}">label</mat-icon>
                <span>{{label.title}}</span>
            </a>
        </div> -->

    </div>

</div>
<!-- / SIDEBAR CONTENT -->