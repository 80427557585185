import { Component, Inject, ViewEncapsulation, OnDestroy, ChangeDetectionStrategy, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MatAutocompleteSelectedEvent } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS, DateValidator, ObjectMapper } from 'app/core/helpers';
import { OperationType, SceneType, Gender, MedicalReferals, CreatePlannerCaseCommand, PlannerCaseShare } from '@appmodels';
import { CasesService } from '@appservices';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { PlannerUser } from 'app/core/models/planner/plannerUser';

@Component({
    selector: 'case-weblink',
    templateUrl: './case-weblink.component.html',
    styleUrls: ['./case-weblink.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class CaseWebLinkDialogComponent implements OnDestroy {

    //medicalReferalsEnum = MedicalReferals;
    //operationTypeEnum = OperationType;
    //sceneTypeEnum = SceneType;
    //genderEnum = Gender;
    //medicalReferalsTypeEnum = MedicalReferals;

    // caseShares: PlannerCaseShare[];

    // caseShareForm: FormGroup;
    // addCaseShareForm: FormGroup;
    // errorText: string;
    // requestInProgress: boolean;

    // @Input()
    // set users(value: PlannerUser[]) {
    //     this.foundUsers = value;
    //     if (!this._cdr['destroyed']) {
    //         this._cdr.detectChanges();
    //     }
    // }

    // foundUsers: PlannerUser[];

    public webLinkToken = '';



    @Output()
    onSearchQueryChanged: EventEmitter<string>;

    private _unsubscribeAll: Subject<any>;

    // get caseSharesEntries(): AbstractControl[] {
    //     return (this.caseShareForm.get('caseSharesEntries') as FormArray).controls;
    // }

    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        // private _casesService: CasesService,
        public matDialogRef: MatDialogRef<CaseWebLinkDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _cdr: ChangeDetectorRef,
        private logger: NGXLogger
    ) {
        this._unsubscribeAll = new Subject();
        // Set the defaults
        _data.title;

        _data.selectCreatedWebLinkToken$.pipe(takeUntil(this._unsubscribeAll)).subscribe(token=>{

this.webLinkToken = token;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    
    onSubmit(action: string) {       

        this.matDialogRef.close([action, this.webLinkToken]);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
