import * as tslib_1 from "tslib";
import { EMessagesActions, GetCaseMessagesSuccess, AddCaseMessageSuccess, NewMessageFromServerSuccess } from '../actions/messages.actions';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { CaseReceivedNewMessageSuccess } from '../actions/cases.actions';
import { switchMap, take, map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectSelectedCase } from '../selectors/cases.selector';
import { of } from 'rxjs';
import { MessagesService } from 'app/core/services/planner/messages.service';
import { PlannerMessage } from '@appmodels';
import { selectCurrentUser } from '../selectors/users.selectors';
import { NewNotification } from '../actions/notifications.actions';
import { AuthenticationService } from '@appservices';
import { NGXLogger } from 'ngx-logger';
import { selectCaseMessages } from '../selectors/messages.selector';
export class MessagesEffects {
    constructor(_messagesService, _authenticationService, _actions$, _store, _logger) {
        this._messagesService = _messagesService;
        this._authenticationService = _authenticationService;
        this._actions$ = _actions$;
        this._store = _store;
        this._logger = _logger;
        this.getCaseMessages$ = this._actions$.pipe(ofType(EMessagesActions.GetCaseMessages), switchMap(action => {
            this._logger.info(`GetCaseMessages, is reload: ${action.isReload}`);
            if (action.isReload) {
                return of([]).pipe(map(() => {
                    return { caseId: action.caseId, existedMessagesCount: 0, isReload: action.isReload };
                }));
            }
            else {
                return this._store.pipe(select(selectCaseMessages), take(1), map(messages => {
                    return { caseId: action.caseId, existedMessagesCount: messages ? messages.length : 0, isReload: action.isReload };
                }));
            }
        }), switchMap((getMessagesRequest) => {
            const skip = getMessagesRequest.isReload ? 0 : getMessagesRequest.existedMessagesCount; //? existedCases.length : 0;
            const take = 20;
            return this._messagesService.getCaseMessages(getMessagesRequest.caseId, skip, take).pipe(map(messages => { return { messages: messages, isReload: getMessagesRequest.isReload }; }));
        }), switchMap((messagesResult) => of(new GetCaseMessagesSuccess(messagesResult.messages, messagesResult.isReload))));
        this.addCaseMessage$ = this._actions$.pipe(ofType(EMessagesActions.AddCaseMessage), 
        //map(action => action.payload),
        withLatestFrom(this._store.select(selectSelectedCase)), map(([addMessageArgs, selectedCase]) => {
            addMessageArgs.messageCreateCommand.PlannerCaseId = selectedCase.id;
            return addMessageArgs;
        }), switchMap((addMessageArgs) => {
            return this._messagesService.addCaseMessage(addMessageArgs.messageCreateCommand).pipe(withLatestFrom(this._store.select(selectCurrentUser), of(addMessageArgs)));
        }), map(([newMessageId, currentUser, addMessageArgs]) => {
            const newMessage = new PlannerMessage();
            newMessage.id = newMessageId;
            newMessage.title = addMessageArgs.messageCreateCommand.Title;
            newMessage.text = addMessageArgs.messageCreateCommand.Text;
            newMessage.userNameFrom = currentUser.userName;
            if (addMessageArgs.messageFile) {
                newMessage.messageFile = addMessageArgs.messageFile;
            }
            newMessage.creationDate = new Date();
            return { newMessage, caseId: addMessageArgs.messageCreateCommand.PlannerCaseId };
        }), switchMap((newMessageResult) => [
            new AddCaseMessageSuccess(newMessageResult.newMessage)
        ]));
        this.newMessageFromServer$ = this._actions$.pipe(ofType(EMessagesActions.NewMessageFromServer), map(action => action.payload), withLatestFrom(this._store.select(selectSelectedCase)), map(([newMessageNotification, selectedCase]) => {
            let isFromAnotherConnectionApp = this._authenticationService.appConnectionUid != newMessageNotification.senderAppConnectionUid;
            if (isFromAnotherConnectionApp) {
                this._store.dispatch(new NewNotification({ type: 'NewMessage', message: `${newMessageNotification.newMessage.userNameFrom}: ${newMessageNotification.newMessage.text}`,
                    action: 'Link', additionalParams: { caseId: newMessageNotification.caseId } }));
            }
            let isFromCurrentCase = selectedCase && selectedCase.id == newMessageNotification.caseId;
            let isNeedToAddToCurrentCaseMessages = isFromAnotherConnectionApp && isFromCurrentCase;
            newMessageNotification.newMessage.isNew = true;
            // console.log(newMessageNotification);
            return { newMessageNotification, isNeedToAddToCurrentCaseMessages };
        }), switchMap((newMessageNotificationResult) => [
            new CaseReceivedNewMessageSuccess(newMessageNotificationResult.newMessageNotification.caseId, newMessageNotificationResult.newMessageNotification.newMessage, this._authenticationService.appConnectionUid != newMessageNotificationResult.newMessageNotification.senderAppConnectionUid),
            new NewMessageFromServerSuccess(newMessageNotificationResult.newMessageNotification, newMessageNotificationResult.isNeedToAddToCurrentCaseMessages)
        ]));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MessagesEffects.prototype, "getCaseMessages$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MessagesEffects.prototype, "addCaseMessage$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MessagesEffects.prototype, "newMessageFromServer$", void 0);
