import * as tslib_1 from "tslib";
import { FilesService } from 'app/core/services/planner/files.sevice';
import { DeleteFile, EFilesActions, EditFile, EditFilesPermissions } from '../actions/files.actions';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectSelectedCase } from '../selectors/cases.selector';
import { SelectCase } from '../actions/cases.actions';
import { VoidAction } from '../actions/app.actions';
import { NewNotification } from '../actions/notifications.actions';
import { DeletePlannerFileCommand, EditPlannerFileCommand, EditPlannerFilePermissionsCommand } from '@appmodels';
import { ObjectMapper } from 'app/core/helpers';
export class FilesEffects {
    constructor(_filesService, _actions$, _store) {
        this._filesService = _filesService;
        this._actions$ = _actions$;
        this._store = _store;
        this.editFile$ = this._actions$.pipe(ofType(EFilesActions.EditFile), map(action => action.payload), withLatestFrom(this._store.select(selectSelectedCase)), switchMap(([fileToEdit, selectedCase]) => {
            const editFileCommand = new EditPlannerFileCommand();
            ObjectMapper.mapObjectToObject(fileToEdit, editFileCommand);
            editFileCommand.caseId = selectedCase.id;
            return this._filesService.editFile(editFileCommand).pipe(
            // tap(res => console.log(res)),
            switchMap(result => {
                if (result.isSuccess) {
                    this._store.dispatch(new NewNotification({ type: 'FileEdit', message: 'File edit success!', action: 'Ok' }));
                    return of(selectedCase);
                }
                else {
                    this._store.dispatch(new NewNotification({ type: 'FileEdit', message: 'File edit error! See the error in the console log.', action: 'Error' }));
                    return of(null);
                }
            }));
        }), switchMap((selectedCase) => { return [selectedCase != null ? (new SelectCase(selectedCase.id)) : (new VoidAction())]; }));
        this.deleteFile$ = this._actions$.pipe(ofType(EFilesActions.DeleteFile), map(action => action.payload), withLatestFrom(this._store.select(selectSelectedCase)), switchMap(([fileToDelete, selectedCase]) => {
            const deleteFileCommand = new DeletePlannerFileCommand();
            deleteFileCommand.fileId = fileToDelete.id;
            deleteFileCommand.caseId = selectedCase.id;
            return this._filesService.deleteFile(deleteFileCommand).pipe(
            // tap(res => console.log(res)),
            switchMap(result => {
                if (result.isSuccess) {
                    this._store.dispatch(new NewNotification({ type: 'FileDelete', message: 'File delete success!', action: 'Ok' }));
                    return of(selectedCase);
                }
                else {
                    this._store.dispatch(new NewNotification({ type: 'FileDelete', message: 'File delete error! See the error in the console log.', action: 'Error' }));
                    return of(null);
                }
            }));
        }), switchMap((selectedCase) => { return [selectedCase != null ? (new SelectCase(selectedCase.id)) : (new VoidAction())]; }));
        this.editFilesPermissions$ = this._actions$.pipe(ofType(EFilesActions.EditFilesPermissions), map(action => action.payload), withLatestFrom(this._store.select(selectSelectedCase)), switchMap(([filesAllowUserDownloadAction, selectedCase]) => {
            const editPlannerFilePermissionsCommand = new EditPlannerFilePermissionsCommand();
            editPlannerFilePermissionsCommand.caseId = selectedCase.id;
            editPlannerFilePermissionsCommand.filesAllowUserDownload = filesAllowUserDownloadAction;
            return this._filesService.editPlannerFilePermissions(editPlannerFilePermissionsCommand).pipe(switchMap(result => {
                if (result.isSuccess) {
                    this._store.dispatch(new NewNotification({ type: 'EditFilesPermissions', message: 'Edit files permissions success!', action: 'Ok' }));
                    return of(selectedCase);
                }
                else {
                    this._store.dispatch(new NewNotification({ type: 'EditFilesPermissions', message: 'Edit files permissions error! See the error in the console log.', action: 'Error' }));
                    return of(null);
                }
            }));
        }), switchMap((selectedCase) => { return [selectedCase != null ? (new SelectCase(selectedCase.id)) : (new VoidAction())]; }));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], FilesEffects.prototype, "editFile$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], FilesEffects.prototype, "deleteFile$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], FilesEffects.prototype, "editFilesPermissions$", void 0);
