import { IApiError } from "@appmodels";

export class ApiResponse<T>{
    public response: T = null;
    public isSuccess: boolean = true;
    public error: IApiError;

    constructor(apiResponse: T, apiIsSuccess?: boolean, apiError?: IApiError)
    constructor(apiResponse: T, apiIsSuccess: boolean, apiError: IApiError) {
        this.response = apiResponse;

        if (apiIsSuccess == null)
        {
            this.isSuccess = true;
        }   
        else
        {
            this.isSuccess = apiIsSuccess;
            this.error = apiError;
        }
    }
}