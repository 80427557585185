import { initialAuthState, IAuthState } from '../state/auth.interface';
import { AuthActions, EAuthActions } from '../actions/auth.actions';
import { ResetPasswordStatus } from '@appmodels';

export function authReducers(
    state = initialAuthState,
    action: AuthActions
): IAuthState {
    switch (action.type) {
        case EAuthActions.SetManagementOrganizationIdSuccess:
            const ManagementOrganizationId = action.payload;
            return {
                ...state,
               currentManagementOrganizationId: ManagementOrganizationId
            }   
        case EAuthActions.RecoverPasswordSuccess:
            const result = action.payload;
            return {
                ...state,
               resetPasswordStatus: result ? ResetPasswordStatus.ResetSuccess : ResetPasswordStatus.ResetError
            }       
        default:
            return state;
    }
}