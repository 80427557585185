import { AppConfig } from 'app/app-config.module';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { PlannerMessage, CreatePlannerCaseMessageCommand } from '@appmodels';
import { tap, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../../app-config.module";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-logger";
export class MessagesService {
    constructor(config, http, logger) {
        this.config = config;
        this.http = http;
        this.logger = logger;
    }
    getCaseMessages(caseId, skip, take) {
        const url = `${this.config.apiEndpoint}/api/public/Messages/Get?PlannerCaseId=${caseId}&Skip=${skip}&Take=${take}`;
        this.logger.debug("HTTP GET case messages.", `Url: ${url}`);
        return this.http.get(url)
            .pipe(tap(response => {
            this.logger.debug("Response case messages:", response);
        }), map(response => response.messages));
    }
    addCaseMessage(createPlannerCaseMessageCommand) {
        const url = `${this.config.apiEndpoint}/api/public/Messages/Add`;
        this.logger.debug("HTTP POST add case message.", `New message: ${createPlannerCaseMessageCommand},`, `url: ${url}`);
        return this.http.post(url, createPlannerCaseMessageCommand)
            .pipe(tap(response => {
            this.logger.debug("Response add case message:", response);
        }), map(response => response.messages));
    }
}
MessagesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessagesService_Factory() { return new MessagesService(i0.ɵɵinject(i1.APP_CONFIG), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.NGXLogger)); }, token: MessagesService, providedIn: "root" });
