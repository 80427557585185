import { Action } from '@ngrx/store';
import { PlannerMessage, CreatePlannerCaseMessageCommand, ChatNewMessageNotification, PlannerFile } from '@appmodels';

export enum EMessagesActions {
    GetCaseMessages = '[Messages] Get Case Messages',
    GetCaseMessagesSuccess = '[Messages] Get Case Messages Success',
    AddCaseMessage = '[Messages] Add Case Message',
    AddCaseMessageSuccess = '[Messages] Add Case Message Success',
  
    NewMessageFromServer= '[Messages] New Message From Server',
    NewMessageFromServerSuccess= '[Messages] NewM essage From Server Success'
}

export class GetCaseMessages implements Action {
    public readonly type = EMessagesActions.GetCaseMessages;
    constructor(public caseId: string, public isReload: boolean = true) { };
}

export class GetCaseMessagesSuccess implements Action {
    public readonly type = EMessagesActions.GetCaseMessagesSuccess;
    constructor(public messages: PlannerMessage[], public isReload: boolean) { };
}

export class AddCaseMessage implements Action {
    public readonly type = EMessagesActions.AddCaseMessage;
    constructor(public messageCreateCommand: CreatePlannerCaseMessageCommand, public messageFile: PlannerFile) { };
}

export class AddCaseMessageSuccess implements Action {
    public readonly type = EMessagesActions.AddCaseMessageSuccess;
    constructor(public payload: PlannerMessage) { };
}



export class NewMessageFromServer implements Action {
    public readonly type = EMessagesActions.NewMessageFromServer;
    constructor(public payload: ChatNewMessageNotification) { };
}

export class NewMessageFromServerSuccess implements Action {
    public readonly type = EMessagesActions.NewMessageFromServerSuccess;
    constructor(public newMessageNotification: ChatNewMessageNotification, public isNeedToAddToCurrentCaseMessages: boolean) { };
}

export type MessagesActions = GetCaseMessages | GetCaseMessagesSuccess | AddCaseMessage | AddCaseMessageSuccess  | NewMessageFromServer | NewMessageFromServerSuccess;