import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AnalyticsService } from 'app/core/services/planner/analytics.service';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EAnalyticsActions, GetCasesAnalyticsSuccess, GetGeneralAnalyticsSuccess, GetUsersAnalyticsSuccess } from '../actions/analytics.actions';
export class AnalyticsEffects {
    constructor(_analyticsService, _actions$, _store, _logger) {
        this._analyticsService = _analyticsService;
        this._actions$ = _actions$;
        this._store = _store;
        this._logger = _logger;
        this.getGeneralAnalytics$ = this._actions$.pipe(ofType(EAnalyticsActions.GetGeneralAnalytics), map(action => action), switchMap((params) => {
            const dateInterval = params.dateInterval;
            const selectedMonth = params.selectedMonth;
            const selectedQuarter = params.selectedQuarter;
            const selectedYear = params.selectedYear;
            return this._analyticsService.getGeneralAnalytics(dateInterval, selectedMonth, selectedQuarter, selectedYear);
        }), switchMap((result) => of(new GetGeneralAnalyticsSuccess(result))));
        this.getCasesAnalytics$ = this._actions$.pipe(ofType(EAnalyticsActions.GetCasesAnalytics), map(action => action), switchMap((params) => {
            const dateInterval = params.dateInterval;
            const selectedMonth = params.selectedMonth;
            const selectedQuarter = params.selectedQuarter;
            const selectedYear = params.selectedYear;
            return this._analyticsService.getCasesAnalytics(dateInterval, selectedMonth, selectedQuarter, selectedYear);
        }), switchMap((result) => of(new GetCasesAnalyticsSuccess(result))));
        this.getUsersAnalytics$ = this._actions$.pipe(ofType(EAnalyticsActions.GetUsersAnalytics), map(action => action), switchMap((params) => {
            const dateInterval = params.dateInterval;
            const selectedMonth = params.selectedMonth;
            const selectedQuarter = params.selectedQuarter;
            const selectedYear = params.selectedYear;
            return this._analyticsService.getUsersAnalytics(dateInterval, selectedMonth, selectedQuarter, selectedYear);
        }), switchMap((result) => of(new GetUsersAnalyticsSuccess(result))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AnalyticsEffects.prototype, "getGeneralAnalytics$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AnalyticsEffects.prototype, "getCasesAnalytics$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AnalyticsEffects.prototype, "getUsersAnalytics$", void 0);
