import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { MedicalReferals, Language, MarketingReferalType, Speciality } from '@appmodels';
import { LanguageService, UsersService } from '@appservices';
import { MatStepper } from '@angular/material';
import { RegisterUserCommand } from 'app/core/models/planner/commands/userCommand';
import { ObjectMapper, confirmPasswordValidator, passwordPattern } from 'app/core/helpers';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as en } from './i18n/en';
import { locale as ru } from './i18n/ru';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/core/store/state/app.state';
import { selectManagementOrganizationId } from 'app/core/store/selectors/auth.selector';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class RegisterComponent implements OnInit, OnDestroy {
    //registerForm: FormGroup;
    firstStepFormGroup: FormGroup;
    secondStepFormGroup: FormGroup;

    medicalReferalsEnum = MedicalReferals;
    specialityEnum = Speciality;
    marketingReferalTypeEnum = MarketingReferalType;
    languageEnum = Language;

    registeringInProgress: boolean = false;
    isRegistrationSuccess: boolean = false;

    private _managementOrganizationId: number;


    @ViewChild('stepper', { static: true })
    public registerStepper: MatStepper;

    // Private
    private _unsubscribeAll: Subject<any>;

    public get selectedLanguage(): string {
        return this._languageService.selectedLang;
    }

    public get supportedLanguages(): string[] {
        return this._languageService.supportedLangs;
    }

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _usersService: UsersService,
        private _logger: NGXLogger,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _languageService: LanguageService,
        private _ref: ChangeDetectorRef,
        private _store: Store<IAppState>
    ) {
        this._fuseTranslationLoaderService.loadTranslations(en, ru);

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();


        // const lang = navigator.language || window.navigator.language;

        // _translateService.use('ru');

        //console.log(lang);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.firstStepFormGroup = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.pattern("notAvailable")]],
            lastName: ['', Validators.required],
            firstName: ['', Validators.required],
            middleName: [''],
            medicalReferalsType: ['', Validators.required],
            speciality: [this.specialityEnum.NotSet, this.validateSpeciality],
            password: ['', [Validators.required, Validators.pattern(passwordPattern)]],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });

        this.secondStepFormGroup = this._formBuilder.group({
            country: ['', Validators.required],
            city: ['', [Validators.required]],
            phoneNumber: ['', Validators.required],
            company: ['', Validators.required],
            referalType: [''],            
            terms: ['', [Validators.required, Validators.pattern('true')]],
            mailing: ['', [Validators.required, Validators.pattern('true')]],
            language: ['', Validators.required]

        });

        this.firstStepFormGroup.get('medicalReferalsType').valueChanges.subscribe(value => {

            if (value == MedicalReferals.Veterinary)
                this.firstStepFormGroup.get('speciality').setValue(Speciality.NotSet);

            this.firstStepFormGroup.get('speciality').updateValueAndValidity();
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.firstStepFormGroup.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.firstStepFormGroup.get('passwordConfirm').updateValueAndValidity();
            });

       

        this.setFormLanguage();
        //   this.registerStepper.selectedIndex = 2;

        this._store.select(selectManagementOrganizationId).pipe(takeUntil(this._unsubscribeAll)).subscribe(organizationId => {
            this._managementOrganizationId = organizationId;
        });
    }

    public setLanguage(lang: string): void {

        // this.removeFormLanguage();

        this._languageService.setLanguage(lang);

        this.setFormLanguage();
    }

    public formLanguageChanged(event): void {

        const formLang = event.value as Language;

        if (formLang == Language.ruRU) {
            this.setLanguage('ru');
        }
        else {
            this.setLanguage('en');
        }
    }

    // private removeFormLanguage() {
    //     this.secondStepFormGroup.controls['language'].setValue(null);
    //  }

    private setFormLanguage() {

        const formLanguage = this._languageService.selectedLang == 'ru' ? Language.ruRU : Language.enUS;

        this.secondStepFormGroup.controls['language'].setValue(formLanguage);

        this._ref.detectChanges();
    }

    isEmailAvailable(email: string) {

        const emailControl = this.firstStepFormGroup.get('email');

        if (emailControl.hasError('required') || emailControl.hasError('email'))
            return;

        this._usersService.isEmailAvailable(email).pipe(takeUntil(this._unsubscribeAll)).subscribe(isAvailable => {
            if (isAvailable) {
                emailControl.setValidators([Validators.required, Validators.email]);
            }
            else {
                emailControl.setValidators([Validators.required, Validators.email, Validators.pattern("notAvailable")]);
            }
            emailControl.updateValueAndValidity();
        });
    }

    register() {

        if (this.firstStepFormGroup.invalid || this.secondStepFormGroup.invalid)
            return;

        this.registeringInProgress = true;

        const registerCommand = new RegisterUserCommand();

        registerCommand.managementOrganizationId = this._managementOrganizationId;
        ObjectMapper.mapFormGroupToObject(this.firstStepFormGroup, registerCommand);
        ObjectMapper.mapFormGroupToObject(this.secondStepFormGroup, registerCommand);

        this._usersService.register(registerCommand).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            result => {
                this._logger.info("Registration success!");
                this.registeringInProgress = false;
                this.isRegistrationSuccess = true;
            },
            error => {
                this._logger.info("Registration error!");
                this.registeringInProgress = false;
            });
    }

    validateSpeciality: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

        if (!control || !control.parent) {
            return null;
        }

        const medicalReferals = control.parent.get('medicalReferalsType').value as MedicalReferals;

        // console.log(control);

        const speciality = control.value as Speciality;

        let isValid = false;

        // console.log(medicalReferals);

        if (medicalReferals == MedicalReferals.Human) {

            if (speciality || speciality != Speciality.NotSet) {
                isValid = true;
            }
            else {
                isValid = false;
            }

        }
        else {
            isValid = true;
        }

        //console.log(isValid);

        return isValid ? null : { wrongSpeciality: true };
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */

