/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./horizontal/style-1/style-1.component.ngfactory";
import * as i3 from "./horizontal/style-1/style-1.component";
import * as i4 from "../../../../@fuse/services/config.service";
import * as i5 from "../../../../@fuse/components/navigation/navigation.service";
import * as i6 from "../../../../@fuse/components/sidebar/sidebar.service";
import * as i7 from "./vertical/style-1/style-1.component.ngfactory";
import * as i8 from "./vertical/style-1/style-1.component";
import * as i9 from "@angular/router";
import * as i10 from "../../../core/services/planner/users.service";
import * as i11 from "./vertical/style-2/style-2.component.ngfactory";
import * as i12 from "./vertical/style-2/style-2.component";
import * as i13 from "@angular/common";
import * as i14 from "./navbar.component";
var styles_NavbarComponent = [i0.styles];
var RenderType_NavbarComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
function View_NavbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "navbar-horizontal-style-1", [], null, null, null, i2.View_NavbarHorizontalStyle1Component_0, i2.RenderType_NavbarHorizontalStyle1Component)), i1.ɵdid(2, 245760, null, 0, i3.NavbarHorizontalStyle1Component, [i4.FuseConfigService, i5.FuseNavigationService, i6.FuseSidebarService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_NavbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "navbar-vertical-style-1", [], null, null, null, i7.View_NavbarVerticalStyle1Component_0, i7.RenderType_NavbarVerticalStyle1Component)), i1.ɵdid(2, 245760, null, 0, i8.NavbarVerticalStyle1Component, [i4.FuseConfigService, i5.FuseNavigationService, i6.FuseSidebarService, i9.Router, i10.UsersService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_NavbarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "navbar-vertical-style-2", [], null, null, null, i11.View_NavbarVerticalStyle2Component_0, i11.RenderType_NavbarVerticalStyle2Component)), i1.ɵdid(2, 245760, null, 0, i12.NavbarVerticalStyle2Component, [i4.FuseConfigService, i5.FuseNavigationService, i6.FuseSidebarService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_NavbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_2)), i1.ɵdid(3, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_3)), i1.ɵdid(5, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.variant === "horizontal-style-1"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.variant === "vertical-style-1"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.variant === "vertical-style-2"); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_NavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i1.ɵdid(1, 49152, null, 0, i14.NavbarComponent, [i1.ElementRef, i1.Renderer2], null, null)], null, null); }
var NavbarComponentNgFactory = i1.ɵccf("navbar", i14.NavbarComponent, View_NavbarComponent_Host_0, { variant: "variant" }, {}, []);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
