import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })

export class LanguageService {


    public get selectedLang(): string {
        return this._selectedLang;
    }

    public get supportedLangs(): string[] {
        return this._supportedLangs;
    }

    private _selectedLang: string;

    private _storageLangSettingName: string = 'userLang';
    private _defaultLang: string = 'en';
    private _supportedLangs: string[] = ['en', 'ru'];

    constructor(
        private translateService: TranslateService,
        private logger: NGXLogger) {

        let lang = localStorage.getItem(this._storageLangSettingName);

        if (!lang) {
            lang = this.getUserLang();
        }

        this.setLanguage(lang);

        logger.log(`user lang: ${this._selectedLang}`);
    }

    public setLanguage(lang: string): void {

        lang = this.selectSupportedLangs(lang);

        if (this._selectedLang != lang) {
            this._selectedLang = lang;
            localStorage.setItem(this._storageLangSettingName, lang);
            this.translateService.use(this._selectedLang);
        }
    }

    private getUserLang(): string {
        let userLang = this.getBrowserLang();

        userLang = this.selectSupportedLangs(userLang);

        return userLang;
    }

    private getBrowserLang(): string {

        let userLang = navigator.language || window.navigator.language;

        userLang = this.formatLangString(userLang);

        return userLang;
    }

    private selectSupportedLangs(lang: string): string {
        if (this._supportedLangs.includes(lang))
            return lang;

        return this._defaultLang;
    }

    private formatLangString(lang: string): string {

        if (!lang)
            return lang;

        // const hyphenIndex = lang.indexOf('-');

        // if(hyphenIndex >= 0)
        // {
        //     lang = lang.substr(0, lang.length - hyphenIndex - 1);
        // }

        lang = lang.substr(0, 2);

        return lang;
    }
}