import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { ICurrentUser } from '../../models/user';
import { AuthenticationService } from '@appservices';
import { Observable, throwError } from 'rxjs';
import { AppConfig, APP_CONFIG } from 'app/app-config.module';
import { NGXLogger } from 'ngx-logger';
import { tap, map, catchError } from 'rxjs/operators';
import { RegisterUserCommand, EditUserCommand, DeleteUserCommand } from 'app/core/models/planner/commands/userCommand';
import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { SortOrder } from 'app/core/models/planner/enums/commonEnums';
import { ConnectionInfo, Language, MedicalReferals, PlannerUsersOrganization, RevokeWebLinkTokenCommand, Speciality } from '@appmodels';
import * as FileSaver from 'file-saver';
import { IUsersFilter } from 'app/core/models/usersFilter';
import { CreateWebLinkTokenCommand } from 'app/core/models/planner/commands/createWebLinkTokenCommand';

@Injectable({ providedIn: 'root' })
export class UsersService {
    constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient, private authenticationService: AuthenticationService, private logger: NGXLogger) { }

    public getCurrentUser(): Observable<ICurrentUser> {
        console.log(this.authenticationService.currentUser);
        return this.authenticationService.currentUser;
    }

    public getPlannerUsersOrganization(organizationTitle: string)   : Observable<PlannerUsersOrganization> {
        const url = `${this.config.apiEndpoint}/api/public/Users/GetPlannerUsersOrganization?title=${organizationTitle}`;
        this.logger.debug("HTTP GET GetPlannerUsersOrganization", `url: ${url}`);

        return this.http.get<PlannerUsersOrganization>(url)
            .pipe(
                tap(response => this.logger.debug("Response get planner users organization:", response)),
                catchError(err => {
                    this.logger.info(err.status);
                    return throwError(err);
                })

            );
    }

    public isEmailAvailable(email: string): Observable<boolean> {
        const url = `${this.config.apiEndpoint}/api/public/Users/IsEmailAvailable`;
        this.logger.debug("HTTP POST IsEmailAvailable", `url: ${url}`);

        return this.http.post<boolean>(url, { Email: email })
            .pipe(
                tap(response => this.logger.debug("Response is email availbale:", response)),
                catchError(err => {
                    this.logger.info(err.status);
                    return throwError(err);
                })

            );
    }

    public register(registerCommand: RegisterUserCommand): Observable<boolean> {
        const url = `${this.config.apiEndpoint}/api/public/Users/Register`;

        this.logger.debug("HTTP POST register user", `url: ${url}`, registerCommand);

        return this.http.post<boolean>(url, registerCommand)
            .pipe(
                tap(response => this.logger.debug("Response register user:", response)),
                catchError(err => {
                    this.logger.error(err);
                    this.logger.error(err.status);
                    return throwError(err);
                })

            );
    }

    public getUsers(usersFilter: IUsersFilter): Observable<PlannerUser[]> {

        const filterQuery = this.createFilterUrlParams(usersFilter);

        const url = `${this.config.apiEndpoint}/api/private/UsersManagement/GetUsers?${filterQuery}&Skip=0&Take=1000&Sort=Email&SortOrder=${SortOrder.Ascending}`;

        this.logger.debug("HTTP GET users list.", `Url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => {
                    this.logger.debug("Response users list:", response);
                }),
                map(response => response.users));
    }

    // public getUsersSearch(searchQuery: string): Observable<PlannerUser[]> {
    //     const url = `${this.config.apiEndpoint}/api/private/UsersManagement/GetUsers?SearchQuery=${searchQuery}&Skip=0&Take=1000&Sort=Email&SortOrder=${SortOrder.Ascending}`;
    //     return this.getUsers(url);
    // }

    public exportUsersList(usersFilter: IUsersFilter) {

        const filterQuery = this.createFilterUrlParams(usersFilter);

        const url = `${this.config.apiEndpoint}/api/private/UsersManagement/ExcelExportUsers?${filterQuery}`;

        this.logger.debug("HTTP GET export users list.", `Url: ${url}`);

        const request = new HttpRequest('GET', url, {
            reportProgress: false,
            responseType: 'blob'
        });

        return this.http.request(request)
            .pipe(
                tap(event => {
                    this.logger.debug("Response export users list:", event);

                    console.log(event);

                    if (event.type === HttpEventType.Response) {
                        //this.logger.info(event.body);

                        console.log(event.headers);// .headers('Content-Disposition');

                        FileSaver.saveAs(event.body as Blob, "export-users.csv");

                        return null;
                    }

                    return null;

                }));
    }

    public getOnlineUsers(): Observable<ConnectionInfo[]> {

        const url = `${this.config.apiEndpoint}/api/private/UsersManagement/GetOnlineUsers`;

        this.logger.debug("HTTP GET online users.", `Url: ${url}`);
        return this.http.get<any>(url)
            .pipe(
                tap(response => {
                    this.logger.debug("Response online users:", response);
                }),
                map(response => response.onlineUsers));

    }

    public editUser(editCommand: EditUserCommand): Observable<boolean> {
        const url = `${this.config.apiEndpoint}/api/private/UsersManagement/EditUser`;

        this.logger.debug("HTTP POST edit user", `url: ${url}`, editCommand);

        return this.http.post<boolean>(url, editCommand)
            .pipe(
                tap(response => this.logger.debug("Response edit user:", response)),
                catchError(err => {
                    this.logger.error(err);
                    this.logger.error(err.status);
                    return throwError(err);
                })

            );
    }

    public deleteUser(deleteCommand: DeleteUserCommand): Observable<boolean> {
        const url = `${this.config.apiEndpoint}/api/private/UsersManagement/DeleteUser`;

        this.logger.debug("HTTP POST edit user", `url: ${url}`, deleteCommand);

        return this.http.post<boolean>(url, deleteCommand)
            .pipe(
                tap(response => this.logger.debug("Response delete user:", response)),
                catchError(err => {
                    this.logger.error(err);
                    this.logger.error(err.status);
                    return throwError(err);
                })

            );
    }

    public createWebLinkToken(createWebLinkTokenCommand: CreateWebLinkTokenCommand): Observable<string> {
        const url = `${this.config.apiEndpoint}/api/private/UsersManagement/CreateWebLinkToken`;

        this.logger.debug("HTTP POST create web link", `url: ${url}`, createWebLinkTokenCommand);

        return this.http.post<any>(url, createWebLinkTokenCommand)
            .pipe(
                tap(response => this.logger.debug("Response create web link:", response))            
            );
    }

    public revokeWebLinkToken(revokeWebLinkTokenCommand: RevokeWebLinkTokenCommand): Observable<number> {
        const url = `${this.config.apiEndpoint}/api/private/UsersManagement/RevokeWebLinkToken`;

        this.logger.debug("HTTP POST revoke web link", `url: ${url}`, revokeWebLinkTokenCommand);

        return this.http.post<any>(url, revokeWebLinkTokenCommand)
            .pipe(
                tap(response => this.logger.debug("Response revoke web link:", response))            
            );
    }

    private createFilterUrlParams(usersFilter: IUsersFilter): string {
        const filterQuery = `isWithAdmins=${usersFilter.isWithAdmins}&medicalReferals=${usersFilter.medicalReferals ? usersFilter.medicalReferals : ''}&speciality=${usersFilter.speciality ? usersFilter.speciality : ''}&language=${usersFilter.language ? usersFilter.language : ''}&searchQuery=${usersFilter.searchQuery ? usersFilter.searchQuery : ''}`;
        return filterQuery;
    }
}