<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Bundle</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>

        <!-- <div fxLayout="row" flexGrow="1" fxLayoutGap="10px" fxLayoutAlign="stretch"
            *ngFor="let visibilityGroup of visibilityGroups">
            1
        </div> -->


        <form name="composeForm" [formGroup]="bundleEditForm" class="edit-bundle-form">

            <div class="p-12" formArrayName="bundleFormGroups" *ngFor="let control of bundleFormArray; index as i"
                fxLayout="row" fxLayoutGap="10px">

                <div fxFlex="100%">
                    <div [formGroupName]="i" fxLayout="row">
                        <div fxFlex="80%">
                            <mat-form-field appearance="outline">
                                <mat-label>Object title</mat-label>
                                <input matInput name="objTitle" formControlName="objTitle" type="text">
                            </mat-form-field>
                        </div>
                        <!-- <mat-form-field fxLayout="column" appearance="outline">
                            <mat-label>Order</mat-label>
                            <input matInput name="objSelected" formControlName="objSelected" type="number">
                        </mat-form-field>     -->
                        <div fxFlex="20%">
                            <mat-slide-toggle class="bundle-toggle" name="objSelected" formControlName="objSelected">
                                In bundle
                            </mat-slide-toggle>
                        </div>

                    </div>

                </div>
            </div>

            <!-- <div fxLayout="row" flexGrow="1" fxLayoutGap="10px" fxLayoutAlign="stretch">

                <div fxLayout="column" style="width: 100%;">&nbsp;</div>
                <div fxLayout="column" style="width: 100%;">&nbsp;</div>

                <div mat-dialog-actions class="group-buttons-wrapper">
                
                    <button mat-raised-button color="accent" style="width: 74px" (click)="addVisabiltyGroup()">
                        Add
                    </button>
            
                </div>
            </div> -->
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div>

            <!-- (click)="createCase()"  -->
            <!-- <button mat-raised-button color="accent"> -->
            <!-- (click)="editSceneObject()" class="save-button"
                [disabled]="sceneObjectEditForm.invalid || requestInProgress" aria-label="Create">
                {{isEditingObject ? 'Edit' : 'Add'}} -->
            <!-- Edit
            </button>

            <span class="p-4 red-500-fg">
                {{errorText}}
            </span> -->

            <!-- <button mat-icon-button matTooltip="Attach a file">
                <mat-icon>attach_file</mat-icon>
            </button> -->
        </div>


        <button mat-raised-button color="accent" [disabled]="bundleEditForm.invalid" (click)="saveBundle()">
            <!-- (click)="editSceneObject()" class="save-button"
            [disabled]="sceneObjectEditForm.invalid || requestInProgress" aria-label="Create">
            {{isEditingObject ? 'Edit' : 'Add'}} -->
            Save
        </button>
    </div>
</div>