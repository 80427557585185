import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { GenerateModuleLicenseCommand, PlannerUsersAvailableModules, PlannerUsersAvailableModulesOffline } from "@appmodels";
import { fuseAnimations } from "@fuse/animations";
import { select, Store } from "@ngrx/store";
import { AddUsersPaidModules, AddUsersPaidModulesOffline, EditUsersPaidModules, RemoveUsersPaidModules } from "app/core/store/actions/paidModules.actions";
import { selectUsersPaidModules, selectUsersPaidModulesOffline } from "app/core/store/selectors/paidModules.selector";
import { IAppState } from "app/core/store/state/app.state";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatDialog } from "@angular/material";
import { selectUsers } from "app/core/store/selectors/users.selectors";
import { PlannerUser } from "app/core/models/planner/plannerUser";
import { SetUsersFilter } from "app/core/store/actions/users.actions";
import { PaidModulesOfflineFormDialogComponent } from "./paidModulesOffline-form/paidModulesOffline-form.component";
import * as FileSaver from 'file-saver';

@Component({
    selector: 'offline-licenses',
    templateUrl: './offline-licenses.component.html',
    styleUrls: ['./offline-licenses.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class OfflineLicensesComponent implements OnInit, OnDestroy {
    dialogRef: any;
    paidModulesOffline$: Observable<PlannerUsersAvailableModulesOffline[]>;
    users$: Observable<PlannerUser[]>;
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _matDialog: MatDialog,
        private _store: Store<IAppState>
    ) {
        this._unsubscribeAll = new Subject();
    }

    addUsersPaidModuleOffline(generateModuleLicenseCommand: GenerateModuleLicenseCommand): void {


        if (!generateModuleLicenseCommand)
            return;

        this._store.dispatch(new AddUsersPaidModulesOffline(generateModuleLicenseCommand));
    }

    // editUsersPaidModule(editUsersPaidModule: PlannerUsersAvailableModules): void {

    //     if (!editUsersPaidModule)
    //         return;

    //     this._store.dispatch(new EditUsersPaidModules(editUsersPaidModule));
    // }

    // deleteUsersPaidModule(deletedUsersPaidModule: PlannerUsersAvailableModules): void {

    //     if (!deletedUsersPaidModule)
    //         return;

    //     this._store.dispatch(new RemoveUsersPaidModules(deletedUsersPaidModule));
    // }

    // /**
    //  * New contact
    //  */
    newUsersPaidModuleOffline(): void {

        this.dialogRef = this._matDialog.open(PaidModulesOfflineFormDialogComponent, {
            panelClass: 'paidModules-form-dialog',
            data: {
                action: 'new'
            }
        });

        const instance = this.dialogRef.componentInstance;

        this.users$.pipe(takeUntil(this._unsubscribeAll)).subscribe((users) => {
            instance.users = users;
        });


        instance.onSearchQueryChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((query) => {
            this.usersSearch(query);
        });


        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }

                const actionType: string = response[0];

                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':

                        const generateLicenseCommand: GenerateModuleLicenseCommand = response[1];

                        this.addUsersPaidModuleOffline(generateLicenseCommand);

                        break;
                    /**
                     * Delete
                     */
                    case 'cancel':

                        //this.deleteContact(user);

                        break;
                }
            });
    }

    usersSearch(searchQuery: string): void {
        this._store.dispatch(new SetUsersFilter({ searchQuery: searchQuery }))
    }

    usersFilterChanged(filter: any): void {


        this._store.dispatch(new SetUsersFilter(filter));

    }

    downloadUsersPaidModuleOffline(paidModuleOffline: PlannerUsersAvailableModulesOffline): void {

        var blob = new Blob([paidModuleOffline.license], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, "bonabyte-planner.license");

    }


    ngOnInit(): void {

        this.users$ = this._store.pipe(select(selectUsers));
        this.paidModulesOffline$ = this._store.pipe(select(selectUsersPaidModulesOffline));

    }
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}