import { OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_DATE_FORMATS } from 'app/core/helpers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
const ɵ0 = APP_DATE_FORMATS;
export class CaseWebLinkDialogComponent {
    // get caseSharesEntries(): AbstractControl[] {
    //     return (this.caseShareForm.get('caseSharesEntries') as FormArray).controls;
    // }
    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
    // private _casesService: CasesService,
    matDialogRef, _data, _cdr, logger) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._cdr = _cdr;
        this.logger = logger;
        //medicalReferalsEnum = MedicalReferals;
        //operationTypeEnum = OperationType;
        //sceneTypeEnum = SceneType;
        //genderEnum = Gender;
        //medicalReferalsTypeEnum = MedicalReferals;
        // caseShares: PlannerCaseShare[];
        // caseShareForm: FormGroup;
        // addCaseShareForm: FormGroup;
        // errorText: string;
        // requestInProgress: boolean;
        // @Input()
        // set users(value: PlannerUser[]) {
        //     this.foundUsers = value;
        //     if (!this._cdr['destroyed']) {
        //         this._cdr.detectChanges();
        //     }
        // }
        // foundUsers: PlannerUser[];
        this.webLinkToken = '';
        this._unsubscribeAll = new Subject();
        // Set the defaults
        _data.title;
        _data.selectCreatedWebLinkToken$.pipe(takeUntil(this._unsubscribeAll)).subscribe(token => {
            this.webLinkToken = token;
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    onSubmit(action) {
        this.matDialogRef.close([action, this.webLinkToken]);
    }
    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
export { ɵ0 };
