import { Component, OnDestroy, OnInit, ViewEncapsulation, AfterContentInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

//import { Mail } from './mail.model';
//import { MailService } from './mail.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import {
    PlannerCase, CreatePlannerCaseCommand, PlannerScene, PlannerSceneObjectOperationArgs, ObjectOperationType, SavePlannerSceneCommand, RollbackPlannerSceneCommand, UpdatePlannerCaseCommand, PlannerSceneVisibilityGroup,
    SavePlannerSceneVisibilityGroupsCommand, PlannerFile, PlannerCaseShare, UpdatePlannerCaseSharesCommand, ClonePlannerCaseCommand, DeletePlannerCaseCommand, PlannerModuleType, RevokeWebLinkTokenCommand,
    SaveBundleCaseCommand,
    PlannerCaseBundle
} from 'app/core/models';
import { CasesService } from '@appservices';
import { IAppState } from 'app/core/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { selectCasesList, selectCasesRequestArgs, selectSelectedCase, selectSelectedCaseBundle, selectSelectedCaseShares, selectSelectedScene } from 'app/core/store/selectors/cases.selector';
import {
    GetCases, GetSelectedCaseScenes, SelectCase, SelectScene, CreateCase, AddSceneObjectSuccess, EditSceneObjectSuccess, DeleteSceneObjectSuccess, SaveScene, CloneCase, DeleteCase, RollbackScene, SetCasesSearchPattern,
    EditCase, AddSceneObjects, SaveSceneVisibilityGroups, GetSelectedCaseShares, UpdateCaseShares, DeselectCase, SetModulesType, SetQCFilter,
    GetCaseBundlePdf,
    SaveCaseBundle,
    GetCaseBundle
} from 'app/core/store/actions/cases.actions';
import { ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';
import { ObjectMapper } from 'app/core/helpers';
import { PlannerSceneObject } from 'app/core/models/planner/plannerSceneObject';
import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { selectCreatedWebLinkToken, selectUsers } from 'app/core/store/selectors/users.selectors';
import { CreateCaseWebLinkShares, GetUsers, RevokeCaseWebLinkShares, SetUsersFilter } from 'app/core/store/actions/users.actions';
import { DeleteFile, EditFile, EditFilesPermissions } from 'app/core/store/actions/files.actions';
import { NewNotification } from 'app/core/store/actions/notifications.actions';
import { CreateWebLinkTokenCommand } from 'app/core/models/planner/commands/createWebLinkTokenCommand';


@Component({
    selector: 'cases',
    templateUrl: './cases.component.html',

    styleUrls: ['./cases.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CasesComponent implements OnInit, OnDestroy {
    hasSelectedMails: boolean;
    isIndeterminate: boolean;
    folders: any[];
    filters: any[];
    labels: any[];
    searchInput: FormControl;
    //currentCase: PlannerCase;

    // Private
    private _unsubscribeAll: Subject<any>;

    cases$: Observable<PlannerCase[]>;
    selectedCase$: Observable<PlannerCase>;
    selectedCaseShares$: Observable<PlannerCaseShare[]>;
    selectedCaseBundle$: Observable<PlannerCaseBundle>;
    selectedScene$: Observable<PlannerScene>;
    users$: Observable<PlannerUser[]>;
    selectCasesRequestArgs$: Observable<any>;
    selectCreatedWebLinkToken$: Observable<any>;

    selectedCase: PlannerCase;
    selectedScene: PlannerScene;

    private _changeSearchPatternTimer: NodeJS.Timer;

    constructor(
        private cdr: ChangeDetectorRef,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _store: Store<IAppState>
    ) {

        // Load the translations
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        // Set the defaults
        this.searchInput = new FormControl('');
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.cases$ = this._store.pipe(select(selectCasesList));
        this.selectedCase$ = this._store.pipe(select(selectSelectedCase), tap(selectedCase => this.selectedCase = selectedCase));
        this.selectedCaseShares$ = this._store.pipe(select(selectSelectedCaseShares));
        this.selectedCaseBundle$ = this._store.pipe(select(selectSelectedCaseBundle));
        this.selectedScene$ = this._store.pipe(select(selectSelectedScene), tap(selectedScene => this.selectedScene = selectedScene));
        this.users$ = this._store.pipe(select(selectUsers));
        this.selectCasesRequestArgs$ = this._store.pipe(select(selectCasesRequestArgs));
        this.selectCreatedWebLinkToken$ = this._store.pipe(select(selectCreatedWebLinkToken));
        // this.cases$.subscribe(x=> console.warn(x));
        // const caseId = this.activatedRoute.snapshot.params.caseId;

        // if (caseId) {
        //     this._store.dispatch(new GetSelectedCaseScenes(caseId));
        //    this.selectScene(caseId, null);
        // }

        this._store.select(selectCasesRequestArgs).pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response) => {
                this.searchInput.setValue(response.searchPattern);
            });
    }

    ngAfterViewInit() {

        this.cdr.detectChanges();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    modulesFilterSelected(moduleType: PlannerModuleType) {
        this._store.dispatch(new SetModulesType(moduleType));
    }

    qcFilterSelected() {
        this._store.dispatch(new SetQCFilter());
    }

    getMoreCases() {
        this._store.dispatch(new GetCases());
    }

    onSearchQuery(event: any): void {
        const searchQuery: string = event.target.value;

        const dispatchFunc = () => this._store.dispatch(new SetCasesSearchPattern(searchQuery));

        clearTimeout(this._changeSearchPatternTimer);
        this._changeSearchPatternTimer = setTimeout(dispatchFunc, 2000);
    }

    createCase(newCase: PlannerCase) {
        let createPlannerCaseCommand = new CreatePlannerCaseCommand();

        ObjectMapper.mapObjectToObject(newCase, createPlannerCaseCommand);
        ObjectMapper.mapObjectToObject(newCase.details, createPlannerCaseCommand);

        this._store.dispatch(new CreateCase(createPlannerCaseCommand));
    }

    caseEdit(editedCase: PlannerCase): void {

        let updatePlannerCaseCommand = new UpdatePlannerCaseCommand();

        ObjectMapper.mapObjectToObject(editedCase, updatePlannerCaseCommand);
        ObjectMapper.mapObjectToObject(editedCase.details, updatePlannerCaseCommand);

        this._store.dispatch(new EditCase(updatePlannerCaseCommand));

    }

    selectCase(caseId: string) {
        this._store.dispatch(new SelectCase(caseId));
        this._store.dispatch(new GetSelectedCaseScenes(caseId));
        this._store.dispatch(new GetSelectedCaseShares(caseId));
        this._store.dispatch(new GetCaseBundle(caseId));
        this.selectScene(caseId, null);
    }

    deselectCase(): void {
        this._store.dispatch(new DeselectCase());
    }

    selectScene(caseId: string, sceneId: string) {
        this._store.dispatch(new SelectScene({ caseId, sceneId }));
    }

    filesUploaded() {
        this._store.dispatch(new SelectCase(this.selectedCase.id));
    }

    filesUploadedAndAdded(plannerSceneObjects: PlannerSceneObject[]) {
        this._store.dispatch(new AddSceneObjects(plannerSceneObjects));
    }

    sceneObjectEvent(plannerSceneObjectOperationArgs: PlannerSceneObjectOperationArgs) {
        switch (plannerSceneObjectOperationArgs.operationType) {
            case ObjectOperationType.Add:
                this._store.dispatch(new AddSceneObjectSuccess(plannerSceneObjectOperationArgs.obj));
                break;
            case ObjectOperationType.Edit:
                this._store.dispatch(new EditSceneObjectSuccess(plannerSceneObjectOperationArgs.obj));
                break;
            case ObjectOperationType.Delete:
                this._store.dispatch(new DeleteSceneObjectSuccess(plannerSceneObjectOperationArgs.obj));
                break;
            default:
                break;
        }
    }

    sceneVisibilityGroupsEvent(sceneVisibilityGroups: PlannerSceneVisibilityGroup[]): void {
        console.log(sceneVisibilityGroups);

        var savePlannerSceneVisibilityGroupsCommand = new SavePlannerSceneVisibilityGroupsCommand();
        savePlannerSceneVisibilityGroupsCommand.caseId = this.selectedCase.id;
        savePlannerSceneVisibilityGroupsCommand.sceneId = this.selectedScene.id;
        savePlannerSceneVisibilityGroupsCommand.sceneConcurrencyStamp = this.selectedScene.concurrencyStamp;
        savePlannerSceneVisibilityGroupsCommand.visibilityGroups = sceneVisibilityGroups;

        this._store.dispatch(new SaveSceneVisibilityGroups(savePlannerSceneVisibilityGroupsCommand));
    }

    filesPermissionsEvent(filesAllowUserDownload: any[]): void {
        console.log(filesAllowUserDownload);

        this._store.dispatch(new EditFilesPermissions(filesAllowUserDownload));
    }

    // openCloneDialog(caseId: string): void {

    // }

    openChat(caseId: string) {
        this.router.navigateByUrl('/chat/' + caseId);
    }

    saveScene(scene: PlannerScene) {
        const saveSceneCommand = new SavePlannerSceneCommand()
        saveSceneCommand.caseId = this.selectedCase.id;
        saveSceneCommand.scene = scene;

        this._store.dispatch(new SaveScene(saveSceneCommand));
    }

    rollbackToScene(scene: PlannerScene) {
        const rollbackSceneCommand = new RollbackPlannerSceneCommand()
        rollbackSceneCommand.caseId = this.selectedCase.id;
        rollbackSceneCommand.sceneId = scene.id;

        this._store.dispatch(new RollbackScene(rollbackSceneCommand));
    }



    caseClone(selectedObject): void {
        const selectedCaseId = selectedObject.selectedCase.id;
        const newTitle = selectedObject.newTitle;
        const selectedUserId = selectedObject.selectedUser.id;

        const cloneCaseCommand = new ClonePlannerCaseCommand();
        cloneCaseCommand.caseId = selectedCaseId;
        cloneCaseCommand.newTitle = newTitle;
        cloneCaseCommand.newOwnerId = selectedUserId;

        this._store.dispatch(new CloneCase(cloneCaseCommand))
        // console.log(selectedUser);
    }

    caseSharesUpdate(selectedObject): void {

        const selectedCaseId = selectedObject.selectedCase.id;

        const usersToShare = selectedObject.usersToShare;

        const updateCaseSharesCommand = new UpdatePlannerCaseSharesCommand();
        updateCaseSharesCommand.caseId = selectedCaseId;

        updateCaseSharesCommand.shareToIds = usersToShare;

        this._store.dispatch(new UpdateCaseShares(updateCaseSharesCommand))
        // console.log(selectedUser);
    }

    createCaseWebLink(caseWebLinkParams: any): void {
        const caseId = caseWebLinkParams.caseId;
        const userId = caseWebLinkParams.userId;
        const issuedUtc = caseWebLinkParams.issuedUtc;
        const expiresUtc = caseWebLinkParams.expiresUtc;

        const createWebLinkTokenCommand = new CreateWebLinkTokenCommand();
        createWebLinkTokenCommand.caseId = caseId;
        createWebLinkTokenCommand.userId = userId;
        createWebLinkTokenCommand.issuedUtc = issuedUtc;
        createWebLinkTokenCommand.expiresUtc = expiresUtc;

        this._store.dispatch(new CreateCaseWebLinkShares(createWebLinkTokenCommand))
    }

    revokeCaseWebLink(caseWebLinkParams: any): void {
        const caseId = caseWebLinkParams.caseId;

        const revokeWebLinkTokenCommand = new RevokeWebLinkTokenCommand();

        revokeWebLinkTokenCommand.caseId = caseId;

        this._store.dispatch(new RevokeCaseWebLinkShares(revokeWebLinkTokenCommand))
    }

    usersSearch(searchQuery: string): void {
        this._store.dispatch(new SetUsersFilter({ searchQuery: searchQuery }))
    }

    caseDelete(caseId: string): void {
        const deleteCaseCommand = new DeletePlannerCaseCommand();
        deleteCaseCommand.caseId = caseId;
        this._store.dispatch(new DeleteCase(deleteCaseCommand))
    }

    editFile(plannerFile: PlannerFile): void {
        this._store.dispatch(new EditFile(plannerFile));
    }

    saveBundle(bundle: any): void {
        const saveCaseBundleCommand = new SaveBundleCaseCommand();
        saveCaseBundleCommand.caseId = this.selectedCase.id;  
        saveCaseBundleCommand.bundle = JSON.stringify(bundle);
        this._store.dispatch(new SaveCaseBundle(saveCaseBundleCommand));
    }

    deleteFile(plannerFile: PlannerFile): void {
        this._store.dispatch(new DeleteFile(plannerFile));
    }

    notify(text: string): void {
        this._store.dispatch(new NewNotification({ type: 'CaseDetails', message: text, action: 'Ok' }));
    }
    /**
     * Select mails
     *
     * @param filterParameter
     * @param filterValue
     */
    selectMails(filterParameter?, filterValue?): void {
    }

    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
}
