import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { FuseSidebarModule, FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { BatchOperationsComponent } from './batch-operations.component';
import { MatSlideToggleModule, MatTabsModule } from '@angular/material';
import { BatchCloneComponent } from './batch-clone/batch-clone.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from 'app/core/shared/shared.module';
// const routes = [
//     {
//         path     : 'auth/login',
//         component: LoginComponent
//     }
// ];

@NgModule({
    declarations: [
        BatchOperationsComponent,
        BatchCloneComponent
    ],
    imports: [
        TranslateModule,
        RouterModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTabsModule,
        MatSlideToggleModule,
        FlexLayoutModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseWidgetModule,
        SharedModule

    ]
})
export class BatchOperationsModule {
}
