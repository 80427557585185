import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'contacts-main-sidebar',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLogsSidebarComponent implements OnInit, OnDestroy {
    @Input()
    appLogsFilterParams: any;

    user: any;
    filterBy: string;

    @Output()
    onIsWithAdminsChanged: EventEmitter<boolean>;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UsersService} _contactsService
     */
    constructor(

    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.onIsWithAdminsChanged = new EventEmitter();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.filterBy = null;
        // this.filterBy = this._contactsService.filterBy || 'all';

        // this._contactsService.onUserDataChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(user => {
        //         this.user = user;
        //     });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Change the filter
     *
     * @param filter
     */
    changeFilter(isWithAdmins: boolean): void {

     
     if(this.appLogsFilterParams.isWithAdmins!=isWithAdmins)
     {
         this.onIsWithAdminsChanged.emit(isWithAdmins);
     }
      //  this.filterBy = filter;
        //this._contactsService.onFilterChanged.next(this.filterBy);
    }
}
