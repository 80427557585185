import { PlannerMessage } from '@appmodels';

export interface IMessagesState
{
    caseMessages: PlannerMessage[];   
}

export const initialMessagesState: IMessagesState =
{
    caseMessages: null,

}