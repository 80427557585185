import { Component, OnDestroy, OnInit, ViewEncapsulation, Output, EventEmitter, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';

//import { MailService } from '../../mail.service';
import { CaseEditDialogComponent } from '../../dialogs/case-edit/case-edit.component';
import { NGXLogger } from 'ngx-logger';
import { PlannerCase } from '@appmodels';
import { PlannerUser } from 'app/core/models/planner/plannerUser';

@Component({
    selector: 'cases-main-sidebar',
    templateUrl: './main-sidebar.component.html',
    styleUrls: ['./main-sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CasesMainSidebarComponent implements OnInit, OnDestroy {
    //folders: any[];
    filters: any[];
    labels: any[];
    accounts: object;
    selectedAccount: string;
    //dialogRef: any;

    @Input()
    set users(value: PlannerUser[]) {
        this.users$.next(value);
    }

    users$: Subject<PlannerUser[]>;

    @Output()
    onCaseCreate: EventEmitter<PlannerCase>;


    @Output()
    onUsersSearch: EventEmitter<string>;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {MailService} _mailService
     * @param {MatDialog} _matDialog
     */
    constructor(
        // private _mailService: MailService,
        public _matDialog: MatDialog, private logger: NGXLogger
    ) {
        // Set the defaults
        this.accounts = {
            creapond: 'johndoe@creapond.com',
            withinpixels: 'johndoe@withinpixels.com'
        };
        this.selectedAccount = 'creapond';

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.onCaseCreate = new EventEmitter();
        this.onUsersSearch = new EventEmitter();

        this.users$ = new Subject();
    }

    public folders = [
        {
            'id': 0,
            'handle': 'all',
            'title': 'All',
            'icon': 'inbox'
        },
        {
            'id': 1,
            'handle': 'own',
            'title': 'Own',
            'icon': 'inbox'
        },
        {
            'id': 2,
            'handle': 'sent',
            'title': 'Shared',
            'icon': 'send'
        },
        {
            'id': 3,
            'handle': 'drafts',
            'title': 'Demo',
            'icon': 'email_open'
        },
        {
            'id': 4,
            'handle': 'spam',
            'title': 'Archive',
            'icon': 'error'
        },
        {
            'id': 5,
            'handle': 'trash',
            'title': 'Trash',
            'icon': 'delete'
        }
    ];

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this._mailService.onFoldersChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(folders => {       
        //         this.folders = folders;
        //     });

        // this._mailService.onFiltersChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(filters => {
        //         this.filters = filters;
        //     });

        // this._mailService.onLabelsChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(labels => {
        //         this.labels = labels;
        //     });

        //     this._mailService.getFolders();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Compose dialog
     */
    caseEditDialog(): void {

        const dialogRef = this._matDialog.open(CaseEditDialogComponent, {
            panelClass: 'case-edit-dialog'
        });

        const instance = dialogRef.componentInstance;

        this.users$.pipe(takeUntil(this._unsubscribeAll)).subscribe((users) => {
            instance.users = users;
        });


        instance.onSearchQueryChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((query) => {
            this.onUsersSearch.emit(query);
        });

        dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const plannerCase: PlannerCase = response[1];
                switch (actionType) {
                    /**
                     * Create
                     */
                    case 'create':
                        this.logger.info('Case created, case :', plannerCase);
                        this.onCaseCreate.emit(plannerCase);
                        break;
                }
            });
    }
}
