export class PlannerCaseCommand {
    constructor() {
        this.title = null;
        this.patientName = null;
        this.patientCode = null;
        this.surgicalDate = null;
        this.operationType = null;
        this.sceneType = null;
        this.serializedData = null;
        this.isAllowAll = null;
        this.isArchived = null;
        this.isExcludeFromAnalytics = null;
        this.medicalReferalsType = null;
        this.gender = null;
        this.service = null;
        this.procedures = null;
        this.birthday = null;
        this.surgicalSide = null;
        this.scanCenter = null;
        this.shipment = null;
        this.description = null;
    }
}
export class CreatePlannerCaseCommand extends PlannerCaseCommand {
    constructor() {
        super(...arguments);
        this.ownerId = null;
    }
}
export class UpdatePlannerCaseCommand extends PlannerCaseCommand {
    constructor() {
        super(...arguments);
        this.id = null;
    }
}
