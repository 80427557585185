import * as tslib_1 from "tslib";
import { AddUserAvailablePaidModuleCommand } from "@appmodels";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { EditUserAvailablePaidModuleCommand } from "app/core/models/planner/commands/editUserAvailablePaidModuleCommand";
import { RemoveUserAvailablePaidModuleCommand } from "app/core/models/planner/commands/removeUserAvailablePaidModuleCommand";
import { PaidModulesService } from "app/core/services/planner/paidModules.service";
import { of } from "rxjs";
import { map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { NewNotification } from "../actions/notifications.actions";
import { AddUsersPaidModulesOfflineSuccess, AddUsersPaidModulesSuccess, EditUsersPaidModulesSuccess, EPaidModulesActions, GetUsersPaidModules, GetUsersPaidModulesOffline, GetUsersPaidModulesOfflineSuccess, GetUsersPaidModulesSuccess, RemoveUsersPaidModulesSuccess, SetPaidModulesSearchPatternSuccess } from "../actions/paidModules.actions";
import { selectPaidModulesSearchPattern } from "../selectors/paidModules.selector";
export class PaidModulesEffects {
    // @Effect()
    // editUsersPaidModules$ = this._actions$.pipe(
    //     ofType<EditUsersPaidModules>(EPaidModulesActions.EditUsersPaidModules),
    //     map(action => action.payload),
    //     switchMap((editedUserPaidModule) => {
    //         const editUserPaidModuleCommand = new EditUserAvailablePaidModuleCommand();
    //         editUserPaidModuleCommand.userId = editedUserPaidModule.userId;
    //         editUserPaidModuleCommand.availablePaidModuleId = editedUserPaidModule.id;
    //         editUserPaidModuleCommand.module = editedUserPaidModule.moduleType;
    //         editUserPaidModuleCommand.startDate = editedUserPaidModule.startDate;
    //         editUserPaidModuleCommand.daysAllocated = editedUserPaidModule.daysAllocated;
    //         editUserPaidModuleCommand.hospital = editedUserPaidModule.hospital;
    //         return this._paidModulesServices.editUsersPaidModule(editUserPaidModuleCommand);
    //     }),
    //     mergeMap((_) => {
    //         return [
    //             new EditUsersPaidModulesSuccess(),
    //             new GetUsersPaidModules(),                
    //             new NewNotification({ message: 'User paid module edited!', type: 'UsersPaidModulesSaved', action: 'Ok' })
    //         ];
    //     })
    // );
    // @Effect()
    // removeUsersPaidModules$ = this._actions$.pipe(
    //     ofType<RemoveUsersPaidModules>(EPaidModulesActions.RemoveUsersPaidModules),
    //     map(action => action.payload),
    //     switchMap((removedUserPaidModule) => {
    //         const editUserPaidModuleCommand = new RemoveUserAvailablePaidModuleCommand();
    //         editUserPaidModuleCommand.userId = removedUserPaidModule.userId;
    //         editUserPaidModuleCommand.availablePaidModuleId = removedUserPaidModule.id;
    //         return this._paidModulesServices.removeUsersPaidModule(editUserPaidModuleCommand);
    //     }),
    //     mergeMap((_) => {
    //         return [
    //             new RemoveUsersPaidModulesSuccess(),
    //             new GetUsersPaidModules(),                
    //             new NewNotification({ message: 'User paid module removed!', type: 'UsersPaidModulesSaved', action: 'Ok' })
    //         ];
    //     })
    // );
    // @Effect()
    // GenerateLicense$ = this._actions$.pipe(
    //     ofType<GenerateLicense>(EPaidModulesActions.GenerateLicense),
    //     map(action => action.payload),
    //     switchMap((generateLicenseCommand) => {
    //         return this._paidModulesServices.generateLicense(generateLicenseCommand);
    //     }),
    //     mergeMap(_ => {   
    //         return [
    //             new VoidAction()
    //         ];
    //     })
    // );
    constructor(_paidModulesServices, _actions$, _store) {
        this._paidModulesServices = _paidModulesServices;
        this._actions$ = _actions$;
        this._store = _store;
        this.setSearchPattern$ = this._actions$.pipe(ofType(EPaidModulesActions.SetPaidModulesSearchPattern), map(action => action.payload), switchMap((searchPattern) => {
            return [
                new SetPaidModulesSearchPatternSuccess(searchPattern),
                new GetUsersPaidModules(),
            ];
        }));
        this.getUsersPaidModules$ = this._actions$.pipe(ofType(EPaidModulesActions.GetUsersPaidModules), map(action => action), withLatestFrom(this._store.select(selectPaidModulesSearchPattern)), switchMap(([_, paidModulesSearchPattern]) => {
            const skip = 0;
            const take = 1000;
            return this._paidModulesServices.getUsersPaidModules(paidModulesSearchPattern, skip, take);
        }), switchMap((usersPaidModules) => of(new GetUsersPaidModulesSuccess(usersPaidModules))));
        this.addUsersPaidModules$ = this._actions$.pipe(ofType(EPaidModulesActions.AddUsersPaidModules), map(action => action.payload), switchMap((newUserPaidModule) => {
            const addUserPaidModuleCommand = new AddUserAvailablePaidModuleCommand();
            addUserPaidModuleCommand.userId = newUserPaidModule.userId;
            addUserPaidModuleCommand.module = newUserPaidModule.moduleType;
            addUserPaidModuleCommand.startDate = newUserPaidModule.startDate;
            addUserPaidModuleCommand.daysAllocated = newUserPaidModule.daysAllocated;
            addUserPaidModuleCommand.hospital = newUserPaidModule.hospital;
            console.log('sadasdasdasd');
            return this._paidModulesServices.addUsersPaidModule(addUserPaidModuleCommand).pipe(map(newUserPaidModuleId => {
                newUserPaidModule.id = newUserPaidModuleId;
                return newUserPaidModule;
            }));
        }), mergeMap((_) => {
            return [
                new AddUsersPaidModulesSuccess(),
                new SetPaidModulesSearchPatternSuccess(''),
                new GetUsersPaidModules(),
                new NewNotification({ message: 'User paid module created!', type: 'UsersPaidModulesSaved', action: 'Ok' })
            ];
        }));
        this.editUsersPaidModules$ = this._actions$.pipe(ofType(EPaidModulesActions.EditUsersPaidModules), map(action => action.payload), switchMap((editedUserPaidModule) => {
            const editUserPaidModuleCommand = new EditUserAvailablePaidModuleCommand();
            editUserPaidModuleCommand.userId = editedUserPaidModule.userId;
            editUserPaidModuleCommand.availablePaidModuleId = editedUserPaidModule.id;
            editUserPaidModuleCommand.module = editedUserPaidModule.moduleType;
            editUserPaidModuleCommand.startDate = editedUserPaidModule.startDate;
            editUserPaidModuleCommand.daysAllocated = editedUserPaidModule.daysAllocated;
            editUserPaidModuleCommand.hospital = editedUserPaidModule.hospital;
            return this._paidModulesServices.editUsersPaidModule(editUserPaidModuleCommand);
        }), mergeMap((_) => {
            return [
                new EditUsersPaidModulesSuccess(),
                new GetUsersPaidModules(),
                new NewNotification({ message: 'User paid module edited!', type: 'UsersPaidModulesSaved', action: 'Ok' })
            ];
        }));
        this.removeUsersPaidModules$ = this._actions$.pipe(ofType(EPaidModulesActions.RemoveUsersPaidModules), map(action => action.payload), switchMap((removedUserPaidModule) => {
            const editUserPaidModuleCommand = new RemoveUserAvailablePaidModuleCommand();
            editUserPaidModuleCommand.userId = removedUserPaidModule.userId;
            editUserPaidModuleCommand.availablePaidModuleId = removedUserPaidModule.id;
            return this._paidModulesServices.removeUsersPaidModule(editUserPaidModuleCommand);
        }), mergeMap((_) => {
            return [
                new RemoveUsersPaidModulesSuccess(),
                new GetUsersPaidModules(),
                new NewNotification({ message: 'User paid module removed!', type: 'UsersPaidModulesSaved', action: 'Ok' })
            ];
        }));
        this.setSearchPatternOffline$ = this._actions$.pipe(ofType(EPaidModulesActions.SetPaidModulesOfflineSearchPattern), map(action => action.payload), switchMap((searchPattern) => {
            return [
                new SetPaidModulesSearchPatternSuccess(searchPattern),
                new GetUsersPaidModulesOffline(),
            ];
        }));
        this.getUsersPaidModulesOffline$ = this._actions$.pipe(ofType(EPaidModulesActions.GetUsersPaidModulesOffline), map(action => action), withLatestFrom(this._store.select(selectPaidModulesSearchPattern)), switchMap(([_, paidModulesSearchPattern]) => {
            const skip = 0;
            const take = 1000;
            return this._paidModulesServices.getUsersPaidModulesOffline(paidModulesSearchPattern, skip, take);
        }), switchMap((usersPaidModules) => of(new GetUsersPaidModulesOfflineSuccess(usersPaidModules))));
        this.addUsersPaidModulesOffline$ = this._actions$.pipe(ofType(EPaidModulesActions.AddUsersPaidModulesOffline), map(action => action.payload), switchMap((generateLicenseCommand) => {
            return this._paidModulesServices.generateLicense(generateLicenseCommand).pipe();
        }), mergeMap((_) => {
            return [
                new AddUsersPaidModulesOfflineSuccess(),
                new SetPaidModulesSearchPatternSuccess(''),
                new GetUsersPaidModulesOffline(),
                new NewNotification({ message: 'User paid module offline created!', type: 'UsersPaidModulesSaved', action: 'Ok' })
            ];
        }));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PaidModulesEffects.prototype, "setSearchPattern$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PaidModulesEffects.prototype, "getUsersPaidModules$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PaidModulesEffects.prototype, "addUsersPaidModules$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PaidModulesEffects.prototype, "editUsersPaidModules$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PaidModulesEffects.prototype, "removeUsersPaidModules$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PaidModulesEffects.prototype, "setSearchPatternOffline$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PaidModulesEffects.prototype, "getUsersPaidModulesOffline$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PaidModulesEffects.prototype, "addUsersPaidModulesOffline$", void 0);
