import { Action } from '@ngrx/store';
import { NotificationArgs } from '@appmodels';

export enum ENotificationsActions {
    NewNotification = '[Notifications] New Notification',
}

export class NewNotification implements Action {
    public readonly type = ENotificationsActions.NewNotification;
    constructor(public payload: NotificationArgs) { };
}

export type NotificationsActions = NewNotification;