import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StorageService } from "@appservices";
import { Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class S3StorageService implements StorageService {

    constructor(private http: HttpClient) {
    }

    public uploadToBlobStorage(file: Blob, preSignedUrl: string, fileBlobName: string): Observable<number> {

        const headers = new HttpHeaders().set('Content-type', '');
           
        return this.http.put(preSignedUrl, file, { reportProgress: true, observe: "events", headers: headers })
            .pipe(
                map(event => {
                    if (event.type === HttpEventType.UploadProgress) {              
                        return event.loaded;
                    }
                    if (event.type === HttpEventType.Response) {               
                        return file.size;
                    }
                }),
                distinctUntilChanged()
            )
    }
}  