export const locale = {
    lang: 'ru',
    data: {
        USERS: {
            CREATEACCOUNT: 'СОЗДАНИЕ АККАУНТА',
            FILLNAME: 'Введите своё имя',
            EMAILREQUIRED: 'Необходимо ввести Email',
            ENTERVALIDEMAIL: 'Пожалуйста, введите правильный email адрес',
            EMAILUNAVAILABLE: 'Email недоступен',
            FIRSTNAME: 'Имя',
            FIRSTNAMEREQUIRED: 'Необходимо ввести Имя',
            LASTNAME: 'Фамилия',
            LASTNAMEREQUIRED: 'Необходимо ввести Фамилию',
            MIDDLENAME: 'Отчество',
            MEDICALREFERALTYPE: 'Медицинское направление',
            HUMAN: 'Медицина',
            VETERINARY: 'Ветеринарная',
            MEDICALREFERALTYPEREQUIRED: 'Необходимо указать Медицинское направление',
            SPECIALITY: 'Специальность',
            NOTSET: 'Не выбрано',
            TRAUMATOLOGISTORTHOPEDIST: 'Травматолог ортопед',
            MAXILLOFACIALSURGEON: 'Челюстнолицевой хирург',
            NEUROSURGEON: 'Нейрохирург',
            OTHER: 'Другое',
            SPECIALITYREQUIRED: 'Необходимо указать специальность',
            PASSWORD: 'Пароль',
            PASSWORDREQUIRED: 'Необходимо ввести Пароль',
            PASSWORDREQUIREMENT: 'Ваш пароль должен иметь от 6 до 30 символов <br> Ваш пароль должен содержать как минимум одну строчную букву <br> Ваш пароль должен содержать как минимум одну заглавную букву <br> Ваш пароль должен содержать как минимум одну цифру(0, 1, 2, 3, итд.) <br> Ваш пароль должен содержать как минимум один спецсимвол - например: -, $, #, @, !,%,^,&,*, (,)',
            PASSWORDCONFIRM: 'Пароль (Подтверждение)',
            PASSWORDCONFIRMREQUIRED: 'Необходимо ввести подтверждение пароля',
            PASSWORDMUSTMATCH: 'Пароли должны совпадать',
            NEXT: 'Далее',
            FILLINFO: 'Дополнительная информация',
            COUNTRY: 'Страна',
            COUNTRYREQUIRED: 'Необходимо указать Страну',
            CITY: 'Город',
            CITYREQUIRED: 'Необходимо ввести Город',
            PHONE: 'Телефон',
            PHONEREQUIRED: 'Необходимо ввести телефон',
            COMPANY: 'Госпиталь/Компания',
            COMPANYREQUIRED: 'Необходимо указать Госпиталь/Компанию',
            MARKETINGREFERALS: 'Как вы услышали о нашей компании?',
            MARKETINGREFERALSRECOMENDATION: 'Рекомендация',
            MARKETINGREFERALSSEARCHQUERY: 'Поисковый запрос',
            MARKETINGREFERALSSOCIALNETWORK: 'Социальные сети',
            LANGUAGE: 'Язык',
            ENGLISH: 'English',
            RUSSIAN: 'Русский',
            LANGUAGEREQUIRED: 'Необходимо указать Язык',
            TERMS1: 'Я прочел и согласен на обработку',
            TERMS2: 'персональных данных',
            TERMSREQUIRED: 'Необходимо подтвердить согласия на обработку персональных данных',
            BACK: 'Назад',
            REGISTER: 'Зарегистрироваться',
            DONE: 'Готово',
            SUCCESS: 'Успешная регистрация!',
            CLICKTODOWNLOAD: 'Нажмите на ссылку ниже для скачивани приложения Бонабайн планировщика для вашей ОС:',
            ERROR: 'Ошибка!',
            TRYAGAINLATER: 'Пожалуйста, попробуйте позже...'
        }
    }
};
