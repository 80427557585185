export class PlannerRoles {
    /// <summary>
    /// Админ
    /// </summary>
    public static Admin: string = "Admin";

    /// <summary>
    /// Редактор кейсов
    /// </summary>
    public static CasesEditor: string = "CasesEditor";

    /// <summary>
    /// Редактор файлов
    /// </summary>
    public static FilesEditor: string = "FilesEditor";

    /// <summary>
    /// Редактор имплантов
    /// </summary>
    public static ImplantsEditor: string = "ImplantsEditor";

    /// <summary>
    /// Просмотр аналитики
    /// </summary>
    public static AnalyticsEditor: string = "AnalyticsEditor";

    /// <summary>
    /// Просмотр логов
    /// </summary>
    public static LogsEditor: string = "LogsEditor";

    /// <summary>
    /// Редактор юзеров
    /// </summary>
    public static UsersEditor: string = "UsersEditor";

    /// <summary>
    /// Редактор продаж
    /// </summary>
    public static SalesEditor: string = "SalesEditor";

    /// <summary>
    /// Продвинутый пользователь (для тестирования нового функционала)
    /// </summary>
    public static AdvancedUser: string = "AdvancedUser";

    /// <summary>
    /// Пользователь планировщика
    /// </summary>
    public static User: string = "User";

    /// <summary>
    /// Биоинженер
    /// </summary>
    public static Bioengineer: string = "Bioengineer";

    /// <summary>
    /// Оператор принтера
    /// </summary>
    public static PrinterOperator: string = "PrinterOperator";

    /// <summary>
    /// SB
    /// </summary>
    public static Security: string = "Security";
}