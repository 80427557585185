import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { fuseAnimations } from '@fuse/animations';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'app/core/store/state/app.state';
import { selectCaseMessages } from 'app/core/store/selectors/messages.selector';
import { PlannerMessage, PlannerCase, CreatePlannerCaseMessageCommand, ICurrentUser, PlannerFile } from '@appmodels';
import { selectSelectedCase, selectCasesList } from 'app/core/store/selectors/cases.selector';
import { selectCurrentUser } from 'app/core/store/selectors/users.selectors';
import { AddCaseMessage, GetCaseMessages } from 'app/core/store/actions/messages.actions';
import { createSignalRHub } from 'ngrx-signalr-core';
//import { CreateHub, DisconnectHub } from 'app/core/store/actions/appsignalr.actions';
import { CaseMessagesReaded, SelectCase } from 'app/core/store/actions/cases.actions';

//import { ChatService } from 'app/pages/chat/chat.service';

@Component({
    selector: 'chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ChatComponent implements OnInit, OnDestroy {
    // selectedChat: any;

    // Private
    private _unsubscribeAll: Subject<any>;
    private _caseMessagesReadedTimer: NodeJS.Timer;

    currentUser$: Observable<ICurrentUser>;
    selectedCase$: Observable<PlannerCase>;
    messages$: Observable<PlannerMessage[]>;
    cases$: Observable<PlannerCase[]>;

    _currentCaseId: string;

    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     */
    constructor(
        private _location: Location,
        private _store: Store<IAppState>
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this._chatService.onChatSelected
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(chatData => {
        //         this.selectedChat = chatData;
        //     });

        this.currentUser$ = this._store.pipe(select(selectCurrentUser));
        this.selectedCase$ = this._store.pipe(select(selectSelectedCase));
        this.messages$ = this._store.pipe(select(selectCaseMessages));
        this.cases$ = this._store.pipe(select(selectCasesList));

        this.selectedCase$.pipe(takeUntil(this._unsubscribeAll)).subscribe(selectedCase=>{
            this._currentCaseId = selectedCase.id;
        })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {


        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    newMessage(messageObj: any) {
        const createPlannerCaseMessageCommand = new CreatePlannerCaseMessageCommand();
        // console.log(messageObj);
        createPlannerCaseMessageCommand.Text = messageObj.message as string;
        createPlannerCaseMessageCommand.UserToId = messageObj.sendToId as string;
        createPlannerCaseMessageCommand.IsMessageEmail = messageObj.isMessageEmail as boolean;

        const messageFile = messageObj.messageFile as PlannerFile;

        if (messageFile) {            
            createPlannerCaseMessageCommand.PlannerUsersFileId = messageFile.id;
        }

        this._store.dispatch(new AddCaseMessage(createPlannerCaseMessageCommand, messageFile));
    }

    messagesReaded(): void {
        const dispatchFunc = () => this._store.dispatch(new CaseMessagesReaded());
        clearTimeout(this._caseMessagesReadedTimer);
        this._caseMessagesReadedTimer = setTimeout(dispatchFunc, 2000);
    }

    getMoreMessages(): void {
        this._store.dispatch(new GetCaseMessages(this._currentCaseId, false));
    }

    selectCase(caseId: string): void {

        // const url = this.router.createUrlTree([], {relativeTo: this.activatedRoute, queryParams: {param: 1}}).toString()

        // this._location.go('chat/' + caseId);

        this._store.dispatch(new SelectCase(caseId));
        this._store.dispatch(new GetCaseMessages(caseId))
    }
}
