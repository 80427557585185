<div class="date-interval">
    <div class="interval-toggles">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="selectedDateInterval"
            (change)="casesDateIntervalChanged($event.value)">
            <mat-button-toggle [value]="dateIntervalEnum.CalendarMonth">Calendar mounth</mat-button-toggle>
            <mat-button-toggle [value]="dateIntervalEnum.Quarter">Quarter</mat-button-toggle>
            <mat-button-toggle [value]="dateIntervalEnum.CalendarYear">Calendar year</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="interval-toggles">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="selectedYear"
            (change)="yearIntervalChanged($event.value)">
            <mat-button-toggle *ngFor="let year of yearsIntervals" [value]="year"> {{year}}</mat-button-toggle>
        </mat-button-toggle-group>

    </div>
    <div class="interval-toggles" [fxShow]="selectedDateInterval == dateIntervalEnum.Quarter">
        <mat-button-toggle-group name="fontStyle" class="month-width" aria-label="Font Style" [value]="selectedQuarter"
            (change)="quarterIntervalChanged($event.value)">
            <mat-button-toggle *ngFor="let quarter of quarterIntervals" [value]="quarter"> {{quarter}}
            </mat-button-toggle>
        </mat-button-toggle-group>

    </div>
    <div class="interval-toggles" [fxShow]="selectedDateInterval == dateIntervalEnum.CalendarMonth">
        <mat-button-toggle-group class="month-width" name="fontStyle" aria-label="Font Style" [value]="selectedMonth"
            (change)="monthIntervalChanged($event.value)">
            <mat-button-toggle *ngFor="let month of monthIntervals" [value]="month.monthIndex"> {{month.monthName}}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>


<div>
    <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom [@animateStagger]="{value:'50'}">

        <!-- WIDGET CASES -->
        <fuse-widget style="height: 260px;" [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
            fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

            <!-- Front -->
            <div class="fuse-widget-front">
                <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">
                    <span>Total cases</span>
                    <!-- <mat-form-field>
                    <mat-select (selectionChange)="casesDateIntervalChanged($event.value)"
                        [value]="dateIntervalEnum.CalendarMonth" class="simplified font-size-16"
                        aria-label="Change range">
                        <mat-option [value]="dateIntervalEnum.CalendarMonth">
                            Calendar mounth
                        </mat-option>                  
                        <mat-option [value]="dateIntervalEnum.CalendarYear">
                            Calendar year
                        </mat-option>
                    </mat-select> 
                </mat-form-field>  -->
                    <button mat-icon-button fuseWidgetToggle aria-label="more">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </div>

                <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center"
                    (click)="selectedAnalyticsChanged('Cases')">
                    <div class="light-blue-fg font-size-72 line-height-72 select-analytics">
                        {{generalAnalytics.dateIntervalCommonCasesCount}}
                    </div>
                    <div class="h3 secondary-text font-weight-500">

                        Common cases

                    </div>
                </div>

                <div class="p-16 border-top margin-sides-14" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row">
                        <span class="h4 secondary-text text-truncate">
                            2d modules:
                        </span>
                        <span class="h4 ml-8">
                            {{generalAnalytics.dateIntervalModules2dCasesCount}}
                        </span>
                    </div>
                    <div fxLayout="row">
                        <span class="h4 secondary-text text-truncate">
                            Total:
                        </span>
                        <span class="h4 ml-8">
                            {{generalAnalytics.dateIntervalTotalCasesCount}}
                        </span>
                    </div>
                </div>
            </div>
            <!-- / Front -->

            <!-- Back -->
            <div class="fuse-widget-back p-16 pt-32">
                <button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button" aria-label="Flip widget">
                    <mat-icon class="s-16">close</mat-icon>
                </button>

                <div>
                    <!-- {{widgets.widget1.detail}} -->
                    Some additional info might be placed here
                </div>
            </div>
            <!-- / Back -->

        </fuse-widget>
        <!-- / WIDGET CASES -->

        <!-- WIDGET LOGINS -->
        <fuse-widget style="height: 260px;" [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
            fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

            <!-- Front -->
            <div class="fuse-widget-front">
                <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">
                    <span>Total logins</span>
                    <!-- <mat-form-field>
                    <mat-select 
                      class="simplified font-size-16"
                        aria-label="Change range">
                        <mat-option [value]="dateIntervalEnum.CalendarMonth">
                            Calendar mounth
                        </mat-option>
                         <mat-option [value]="dateIntervalEnum.Quarter">
                           Quarter
                       </mat-option> 
                        <mat-option [value]="dateIntervalEnum.CalendarYear">
                            Calendar year
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
                    <button mat-icon-button fuseWidgetToggle aria-label="more">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </div>

                <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center"
                    (click)="selectedAnalyticsChanged('Logins')">
                    <div class="light-blue-fg font-size-72 line-height-72 select-analytics">
                        <!-- {{widgets.widget1.data.count[widgets.widget1.currentRange]}} -->
                        {{generalAnalytics.loginsCount}}
                    </div>
                    <div class="h3 secondary-text font-weight-500">

                        Logins count

                    </div>
                </div>


            </div>
            <!-- / Front -->

            <!-- Back -->
            <div class="fuse-widget-back p-16 pt-32">
                <button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button" aria-label="Flip widget">
                    <mat-icon class="s-16">close</mat-icon>
                </button>

                <div>
                    <!-- {{widgets.widget1.detail}} -->
                    Some additional info might be placed here
                </div>
            </div>
            <!-- / Back -->

        </fuse-widget>
        <!-- / WIDGET LOGINS -->


        <!-- WIDGET UNIQ LOGINS -->
        <fuse-widget style="height: 260px;" [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
            fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

            <!-- Front -->
            <div class="fuse-widget-front">
                <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">
                    <span>Total unique logins</span>
                    <!-- <mat-form-field>
                 <mat-select 
                     class="simplified font-size-16"
                     aria-label="Change range">
                     <mat-option [value]="dateIntervalEnum.CalendarMonth">
                         Calendar mounth
                     </mat-option>
                 
                     <mat-option [value]="dateIntervalEnum.CalendarYear">
                         Calendar year
                     </mat-option>
                 </mat-select>
             </mat-form-field> -->
                    <button mat-icon-button fuseWidgetToggle aria-label="more">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </div>

                <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center"
                    (click)="selectedAnalyticsChanged('Unique logins')">
                    <div class="light-blue-fg font-size-72 line-height-72 select-analytics">
                        <!-- {{widgets.widget1.data.count[widgets.widget1.currentRange]}} -->
                        {{generalAnalytics.uniqLoginsCount}}
                    </div>
                    <div class="h3 secondary-text font-weight-500">

                        Unique logins count

                    </div>
                </div>


            </div>
            <!-- / Front -->

            <!-- Back -->
            <div class="fuse-widget-back p-16 pt-32">
                <button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button" aria-label="Flip widget">
                    <mat-icon class="s-16">close</mat-icon>
                </button>

                <div>
                    <!-- {{widgets.widget1.detail}} -->
                    Some additional info might be placed here
                </div>
            </div>
            <!-- / Back -->

        </fuse-widget>
        <!-- / WIDGET UNIQ LOGINS -->


        <!-- WIDGET NEW USERS  -->
        <fuse-widget style="height: 260px;" [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
            fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

            <!-- Front -->
            <div class="fuse-widget-front">
                <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">
                    <span>Total new users</span>
                    <!-- <mat-form-field>
               <mat-select 
                   class="simplified font-size-16"
                   aria-label="Change range">
                   <mat-option [value]="dateIntervalEnum.CalendarMonth">
                       Calendar mounth
                   </mat-option>
              
                   <mat-option [value]="dateIntervalEnum.CalendarYear">
                       Calendar year
                   </mat-option>
               </mat-select>
           </mat-form-field> -->
                    <button mat-icon-button fuseWidgetToggle aria-label="more">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </div>

                <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center"
                    (click)="selectedAnalyticsChanged('New users')">
                    <div class="light-blue-fg font-size-72 line-height-72 select-analytics">
                        <!-- {{widgets.widget1.data.count[widgets.widget1.currentRange]}} -->
                        {{generalAnalytics.newUsersCount}}
                    </div>
                    <div class="h3 secondary-text font-weight-500">

                        New users count

                    </div>
                </div>


            </div>
            <!-- / Front -->

            <!-- Back -->
            <div class="fuse-widget-back p-16 pt-32">
                <button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button" aria-label="Flip widget">
                    <mat-icon class="s-16">close</mat-icon>
                </button>

                <div>
                    <!-- {{widgets.widget1.detail}} -->
                    Some additional info might be placed here
                </div>
            </div>
            <!-- / Back -->

        </fuse-widget>
        <!-- / WIDGET NEW USERS -->

        <!-- WIDGET 5 -->


        <!-- Widget 5 -->
        <fuse-widget style="" [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
            [fxShow]="selectedAnalytics=='Cases'" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-md="100">

            <div class="fuse-card auto-width">

                <div class="position-relative p-24" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutAlign="start start">
                        <span class="h2">Cases chart</span>
                    </div>
                    <!-- <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="py-8 px-12 border-radius-2 line-height-1 mr-8 cursor-pointer"
                            (click)="widget5SelectedDay = 'yesterday'"
                            [ngClass]="{'accent': widget5SelectedDay === 'yesterday'}">
                            Yesterday
                        </div>
                        <div class="py-8 px-12 border-radius-2 line-height-1 mr-8 cursor-pointer"
                            (click)="widget5SelectedDay = 'today'"
                            [ngClass]="{'accent': widget5SelectedDay === 'today'}">
                            Today
                        </div>
                    </div> -->
                </div>

                <div class="position-relative h-368 pb-16">
                    <canvas baseChart [datasets]="casesWidget.dataset" [labels]="casesWidget.labels"
                        [colors]="casesWidget.colors" [options]="casesWidget.options"
                        [chartType]="casesWidget.chartType">
                    </canvas>
                </div>

            </div>

        </fuse-widget>

        <fuse-widget [fxShow]="selectedAnalytics=='Cases'" style="" [@animate]="{value:'*',params:{y:'100%'}}"
            class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-md="100">
            <div class="" [ngClass.lt-lg]="'mr-32'" [ngClass.xs]="'mr-0'">

                <div class="fuse-card auto-width">
                    <!-- <div class="fuse-card"> -->

                    <div class="p-16" fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="h2">Common cases list</span>

                        <div>
                            <button mat-icon-button [matMenuTriggerFor]="card20Menu" aria-label="more">
                                <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #card20Menu="matMenu">
                                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                    <mat-icon color="accent">check_box</mat-icon>
                                    <span>Show 1</span>
                                </button>
                                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                    <mat-icon color="accent">check_box</mat-icon>
                                    <span>Show 2</span>
                                </button>
                                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                    <mat-icon>check_box_outline_blank</mat-icon>
                                    <span>Show 3</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>

                    <table class="simple clickable">
                        <thead>
                            <tr>
                                <th style="padding-left: 12px;" class="">#</th>
                                <th class="text-right">Title</th>
                                <th class="text-right">Owner</th>
                                <th class="text-right">Medical referals</th>
                                <th class="text-right">Scene type</th>
                                <th class="text-right">Status</th>
                                <th class="text-right">Creation date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let plannerCase of (casesAnalytics!=null ? casesAnalytics.commonCases : []);let i=index;">
                                <td style="padding-left: 12px;" class="">{{i+1}}</td>
                                <td><a href="{{'/cases/all/'+plannerCase.id}}">{{plannerCase.title}}</a></td>
                                <td style="cursor: poiner;" class="text-right">{{plannerCase.ownerName}}</td>
                                <td class="text-right">{{plannerCase.medicalReferalsType}}</td>
                                <td class="text-right">{{plannerCase.sceneType}}</td>
                                <td class="text-right">{{plannerCase.status}}</td>
                                <td class="text-right">{{plannerCase.creationDate | date : 'HH:mm dd.MM.yyyy'}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="card-divider full-width"></div>

                    <!-- <div class="p-8 pt-16" fxLayout="row" fxLayoutAlign="start center">
                        <button mat-button color="accent">GO TO CAMPAIGNS</button>
                    </div> -->

                </div>

               

            </div>

            <!-- </div> -->
        </fuse-widget>

        <fuse-widget [fxShow]="selectedAnalytics=='Cases'" style="" [@animate]="{value:'*',params:{y:'100%'}}"
            class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-md="100">
            <div class="mb-48" [ngClass.lt-lg]="'mr-32'" [ngClass.xs]="'mr-0'">

                

                <div class="fuse-card auto-width">
                    <!-- <div class="fuse-card"> -->

                    <div class="p-16" fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="h2">Modules 2d cases list</span>

                        <div>
                            <button mat-icon-button [matMenuTriggerFor]="card20Menu" aria-label="more">
                                <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #card20Menu="matMenu">
                                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                    <mat-icon color="accent">check_box</mat-icon>
                                    <span>Show 1</span>
                                </button>
                                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                    <mat-icon color="accent">check_box</mat-icon>
                                    <span>Show 2</span>
                                </button>
                                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                    <mat-icon>check_box_outline_blank</mat-icon>
                                    <span>Show 3</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>

                    <table class="simple clickable">
                        <thead>
                            <tr>
                                <th style="padding-left: 12px;" class="">#</th>
                                <th class="text-right">Title</th>
                                <th class="text-right">Owner</th>
                                <th class="text-right">Medical referals</th>
                                <th class="text-right">Scene type</th>
                                <th class="text-right">Status</th>
                                <th class="text-right">Creation date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let plannerCase of (casesAnalytics!=null ? casesAnalytics.modules2dCases : []);let i=index;">
                                <td style="padding-left: 12px;" class="">{{i+1}}</td>
                                <td><a href="{{'/cases/all/'+plannerCase.id}}">{{plannerCase.title}}</a></td>
                                <td style="cursor: poiner;" class="text-right">{{plannerCase.ownerName}}</td>
                                <td class="text-right">{{plannerCase.medicalReferalsType}}</td>
                                <td class="text-right">{{plannerCase.sceneType}}</td>
                                <td class="text-right">{{plannerCase.status}}</td>
                                <td class="text-right">{{plannerCase.creationDate | date : 'HH:mm dd.MM.yyyy'}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="card-divider full-width"></div>

                    <!-- <div class="p-8 pt-16" fxLayout="row" fxLayoutAlign="start center">
                        <button mat-button color="accent">GO TO CAMPAIGNS</button>
                    </div> -->

                </div>

            </div>

            <!-- </div> -->
        </fuse-widget>




        <fuse-widget style="" [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
            [fxShow]="selectedAnalytics=='Logins' || selectedAnalytics=='Unique logins'" fxFlex="100" fxFlex.gt-xs="100"
            fxFlex.gt-md="100">

            <div class="fuse-card auto-width">

                <div class="position-relative p-24" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutAlign="start start">
                        <span class="h2">Logins & unique logins chart</span>
                    </div>
                    <!-- <div fxLayout="row" fxLayoutAlign="start center">
                <div class="py-8 px-12 border-radius-2 line-height-1 mr-8 cursor-pointer"
                    (click)="widget5SelectedDay = 'yesterday'"
                    [ngClass]="{'accent': widget5SelectedDay === 'yesterday'}">
                    Yesterday
                </div>
                <div class="py-8 px-12 border-radius-2 line-height-1 mr-8 cursor-pointer"
                    (click)="widget5SelectedDay = 'today'"
                    [ngClass]="{'accent': widget5SelectedDay === 'today'}">
                    Today
                </div>
            </div> -->
                </div>

                <div class="position-relative h-368 pb-16">
                    <canvas baseChart [datasets]="loginsWidget.dataset" [labels]="loginsWidget.labels"
                        [colors]="loginsWidget.colors" [options]="loginsWidget.options"
                        [chartType]="loginsWidget.chartType">
                    </canvas>
                </div>

            </div>

        </fuse-widget>

        <fuse-widget [fxShow]="selectedAnalytics=='Logins'" style="" [@animate]="{value:'*',params:{y:'100%'}}"
            class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-md="100">
            <div class="mb-48" [ngClass.lt-lg]="'mr-32'" [ngClass.xs]="'mr-0'">

                <div class="fuse-card auto-width">
                    <!-- <div class="fuse-card"> -->

                    <div class="p-16" fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="h2">Logins list</span>

                        <div>
                            <button mat-icon-button [matMenuTriggerFor]="card20Menu" aria-label="more">
                                <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #card20Menu="matMenu">
                                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                    <mat-icon color="accent">check_box</mat-icon>
                                    <span>Show 1</span>
                                </button>
                                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                    <mat-icon color="accent">check_box</mat-icon>
                                    <span>Show 2</span>
                                </button>
                                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                    <mat-icon>check_box_outline_blank</mat-icon>
                                    <span>Show 3</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>

                    <table class="simple clickable">
                        <thead>
                            <tr>
                                <th style="padding-left: 12px;" class="">#</th>
                                <th class="text-right">User</th>
                                <th class="text-right">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let login of (usersAnalytics !=null ? usersAnalytics.logins : []) | orderBy: ['-creationDate'];let i=index;">
                                <td style="padding-left: 12px;" class="">{{i+1}}</td>
                                <!-- <td><a href="{{'/cases/all/'+plannerCase.id}}">{{plannerCase.title}}</a></td> -->
                                <td style="cursor: poiner;" class="text-right">{{login.userName}}</td>
                                <td class="text-right">{{login.creationDate | date}}</td>

                            </tr>
                        </tbody>
                    </table>

                    <div class="card-divider full-width"></div>

                </div>

            </div>

        </fuse-widget>

        <fuse-widget [fxShow]="selectedAnalytics=='Unique logins'" style="" [@animate]="{value:'*',params:{y:'100%'}}"
            class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-md="100">
            <div class="mb-48" [ngClass.lt-lg]="'mr-32'" [ngClass.xs]="'mr-0'">

                <div class="fuse-card auto-width">
                    <!-- <div class="fuse-card"> -->

                    <div class="p-16" fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="h2">Unique logins list</span>

                        <div>
                            <button mat-icon-button [matMenuTriggerFor]="card20Menu" aria-label="more">
                                <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #card20Menu="matMenu">
                                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                    <mat-icon color="accent">check_box</mat-icon>
                                    <span>Show 1</span>
                                </button>
                                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                    <mat-icon color="accent">check_box</mat-icon>
                                    <span>Show 2</span>
                                </button>
                                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                    <mat-icon>check_box_outline_blank</mat-icon>
                                    <span>Show 3</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>

                    <table class="simple clickable">
                        <thead>
                            <tr>
                                <th style="padding-left: 12px;" class="">#</th>
                                <th class="text-right">User</th>
                                <!-- <th class="text-right">Date</th>                            -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let login of (usersAnalytics != null ? usersAnalytics.uniqLogins : []) ;let i=index;">
                                <td style="padding-left: 12px;" class="">{{i+1}}</td>
                                <!-- <td><a href="{{'/cases/all/'+plannerCase.id}}">{{plannerCase.title}}</a></td> -->
                                <td style="cursor: poiner;" class="text-right">{{login.userName}}</td>
                                <!-- <td class="text-right">{{login.creationDate | date}}</td> -->

                            </tr>
                        </tbody>
                    </table>

                    <div class="card-divider full-width"></div>

                </div>

            </div>

        </fuse-widget>




        <fuse-widget style="" [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
            [fxShow]="selectedAnalytics=='New users'" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-md="100">

            <div class="fuse-card auto-width">

                <div class="position-relative p-24" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutAlign="start start">
                        <span class="h2">New users chart</span>
                    </div>
                </div>

                <div class="position-relative h-368 pb-16">
                    <canvas baseChart [datasets]="usersWidget.dataset" [labels]="usersWidget.labels"
                        [colors]="usersWidget.colors" [options]="usersWidget.options"
                        [chartType]="usersWidget.chartType">
                    </canvas>
                </div>

            </div>

        </fuse-widget>

        <fuse-widget [fxShow]="selectedAnalytics=='New users'" style="" [@animate]="{value:'*',params:{y:'100%'}}"
            class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-md="100">
            <div class="mb-48" [ngClass.lt-lg]="'mr-32'" [ngClass.xs]="'mr-0'">

                <div class="fuse-card auto-width">
                    <!-- <div class="fuse-card"> -->

                    <div class="p-16" fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="h2">New users list</span>

                        <div>
                            <button mat-icon-button [matMenuTriggerFor]="card20Menu" aria-label="more">
                                <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #card20Menu="matMenu">
                                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                    <mat-icon color="accent">check_box</mat-icon>
                                    <span>Show 1</span>
                                </button>
                                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                    <mat-icon color="accent">check_box</mat-icon>
                                    <span>Show 2</span>
                                </button>
                                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                    <mat-icon>check_box_outline_blank</mat-icon>
                                    <span>Show 3</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>

                    <table class="simple clickable">
                        <thead>
                            <tr>
                                <th style="padding-left: 12px;" class="">#</th>
                                <th class="text-right">User</th>
                                <th class="text-right">Name</th>

                                <th class="text-right">Country</th>
                                <th class="text-right">City</th>
                                <th class="text-right">Phone</th>
                                <th class="text-right">Company</th>
                                <th class="text-right">Speciality</th>
                                <th class="text-right">Language</th>

                                <th class="text-right">Medical referals</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let newUser of (usersAnalytics!=null ? usersAnalytics.newUsers : []) | orderBy: ['-creationDate'];let i=index;">
                                <td style="padding-left: 12px;" class="">{{i+1}}</td>
                                <!-- <td><a href="{{'/cases/all/'+plannerCase.id}}">{{plannerCase.title}}</a></td> -->
                                <td style="cursor: poiner;" class="text-right">{{newUser.userName}}</td>
                                <td class="text-right">{{newUser.lastName}} {{newUser.middleName}} {{newUser.firstName}}
                                </td>
                                <td class="text-right">{{newUser.country}}</td>
                                <td class="text-right">{{newUser.city}}</td>
                                <td class="text-right">{{newUser.phoneNumber}}</td>
                                <td class="text-right">{{newUser.company}}</td>

                                <td class="text-right">

                                    <span [fxShow]="newUser.speciality == specialityEnum.MaxillofacialSurgeon">
                                        Maxillofacial surgeon </span>
                                    <span [fxShow]="newUser.speciality == specialityEnum.Neurosurgeon"> Neurosurgeon
                                    </span>
                                    <span [fxShow]="newUser.speciality == specialityEnum.NotSet"> Not set </span>
                                    <span [fxShow]="newUser.speciality == specialityEnum.Other"> Other </span>
                                    <span [fxShow]="newUser.speciality == specialityEnum.TraumatologistOrthopedist">
                                        Traumatologist orthopedist </span>

                                </td>
                                <td class="text-right">
                                    <span [fxShow]="newUser.language == languageEnum.enUS"> English </span>
                                    <span [fxShow]="newUser.language == languageEnum.ruRU"> Russian </span>
                                </td>
                                <td class="text-right">

                                    <span [fxShow]="newUser.medicalReferalsType == medicalReferalsEnum.Human"> Human
                                    </span>
                                    <span [fxShow]="newUser.medicalReferalsType == medicalReferalsEnum.Veterinary">
                                        Veterinary </span>


                                </td>

                            </tr>
                        </tbody>
                    </table>

                    <div class="card-divider full-width"></div>

                </div>

            </div>

            <!-- </div> -->
        </fuse-widget>


        <!-- / Widget 5 -->
        <!-- <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" fxLayout="row" fxFlex="100"
    class="widget widget5">

   
    <div class="fuse-widget-front">

        <div class="px-16 border-bottom" fxLayout="row wrap"
            fxLayoutAlign="space-between center">

            <div fxFlex class="py-16 h3">111</div>

            <div fxFlex="0 1 auto" class="py-16" fxLayout="row">
                <button mat-button class="px-16"
                    *ngFor="let range of widgets.widget5.ranges | keys"
                    (click)="widget5.currentRange = range.key"
                    [ngClass]="{'accent' : widget5.currentRange == range.key}">
                    {{range.value}}
                </button>
            </div>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start start">

            <div class="h-420 my-16" fxLayout="row" fxFlex="100" fxFlex.gt-sm="50">
                <ngx-charts-bar-vertical-stacked *fuseIfOnDom [scheme]="widget5.scheme"
                    [results]="widget5.mainChart[this.widget5.currentRange]"
                    [gradient]="widget5.gradient" [xAxis]="widget5.xAxis"
                    [yAxis]="widget5.yAxis" [legend]="widget5.legend"
                    [showXAxisLabel]="widget5.showXAxisLabel"
                    [showYAxisLabel]="widget5.showYAxisLabel" [xAxisLabel]="widget5.xAxisLabel"
                    [yAxisLabel]="widget5.yAxisLabel" (select)="widget5.onSelect($event)">
                </ngx-charts-bar-vertical-stacked>
            </div>

            <div class="my-16" fxFlex="100" fxLayout="row wrap" fxFlex.gt-sm="50">

                <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxLayoutAlign="center"
                    *ngFor="let widget of widgets.widget5.supporting | keys" class="mb-24">

                    <div class="px-24">
                        <div class="h4 secondary-text">{{widget.value.label}}</div>
                        <div class="mat-display-1 m-0">
                            {{widget.value.count[widget5.currentRange]}}
                        </div>
                    </div>

                    <div class="h-64">
                        <ngx-charts-area-chart *fuseIfOnDom
                            [results]="widget.value.chart[widget5.currentRange]"
                            [scheme]="widget5.supporting.scheme"
                            [gradient]="widget5.supporting.gradient"
                            [xAxis]="widget5.supporting.xAxis"
                            [yAxis]="widget5.supporting.yAxis"
                            [legend]="widget5.supporting.legend"
                            [showXAxisLabel]="widget5.supporting.showXAxisLabel"
                            [showYAxisLabel]="widget5.supporting.showYAxisLabel"
                            [xAxisLabel]="widget5.supporting.xAxisLabel"
                            [yAxisLabel]="widget5.supporting.yAxisLabel"
                            [curve]="widget5.supporting.curve">
                        </ngx-charts-area-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>                         

</fuse-widget> -->
        <!-- / WIDGET 5 -->
    </div>

</div>
<!-- / WIDGET GROUP -->