import { Injectable } from '@angular/core';
import { IAppState } from '../store/state/app.state';
import { Store } from '@ngrx/store';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { GetCases, GetSelectedCaseScenes, GetSelectedCaseShares, SelectCase, SelectScene, SetCasesFilterParams, SetCasesFolder, SetCasesSearchPattern } from '../store/actions/cases.actions';
import { selectCasesList, selectSelectedCase } from '../store/selectors/cases.selector';
import { take, filter, tap } from 'rxjs/operators';
import { CaseFolderType, PlannerModuleType } from '../models/planner/enums/plannerCaseEnums';
import { ResetUsersFilter } from '../store/actions/users.actions';
import { selectQualitySurveyCasesList } from '../store/selectors/qualitySurveyCases.selector';
import { SetQualitySurveysCasesFilterParams } from '../store/actions/qualitySurveyCases.actions';

@Injectable({ providedIn: 'root' })
export class CaseQualitySurveysResolver {
    constructor(private _store: Store<IAppState>, private logger: NGXLogger) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        const routeParams = route.params;

        this.logger.info('Case quality surveys route resolver: ', route.params)

        const obsCollection: Observable<any>[] = [];

        obsCollection.push(this._store.select(selectQualitySurveyCasesList).pipe(
            filter(cases => cases != null),
            take(1)
        ));

        obsCollection.push(of(this._store.dispatch(new SetQualitySurveysCasesFilterParams(true, ''))));        

        this._store.dispatch(new ResetUsersFilter());

        return forkJoin(obsCollection);
    }
}