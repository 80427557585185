import { Action } from '@ngrx/store';

export enum EAppSignalRActions {
    CreateHub = '[AppSignalR] Create Hub',
    DisconnectHub = '[AppSignalR] Disconnect Hub'
}

export class CreateHub implements Action {
    public readonly type = EAppSignalRActions.CreateHub;
    constructor() { };
}

export class DisconnectHub implements Action {
    public readonly type = EAppSignalRActions.DisconnectHub;
    constructor() { };
}

export type AppActions = CreateHub | DisconnectHub;