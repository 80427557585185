import { Action } from '@ngrx/store';
import { ConnectionInfo, ICurrentUser, Language, MedicalReferals, RevokeWebLinkTokenCommand, Speciality } from '@appmodels';
import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { CreateWebLinkTokenCommand } from 'app/core/models/planner/commands/createWebLinkTokenCommand';

export enum EUsersActions {
    GetCurrentUser = '[User] Get Current User',
    GetCurrentUserSuccess = '[User] Get Current User Success',
    SetUsersFilter = '[User] Set Users Filter',
    SetUsersFilterSuccess = '[User] SetUsers Filter Success',
    // SetUsersFilterMedicalReferals = '[User] Set Users Filter Medical Referals',
    // SetUsersFilterMedicalReferalsSuccess = '[User] Set Users Filter Medical Referals Success',
    // SetUsersFilterSpeciality = '[User] Set Users Filter Speciality',
    // SetUsersFilterSpecialitySuccess = '[User] Set Users Filter Speciality Success',
    // SetUsersFilterLanguage = '[User] Set Users Filter Language',
    // SetUsersFilterLanguageSuccess = '[User] Set Users Filter Language Success',
    // SetUsersFilterSearchPattern = '[User] Set Users Filter SearchPattern',
    // SetUsersFilterSearchPatternSuccess = '[User] Set Users Filter SearchPattern Success',
    ResetUsersFilter = '[User] Reset Users Filter',
    ResetUsersFilterSuccess = '[User] Reset Users Filter Success',
    GetUsers = '[User] Get Users',
    GetUsersSuccess = '[User] Get Users Success',
    ExportUsersList = '[User] Export Users List',
    GetOnlineUsers = '[User] Get Online Users',
    GetOnlineUsersSuccess = '[User] Get Online Users Success',
    EditUser = '[User] Edit User',
    EditUserSuccess = '[User] Edit User Success',
    DeleteUser = '[User] Delete User',
    DeleteUserSuccess = '[User] Delete User Success',
    CreateCaseWebLinkShares = '[User] Create Case Web Link Shares',
    CreateCaseWebLinkSharesSuccess = '[User] Create Case Web Link Shares Success',
    RevokeCaseWebLinkShares = '[User] Revoke Case Web Link Shares',
    RevokeCaseWebLinkSharesSuccess = '[User] Revoke Case Web Link Shares Success'
}

export class GetCurrentUser implements Action {
    public readonly type = EUsersActions.GetCurrentUser;
    constructor() { };
}

export class GetCurrentUserSuccess implements Action {
    public readonly type = EUsersActions.GetCurrentUserSuccess;
    constructor(public payload: ICurrentUser) { };
}

export class SetUsersFilter implements Action {
    public readonly type = EUsersActions.SetUsersFilter;
    constructor(public payload: any) { };
}

export class SetUsersFilterSuccess implements Action {
    public readonly type = EUsersActions.SetUsersFilterSuccess;
    constructor(public payload: any) { };
}


export class ResetUsersFilter implements Action {
    public readonly type = EUsersActions.ResetUsersFilter;
    constructor();
    constructor(payload: any);
    constructor(public payload?: any) { };
}

export class ResetUsersFilterSuccess implements Action {
    public readonly type = EUsersActions.ResetUsersFilterSuccess;   
    constructor(public payload: any){ };
}

export class GetUsers implements Action {
    public readonly type = EUsersActions.GetUsers;
}

export class GetUsersSuccess implements Action {
    public readonly type = EUsersActions.GetUsersSuccess;
    constructor(public payload: PlannerUser[]) { };
}

export class ExportUsersList implements Action {
    public readonly type = EUsersActions.ExportUsersList;
    constructor();
    constructor(payload: string);
    constructor(public payload?: string) { };
}

export class GetOnlineUsers implements Action {
    public readonly type = EUsersActions.GetOnlineUsers;
}

export class GetOnlineUsersSuccess implements Action {
    public readonly type = EUsersActions.GetOnlineUsersSuccess;
    constructor(public payload: ConnectionInfo[]) { };
}

export class EditUser implements Action {
    public readonly type = EUsersActions.EditUser;
    constructor(public editedUser: PlannerUser, public newPassword: string) { };
}

export class EditUserSuccess implements Action {
    public readonly type = EUsersActions.EditUserSuccess;
    constructor(public payload: PlannerUser) { };
}

export class DeleteUser implements Action {
    public readonly type = EUsersActions.DeleteUser;
    constructor(public payload: string) { };
}

export class DeleteUserSuccess implements Action {
    public readonly type = EUsersActions.DeleteUserSuccess;
    constructor(public payload: string) { };
}

export class CreateCaseWebLinkShares implements Action {
    public readonly type = EUsersActions.CreateCaseWebLinkShares;
    constructor(public payload: CreateWebLinkTokenCommand) { };
}

export class CreateCaseWebLinkSharesSuccess implements Action {
    public readonly type = EUsersActions.CreateCaseWebLinkSharesSuccess;
    constructor(public payload: string) { };
}

export class RevokeCaseWebLinkShares implements Action {
    public readonly type = EUsersActions.RevokeCaseWebLinkShares;
    constructor(public payload: RevokeWebLinkTokenCommand) { };
}

export class RevokeCaseWebLinkSharesSuccess implements Action {
    public readonly type = EUsersActions.RevokeCaseWebLinkSharesSuccess;
    constructor(public payload: string) { };
}

export type UsersActions = GetCurrentUser | GetCurrentUserSuccess |
SetUsersFilter | SetUsersFilterSuccess |
ResetUsersFilter | ResetUsersFilterSuccess |
GetUsers | GetUsersSuccess | ExportUsersList |
    GetOnlineUsers | GetOnlineUsersSuccess | EditUser | EditUserSuccess | DeleteUser | DeleteUserSuccess|
    CreateCaseWebLinkShares | CreateCaseWebLinkSharesSuccess | RevokeCaseWebLinkShares | RevokeCaseWebLinkSharesSuccess;