import { OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { APP_DATE_FORMATS } from 'app/core/helpers';
import { Subject } from 'rxjs';
import { PlannerCaseBundle } from '@appmodels';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
const ɵ0 = APP_DATE_FORMATS;
export class EditBundleComponent {
    constructor(matDialogRef, _data) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        //this.visibilityGroups = this._data.visibilityGroups;
        this.sceneObjects = _.orderBy(this._data.sceneObjects, ['foundationDate', obj => obj.title.toLowerCase()], ['desc', 'asc']);
        this.bundle = this._data.bundle;
        this.bundleEditForm = this.createBundleEditForm(this.bundle); //this.visibilityGroups);
        this._unsubscribeAll = new Subject();
    }
    get visabilityGroupsFormArray() {
        return this.bundleEditForm.get('bundleEdit').controls;
    }
    getArrayGroup(control, name) {
        return control.get(name).controls;
    }
    ngOnInit() {
    }
    createBundleEditForm(bundleObj) {
        const form = new FormGroup({});
        const bundleFormArray = new FormArray([]);
        let bundle = null;
        if (bundleObj)
            bundle = JSON.parse(bundleObj.bundle);
        this.sceneObjects.forEach(sceneObject => {
            let selectedObjectInBundle = false;
            if (bundle) {
                if (bundle.some(e => e.objId === sceneObject.id)) {
                    selectedObjectInBundle = true;
                }
            }
            const bundleFormGroup = new FormGroup({
                objId: new FormControl(sceneObject.id),
                objTitle: new FormControl({ value: sceneObject.title, disabled: true }, Validators.required),
                objCount: new FormControl(1, Validators.required),
                objSelected: new FormControl(selectedObjectInBundle, Validators.required),
            });
            bundleFormArray.push(bundleFormGroup);
        });
        form.addControl("bundleFormGroups", bundleFormArray);
        return form;
    }
    get bundleFormArray() {
        return this.bundleEditForm.get('bundleFormGroups').controls;
    }
    saveBundle() {
        const formArray = this.bundleEditForm.controls['bundleFormGroups'];
        const bundle = [];
        formArray.controls.forEach(bundleFormGroup => {
            const objSelected = bundleFormGroup.get('objSelected').value;
            if (objSelected) {
                const objId = bundleFormGroup.get('objId').value;
                const objTitle = bundleFormGroup.get('objTitle').value;
                // const objCount = bundleFormGroup.get('id').value;
                bundle.push({ objId, objTitle });
            }
        });
        this.matDialogRef.close(['save', bundle]);
    }
    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
export { ɵ0 };
