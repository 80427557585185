

<div class="attachment" >

    <div [matMenuTriggerFor]="sceneObjectMenu">

        <mat-menu #sceneObjectMenu="matMenu" overlapTrigger>      
            
            <button mat-menu-item (click)="editClicked(file)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
            </button>

            <button mat-menu-item (click)="selectClicked(file)">
                <mat-icon>select_check_box</mat-icon>
                <span>Select</span>
            </button>
       
            <button mat-menu-item (click)="deleteClicked(file)">
                <mat-icon>delete_forever</mat-icon>
                <span>Delete</span>
            </button>            

            <button [fxShow]="file.fileType == fileTypeEnum.STLMODEL" mat-menu-item (click)="downloadAsPlyClicked(file)">
                <mat-icon>cloud_download</mat-icon>
                <span>Download as '*ply'</span>
            </button>


        </mat-menu>

        <div (mouseover)="mouseOvered=true"
            (mouseout)="mouseOvered=false">

            <div [ngSwitch]="file.fileType"  [class.light-blue-600]="mouseOvered">

                <img *ngSwitchCase="fileTypeEnum.DICOM" class="preview" src="/assets/images/case/FileTypes/dicom.png">
                <img *ngSwitchCase="fileTypeEnum.DICOMImages" class="preview" src="/assets/images/case/FileTypes/dicomImage.png">
                <img *ngSwitchCase="fileTypeEnum.STLMODEL" class="preview" src="/assets/images/case/FileTypes/stl.png">
                <img *ngSwitchCase="fileTypeEnum.PLANNERMODEL" class="preview" src="/assets/images/case/FileTypes/plannerModel.png">
                <img *ngSwitchCase="fileTypeEnum.PICTURE" class="preview" src="/assets/images/case/FileTypes/picture.png">
                <img *ngSwitchCase="fileTypeEnum.VIDEO" class="preview" src="/assets/images/case/FileTypes/video.png">
                <img *ngSwitchCase="fileTypeEnum.PDF" class="preview" src="/assets/images/case/FileTypes/pdf.png">
                <img *ngSwitchCase="fileTypeEnum.DOC" class="preview" src="/assets/images/case/FileTypes/doc.png">

                <img *ngSwitchCase="fileTypeEnum.TEXTURE" class="preview" src="/assets/images/case/FileTypes/picture.png">
            
            </div>
            
          
        </div>

        <div fxLayout="column">
            <a href="#" onclick="event.preventDefault()" [fxShow]="file.fileType == fileTypeEnum.DICOM">{{file.creationDate | date}}</a>
            <a href="#" onclick="event.preventDefault()" [fxHide]="file.fileType == fileTypeEnum.DICOM">{{file.title}}</a>
            <a href="#" onclick="event.preventDefault()" (click)="downloadClicked(file)">Download</a>            
     
            <div class="size">
                ({{(file.isZipped ? file.fileSizeZipped : file.fileSize)/1024/1024 | number:'1.2-2'}}Mb)</div>
        </div>
    </div>

</div> 