import { RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginComponent } from './pages/authentication/login/login.component';
// import { ContactsComponent1 } from './pages/payments/payments.component';
// import { ContactsService1 } from './pages/payments/payments.service';
import { CasesComponent } from './pages/cases/cases.component';
import { CasesResolver, ChatResolver, PaidModulesResolver, UsersResolver } from './core/resolvers';
import { ChatComponent } from './pages/chat/chat.component';
import { RegisterComponent } from './pages/authentication/register/register.component';
import { UsersComponent } from './pages/users/users.component';
import { AppLogsComponent } from './pages/appLogs/appLogs.component';
import { AppLogsResolver } from './core/resolvers/appLogs.resolver';
import { AdminGuard } from './core/guards/admin.guard';
import { ForgotPasswordComponent } from './pages/authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/authentication/reset-password/reset-password.component';
import { ProjectDashboardComponent } from './pages/dashboards/project/project.component';
import { AnalyticsResolver } from './core/resolvers/analytics.resolver';
import { DownloadsComponent } from './pages/downloads-page/downloads.component';
import { PaidModulesComponent } from './pages/paidModules/paidModules.component';
import { RegisterResolver } from './core/resolvers/register.resolver';
import { BatchOperationsComponent } from './pages/batch-operations/batch-operations.component';
import { CaseManagementComponent } from './pages/caseManagement/caseManagement.component';
import { CaseManagementResolver } from './core/resolvers/caseManagement.resolver';
import { CaseQualitySurveysComponent } from './pages/caseQualitySurveys/caseQualitySurveys.component';
import { CaseQualitySurveysResolver } from './core/resolvers/caseQualitySurveys.resolver';
const ɵ0 = AnalyticsResolver, ɵ1 = CaseManagementResolver, ɵ2 = CaseQualitySurveysResolver;
//import { MailService } from './modules/pages/mail/mail.service';
const appRoutes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'register',
        component: RegisterComponent,
        resolve: {
            RegisterResolver
        }
    },
    {
        path: 'register/:organization',
        component: RegisterComponent,
        resolve: {
            RegisterResolver
        }
    },
    {
        path: 'forgotpassword',
        component: ForgotPasswordComponent
    },
    {
        path: 'resetpassword',
        component: ResetPasswordComponent
    },
    {
        path: 'downloads',
        component: DownloadsComponent
    },
    {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuard, AdminGuard],
        resolve: {
            UsersResolver
        }
    },
    {
        path: 'paidModules',
        component: PaidModulesComponent,
        canActivate: [AuthGuard, AdminGuard],
        resolve: {
            PaidModulesResolver
        }
    },
    {
        path: 'applogs',
        component: AppLogsComponent,
        canActivate: [AuthGuard, AdminGuard],
        resolve: {
            AppLogsResolver
        }
    },
    {
        path: 'dashboard',
        component: ProjectDashboardComponent,
        canActivate: [AuthGuard, AdminGuard],
        resolve: {
            data: ɵ0
        }
    },
    {
        path: 'casemanagement',
        component: CaseManagementComponent,
        canActivate: [AuthGuard, AdminGuard],
        resolve: {
            data: ɵ1
        }
    },
    {
        path: 'casequalitysurveys',
        component: CaseQualitySurveysComponent,
        canActivate: [AuthGuard, AdminGuard],
        resolve: {
            data: ɵ2
        }
    },
    {
        path: 'cases/:folderHandle',
        component: CasesComponent,
        canActivate: [AuthGuard, AdminGuard],
        resolve: {
            CasesResolver
        }
    },
    {
        path: 'cases/:folderHandle/:caseId',
        component: CasesComponent,
        canActivate: [AuthGuard, AdminGuard],
        resolve: {
            CasesResolver
        }
    },
    {
        path: 'batch-operations',
        component: BatchOperationsComponent,
        canActivate: [AuthGuard, AdminGuard],
        resolve: {}
    },
    {
        path: 'chat',
        component: ChatComponent,
        canActivate: [AuthGuard, AdminGuard],
        resolve: {
            ChatResolver
        }
    },
    {
        path: 'chat/:caseId',
        component: ChatComponent,
        canActivate: [AuthGuard, AdminGuard],
        resolve: {
            ChatResolver
        }
    },
    //   { path: 'mail', redirectTo: 'mail/own' },
    // otherwise redirect to home
    { path: 'cases', redirectTo: 'cases/all' },
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
];
export const routing = RouterModule.forRoot(appRoutes);
export { ɵ0, ɵ1, ɵ2 };
