import { NgModule } from '@angular/core';
import { LoginModule } from './authentication/login/login.module';
import { RegisterModule } from './authentication/register/register.module';
import { ForgotPasswordModule } from './authentication/forgot-password/forgot-password.module';
import { ResetPasswordModule } from './authentication/reset-password/reset-password.module';
import { LockModule } from './authentication/lock/lock.module';
import { MailConfirmModule } from './authentication/mail-confirm/mail-confirm.module';

import { CasesModule } from './cases/cases.module';
import { ChatModule } from './chat/chat.module';
import { UsersModule } from './users/users.module';
import { AppLogsModule } from './appLogs/appLogs.module';
import { Error403Module } from './errors/403/error-403.module';
import { ProjectDashboardModule } from './dashboards/project/project.module';
import { DownloadsModule } from './downloads-page/downloads.module';
import { PaidModulesModule } from './paidModules/paidModules.module';
import { BatchOperationsModule } from './batch-operations/batch-operations.module';
import { CaseManagementModule } from './caseManagement/caseManagement.module';
import { CaseQualitySurveysModule } from './caseQualitySurveys/caseQualitySurveys.module';


@NgModule({
  imports: [
    LoginModule,
    RegisterModule,
    ForgotPasswordModule,
    ResetPasswordModule,
    LockModule,
    MailConfirmModule,
    ProjectDashboardModule,
    CaseManagementModule,
    CaseQualitySurveysModule,
    CasesModule,
    BatchOperationsModule,
    ChatModule,
    UsersModule,
    PaidModulesModule,
    AppLogsModule,
    DownloadsModule,
    Error403Module
  ]
})
export class PagesModule {

}
