import { FilesService } from 'app/core/services/planner/files.sevice';
import { DeleteFile, EFilesActions, DeleteFileSuccess, EditFile, EditFileSuccess, EditFilesPermissions } from '../actions/files.actions';
import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { selectSelectedCase } from '../selectors/cases.selector';

import { SelectCase } from '../actions/cases.actions';
import { VoidAction } from '../actions/app.actions';
import { NewNotification } from '../actions/notifications.actions';
import { DeletePlannerFileCommand, EditPlannerFileCommand, EditPlannerFilePermissionsCommand } from '@appmodels';
import { ObjectMapper } from 'app/core/helpers';

@Injectable()
export class FilesEffects {

    @Effect()
    editFile$ = this._actions$.pipe(
        ofType<EditFile>(EFilesActions.EditFile),
        map(action => action.payload),
        withLatestFrom(this._store.select(selectSelectedCase)),
        switchMap(([fileToEdit, selectedCase]) => {

            const editFileCommand = new EditPlannerFileCommand();

            ObjectMapper.mapObjectToObject(fileToEdit, editFileCommand)

            editFileCommand.caseId = selectedCase.id;

            return this._filesService.editFile(editFileCommand).pipe(
                // tap(res => console.log(res)),
                switchMap(result => {

                    if (result.isSuccess) {
                        this._store.dispatch(new NewNotification({ type: 'FileEdit', message: 'File edit success!', action: 'Ok' }));

                        return of(selectedCase);
                    }
                    else {

                        this._store.dispatch(new NewNotification({ type: 'FileEdit', message: 'File edit error! See the error in the console log.', action: 'Error' }))

                        return of(null);
                    }
                })

            );
        }),
        switchMap((selectedCase) => { return [selectedCase != null ? (new SelectCase(selectedCase.id)) : (new VoidAction())]; })
    );

    @Effect()
    deleteFile$ = this._actions$.pipe(
        ofType<DeleteFile>(EFilesActions.DeleteFile),
        map(action => action.payload),
        withLatestFrom(this._store.select(selectSelectedCase)),
        switchMap(([fileToDelete, selectedCase]) => {

            const deleteFileCommand = new DeletePlannerFileCommand();
            deleteFileCommand.fileId = fileToDelete.id;
            deleteFileCommand.caseId = selectedCase.id;

            return this._filesService.deleteFile(deleteFileCommand).pipe(
                // tap(res => console.log(res)),
                switchMap(result => {

                    if (result.isSuccess) {
                        this._store.dispatch(new NewNotification({ type: 'FileDelete', message: 'File delete success!', action: 'Ok' }));
                        return of(selectedCase);
                    }
                    else {

                        this._store.dispatch(new NewNotification({ type: 'FileDelete', message: 'File delete error! See the error in the console log.', action: 'Error' }))

                        return of(null);
                    }
                })

            );
        }),
        switchMap((selectedCase) => { return [selectedCase != null ? (new SelectCase(selectedCase.id)) : (new VoidAction())]; })
    );

    @Effect()
    editFilesPermissions$ = this._actions$.pipe(
        ofType<EditFilesPermissions>(EFilesActions.EditFilesPermissions),
        map(action => action.payload),
        withLatestFrom(this._store.select(selectSelectedCase)),
        switchMap(([filesAllowUserDownloadAction, selectedCase]) => {

            const editPlannerFilePermissionsCommand = new EditPlannerFilePermissionsCommand();

            editPlannerFilePermissionsCommand.caseId = selectedCase.id;

            editPlannerFilePermissionsCommand.filesAllowUserDownload = filesAllowUserDownloadAction;

            return this._filesService.editPlannerFilePermissions(editPlannerFilePermissionsCommand).pipe(

                switchMap(result => {

                    if (result.isSuccess) {
                        this._store.dispatch(new NewNotification({ type: 'EditFilesPermissions', message: 'Edit files permissions success!', action: 'Ok' }));
                        return of(selectedCase);
                    }
                    else {

                        this._store.dispatch(new NewNotification({ type: 'EditFilesPermissions', message: 'Edit files permissions error! See the error in the console log.', action: 'Error' }))

                        return of(null);
                    }
                })

            );
        }),
        switchMap((selectedCase) => { return [selectedCase != null ? (new SelectCase(selectedCase.id)) : (new VoidAction())]; })
    );



    constructor(
        private _filesService: FilesService,
        private _actions$: Actions,
        private _store: Store<IAppState>
    ) { }

}