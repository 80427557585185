import { initialCasesState, ICasesState } from '../state/cases.interface';
import { CasesActions, ECasesActions } from '../actions/cases.actions';

export function casesReducers(
    state = initialCasesState,
    action: CasesActions
): ICasesState {
    switch (action.type) {
        case ECasesActions.SetFiltersFromSettingsSuccess:
            return {
                ...state,
                modulesFilter: action.modulesTypes
            }
        case ECasesActions.SetModulesTypeSuccess:
            return {
                ...state,
                modulesFilter: action.modulesTypes,
                cases: [],
                selectedCase: null
            }
        case ECasesActions.SetQCFilterSuccess:
            return {
                ...state,
                forQualityControl: action.payload,
                cases: [],
                selectedCase: null
            }
        case ECasesActions.SetCasesFolderSuccess:
            return {
                ...state,
                folder: action.folderType,
                sortType: action.sortType,
                notAdmins: false,
                onlyUncompleted: false,
                onlyWithGuideOrImplant: false,
                searchPattern: null,
                cases: [],
                selectedCase: null
            }
        case ECasesActions.SetCasesSearchPatternSuccess:
            return {
                ...state,
                searchPattern: action.payload,
                cases: [],
                selectedCase: null
            }
        case ECasesActions.SetCasesFilterParamsSuccess:
            return {
                ...state,
                folder: action.folder,
                modulesFilter: action.modulesFilter,
                notAdmins: action.notAdmins,
                onlyUncompleted: action.onlyUncompleted,
                onlyWithGuideOrImplant: action.onlyWithGuideOrImplant,
                searchPattern: action.searchPattern,
                cases: [],
                selectedCase: null
            }
        case ECasesActions.GetCasesSuccess:
            {
                const existedCases = action.isReload ? [] : (state.cases ? state.cases : []);

                const newCases = existedCases.concat(action.cases);

                console.log(newCases);

                return {
                    ...state,
                    cases: newCases
                }
            }
        case ECasesActions.GetCaseSuccess:
            {
                var existedCases = state.cases;

                const existedCaseIndex = existedCases.findIndex(x => x.id == action.payload.id);

                if (existedCaseIndex >= 0) {

                    var clonnedCases = [].concat(existedCases);

                    clonnedCases.splice(existedCaseIndex, 1, action.payload);

                    const selectedCase = state.selectedCase.id == action.payload.id ? action.payload : state.selectedCase;

                    //console.log(selectedCase);

                    return {
                        ...state,
                        cases: clonnedCases,
                        selectedCase: selectedCase
                    }
                }
                else {
                    return {
                        ...state
                    }
                }
            }

        case ECasesActions.SelectCaseSuccess:
            const newSelectedCase = action.payload;
            if (state.selectedCase) {
                const selectedCaseScenes = state.selectedCase.scenes;
                newSelectedCase.scenes = selectedCaseScenes;
            }
            return {
                ...state,
                selectedCase: newSelectedCase
            }
        case ECasesActions.DeselectCaseSuccess:
            return {
                ...state,
                selectedCase: null
            }
        case ECasesActions.CreateCaseSuccess:
            return {
                ...state,
                cases: []
            }
        case ECasesActions.EditCaseSuccess:
            return {
                ...state,
                cases: []
            }
        case ECasesActions.CloneCaseSuccess:
            return {
                ...state,
                cases: []
            }
        case ECasesActions.CloneCaseBatchSuccess:
            const cloneBatchTaskInfo = action.payload;
            return {
                ...state,
                cases: [],
                cloneBatchTaskInfo: cloneBatchTaskInfo
            }
        case ECasesActions.GetSelectedCaseSharesSuccess:
            return {
                ...state,
                selectedCaseShares: action.payload
            }
        case ECasesActions.DeleteCaseSuccess:
            return {
                ...state,
                cases: []
            }
        case ECasesActions.GetSelectedCaseScenesSuccess:
            const caseScenes = action.payload;
            const selectedCase = { ...state.selectedCase };
            selectedCase.scenes = caseScenes;
            return {
                ...state,
                selectedCase: selectedCase
            }
        case ECasesActions.SelectSceneSuccess:
            return {
                ...state,
                selectedScene: action.payload
            }
        // case ECasesActions.AddSceneObjectsSuccess:
        //     return {
        //         ...state
        //     }
        case ECasesActions.AddSceneObjectSuccess:
            const selectedScene1 = { ...state.selectedScene };
            selectedScene1.sceneObjects.push(action.payload);
            return {
                ...state,
                selectedScene: selectedScene1
            }
        case ECasesActions.EditSceneObjectSuccess:
            const selectedScene2 = { ...state.selectedScene };
            const objectIndex1 = selectedScene2.sceneObjects.indexOf(action.payload);
            selectedScene2.sceneObjects[objectIndex1] = { ...action.payload };
            return {
                ...state,
                selectedScene: selectedScene2
            }
        case ECasesActions.DeleteSceneObjectSuccess:
            const selectedScene3 = { ...state.selectedScene };
            const objectIndex2 = selectedScene3.sceneObjects.indexOf(action.payload);
            selectedScene3.sceneObjects.splice(objectIndex2, 1);
            return {
                ...state,
                selectedScene: selectedScene3
            }
        case ECasesActions.SaveSceneSuccess:
            return {
                ...state
            }
        case ECasesActions.RollbackSceneSuccess:
            return {
                ...state
            }
        case ECasesActions.SetCaseStatusSuccess:

            if (action.isRemoveCaseFromList) {
                const cases = state.cases.filter(x => x.id != action.caseId);

                return {
                    ...state,
                    cases
                }
            }

            return {
                ...state
            }
        case ECasesActions.GetCaseBundleSuccess:
            return {
                ...state,
                selectedCaseBundle: action.payload
            }
        case ECasesActions.SaveCaseBundleSuccess:

            const newCaseBundle = {
                ...state.selectedCaseBundle,
                bundle: action.payload
            }           

            return {
                ...state,
                selectedCaseBundle: newCaseBundle
            }
        case ECasesActions.CaseMessagesReadedSuccess:
            {
                let caseId = action.payload;

                let existedCases = state.cases;

                if (existedCases && existedCases.length > 0) {
                    existedCases.forEach(existedCase => {

                        if (existedCase.id == caseId) {
                            existedCase.hasNewMessages = false;
                            existedCase.newMessagesCount = 0;
                        }

                    });
                }

                return {
                    ...state
                }
            }
        case ECasesActions.CaseReceivedNewMessageSuccess:
            {
                let caseId = action.caseId;

                let existedCases = state.cases;

                if (existedCases && existedCases.length > 0) {
                    existedCases.forEach(existedCase => {
                        if (existedCase.id == caseId) {
                            existedCase.lastMessageDate = action.newMessage.creationDate;
                            existedCase.lastMessageText = action.newMessage.text;

                            if (action.isNewMessage) {

                                existedCase.hasNewMessages = true;

                                if (!existedCase.newMessagesCount)
                                    existedCase.newMessagesCount = 0;

                                existedCase.newMessagesCount++;
                            }
                        }
                    });

                    // if (existedCases[0].id != caseId) {
                    existedCases.sort((a, b) => {
                        if (a.lastMessageDate > b.lastMessageDate) {
                            return -1;
                        }
                        if (a.lastMessageDate < b.lastMessageDate) {
                            return 1;
                        }
                        return 0;
                    });
                    // }
                }
            }

        default:
            return state;
    }
}